import { useState, useMemo, useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { Box, Pagination } from '@mui/material';
import { Link } from 'react-router-dom';

import { Container } from '../../../commonStyles';
import BasicTabs from '../../common/Tabs';
import StandardTable from '../../tables/StandardTable';
import { IDocuments } from '../../../types/docusign';
import { numToCurrency, convertAllDates } from '../../../utils/Functions';
import { IPurchaserInfo } from '../../../types/CreateDealForm';

const Agreements = () => {
  const [value, setValue] = useState<number>(0);
  const [data, setData] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    if (!value) {
      getAgreements({ variables: { type: 'all', search, page } });
    } else if (value === 1) {
      getAgreements({ variables: { type: 'tenancy', search, page } });
    } else if (value === 2) {
      getAgreements({ variables: { type: 'listing', search, page } });
    } else if (value === 3) {
      getAgreements({ variables: { type: 'management', search, page } });
    }
  }, [value, search, page]);

  const [getAgreements, { loading }] = useLazyQuery(GETAGREEMENTS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      setData(data.getAgreements.items);
      setCount(data.getAgreements.count);
    },
    onError: (err: any) => {
      console.log(err, 'err');
    },
  });

  const allColumns = useMemo(() => {
    return [
      {
        Header: 'Project',
        accessor: (rowData: any) =>
          rowData.project && rowData.project.name === 'Leasing' ? rowData.unit.projectName : rowData.project.name,
      },
      {
        Header: 'Suite',
        accessor: (rowData: any) => {
          return (
            <Link
              style={{ textDecoration: 'none', color: 'inherit' }}
              rel="noopener noreferrer"
              target="_blank"
              to={`/${rowData.project._id}/dashboard/${rowData.unit._id}`}
            >
              <strong style={{ cursor: 'pointer' }}>{rowData.unit.suite}</strong>
            </Link>
          );
        },
      },
      {
        Header: 'Tenancy',
        accessor: (rowData: any) => {
          if (rowData.tenancy) {
            if (rowData.documents.length) {
              let completedTenancy = rowData.documents.find(
                (document: IDocuments) => document.type === 'Tenancy' && document.status === 'Completed'
              );
              if (completedTenancy) {
                return `${convertAllDates(rowData.tenancy.dateStart, 'PP')} - ${convertAllDates(rowData.tenancy.dateEnd, 'PP')}`;
              } else return 'Pending';
            } else return 'Pending';
          } else return 'None';
        },
      },
      {
        Header: 'Listing',
        accessor: (rowData: any) => {
          if (rowData.listing) {
            if (rowData.documents.length) {
              let completedListing = rowData.documents.find(
                (document: IDocuments) => document.type === 'Listing' && document.status === 'Completed'
              );
              if (completedListing) {
                return `${convertAllDates(rowData.listing.dateStart, 'PP')} - ${convertAllDates(rowData.listing.dateEnd, 'PP')}`;
              } else return 'Pending';
            } else return 'Pending';
          } else return 'None';
        },
      },
      {
        Header: 'Management',
        accessor: (rowData: any) => {
          if (rowData.management) {
            if (rowData.documents.length) {
              let completedManagement = rowData.documents.find(
                (document: IDocuments) => document.type === 'Management' && document.status === 'Completed'
              );
              if (completedManagement) {
                return 'Completed';
              } else return 'Pending';
            } else return 'Pending';
          } else return 'None';
        },
      },
    ];
  }, []);

  const tenancyColumns = useMemo(() => {
    return [
      {
        Header: 'Project',
        accessor: (rowData: any) =>
          rowData.project && rowData.project.name === 'Leasing' ? rowData.unit.projectName : rowData.project.name,
      },
      {
        Header: 'Suite',
        accessor: (rowData: any) => {
          return (
            <Link
              style={{ textDecoration: 'none', color: 'inherit' }}
              rel="noopener noreferrer"
              target="_blank"
              to={`/${rowData.project._id}/dashboard/${rowData.unit._id}`}
            >
              <strong style={{ cursor: 'pointer' }}>{rowData.unit.suite}</strong>
            </Link>
          );
        },
      },
      {
        Header: 'Status',
        accessor: (rowData: any) => {
          if (rowData.documents.length) {
            let completedTenancy = rowData.documents.find(
              (document: IDocuments) => document.type === 'Tenancy' && document.status === 'Completed'
            );
            if (completedTenancy) {
              return 'Completed';
            } else return 'Pending';
          } else return 'Pending';
        },
      },
      {
        Header: 'Tenancy Start Date',
        accessor: (rowData: any) => (rowData.tenancy ? convertAllDates(rowData.tenancy.dateStart, 'PP') : ''),
      },
      {
        Header: 'Tenancy End Date',
        accessor: (rowData: any) => (rowData.tenancy ? convertAllDates(rowData.tenancy.dateEnd, 'PP') : ''),
      },
      {
        Header: 'Amount',
        accessor: (rowData: any) => (rowData.tenancy ? numToCurrency.format(rowData.tenancy.amount) : ''),
      },
      {
        Header: 'Landlords',
        accessor: (rowData: any) => {
          if (rowData.landlords && rowData.landlords.length) {
            return rowData.landlords.map((purchaser: IPurchaserInfo) => purchaser.fullName).join(', ');
          } else return '-';
        },
      },
      {
        Header: 'Tenants',
        accessor: (rowData: any) => {
          if (rowData.tenancy && rowData.tenancy.tenants.length) {
            return rowData.tenancy.tenants.map((tenant: IPurchaserInfo) => tenant.fullName).join(', ');
          } else return '-';
        },
      },
      {
        Header: 'Active',
        accessor: (rowData: any) => (rowData.active ? 'Active' : 'Inactive'),
      },
    ];
  }, []);

  const listingColumns = useMemo(() => {
    return [
      {
        Header: 'Project',
        accessor: (rowData: any) =>
          rowData.project && rowData.project.name === 'Leasing' ? rowData.unit.projectName : rowData.project.name,
      },
      {
        Header: 'Suite',
        accessor: (rowData: any) => {
          return (
            <Link
              style={{ textDecoration: 'none', color: 'inherit' }}
              rel="noopener noreferrer"
              target="_blank"
              to={`/${rowData.project._id}/dashboard/${rowData.unit._id}`}
            >
              <strong style={{ cursor: 'pointer' }}>{rowData.unit.suite}</strong>
            </Link>
          );
        },
      },
      {
        Header: 'Status',
        accessor: (rowData: any) => {
          if (rowData.documents.length) {
            let completedListing = rowData.documents.find(
              (document: IDocuments) => document.type === 'Listing' && document.status === 'Completed'
            );
            if (completedListing) {
              return 'Completed';
            } else return 'Pending';
          } else return 'Pending';
        },
      },
      {
        Header: 'Listing Start Date',
        accessor: (rowData: any) => (rowData.listing ? convertAllDates(rowData.listing.dateStart, 'PP') : ''),
      },
      {
        Header: 'Listing End Date',
        accessor: (rowData: any) => (rowData.listing ? convertAllDates(rowData.listing.dateEnd, 'PP') : ''),
      },
      {
        Header: 'Amount',
        accessor: (rowData: any) => (rowData.listing ? numToCurrency.format(rowData.listing.amount) : ''),
      },
      {
        Header: 'Landlords',
        accessor: (rowData: any) => {
          if (rowData.landlords && rowData.landlords.length) {
            return rowData.landlords.map((purchaser: IPurchaserInfo) => purchaser.fullName).join(', ');
          } else return '-';
        },
      },
      {
        Header: 'Active',
        accessor: (rowData: any) => (rowData.active ? 'Active' : 'Inactive'),
      },
    ];
  }, []);

  const managementColumns = useMemo(() => {
    return [
      {
        Header: 'Project',
        accessor: (rowData: any) =>
          rowData.project && rowData.project.name === 'Leasing' ? rowData.unit.projectName : rowData.project.name,
      },
      {
        Header: 'Suite',
        accessor: (rowData: any) => (rowData.listing ? rowData.unit.suite : ''),
      },
      {
        Header: 'Status',
        accessor: (rowData: any) => {
          if (rowData.documents.length) {
            let completedListing = rowData.documents.find(
              (document: IDocuments) => document.type === 'Management' && document.status === 'Completed'
            );
            if (completedListing) {
              return 'Completed';
            } else return 'Pending';
          } else return 'Pending';
        },
      },
      {
        Header: 'REC',
        accessor: (rowData: any) => (rowData.rec ? 'REC' : 'No REC'),
      },
      {
        Header: 'Active',
        accessor: (rowData: any) => (rowData.active ? 'Active' : 'Inactive'),
      },
    ];
  }, []);

  const handleGlobalFilterValue = (value: string) => {
    setPage(1);
    setSearch(value);
  };

  const tabs = useMemo(() => {
    return [
      {
        label: 'All',
        component: (
          <Box sx={{ p: 2 }}>
            <StandardTable
              loading={loading}
              columns={allColumns}
              data={data}
              handleGlobalFilterValue={handleGlobalFilterValue}
              count={count}
            />
          </Box>
        ),
      },
      {
        label: 'Tenancy',
        component: (
          <Box sx={{ p: 2 }}>
            <StandardTable
              loading={loading}
              columns={tenancyColumns}
              data={data}
              handleGlobalFilterValue={handleGlobalFilterValue}
              count={count}
            />
          </Box>
        ),
      },
      {
        label: 'Listing',
        component: (
          <Box sx={{ p: 2 }}>
            <StandardTable
              loading={loading}
              columns={listingColumns}
              data={data}
              handleGlobalFilterValue={handleGlobalFilterValue}
              count={count}
            />
          </Box>
        ),
      },
      {
        label: 'Rental Management',
        component: (
          <Box sx={{ p: 2 }}>
            <StandardTable
              loading={loading}
              columns={managementColumns}
              data={data}
              handleGlobalFilterValue={handleGlobalFilterValue}
              count={count}
            />
          </Box>
        ),
      },
    ];
  }, [data, tenancyColumns, listingColumns, managementColumns]);

  return (
    <Container sx={{ backgroundColor: '#fff', pt: 0, px: 0 }}>
      <BasicTabs color="secondary.main" tabs={tabs} sub={false} value={value} setValue={setValue} />
      <Box sx={{ mt: 2 }}>
        <Pagination
          sx={{ '& .MuiPagination-ul': { justifyContent: 'center' } }}
          color="secondary"
          onChange={(e: any, page: any) => {
            setPage(page);
          }}
          count={Math.ceil(count / 15)}
          page={page}
        />
      </Box>
    </Container>
  );
};

const GETAGREEMENTS = gql`
  query getAgreements($type: String!, $search: String, $page: Int!) {
    getAgreements(type: $type, search: $search, page: $page) {
      count
      items {
        _id
        active
        project {
          _id
          name
        }
        unit {
          _id
          suite
          projectName
        }
        documents {
          type
          status
        }
        landlords {
          fullName
        }
        tenancy {
          project {
            _id
            name
          }
          _id
          tenants {
            fullName
          }
          dateStart
          dateEnd
          amount
        }
        listing {
          _id
          dateStart
          dateEnd
          amount
        }
        management {
          _id
          unit {
            suite
            projectName
          }
          project {
            _id
            name
          }
          rec
          executor {
            fullName
          }
        }
      }
    }
  }
`;

export default Agreements;
