import { useState, useContext, useMemo } from 'react';
import axios from 'axios';
import { Buffer } from 'buffer';
import { gql, useMutation } from '@apollo/client';
import { Typography, Box, TextField, Select, FormControl, MenuItem, InputLabel, InputAdornment, Button, Autocomplete } from '@mui/material';
import Dropzone, { useDropzone } from 'react-dropzone';

import { IUnit } from '../../../types/unit';
import Unit from '../../common/Unit';
import { useSelector } from 'react-redux';
import { selectProject } from '../../../features/project/projectSlice';
import { FlexBetween } from '../../../commonStyles';
import { handleModal } from '../../../features/modal/modalSlice';
import { useAppDispatch } from '../../../app/hooks';
import { showErrorSnackbar } from '../../../features/snackbar/snackbarSlice';
import { PriceGridContext } from '../../../context/PriceGridContext';
import PdfCard from '../../common/PdfCard';
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from '../../../utils/Constants';

const CreateUnit = () => {
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const { units, setUnits, displayedInfo, setDisplayedInfo, setModalType } = useContext(PriceGridContext);
  const [suite, setSuite] = useState<string>('');
  const [level, setLevel] = useState<string>('');
  const [rental, setRental] = useState<string | null>('');
  const [unitNumber, setUnitNumber] = useState<string>('');
  const [modelType, setModelType] = useState<string>('');
  const [unitType, setUnitType] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [size, setSize] = useState<string>('');
  const [basePrice, setBasePrice] = useState<string>('');
  const [bathroom, setBathroom] = useState<string>('');
  const [exposure, setExposure] = useState<string>('');
  const [outdoorSize, setOutdoorSize] = useState<string>('');
  const [outdoorType, setOutdoorType] = useState<string>('');
  const [maintenanceFee, setMaintenanceFee] = useState<string>('');
  const [col, setCol] = useState<string>('');
  const [row, setRow] = useState<string>('');
  const [projectName, setProjectName] = useState<string>('');
  const [file, setFile] = useState<any>(null);
  const [fileImage, setFileImage] = useState<any>(null);

  const { isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'application/pdf',
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const [createUnit] = useMutation(CREATEUNIT, {
    onCompleted: async (data) => {
      let allUnits = [...units, data.unitCreateOne.record];
      setDisplayedInfo({
        ...displayedInfo,
        rental: allUnits.some((unit: IUnit) => unit.rental),
        tier: allUnits.some((unit: IUnit) => unit.tier),
      });
      setUnits(allUnits);
      if (file) {
        const buffer = Buffer.from(fileImage.replace(/^data:application\/\w+;base64,/, ''), 'base64');
        const options = {
          headers: { 'Content-Type': file.type },
          maxContentLength: Infinity,
          maxBodyLength: Infinity,
        };
        await axios.put(data.unitCreateOne.record.putUrl, buffer, options);
      }
      storeDispatch(handleModal(false));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const handleDropdown = (e: any) => {
    if (e.target.name === 'exposure') {
      setExposure(e.target.value);
    }
    if (e.target.name === 'outdoorType') {
      setOutdoorType(e.target.value);
    }
    if (e.target.name === 'unitType') {
      setUnitType(e.target.value);
    }
  };

  const handleDrop = (acceptedFiles: any) => {
    const file = acceptedFiles[0];
    const fileReader = new FileReader();
    if (file) {
      fileReader.readAsDataURL(file);
    }
    fileReader.onloadend = async () => {
      setFileImage(fileReader.result);
      setFile(acceptedFiles[0]);
    };
  };

  const handleSaveUnit = (e: any) => {
    e.preventDefault();

    if (parseInt(col, 10) < 0) return storeDispatch(showErrorSnackbar('Column cannot be less than 0'));
    if (parseInt(row, 10) < 0) return storeDispatch(showErrorSnackbar('Row cannot be less than 0'));

    let duplicateSuite = units.some((unit: IUnit) => unit.suite === suite);
    if (duplicateSuite) return storeDispatch(showErrorSnackbar('Duplicate Suite Number'));
    let duplicateLevelUnit = units.some((unit: IUnit) => unit.level === level && unit.unit === unitNumber);
    if (duplicateLevelUnit) return storeDispatch(showErrorSnackbar('Duplicate Level and Unit Number'));
    let duplicateColRow = units.some((unit: IUnit) => unit.col === parseInt(col, 10) && unit.row === parseInt(row, 10));
    if (duplicateColRow) return storeDispatch(showErrorSnackbar('Duplicate Column and Row'));

    createUnit({
      variables: {
        record: {
          project: project._id,
          status: 'A',
          suite,
          level,
          rental: parseInt(size, 10),
          unit: unitNumber,
          size: parseInt(size, 10),
          modelType,
          unitType,
          bathroom: parseInt(bathroom, 10),
          exposure,
          outdoorSize: outdoorSize ? parseInt(outdoorSize, 10) : 0,
          outdoorType,
          col: parseInt(col, 10),
          row: parseInt(row, 10),
          basePrice: parseInt(basePrice, 10),
          originalPrice: parseInt(basePrice, 10),
          projectName: projectName,
          type,
          notes: [],
        },
      },
    });
  };

  const handleDelete = () => {
    setFile(null);
    setFileImage(null);
  };

  return (
    <div>
      <Typography variant="h2">
        <strong>Create Unit</strong>
      </Typography>
      <form id="unitForm" onSubmit={handleSaveUnit}>
        <TextField
          sx={{ my: 2 }}
          title={'Suite'}
          name={'suite'}
          fullWidth
          value={suite}
          label={'Suite'}
          required
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSuite(e.target.value)}
        />
        <Unit
          level={level}
          setLevel={setLevel}
          unitNumber={unitNumber}
          setUnitNumber={setUnitNumber}
          rental={rental}
          setRental={setRental}
          size={size}
          setSize={setSize}
          modelType={modelType}
          setModelType={setModelType}
          handleDropdown={handleDropdown}
          unitType={unitType}
          bathroom={bathroom}
          setBathroom={setBathroom}
          exposure={exposure}
          outdoorSize={outdoorSize}
          setOutdoorSize={setOutdoorSize}
          outdoorType={outdoorType}
          maintenanceFee={maintenanceFee}
          setMaintenanceFee={setMaintenanceFee}
        />
        <TextField
          sx={{ mt: 2 }}
          title={'Base Price'}
          type="number"
          onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          name={'suite'}
          fullWidth
          value={basePrice}
          label={'Base Price'}
          required
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBasePrice(e.target.value)}
        />
        {project._id === '65bd404201d843802bb37a51' ? (
          <Autocomplete
            options={[...new Set(units.map((unit: IUnit) => unit.projectName))]}
            getOptionLabel={(option: any) => option}
            title={'Project Name'}
            value={projectName}
            freeSolo={true}
            onChange={(e, value: any) => setProjectName(value)}
            onInputChange={(e, value, reason) => {
              setProjectName(value);
            }}
            renderInput={(params) => <TextField {...params} sx={{ mt: 2 }} variant="outlined" label="Project Name" />}
          />
        ) : null}
        <FormControl required sx={{ width: '100%', mt: 2 }}>
          <InputLabel id="demo-simple-select-label">Suite Type</InputLabel>
          <Select
            label="Suite Type"
            sx={{ width: '100%' }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={type}
            required
            onChange={(e) => setType(e.target.value)}
          >
            <MenuItem value={'residential'}>Residential</MenuItem>
            <MenuItem value={'commercial'}>Commercial</MenuItem>
          </Select>
        </FormControl>
        <TextField
          sx={{ mt: 2 }}
          title={'Row'}
          name={'row'}
          type="number"
          onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          fullWidth
          value={row}
          label={'Row (Row 1 will start from bottom)'}
          required
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRow(e.target.value)}
        />
        <TextField
          sx={{ mt: 2 }}
          title={'Column'}
          name={'col'}
          type="number"
          onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          fullWidth
          value={col}
          label={'Column (Column 1 will start from left)'}
          required
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCol(e.target.value)}
        />
        <div>
          {!file ? (
            <Dropzone onDrop={handleDrop} accept="application/pdf">
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <p>Drag and Drop or Upload the Legal Floor Plan</p>
                  </div>
                </section>
              )}
            </Dropzone>
          ) : (
            <Box sx={{ mt: 2 }}>
              <PdfCard file={fileImage} title={suite ? suite : 'Floorplan'} id={'0'} handleDelete={handleDelete} download={true} />
            </Box>
          )}
        </div>
        <FlexBetween>
          <Button
            onClick={() => {
              setModalType('');
              storeDispatch(handleModal(false));
            }}
            sx={{ mt: 2 }}
            color="error"
            variant="contained"
          >
            Cancel
          </Button>
          <Button type="submit" sx={{ mt: 2 }} color="success" variant="contained">
            Create
          </Button>
        </FlexBetween>
      </form>
    </div>
  );
};

const CREATEUNIT = gql`
  mutation unitCreateOne($record: CreateOneUnitInput!) {
    unitCreateOne(record: $record) {
      record {
        _id
        suite
        modelType
        modelName
        unitType
        bathroom
        size
        unit
        outdoorType
        basePrice
        level
        exposure
        outdoorSize
        col
        row
        stackCol
        stackRow
        status
        allocation {
          fullName
          _id
        }
        getUrl
        allocatedDate
        tempAllocation
        custom
        flag
        tier
        rental
        type
        putUrl
        projectName
      }
    }
  }
`;

export default CreateUnit;
