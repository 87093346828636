import { useState } from 'react';
import { Link } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../app/hooks';
import { selectProject } from '../../features/project/projectSlice';
import { showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';
import { dateDiffInDays, convertAllDates } from '../../utils/Functions';
import StandardTable from '../tables/StandardTable';
import { IDeal } from '../../types/CreateDealForm';
import { handleModal } from '../../features/modal/modalSlice';

const ExtendedConditionalModal = (props: ChildProps) => {
  const { displayedRows, onClose, handleFirmDateChange } = props;
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);

  const [newFirmingDate, setNewFirmingDate] = useState<Date[]>(displayedRows.map((row) => new Date(row.deal.firmDate)));
  const [openConfirm, setOpenConfirm] = useState(false);
  const [extendedBy, setExtendedBy] = useState(displayedRows.map((row) => 0));

  const [updateDeal] = useMutation(UPDATEDEAL, {
    onCompleted: (data) => {
      storeDispatch(handleModal(false));
      storeDispatch(showSuccessSnackbar('Firm Date Updated!'));
      setOpenConfirm(false);
      onClose(data.dealUpdateById.record.unit.suite, data.dealUpdateById.record.firmDate);
      handleFirmDateChange(data.dealUpdateById.record);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const columns = [
    {
      Header: 'Suite',
      accessor: (rowData: any) => {
        return (
          <strong>
            <Link
              rel="noopener noreferrer"
              target="_blank"
              style={{ textDecoration: 'none', color: 'inherit' }}
              to={`/${project._id}/dashboard/${rowData.unit._id}`}
            >
              {rowData.suite}
            </Link>
          </strong>
        );
      },
    },
    {
      Header: 'Signing Date',
      accessor: (rowData: any) => {
        return convertAllDates(rowData.deal.signDate, 'PP');
      },
    },
    {
      Header: 'Firm Date',
      accessor: (rowData: any) => {
        return convertAllDates(rowData.deal.firmDate, 'PP');
      },
    },
    {
      Header: 'Extended Days',
      accessor: (rowData: any, index: number) => {
        return extendedBy[index];
      },
    },
    {
      Header: 'New Firming Date',
      accessor: (rowData: any, index: number) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Select Start Date"
              value={newFirmingDate[index] ? new Date(newFirmingDate[index]) : null}
              onChange={(date: any) => changeFirmingDate(date, index)}
            />
          </LocalizationProvider>
        );
      },
    },
  ];

  const submitChanges = () => {
    displayedRows.forEach((row, index) => {
      updateDeal({ variables: { _id: row.deal._id, record: { firmDate: newFirmingDate[index] } } });
    });
  };

  const changeFirmingDate = (newDate: Date | null, index: number) => {
    const extendedDate = newDate || new Date();
    const newFirmingDates = newFirmingDate.map((date, i) => {
      // found correct index in the new extended dates array
      if (i === index) {
        return extendedDate;
      } else {
        return date;
      }
    });
    const newExtendedBy = newFirmingDate.map((date, i) => {
      if (i === index) {
        const oldFirmDate = new Date(displayedRows[index].deal.firmDate);
        const diffDays = dateDiffInDays(oldFirmDate, extendedDate);
        return diffDays;
      } else {
        return extendedBy[i];
      }
    });
    setNewFirmingDate(newFirmingDates);
    setExtendedBy(newExtendedBy);
  };

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        border: '3px solid #000',
        p: 4,
        width: '1000px',
        // [theme.breakpoints.down('sm')]: {
        //   width: '90%',
        //   justifyContent: 'flex-start',
        //   marginLeft: 0,
        // },
        // '@media (max-height: 600px)': {
        //   height: '560px',
        // },
      }}
    >
      <Typography variant={'h5'} gutterBottom>
        Extend Conditional
      </Typography>
      <StandardTable data={displayedRows} columns={columns} loading={false} />
      <Button
        variant="contained"
        color="success"
        sx={{ mt: 2 }}
        onClick={() => {
          setOpenConfirm(true);
        }}
      >
        Submit
      </Button>
      <Dialog
        open={openConfirm}
        onClose={() => {
          setOpenConfirm(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Confirm Changes?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Confirm the changes for the selected units?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenConfirm(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button color="primary" onClick={submitChanges} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const UPDATEDEAL = gql`
  mutation dealUpdateById($_id: MongoID!, $record: UpdateByIdDealInput!) {
    dealUpdateById(_id: $_id, record: $record) {
      record {
        unit {
          _id
          allocation {
            _id
            fullName
          }
          tempAllocation
          suite
          unit
          modelType
          unitType
          size
          basePrice
          status
          allocatedDate
          worksheets {
            updatedAt
          }
        }
        createdAt
        signDate
        firmDate
        tags
        realtor {
          fullName
          realtor {
            _id
          }
        }
        _id
      }
    }
  }
`;

interface ChildProps {
  displayedRows: any[];
  onClose: (suite: string, firmingDate: string) => void;
  handleFirmDateChange: (data: any) => void;
}

export default ExtendedConditionalModal;
