import { useContext, useState } from 'react';
import Dropzone from 'react-dropzone';
import { subYears } from 'date-fns';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Box, Divider, Grid, Button, FormControl, TextField, Autocomplete, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useCreateActivity } from '../../../../features/activity/activityHooks';
import { useAppDispatch } from '../../../../app/hooks';

import { selectProject } from '../../../../features/project/projectSlice';
import { selectUser } from '../../../../features/auth/authSlice';
import { showSuccessSnackbar, showErrorSnackbar } from '../../../../features/snackbar/snackbarSlice';
import { accessAllowed } from '../../../../features/project/projectHooks';
import SwitchButton from '../../../common/formControls/SwitchButton';
import PurchaserForm from '../../../common/forms/PurchaserForm';
import { UnitContext } from '../../../../context/UnitContext';
import { IPurchaserInfo, IPurchasersArray, ISigningOfficer, IPurchaser, IIdentification } from '../../../../types/CreateDealForm';
import PdfCard from '../../../common/PdfCard';
import { FlexBetween, FlexEnd } from '../../../../commonStyles';
import LoadingWrapper from '../../../common/LoadingWrapper';
import PurchaserDialog from './PurchaserDialog';

const PurchaserInfo = (props: ChildProps) => {
  const { style, handleDrop } = props;
  const createActivity = useCreateActivity();
  const storeDispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const project = useSelector(selectProject);
  const { filteredDeal, setFilteredDeal } = useContext(UnitContext);

  const [errors, setErrors] = useState<any>(null);
  const [idImage, setIdImage] = useState<any>([]);
  const [searchInfo, setSearchInfo] = useState<IPurchaserInfo[]>([]);
  const [addPurchaser, setAddPurchaser] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogType, setDialogType] = useState<string>('');
  const [purchaserIndex, setPurchaserIndex] = useState<number>(0);
  const [oldPurchasers, setOldPurchasers] = useState<IPurchaserInfo[]>([]);
  const [registrants, setRegistrants] = useState<IRegistrant[]>([]);

  // Queries and Mutations

  useQuery(REGISTRANTS, {
    skip: !filteredDeal.purchasers && filteredDeal.purchasers.length === 0,
    variables: { filter: { project: project._id, emails: filteredDeal.purchasers.map((purchaser: IPurchaserInfo) => purchaser.email) } },
    onCompleted: (data) => {
      setRegistrants(data.registrantMany);
    },
  });

  const [getAllPurchasers, { loading: purchaserLoading }] = useLazyQuery<IPurchasersArray>(PURCHASERS, {
    variables: { filter: { project: project._id } },
    onCompleted: (data) => {
      setSearchInfo(data.purchaserMany);
    },
  });

  const [getPurchaser] = useLazyQuery<IPurchaser>(GETPURCHASER, {
    onCompleted: async (data) => {
      setFilteredDeal({
        ...filteredDeal,
        purchasers: [...filteredDeal.purchasers, data.purchaserById],
      });
      setOldPurchasers(filteredDeal.purchasers);
      setAddPurchaser(false);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [saveNewPurchaser] = useMutation(CREATEPURCHASERUPLOAD, {
    onCompleted: (data) => {},
    onError: (err) => {
      if (err.message.includes('duplicate')) {
        storeDispatch(showErrorSnackbar('This email has already been used for another purchaser'));
      } else {
        storeDispatch(showErrorSnackbar(`Error with creating purchaser`));
      }
    },
  });

  const [addNewPurchaser] = useMutation(UPDATEDEALINFO, {
    onCompleted: (data) => {
      createActivity({
        project: project._id,
        user: user._id,
        deal: filteredDeal._id,
        title: `Add Purchaser`,
        content: `Purchaser has been added to deal. Add Purchaser Amendment needs to be sent`,
      });
      setLoading(false);
      storeDispatch(showSuccessSnackbar(`Purchaser has been added!`));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updatePurchaser] = useMutation(UPDATEPURCHASER, {
    onCompleted: (data) => {
      let purchaser = data.purchaserUpdateById.record;
      storeDispatch(showSuccessSnackbar(`Purchaser ${purchaser.firstName} ${purchaser.lastName} profile has been updated!`));

      // let selectedPurchaser = filteredDeal.purchasers.find((purchaserInfo: IPurchaserInfo) => {
      //   return purchaserInfo._id === purchaser._id;
      // });

      createActivity({
        project: project._id,
        user: user._id,
        deal: null,
        title: `Update Purchaser Information ${purchaser.firstName} ${purchaser.lastName}`,
        content: 'Purchaser Information Updated',
      });
      setLoading(false);
    },
    onError: (err) => {
      if (err.message.includes('duplicate')) {
        storeDispatch(showErrorSnackbar('This email has already been used for another purchaser'));
      } else {
        storeDispatch(showErrorSnackbar(`Error with updating purchaser`));
      }
    },
  });

  const [uploadID] = useMutation(UPLOADID, {
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const handlePurchaserSubmit = async (event: any, index: number) => {
    event.preventDefault();
    if (!filteredDeal.purchasers[index].corp) {
      if (new Date(filteredDeal.purchasers[index].dob) > subYears(new Date(), 18)) {
        storeDispatch(showErrorSnackbar(`Cannot be under 18`));
        return;
      }
    }
    if (errors) {
      storeDispatch(showErrorSnackbar(`Invalid Date`));
      return;
    }
    setLoading(true);

    if (filteredDeal.purchasers[index]) {
      if (filteredDeal.purchasers[index]._id) {
        const { _id, getUrl, putUrl, identifications, ...newObject } = filteredDeal.purchasers[index];
        for (const file of filteredDeal.purchasers[index].identifications) {
          if (file.file) {
            await uploadID({ variables: { id: filteredDeal.purchasers[index]._id, name: file.name, file: file.file } });
          }
        }
        await updatePurchaser({ variables: { _id: _id, record: newObject } });
        if (filteredDeal.purchasers.length !== oldPurchasers.length && oldPurchasers.length > 0) {
          let purchaserIdDeal = filteredDeal.purchasers.map((purchaser: IPurchaserInfo) => purchaser._id);
          addNewPurchaser({ variables: { _id: filteredDeal._id, record: { purchasers: purchaserIdDeal } } });
        }
      } else {
        if (filteredDeal.purchasers[index].identifications.length > 0) {
          let newId = await filteredDeal.purchasers[index].identifications.map((identification: any) => identification.file);
          let identificationName = filteredDeal.purchasers[index].identifications.map((identification: any) => {
            return {
              name: identification.name,
            };
          });
          filteredDeal.purchasers[index].identifications = identificationName;
          await saveNewPurchaser({ variables: { record: filteredDeal.purchasers[index], proof: null, files: newId } }).then((res) => {
            let purchaserIds = filteredDeal.purchasers
              .filter((purchaser: IPurchaserInfo) => {
                return purchaser._id;
              })
              .map((purchaser: IPurchaserInfo) => purchaser._id);
            let purchaserIdDeal = [...purchaserIds, res.data.purchaserCreateOne._id];
            addNewPurchaser({ variables: { _id: filteredDeal._id, record: { purchasers: purchaserIdDeal } } });
          });
        } else {
          storeDispatch(showErrorSnackbar(`Purchaser Image has not been uploaded`));
        }
      }
    }
  };

  // Functions

  const handleNewPurchaser = () => {
    setIdImage([...idImage, []]);
    setFilteredDeal({
      ...filteredDeal,
      purchasers: [
        ...filteredDeal.purchasers,
        {
          project: project._id,
          corp: false,
          firstName: '',
          lastName: '',
          identifications: [],
          dob: subYears(new Date(), 18),
          streetAddress: '',
          city: '',
          province: '',
          postalCode: '',
          idType: "Driver's Licence",
          idNumber: '',
          idJurisdiction: 'Ontario',
          idExpiry: new Date(),
          proofExpiry: null,
          proofNumber: '',
          proofType: '',
          sin: '',
          primaryPhone: '',
          email: '',
          occupation: '',
          employer: '',
          purchaserType: 'Investor',
          directors: '',
          businessNumber: '',
          signingOfficers: [],
        },
      ],
    });
    setAddPurchaser(false);
  };

  const handleSearchInput = (event: any, values: IPurchaserInfo) => {
    getPurchaser({ variables: { _id: values._id } });
  };

  const handleCorpChange = (type: number) => {
    let corpValue = filteredDeal.purchasers.map((purchaser: IPurchaserInfo, index: number) => {
      if (index === type) {
        return { ...purchaser, corp: !purchaser.corp };
      } else {
        return purchaser;
      }
    });
    setFilteredDeal({ ...filteredDeal, purchasers: corpValue });
  };

  const handleStreetAddress = async (value: string, type: number) => {
    let purchaserArray = await filteredDeal.purchasers.map((purchaser: IPurchaserInfo, index: number) => {
      if (index === type) {
        return { ...purchaser, streetAddress: value };
      } else {
        return purchaser;
      }
    });
    setFilteredDeal({ ...filteredDeal, purchasers: purchaserArray });
  };

  const handleDetails = async (city: string, province: string, postalCode: string, country: string, address: string, type: number) => {
    let purchaserArray = await filteredDeal.purchasers.map((purchaser: IPurchaserInfo, index: number) => {
      if (index === type) {
        return { ...purchaser, city: city, province: province, postalCode: postalCode, country: country, streetAddress: address };
      } else {
        return purchaser;
      }
    });
    setFilteredDeal({ ...filteredDeal, purchasers: purchaserArray });
  };

  const handleIdTypeInput = (event: any, idType: string, type: number) => {
    let idTypeArray = filteredDeal.purchasers.map((purchaser: IPurchaserInfo, index: number) => {
      if (index === type) {
        return { ...purchaser, [idType]: event.target.innerText };
      } else {
        return purchaser;
      }
    });
    setFilteredDeal({ ...filteredDeal, purchasers: idTypeArray });
  };

  const handleIdIssuedByInput = (event: any, type: number) => {
    let idIssuedArray = filteredDeal.purchasers.map((purchaser: IPurchaserInfo, index: number) => {
      if (index === type) {
        return { ...purchaser, idJurisdiction: event.target.innerText };
      } else {
        return purchaser;
      }
    });
    setFilteredDeal({ ...filteredDeal, purchasers: idIssuedArray });
  };

  const handleEndvestorInput = (event: any, type: number) => {
    let idIssuedArray = filteredDeal.purchasers.map((purchaser: IPurchaserInfo, index: number) => {
      if (index === type) {
        return { ...purchaser, purchaserType: event.target.innerText };
      } else {
        return purchaser;
      }
    });
    setFilteredDeal({ ...filteredDeal, purchasers: idIssuedArray });
  };

  const handleDateChange = (newValue: any, id: string, type: number) => {
    let datePickerArray = filteredDeal.purchasers.map((purchaser: IPurchaserInfo, index: number) => {
      if (index === type) {
        return { ...purchaser, [id]: newValue };
      } else {
        return purchaser;
      }
    });
    if (newValue == 'Invalid Date') {
      setErrors('Invalid Date');
      return;
    } else {
      setErrors(null);
    }
    setFilteredDeal({ ...filteredDeal, purchasers: datePickerArray });
  };

  // Signing Officer

  const handleSigningOfficerInput = async (event: any, numIndex: number, type: number) => {
    if (event && event.target.name) {
      let purchaserArray = filteredDeal?.purchasers?.map((purchaser: IPurchaserInfo, index: number) => {
        if (index === type) {
          let newSigningOfficer = purchaser.signingOfficers.map((signingOfficer: ISigningOfficer, index: number) => {
            if (index === numIndex) {
              return {
                ...signingOfficer,
                [event.target.name]: event.target.value,
              };
            } else return signingOfficer;
          });
          return {
            ...purchaser,
            signingOfficers: newSigningOfficer,
          };
        } else {
          return purchaser;
        }
      });
      setFilteredDeal({ ...filteredDeal, purchasers: purchaserArray });
    }
  };

  const handleDeleteSigningOfficer = (numIndex: number, type: number) => {
    let purchaserArray = filteredDeal?.purchasers?.map((purchaser: IPurchaserInfo, index: number) => {
      if (purchaser.signingOfficers.length === 1) {
        storeDispatch(showErrorSnackbar(`Must have at least 1 Signing Officer`));
        return purchaser;
      } else if (index === type) {
        let deleteSigningOfficer = purchaser.signingOfficers.filter((signingOfficer: ISigningOfficer, index: number) => numIndex !== index);
        return {
          ...purchaser,
          signingOfficers: deleteSigningOfficer,
        };
      } else {
        return purchaser;
      }
    });
    setFilteredDeal({ ...filteredDeal, purchasers: purchaserArray });
  };

  const addSigningOfficer = async (numIndex: number) => {
    let purchaserArray = filteredDeal?.purchasers?.map((purchaser: IPurchaserInfo, index: number) => {
      if (index === numIndex) {
        return {
          ...purchaser,
          signingOfficers: [
            ...purchaser.signingOfficers,
            {
              fullName: '',
              dob: subYears(new Date(), 18),
              streetAddress: '',
              primaryPhone: '',
              email: '',
              sin: '',
            },
          ],
        };
      } else return purchaser;
    });

    setFilteredDeal({ ...filteredDeal, purchasers: purchaserArray });
  };

  const handleDob = (newValue: any, id: number, type: number) => {
    let datePickerArray = filteredDeal.purchasers.map((purchaser: IPurchaserInfo, index: number) => {
      if (index === type) {
        let newSigningOfficer = purchaser.signingOfficers.map((signingOfficer: ISigningOfficer, index: number) => {
          if (index === id) {
            return {
              ...signingOfficer,
              dob: newValue,
            };
          } else return signingOfficer;
        });
        return {
          ...purchaser,
          signingOfficers: newSigningOfficer,
        };
      } else {
        return purchaser;
      }
    });
    if (newValue == 'Invalid Date') {
      setErrors('Invalid Date');
      return;
    } else {
      setErrors(null);
    }
    setFilteredDeal({ ...filteredDeal, purchasers: datePickerArray });
  };

  // End Signing Officer

  const handleTextInput = async (event: any, type: number) => {
    if (event && event.target.id === 'streetAddress') {
      let purchaserArray = await filteredDeal?.purchasers?.map((purchaser: IPurchaserInfo, index: number) => {
        if (index === type) {
          return { ...purchaser, [event.target.id]: event.target.value };
        } else {
          return purchaser;
        }
      });
      setFilteredDeal({ ...filteredDeal, purchasers: purchaserArray });
    }

    if (event && event.target.name) {
      let purchaserArray = await filteredDeal?.purchasers?.map((purchaser: IPurchaserInfo, index: number) => {
        if (index === type) {
          return { ...purchaser, [event.target.name]: event.target.value };
        } else {
          return purchaser;
        }
      });
      setFilteredDeal({ ...filteredDeal, purchasers: purchaserArray });
    }
  };

  const handleDelete = (id: number, title: string, index: number) => {
    if (filteredDeal.purchasers[index].identifications[id]._id) return storeDispatch(showErrorSnackbar(`Cannot Delete ID`));

    let purchasers = filteredDeal.purchasers.map((purchaser: IPurchaserInfo) => {
      let identifications = purchaser.identifications.filter((identification: IIdentification, index) => index !== id);

      return {
        ...purchaser,
        identifications: identifications,
      };
    });

    setFilteredDeal({
      ...filteredDeal,
      purchasers: purchasers,
    });
  };

  const getRegistrant = (email: string) => {
    let registrant = registrants.find((registrant: IRegistrant) => registrant.email === email);
    if (registrant) {
      return (
        <a
          style={{
            textDecoration: 'none',
          }}
          download
          href={`https://crm.rdsre.ca/dashboard/project/${project._id}/registrant/${registrant._id}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button variant="contained" color="primary">
            View Registrant Profile
          </Button>
        </a>
      );
    } else return null;
  };

  return !loading ? (
    <div>
      {filteredDeal?.purchasers?.map((pInfo: IPurchaserInfo, index: number) => {
        return (
          <Box key={index} sx={{ mb: 2 }}>
            <FlexBetween>
              <Typography variant="h2">
                <strong>Purchaser {index + 1}</strong>
              </Typography>
              {getRegistrant(pInfo.email)}
            </FlexBetween>
            <Dropzone onDrop={(files) => handleDrop(files, index, 'purchaser')} accept="image/jpg, image/jpeg, image/png, application/pdf">
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <p>Drag and Drop or Upload ID or Incorporation Documents</p>
                  </div>
                </section>
              )}
            </Dropzone>
            {pInfo.identifications.length > 0 ? (
              <Grid container spacing={2}>
                {pInfo.identifications.map((attachment: any, pIndex: number) => {
                  return (
                    <Grid key={pIndex} item lg={3} md={4} sm={6} xs={12}>
                      <PdfCard
                        file={attachment.getUrl ? attachment.getUrl : attachment.url}
                        title={attachment.name ? attachment.name : attachment.file.name}
                        id={pIndex}
                        handleDelete={handleDelete}
                        download={true}
                        index={index}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            ) : null}
            <SwitchButton state={pInfo.corp} handleChange={() => handleCorpChange(index)} title={'Corporation'} />
            <form id={`purchaser-form-${index}`} onSubmit={(e: any) => handlePurchaserSubmit(e, index)} autoComplete="off">
              <PurchaserForm
                key={index}
                pInfo={pInfo}
                handleTextInput={handleTextInput}
                handleDateChange={handleDateChange}
                handleEndvestorInput={handleEndvestorInput}
                handleIdTypeInput={handleIdTypeInput}
                handleIdIssuedByInput={handleIdIssuedByInput}
                handleDetails={handleDetails}
                handleStreetAddress={handleStreetAddress}
                handleDeleteSigningOfficer={handleDeleteSigningOfficer}
                handleSigningOfficerInput={handleSigningOfficerInput}
                addSigningOfficer={addSigningOfficer}
                handleDob={handleDob}
                type={index}
              />
              {accessAllowed(user, project._id, 'editDeals') &&
              !filteredDeal.cancelled.dateCancelled &&
              !filteredDeal.rescission.dateRescinded ? (
                <FlexEnd sx={{ my: 3 }}>
                  <Button form={`purchaser-form-${index}`} sx={{ mr: 2 }} type="submit" color="success" variant="contained">
                    Save Purchaser Info
                  </Button>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => {
                      setPurchaserIndex(index);
                      setDialogType('deletePurchaser');
                      setDialogOpen(true);
                    }}
                  >
                    Delete Purchaser
                  </Button>
                </FlexEnd>
              ) : null}
            </form>
            <Divider />
          </Box>
        );
      })}
      <PurchaserDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        dialogType={dialogType}
        setDialogType={setDialogType}
        purchaserIndex={purchaserIndex}
      />
      {accessAllowed(user, project._id, 'editDeals') && !filteredDeal.cancelled.dateCancelled && !filteredDeal.rescission.dateRescinded ? (
        <FlexEnd>
          <FlexEnd sx={{ mt: 2 }}>
            <Button
              sx={{ mr: 2 }}
              onClick={() => {
                setDialogType('purchaserAmendment');
                setDialogOpen(true);
              }}
              id="purchaserAmendment"
              color="primary"
              variant="contained"
            >
              Create Purchaser Information Amendment
            </Button>
          </FlexEnd>
          <FlexEnd sx={{ mt: 2 }}>
            <Button onClick={() => setAddPurchaser(true)} color="primary" variant="contained">
              Add Another Purchaser
            </Button>
          </FlexEnd>
        </FlexEnd>
      ) : null}
      <div>
        {addPurchaser ? (
          <div>
            <h3>
              <strong>Select a Purchaser</strong>
            </h3>
            <FormControl
              sx={{
                width: '100%',
                '& .MuiFormLabel-asterisk': {
                  color: 'red',
                },
              }}
            >
              <Autocomplete
                sx={{
                  width: '100%',
                  '& .MuiFormLabel-asterisk': {
                    color: 'red',
                  },
                }}
                id={'search'}
                disableClearable
                freeSolo={false}
                options={searchInfo}
                getOptionLabel={(option: IPurchaserInfo) => `${option.firstName} ${option.lastName} ${option.email}`}
                // getOptionSelected={(option, value) => option === value}
                onChange={(event, value) => handleSearchInput(event, value)}
                renderInput={(params) => (
                  <TextField
                    onFocus={() => (!purchaserLoading ? getAllPurchasers() : null)}
                    required={false}
                    {...params}
                    size="small"
                    label={'Search By Name or Email'}
                    margin="normal"
                  />
                )}
              />
            </FormControl>
            <Button sx={{ mt: 2 }} color="primary" variant="contained" onClick={() => handleNewPurchaser()}>
              Add a New Purchaser
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  ) : (
    <LoadingWrapper title="Saving..." modal={false} />
  );
};

interface ChildProps {
  handleDrop: any;
  style: any;
  openDialog: any;
  handleDialog: any;
}

interface IRegistrant {
  _id: string;
  email: string;
}

const CREATEPURCHASERUPLOAD = gql`
  mutation purchaserCreateOne($record: CreateOnePurchaserInput!, $proof: Upload, $files: [Upload!]) {
    purchaserCreateOne(record: $record, proof: $proof, files: $files) {
      _id
      project {
        _id
      }
      identifications {
        _id
        name
        getUrl
      }
      email
      firstName
      lastName
      sin
      dob
      streetAddress
      city
      province
      country
      postalCode
      occupation
      employer
      directors
      businessNumber
      signingOfficers {
        fullName
        dob
        sin
        primaryPhone
        streetAddress
        email
      }
      purchaserType
      primaryPhone
      idType
      idNumber
      idExpiry
      proofExpiry
      proofNumber
      proofType
      unit
      idJurisdiction
      corp
      getUrl
    }
  }
`;

const UPDATEDEALINFO = gql`
  mutation dealUpdateById($_id: MongoID!, $record: UpdateByIdDealInput!) {
    dealUpdateById(_id: $_id, record: $record) {
      record {
        purchasers {
          _id
          firstName
          lastName
        }
      }
    }
  }
`;

const UPDATEPURCHASER = gql`
  mutation purchaserUpdateById($_id: MongoID!, $record: UpdateByIdPurchaserInput!) {
    purchaserUpdateById(_id: $_id, record: $record) {
      record {
        _id
        email
        firstName
        lastName
        identifications {
          _id
          name
          getUrl
        }
        sin
        dob
        streetAddress
        city
        province
        country
        postalCode
        occupation
        employer
        directors
        businessNumber
        signingOfficers {
          fullName
          dob
          sin
          primaryPhone
          streetAddress
          email
        }
        purchaserType
        primaryPhone
        idType
        idNumber
        idExpiry
        proofExpiry
        proofNumber
        proofType
        unit
        idJurisdiction
        putUrl
      }
    }
  }
`;

const UPLOADID = gql`
  mutation uploadID($id: MongoID!, $name: String!, $file: Upload!) {
    uploadID(id: $id, name: $name, file: $file) {
      identifications {
        _id
        name
        getUrl
      }
    }
  }
`;

const PURCHASERS = gql`
  query purchaserMany($filter: FilterFindManyPurchaserInput) {
    purchaserMany(filter: $filter, limit: 10000) {
      _id
      email
      firstName
      lastName
      corp
    }
  }
`;

const REGISTRANTS = gql`
  query registrantMany($filter: FilterFindManyRegistrantInput) {
    registrantMany(filter: $filter, limit: 10000) {
      email
      _id
    }
  }
`;

const GETPURCHASER = gql`
  query purchaserById($_id: MongoID!) {
    purchaserById(_id: $_id) {
      _id
      email
      firstName
      lastName
      corp
      sin
      dob
      identifications {
        _id
        name
        getUrl
      }
      streetAddress
      city
      province
      country
      postalCode
      occupation
      employer
      directors
      businessNumber
      signingOfficers {
        fullName
        dob
        sin
        primaryPhone
        streetAddress
        email
      }
      purchaserType
      primaryPhone
      idType
      idNumber
      idExpiry
      proofExpiry
      proofNumber
      proofType
      unit
      idJurisdiction
      getUrl
      putUrl
    }
  }
`;

export default PurchaserInfo;
