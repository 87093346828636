import React, { useState, useContext } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../app/hooks';
import { showErrorSnackbar } from '../features/snackbar/snackbarSlice';
import { IPurchaserInfo } from '../types/CreateDealForm';
import { UnitContext } from './UnitContext';
import { ILease } from '../types/lease';
import { IUtility } from '../components/unitMain/leasing/Tenancy';
import { IDocuments } from '../types/docusign';
import { showSuccessSnackbar } from '../features/snackbar/snackbarSlice';
import { selectProject } from '../features/project/projectSlice';
import { IUser } from '../types/user';
import { numToCurrency } from '../utils/Functions';

const LeaseContext = React.createContext<any>(null);

const LeaseProvider = (props: any) => {
  const project = useSelector(selectProject);
  const { unit } = useContext(UnitContext);
  const storeDispatch = useAppDispatch();
  const [lease, setLease] = useState<ILease | null>(null);
  const [leases, setLeases] = useState([]);
  const [agreementType, setAgreementType] = useState<string>('');

  const { loading } = useQuery(LEASES, {
    variables: { filter: { unit: unit._id } },
    onCompleted: (data) => {
      if (data.leaseMany.length) {
        let activeLease = data.leaseMany.find((lease: ILease) => lease.active);
        if (activeLease) {
          setLease(activeLease);
          setAgreementType('summary');
        }
      }
      setLeases(data.leaseMany);
    },
  });

  const [createManagement] = useMutation(CREATEMANAGEMENT, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Management Agreement has been created'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateManagement] = useMutation(UPDATEMANAGEMENT, {
    onCompleted: (data) => {
      setLease({
        ...lease!,
        management: data.managementUpdateById.record,
      });
      storeDispatch(showSuccessSnackbar('Management Agreement has been updated'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [removeManagement] = useMutation(REMOVEMANAGEMENT, {
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [createListing] = useMutation(CREATELISTING, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Listing Agreement has been created'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateListing] = useMutation(UPDATELISTING, {
    onCompleted: (data) => {
      setLease({
        ...lease!,
        listing: data.listingUpdateById.record,
      });
      storeDispatch(showSuccessSnackbar('Listing Agreement has been updated'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [removeListing] = useMutation(REMOVELISTING, {
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [createTenancy] = useMutation(CREATETENANCY, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Tenancy Agreement has been created'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateTenancy] = useMutation(UPDATETENANCY, {
    onCompleted: (data) => {
      setLease({
        ...lease!,
        tenancy: data.tenancyUpdateById.record,
      });
      storeDispatch(showSuccessSnackbar('Tenancy Agreement has been updated'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [removeTenancy] = useMutation(REMOVETENANCY, {
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [createLease] = useMutation(CREATELEASE, {
    onCompleted: (data) => {
      setLease(data.leaseCreateOne.record);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateLease] = useMutation(UPDATELEASE, {
    onCompleted: (data) => {
      setLease(data.leaseUpdateById.record);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const saveManagement = async (
    e: any,
    insurance: boolean,
    options: any[],
    rec: boolean,
    executor: IUser,
    reRentalFee: string,
    inspection: string,
    leaseRenewal: string,
    startupFee: string,
    managementFee: string
  ) => {
    e.preventDefault();
    if (!lease) {
      await createManagement({
        variables: {
          record: {
            project: project._id,
            unit: unit._id,
            insurance: insurance,
            rec: rec,
            options: options,
            reRentalFee,
            inspection,
            leaseRenewal,
            startupFee,
            managementFee,
            executor: executor._id,
          },
        },
      }).then((res) => {
        createLease({
          variables: {
            record: {
              active: true,
              project: project._id,
              unit: unit._id,
              management: res.data.managementCreateOne.record._id,
              listing: null,
              tenancy: null,
              documents: [],
            },
          },
        }).then((leaseRes) => {
          updateManagement({
            variables: {
              _id: res.data.managementCreateOne.record._id,
              record: {
                lease: leaseRes.data.leaseCreateOne.record._id,
              },
            },
          });
        });
      });
    } else {
      if (lease.management) {
        updateManagement({
          variables: {
            _id: lease.management._id,
            record: {
              project: project._id,
              unit: unit._id,
              insurance: insurance,
              rec: rec,
              options: options,
              reRentalFee,
              inspection,
              leaseRenewal,
              startupFee,
              managementFee,
              executor: executor._id,
            },
          },
        });
      } else {
        await createManagement({
          variables: {
            record: {
              project: project._id,
              unit: unit._id,
              insurance: insurance,
              rec: rec,
              options: options,
              reRentalFee,
              inspection,
              leaseRenewal,
              startupFee,
              managementFee,
              executor: executor._id,
            },
          },
        }).then((res) => {
          updateLease({
            variables: {
              _id: lease._id,
              record: {
                management: res.data.managementCreateOne.record._id,
              },
            },
          });
        });
      }
    }
  };

  const deleteManagement = async (lease: ILease) => {
    if (lease && lease.management) {
      removeManagement({
        variables: {
          _id: lease.management._id,
        },
      }).then((res) => {
        updateLease({
          variables: {
            _id: lease._id,
            record: {
              management: null,
            },
          },
        });
        storeDispatch(showSuccessSnackbar('Rental Management Profile has been deleted'));
        setAgreementType('summary');
      });
    }
  };

  const saveListing = async (
    e: any,
    dateStart: Date,
    dateEnd: Date,
    amount: string,
    executor: IUser,
    listingWords: string,
    listingAmount: string,
    cooperatingWords: string,
    cooperatingAmount: string,
    holdover: string,
    mls: string
  ) => {
    e.preventDefault();
    if (!lease) return storeDispatch(showErrorSnackbar('No Lease Found'));

    let listingValue = listingWords;

    if (listingAmount) {
      listingValue = `${listingWords} + ${numToCurrency.format(parseFloat(listingAmount))}`;
    }

    let cooperatingValue = cooperatingWords;

    if (cooperatingAmount) {
      cooperatingValue = `${cooperatingWords} + ${numToCurrency.format(parseFloat(cooperatingAmount))}`;
    }

    if (lease.listing) {
      updateListing({
        variables: {
          _id: lease.listing._id,
          record: {
            project: project._id,
            unit: unit._id,
            dateStart: new Date(dateStart).setHours(5, 0, 0, 0),
            dateEnd: new Date(dateEnd).setHours(5, 0, 0, 0),
            amount: parseFloat(amount),
            executor: executor._id,
            listing: listingValue,
            cooperating: cooperatingValue,
            holdover: parseInt(holdover, 10),
            mls: mls
          },
        },
      });
    } else {
      await createListing({
        variables: {
          record: {
            project: project._id,
            unit: unit._id,
            lease: lease._id,
            dateStart: new Date(dateStart).setHours(5, 0, 0, 0),
            dateEnd: new Date(dateEnd).setHours(5, 0, 0, 0),
            amount: parseFloat(amount),
            executor: executor._id,
            listing: listingValue,
            cooperating: cooperatingValue,
            holdover: parseInt(holdover, 10),
            mls: mls
          },
        },
      }).then((res) => {
        updateLease({
          variables: {
            _id: lease._id,
            record: {
              listing: res.data.listingCreateOne.record._id,
            },
          },
        });
      });;
    }
  };

  const deleteListing = async (lease: ILease) => {
    if (lease && lease.listing) {
      removeListing({
        variables: {
          _id: lease.listing._id,
        },
      }).then((res) => {
        updateLease({
          variables: {
            _id: lease._id,
            record: {
              listing: null,
            },
          },
        });
        storeDispatch(showSuccessSnackbar('Listing Profile has been deleted'));
        setAgreementType('summary');
      });
    }
  };

  const saveTenancy = async (
    e: any,
    tenants: IPurchaserInfo[],
    tenancyType: string,
    tenancyDateStart: Date | null,
    tenancyDateEnd: Date | null,
    amount: string,
    payableTo: string,
    paymentDay: string,
    paymentType: string,
    paymentMethod: string,
    partialRentAmount: string,
    partialRentPaymentDate: Date | null,
    partialRentStartDate: Date | null,
    partialRentEndDate: Date | null,
    utilitiesState: IUtility[],
    parkingDescription: string,
    lockerDescription: string,
    electricity: string,
    heat: string,
    water: string,
    rentDiscount: string,
    rentDepositAmount: string,
    keyDepositAmount: string,
    keyDeposit: string,
    smokingRules: boolean,
    smokingRulesDescription: string
  ) => {
    e.preventDefault();
    if (!tenants.length) {
      return storeDispatch(showErrorSnackbar('No Purchasers found'));
    }
    if (!tenants.every((purchaser: IPurchaserInfo) => purchaser._id)) {
      return storeDispatch(showErrorSnackbar('A Purchaser has not been saved'));
    }
    if (!utilitiesState.every((utility) => utility.answer.length))
      return storeDispatch(showErrorSnackbar('Every utility needs at least one selected option'));

    if (lease && lease.tenancy) {
      updateTenancy({
        variables: {
          _id: lease.tenancy._id,
          record: {
            project: project._id,
            unit: unit._id,
            tenants: tenants.map((purchaser: IPurchaserInfo) => purchaser._id),
            tenancyType: tenancyType,
            dateStart: tenancyDateStart ? new Date(tenancyDateStart).setHours(5, 0, 0, 0) : null,
            dateEnd: tenancyDateEnd ? new Date(tenancyDateEnd).setHours(5, 0, 0, 0) : null,
            parkingDescription: parkingDescription,
            lockerDescription: lockerDescription,
            payableTo: payableTo,
            paymentDay: paymentDay,
            paymentType: paymentType,
            paymentMethod: paymentMethod,
            partialRentAmount: partialRentAmount ? parseFloat(partialRentAmount) : 0,
            partialRentPaymentDate: partialRentPaymentDate,
            partialRentStartDate: partialRentStartDate,
            partialRentEndDate: partialRentEndDate,
            amount: parseFloat(amount),
            utilities: utilitiesState.map((utility: IUtility) => {
              return {
                name: utility.name,
                answer: utility.answer,
              };
            }),
            electricity: electricity,
            heat: heat,
            water: water,
            rentDiscount: rentDiscount,
            rentDepositAmount: rentDepositAmount ? parseFloat(rentDepositAmount) : 0,
            keyDepositAmount: keyDepositAmount ? parseFloat(keyDepositAmount) : 0,
            keyDeposit: keyDeposit,
            smokingRules: smokingRules,
            smokingRulesDescription: smokingRulesDescription,
          },
        },
      });
    } else {
      await createTenancy({
        variables: {
          record: {
            project: project._id,
            unit: unit._id,
            tenants: tenants.map((purchaser: IPurchaserInfo) => purchaser._id),
            tenancyType: tenancyType,
            dateStart: tenancyDateStart ? new Date(tenancyDateStart).setHours(5, 0, 0, 0) : null,
            dateEnd: tenancyDateEnd ? new Date(tenancyDateEnd).setHours(5, 0, 0, 0) : null,
            parkingDescription: parkingDescription,
            lockerDescription: lockerDescription,
            payableTo: payableTo,
            paymentDay: paymentDay,
            paymentType: paymentType,
            paymentMethod: paymentMethod,
            partialRentAmount: partialRentAmount ? parseFloat(partialRentAmount) : 0,
            partialRentPaymentDate: partialRentPaymentDate,
            partialRentStartDate: partialRentStartDate,
            partialRentEndDate: partialRentEndDate,
            amount: parseFloat(amount),
            utilities: utilitiesState.map((utility: IUtility) => {
              return {
                name: utility.name,
                answer: utility.answer,
              };
            }),
            electricity: electricity,
            heat: heat,
            water: water,
            rentDiscount: rentDiscount,
            rentDepositAmount: rentDepositAmount ? parseFloat(rentDepositAmount) : 0,
            keyDepositAmount: keyDepositAmount ? parseFloat(keyDepositAmount) : 0,
            keyDeposit: keyDeposit,
            smokingRules: smokingRules,
            smokingRulesDescription: smokingRulesDescription,
          },
        },
      }).then((res) => {
        updateLease({
          variables: {
            _id: lease?._id,
            record: {
              tenancy: res.data.tenancyCreateOne.record._id,
            },
          },
        });
      });
    }
  };

  const deleteTenancy = async (lease: ILease) => {
    if (lease && lease.tenancy) {
      removeTenancy({
        variables: {
          _id: lease.tenancy._id,
        },
      }).then((res) => {
        updateLease({
          variables: {
            _id: lease._id,
            record: {
              listing: null,
            },
          },
        });
        storeDispatch(showSuccessSnackbar('Tenancy Profile has been deleted'));
        setAgreementType('summary');
      });
    }
  };

  const handleDocuments = (documents: IDocuments[]) => {
    if (lease) {
      setLease({
        ...lease,
        documents: documents,
      });
    }
  };

  return !loading ? (
    <LeaseContext.Provider
      value={{
        lease,
        setLease,
        leases,
        setLeases,
        saveManagement,
        deleteManagement,
        agreementType,
        setAgreementType,
        handleDocuments,
        saveListing,
        deleteListing,
        saveTenancy,
        deleteTenancy,
        updateLease,
        createLease,
      }}
    >
      {props.children}
    </LeaseContext.Provider>
  ) : null;
};

const CREATETENANCY = gql`
  mutation tenancyCreateOne($record: CreateOneTenancyInput!) {
    tenancyCreateOne(record: $record) {
      record {
        _id
        tenants {
          _id
          email
          firstName
          lastName
          corp
          sin
          dob
          identifications {
            _id
            name
            getUrl
          }
          streetAddress
          city
          province
          country
          postalCode
          occupation
          employer
          directors
          signingOfficers {
            fullName
            dob
            sin
            primaryPhone
            streetAddress
            email
          }
          purchaserType
          primaryPhone
          idType
          idNumber
          idExpiry
          unit
          idJurisdiction
          getUrl
          putUrl
        }
        tenancyType
        dateStart
        dateEnd
        parkingDescription
        lockerDescription
        payableTo
        paymentDay
        paymentType
        paymentMethod
        partialRentAmount
        partialRentPaymentDate
        partialRentStartDate
        partialRentEndDate
        amount
        utilities {
          name
          answer
        }
        electricity
        heat
        water
        rentDiscount
        rentDepositAmount
        keyDepositAmount
        keyDeposit
        smokingRules
        smokingRulesDescription
      }
    }
  }
`;

const CREATEMANAGEMENT = gql`
  mutation managementCreateOne($record: CreateOneManagementInput!) {
    managementCreateOne(record: $record) {
      record {
        _id
        options
        rec
        insurance
        reRentalFee
        inspection
        leaseRenewal
        startupFee
        managementFee
        executor {
          _id
          fullName
        }
      }
    }
  }
`;

const CREATELISTING = gql`
  mutation listingCreateOne($record: CreateOneListingInput!) {
    listingCreateOne(record: $record) {
      record {
        _id
        dateStart
        dateEnd
        amount
        listing
        cooperating
        executor {
          _id
          fullName
        }
        holdover
        mls
      }
    }
  }
`;

const CREATELEASE = gql`
  mutation leaseCreateOne($record: CreateOneLeaseInput!) {
    leaseCreateOne(record: $record) {
      record {
        _id
        active
        createdAt
        project {
          _id
        }
        unit {
          _id
        }
        management {
          _id
        }
        listing {
          _id
        }
        landlords {
          _id
          email
          firstName
          lastName
          corp
          sin
          dob
          identifications {
            _id
            name
            getUrl
          }
          streetAddress
          city
          province
          country
          postalCode
          occupation
          employer
          directors
          signingOfficers {
            fullName
            dob
            sin
            primaryPhone
            streetAddress
            email
          }
          purchaserType
          primaryPhone
          idType
          idNumber
          idExpiry
          unit
          idJurisdiction
          getUrl
          putUrl
        }
        tenancy {
          _id
          tenants {
            _id
            email
            firstName
            lastName
            corp
            sin
            dob
            identifications {
              _id
              name
              getUrl
            }
            streetAddress
            city
            province
            country
            postalCode
            occupation
            employer
            directors
            signingOfficers {
              fullName
              dob
              sin
              primaryPhone
              streetAddress
              email
            }
            purchaserType
            primaryPhone
            idType
            idNumber
            idExpiry
            unit
            idJurisdiction
            getUrl
            putUrl
          }
          tenancyType
          dateStart
          dateEnd
          parkingDescription
          lockerDescription
          payableTo
          paymentDay
          paymentType
          paymentMethod
          partialRentAmount
          partialRentPaymentDate
          partialRentStartDate
          partialRentEndDate
          amount
          electricity
          heat
          water
          rentDiscount
          rentDepositAmount
          utilities {
            name
            answer
          }
          keyDepositAmount
          keyDeposit
          smokingRules
          smokingRulesDescription
        }
        documents {
          _id
          name
          project {
            _id
          }
          type
          getUrl
          status
          isAPS
          dsEnvelopeId
        }
      }
    }
  }
`;

const LEASES = gql`
  query leaseMany($filter: FilterFindManyLeaseInput) {
    leaseMany(filter: $filter) {
      _id
      active
      landlords {
        _id
        email
        firstName
        lastName
        corp
        sin
        dob
        identifications {
          _id
          name
          getUrl
        }
        streetAddress
        city
        province
        country
        postalCode
        occupation
        employer
        directors
        signingOfficers {
          fullName
          dob
          sin
          primaryPhone
          streetAddress
          email
        }
        purchaserType
        primaryPhone
        idType
        idNumber
        idExpiry
        unit
        idJurisdiction
        getUrl
        putUrl
      }
      createdAt
      project {
        _id
      }
      unit {
        _id
      }
      uploads {
        _id
        name
        getUrl
      }
      management {
        _id
        options
        rec
        insurance
        reRentalFee
        inspection
        leaseRenewal
        startupFee
        managementFee
        executor {
          _id
          fullName
        }
      }
      listing {
        _id
        dateStart
        dateEnd
        amount
        listing
        cooperating
        executor {
          _id
          fullName
        }
        holdover
        mls
      }
      tenancy {
        _id
        tenants {
          _id
          email
          firstName
          lastName
          corp
          sin
          dob
          identifications {
            _id
            name
            getUrl
          }
          streetAddress
          city
          province
          country
          postalCode
          occupation
          employer
          directors
          signingOfficers {
            fullName
            dob
            sin
            primaryPhone
            streetAddress
            email
          }
          purchaserType
          primaryPhone
          idType
          idNumber
          idExpiry
          unit
          idJurisdiction
          getUrl
          putUrl
        }
        tenancyType
        dateStart
        dateEnd
        parkingDescription
        lockerDescription
        payableTo
        paymentDay
        paymentType
        paymentMethod
        partialRentAmount
        partialRentPaymentDate
        partialRentStartDate
        partialRentEndDate
        amount
        electricity
        heat
        water
        rentDiscount
        rentDepositAmount
        utilities {
          name
          answer
        }
        keyDepositAmount
        keyDeposit
        smokingRules
        smokingRulesDescription
      }
      documents {
        _id
        name
        project {
          _id
        }
        type
        getUrl
        status
        isAPS
        dsEnvelopeId
      }
    }
  }
`;

const UPDATEMANAGEMENT = gql`
  mutation managementUpdateById($_id: MongoID!, $record: UpdateByIdManagementInput!) {
    managementUpdateById(_id: $_id, record: $record) {
      record {
        _id
        options
        rec
        insurance
        reRentalFee
        inspection
        leaseRenewal
        startupFee
        managementFee
        executor {
          _id
          fullName
        }
      }
    }
  }
`;

const UPDATELISTING = gql`
  mutation listingUpdateById($_id: MongoID!, $record: UpdateByIdListingInput!) {
    listingUpdateById(_id: $_id, record: $record) {
      record {
        _id
        dateStart
        dateEnd
        amount
        listing
        cooperating
        executor {
          _id
          fullName
        }
        holdover
        mls
      }
    }
  }
`;

const UPDATETENANCY = gql`
  mutation tenancyUpdateById($_id: MongoID!, $record: UpdateByIdTenancyInput!) {
    tenancyUpdateById(_id: $_id, record: $record) {
      record {
        _id
        tenants {
          _id
          email
          firstName
          lastName
          corp
          sin
          dob
          identifications {
            _id
            name
            getUrl
          }
          streetAddress
          city
          province
          country
          postalCode
          occupation
          employer
          directors
          signingOfficers {
            fullName
            dob
            sin
            primaryPhone
            streetAddress
            email
          }
          purchaserType
          primaryPhone
          idType
          idNumber
          idExpiry
          unit
          idJurisdiction
          getUrl
          putUrl
        }
        tenancyType
        dateStart
        dateEnd
        parkingDescription
        lockerDescription
        payableTo
        paymentDay
        paymentType
        paymentMethod
        partialRentAmount
        partialRentPaymentDate
        partialRentStartDate
        partialRentEndDate
        amount
        electricity
        heat
        water
        rentDiscount
        rentDepositAmount
        utilities {
          name
          answer
        }
        keyDepositAmount
        keyDeposit
        smokingRules
        smokingRulesDescription
      }
    }
  }
`;

const UPDATELEASE = gql`
  mutation leaseUpdateById($_id: MongoID!, $record: UpdateByIdLeaseInput!) {
    leaseUpdateById(_id: $_id, record: $record) {
      record {
        _id
        active
        createdAt
        project {
          _id
        }
        unit {
          _id
        }
        uploads {
          name
          getUrl
        }
        documents {
          _id
          name
          project {
            _id
          }
          type
          getUrl
          status
          isAPS
          dsEnvelopeId
        }
        tenancy {
          _id
          tenants {
            _id
            email
            firstName
            lastName
            corp
            sin
            dob
            identifications {
              _id
              name
              getUrl
            }
            streetAddress
            city
            province
            country
            postalCode
            occupation
            employer
            directors
            signingOfficers {
              fullName
              dob
              sin
              primaryPhone
              streetAddress
              email
            }
            purchaserType
            primaryPhone
            idType
            idNumber
            idExpiry
            unit
            idJurisdiction
            getUrl
            putUrl
          }
          tenancyType
          dateStart
          dateEnd
          parkingDescription
          lockerDescription
          payableTo
          paymentDay
          paymentType
          paymentMethod
          partialRentAmount
          partialRentPaymentDate
          partialRentStartDate
          partialRentEndDate
          amount
          electricity
          heat
          water
          rentDiscount
          rentDepositAmount
          utilities {
            name
            answer
          }
          keyDepositAmount
          keyDeposit
          smokingRules
          smokingRulesDescription
        }
        management {
          _id
          options
          rec
          insurance
          reRentalFee
          inspection
          leaseRenewal
          startupFee
          managementFee
          executor {
            _id
            fullName
          }
        }
        listing {
          _id
          dateStart
          dateEnd
          amount
          listing
          cooperating
          executor {
            _id
            fullName
          }
          holdover
          mls
        }
      }
    }
  }
`;

const REMOVEMANAGEMENT = gql`
  mutation managementRemoveById($_id: MongoID!) {
    managementRemoveById(_id: $_id) {
      record {
        _id
      }
    }
  }
`;

const REMOVELISTING = gql`
  mutation listingRemoveById($_id: MongoID!) {
    listingRemoveById(_id: $_id) {
      record {
        _id
      }
    }
  }
`;

const REMOVETENANCY = gql`
  mutation tenancyRemoveById($_id: MongoID!) {
    tenancyRemoveById(_id: $_id) {
      record {
        _id
      }
    }
  }
`;

export { LeaseContext, LeaseProvider };
