import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Autocomplete, FormControl, TextField, Button, Grid, Card, Box } from '@mui/material';
import { subYears } from 'date-fns';
import { gql, useSubscription, useLazyQuery } from '@apollo/client';
import PurchasersInfo from '../PurchasersInfo';
import CustomDialog from '../../common/CustomDialog';
import { IUnit } from '../../../types/unit';
import { IPurchaserInfo, IPurchasersArray, IPurchaser } from '../../../types/CreateDealForm';
import { CreateDealContext } from '../../../context/CreateDealContext';
import PageTitle from '../../common/PageTitle';
import { FlexBetween } from '../../../commonStyles';
import { useSelector } from 'react-redux';
import { selectProject } from '../../../features/project/projectSlice';
import { useAppDispatch } from '../../../app/hooks';
import { showErrorSnackbar } from '../../../features/snackbar/snackbarSlice';

const PurchaserStep = () => {
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const {
    setActiveStep,
    activeStep,
    purchaserInfo,
    setPurchaserInfo,
    showForm,
    setShowForm,
    handleNewPurchaser,
    purchaserCount,
    setPurchaserCount,
    setPurchaserId,
    purchaserId,
  } = useContext(CreateDealContext);
  const [searchInfo, setSearchInfo] = useState<IPurchaserInfo[]>([]);
  const [purchaserIndex, setPurchaserIndex] = useState<number>(0);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [duplicateDeal, setDuplicateDeal] = useState<IUnit[]>([]);
  // Bug with Material UI to clear input field
  const [random, setRandom] = useState<number>(Math.floor(Math.random() * 1000000 + 1));

  useSubscription(PURCHASERSUBSCRIPTION, {
    variables: { projectId: project._id },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      let searchUpdate = searchInfo.map((search: IPurchaserInfo) => {
        if (search.email === data.logPurchaser.email) {
          return data.logPurchaser;
        } else return search;
      });
      let newPurchaserInfo = purchaserInfo.map((purchaser: IPurchaserInfo) => {
        if (purchaser.email === data.logPurchaser.email) {
          return data.logPurchaser;
        } else return purchaser;
      });
      setPurchaserInfo(newPurchaserInfo);
      setSearchInfo(searchUpdate);
    },
  });

  const [checkDuplicate] = useLazyQuery(CHECKDUPLICATES, {
    onCompleted: (data) => {
      if (data.checkDuplicateDeals.length > 0) {
        setDuplicateDeal(data.checkDuplicateDeals);
        setDialogOpen(true);
      } else {
        setActiveStep(activeStep + 1);
      }
    },
  });

  const [getAllPurchasers, { loading: purchaserLoading }] = useLazyQuery<IPurchasersArray>(PURCHASERS, {
    variables: { filter: { project: project._id } },
    onCompleted: (data) => {
      setSearchInfo(data.purchaserMany);
    },
  });

  const [getPurchaser] = useLazyQuery<IPurchaser>(GETPURCHASER, {
    onCompleted: (data) => {
      setRandom(Math.floor(Math.random() * 1000000 + 1));
      const { _id, ...newObject } = data.purchaserById;
      newObject.project = project._id;
      if (purchaserId.includes(_id)) {
        storeDispatch(showErrorSnackbar('You cannot select the same purchaser'));
        return;
      }

      if (purchaserCount === 0) {
        setPurchaserInfo([newObject]);
      } else {
        setPurchaserInfo([...purchaserInfo, newObject]);
      }
      setPurchaserId([...purchaserId, _id]);
      setPurchaserCount(purchaserCount + 1);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const handleSearchInput = (event: any, values: IPurchaserInfo) => {
    getPurchaser({ variables: { _id: values._id } });
  };

  const editPurchaser = (index: number) => {
    setPurchaserIndex(index);
    setShowForm(!showForm);
  };

  const deletePurchaser = (numberIndex: number) => {
    let removePurchaser = purchaserInfo.filter((data: any, index: number) => numberIndex !== index);
    let removePurchaserId = purchaserId.filter((data: any, index: number) => numberIndex !== index);
    setPurchaserCount(purchaserCount - 1);
    setPurchaserId(removePurchaserId);

    if (removePurchaser.length > 0) {
      setPurchaserInfo(removePurchaser);
    } else {
      setPurchaserInfo([
        {
          project: project._id,
          corp: false,
          firstName: '',
          lastName: '',
          dob: subYears(new Date(), 18),
          streetAddress: '',
          city: '',
          province: '',
          country: '',
          postalCode: '',
          idType: "Driver's Licence",
          idNumber: '',
          proofType: '',
          proofNumber: '',
          proofExpiry: null,
          identifications: [],
          idJurisdiction: 'Ontario',
          idExpiry: new Date(),
          sin: '',
          primaryPhone: '',
          email: '',
          occupation: '',
          employer: '',
          directors: '',
          businessNumber: '',
          signingOfficers: [],
          purchaserType: 'Investor',
        },
      ]);
    }
  };

  const addNewPurchaser = async () => {
    if (purchaserCount > 0) {
      await handleNewPurchaser();
      setPurchaserIndex(purchaserInfo.length);
    } else if (purchaserCount === 0) {
      await setPurchaserIndex(purchaserInfo.length - 1);
    }
    setShowForm(true);
  };

  const checkDuplicateDeals = () => {
    // Check if Purchasers have signing officers
    let checkPurchaserSO = purchaserInfo.every((purchaser: IPurchaserInfo) => {
      if (purchaser.corp && purchaser.signingOfficers.length > 0) {
        return true;
      } else if (!purchaser.corp) {
        return true;
      } else return false;
    });

    if (!checkPurchaserSO) {
      storeDispatch(showErrorSnackbar('A Corporation is missing a signing officer'));
      return;
    }

    if (duplicateDeal.length > 0) {
      setDialogOpen(true);
    }
    checkDuplicate({
      variables: { project: project._id, purchaserEmails: purchaserInfo.map((purchaser: IPurchaserInfo) => purchaser.email) },
    });
  };

  const handleCloseSuccess = () => {
    setActiveStep(activeStep + 1);
  };

  const dialogContent = () => {
    if (duplicateDeal.length > 0) {
      return (
        <div>
          <div>A purchaser was found on the following deal:</div>
          <div>Please click on the link of the suite number to confirm.</div>
          <div>
            {duplicateDeal.map((deal: IUnit, index: number) => {
              return (
                <Box key={index} sx={{ mt: 2 }}>
                  <Link
                    style={{
                      textDecoration: 'none',
                      fontWeight: 700,
                      color: '#000',
                    }}
                    target="_blank"
                    to={`/${project._id}/dashboard/${deal._id}`}
                  >
                    {deal.suite}
                  </Link>
                </Box>
              );
            })}
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <CustomDialog
        handleClose={() => setDialogOpen(false)}
        handleCloseRemove={() => setDialogOpen(false)}
        handleCloseSuccess={handleCloseSuccess}
        open={dialogOpen}
        removeButton={'Cancel'}
        successButton={'Confirm'}
        dialogContent={dialogContent()}
        dialogTitle={'Duplicate Deals Found!'}
      />
      <div>
        <PageTitle title={'Purchaser Info'} />
      </div>
      {!showForm ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <FormControl
                sx={{
                  width: '100%',
                  '& .MuiFormLabel-asterisk': {
                    color: 'red',
                  },
                }}
              >
                <Autocomplete
                  key={random}
                  sx={{
                    width: '250px',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    fontSize: '14px',
                  }}
                  id={'search'}
                  disableClearable
                  noOptionsText={purchaserLoading ? `Loading...` : 'No Options'}
                  freeSolo={false}
                  options={searchInfo}
                  getOptionLabel={(option: any) => `${option.firstName} ${option.lastName} ${option.email}`}
                  onChange={handleSearchInput}
                  renderInput={(params) => (
                    <TextField
                      onFocus={() => (!purchaserLoading ? getAllPurchasers() : null)}
                      required={false}
                      {...params}
                      size="small"
                      label={'Search By Name or Email'}
                      margin="normal"
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <div>
            {purchaserCount > 0 ? (
              <Grid container spacing={2}>
                {purchaserInfo.map((info: IPurchaserInfo, index: number) => {
                  return (
                    <Grid key={index} item xs={12} sm={6} md={6} lg={6} xl={4}>
                      <Card
                        sx={{
                          width: '100%',
                          border: '1px solid #000',
                          padding: '16px',
                          position: 'relative',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          fontSize: '14px',
                        }}
                      >
                        <div>
                          <div>
                            Purchaser Name:{' '}
                            <strong>
                              {info.firstName} {info.lastName}
                            </strong>
                          </div>
                          <Box sx={{ mt: 1 }}>
                            {info.corp ? 'Corporation' : 'Purchaser'} Address:{' '}
                            <strong>
                              {info.streetAddress} {info.city} {info.province} {info.postalCode}
                            </strong>
                          </Box>
                          <Box sx={{ mt: 1 }}>
                            {info.corp ? 'Corporation' : 'Purchaser'} Email: <strong>{info.email}</strong>
                          </Box>
                          <Box sx={{ mt: 1 }}>
                            {info.corp ? 'Corporation' : 'Purchaser'} Phone Number: <strong>{info.primaryPhone}</strong>
                          </Box>
                        </div>
                        <FlexBetween sx={{ mt: 2 }}>
                          <Button onClick={() => editPurchaser(index)} variant="contained" color="primary">
                            Edit
                          </Button>
                          <Button onClick={() => deletePurchaser(index)} variant="contained" color="error">
                            Delete
                          </Button>
                        </FlexBetween>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            ) : null}
          </div>
        </>
      ) : (
        <PurchasersInfo key={purchaserIndex} pInfo={purchaserInfo[purchaserIndex]} type={purchaserIndex} />
      )}
      {!showForm ? (
        <FlexBetween sx={{ mt: 2 }}>
          <Button color="success" onClick={() => addNewPurchaser()} variant="contained">
            Add New Purchaser
          </Button>
          {purchaserCount > 0 ? (
            <Button variant="contained" color="primary" onClick={() => checkDuplicateDeals()}>
              Next
            </Button>
          ) : null}
        </FlexBetween>
      ) : null}
    </div>
  );
};

const PURCHASERS = gql`
  query purchaserMany($filter: FilterFindManyPurchaserInput) {
    purchaserMany(filter: $filter, limit: 10000) {
      _id
      email
      firstName
      lastName
      corp
    }
  }
`;

const GETPURCHASER = gql`
  query purchaserById($_id: MongoID!) {
    purchaserById(_id: $_id) {
      _id
      email
      firstName
      lastName
      corp
      sin
      dob
      identifications {
        _id
        name
        getUrl
      }
      streetAddress
      city
      province
      country
      postalCode
      occupation
      employer
      directors
      businessNumber
      signingOfficers {
        fullName
        dob
        sin
        primaryPhone
        streetAddress
        email
      }
      purchaserType
      primaryPhone
      idType
      idNumber
      idExpiry
      unit
      proofType
      proofNumber
      proofExpiry
      idJurisdiction
      getUrl
      putUrl
    }
  }
`;

const PURCHASERSUBSCRIPTION = gql`
  subscription logPurchaser($projectId: String!) {
    logPurchaser(projectId: $projectId) {
      email
      firstName
      lastName
      corp
      sin
      dob
      identifications {
        _id
        name
        getUrl
      }
      streetAddress
      city
      province
      country
      postalCode
      occupation
      employer
      directors
      businessNumber
      signingOfficers {
        fullName
        dob
        sin
        primaryPhone
        streetAddress
        email
      }
      purchaserType
      primaryPhone
      idType
      idNumber
      idExpiry
      unit
      proofType
      proofNumber
      proofExpiry
      idJurisdiction
      getUrl
      putUrl
    }
  }
`;

const CHECKDUPLICATES = gql`
  query checkDuplicateDeals($project: MongoID!, $purchaserEmails: [String]!) {
    checkDuplicateDeals(project: $project, purchaserEmails: $purchaserEmails) {
      _id
      suite
      basePrice
    }
  }
`;

export default PurchaserStep;
