import React, { useState, useContext, useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Box, TextField, FormControl, Autocomplete } from '@mui/material';
import { UnitContext } from '../../context/UnitContext';
import StepProgressBar from '../common/StepProgressBar';
import { unitStatusTitles } from '../../utils/Constants';
import { IStatus } from '../../types/project';
import { IDocuments } from '../../types/docusign';
import { IDealDeposit, IRealtorInfo, IDealRealtor } from '../../types/CreateDealForm';
import DealInfo from './deal/DealInfo';
import Floorplan from './Floorplan';
import UnitHistory from './UnitHistory';
import Comments from './Comments';
import Adjustments from './Adjustments';
import ColourSelection from './colourSelection/ColourSelection';
import Assignment from './assignment/Assignment';
import Leasing from './leasing/Leasing';
import CustomDialog from '../common/CustomDialog';
import LoadingWrapper from '../common/LoadingWrapper';
import LoadingLogo from '../common/LoadingLogo';
import '../../css/tabs.css';
import Dropdown from '../common/formControls/Dropdown';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import { selectUser } from '../../features/auth/authSlice';
import { useAppDispatch } from '../../app/hooks';
import { showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';
import { useCreateActivity } from '../../features/activity/activityHooks';
import { selectMerges } from '../../features/merge/mergeSlice';
import { LeaseProvider } from '../../context/LeaseContext';
import Construction from './Construction';

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}

const UnitMain = (props: any) => {
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const {
    unit,
    filteredDeal,
    setFilteredDeal,
    loading,
    unitLoading,
    setDocuments,
    documents,
    setPendingDeposits,
    removeDeposits,
    setDocument,
    updateDealDocuments,
    realtors,
    realtorLoading,
    assignment,
  } = useContext(UnitContext);
  const [tab, setTab] = useState<number>(0);
  const [statusList, setStatusList] = useState<IStatus[]>([]);
  const [floorPlan, setFloorPlan] = useState<any>(unit.getUrl);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogType, setDialogType] = useState<string>('');
  const [checked, setChecked] = useState<boolean>(false);
  const [selectedRealtor, setSelectedRealtor] = useState<IRealtorInfo>({});
  const [numIndex, setNumIndex] = useState<number>(1);
  const createActivity = useCreateActivity();

  useEffect(() => {
    setFloorPlan(unit.getUrl);
  }, [unit.getUrl]);

  useEffect(() => {
    getStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [updateDealRealtor] = useMutation(DELETEREALTORDEAL, {
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [addRealtor] = useMutation(ADDREALTOR, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Realtor has been added to the deal'));
      createActivity({
        project: project._id,
        user: user._id,
        deal: filteredDeal._id,
        title: `Add Realtor`,
        content: `Realtor has been added to the deal`,
      });
      let dealRealtorIds = filteredDeal.realtor.map((realtor: IDealRealtor) => realtor._id);
      updateDealRealtor({
        variables: { _id: filteredDeal._id, record: { realtor: [...dealRealtorIds, data.dealRealtorCreateOne.record._id] } },
      });
      setFilteredDeal({
        ...filteredDeal,
        realtor: [
          ...filteredDeal.realtor,
          {
            _id: data.dealRealtorCreateOne.record._id,
            firstName: data.dealRealtorCreateOne.record.firstName,
            lastName: data.dealRealtorCreateOne.record.lastName,
            brokerage: data.dealRealtorCreateOne.record.brokerage,
            email: data.dealRealtorCreateOne.record.email,
            streetAddress: data.dealRealtorCreateOne.record.streetAddress,
            city: data.dealRealtorCreateOne.record.city,
            province: data.dealRealtorCreateOne.record.province,
            country: data.dealRealtorCreateOne.record.country,
            postalCode: data.dealRealtorCreateOne.record.postalCode,
            brokeragePhone: data.dealRealtorCreateOne.record.brokeragePhone,
            directPhone: data.dealRealtorCreateOne.record.directPhone,
            brokerageFax: data.dealRealtorCreateOne.record.brokerageFax,
            coopRates: data.dealRealtorCreateOne.record.coopRates,
          },
        ],
      });
      updateAllocation({
        variables: {
          _id: unit._id,
          record: {
            allocation: selectedRealtor._id,
          },
        },
      });
      setSelectedRealtor({});
      setDialogOpen(false);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateAllocation] = useMutation(UPDATEUNIT, {
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [deleteDocument] = useMutation(DELETEDOCUMENT, {
    onCompleted: (data) => {
      let removedDocument = documents.filter((document: IDocuments) => {
        return data.documentDeleteById._id !== document._id;
      });
      setDocuments(removedDocument);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  function calculatePercent(status: string) {
    let percent = 100 / 5;
    switch (status) {
      case 'A':
        return 0;
      case 'P':
        return percent;
      case 'O':
        return percent * 2;
      case 'IP':
        return percent * 2;
      case 'S':
        return percent * 3;
      case 'C':
        return percent * 4;
      case 'F':
        return percent * 5;
      default:
        return 0;
    }
  }

  const getStatus = () => {
    let statusArray = [...unitStatusTitles];

    if (
      unit.status === 'A' ||
      unit.status !== 'CH' ||
      unit.status !== 'M' ||
      unit.status !== 'UT' ||
      unit.status !== 'W' ||
      unit.status !== 'WA' ||
      unit.status !== 'D'
    ) {
      statusArray.splice(
        statusArray.findIndex((v) => v.code === 'M'),
        1
      );
      statusArray.splice(
        statusArray.findIndex((v) => v.code === 'CH'),
        1
      );
      statusArray.splice(
        statusArray.findIndex((v) => v.code === 'UT'),
        1
      );
      statusArray.splice(
        statusArray.findIndex((v) => v.code === 'W'),
        1
      );
      statusArray.splice(
        statusArray.findIndex((v) => v.code === 'WA'),
        1
      );
      statusArray.splice(
        statusArray.findIndex((v) => v.code === 'D'),
        1
      );
    } else if (unit.status === 'CH') {
      statusArray.splice(
        statusArray.findIndex((v) => v.code === 'A'),
        1
      );
      statusArray.splice(
        statusArray.findIndex((v) => v.code === 'M'),
        1
      );
    } else if (unit.status === 'M') {
      statusArray.splice(
        statusArray.findIndex((v) => v.code === 'A'),
        1
      );
      statusArray.splice(
        statusArray.findIndex((v) => v.code === 'CH'),
        1
      );
    } else if (unit.status === 'UT') {
      statusArray.splice(
        statusArray.findIndex((v) => v.code === 'A'),
        1
      );
      statusArray.splice(
        statusArray.findIndex((v) => v.code === 'UT'),
        1
      );
    } else if (unit.status === 'W') {
      statusArray.splice(
        statusArray.findIndex((v) => v.code === 'A'),
        1
      );
      statusArray.splice(
        statusArray.findIndex((v) => v.code === 'W'),
        1
      );
    } else if (unit.status === 'WA') {
      statusArray.splice(
        statusArray.findIndex((v) => v.code === 'A'),
        1
      );
      statusArray.splice(
        statusArray.findIndex((v) => v.code === 'WA'),
        1
      );
    } else if (unit.status === 'D') {
      statusArray.splice(
        statusArray.findIndex((v) => v.code === 'A'),
        1
      );
      statusArray.splice(
        statusArray.findIndex((v) => v.code === 'D'),
        1
      );
    }
    setStatusList(statusArray);
  };

  const handleTabChange = (e: any) => {
    const index = tabList.map((item) => item).indexOf(e.target.value);
    setTab(index);
  };

  const handleCloseSuccess = async () => {
    if (dialogType === 'createCoop') {
      const { _id, ...newObject } = selectedRealtor;
      newObject.deal = filteredDeal._id;
      newObject.project = project._id;
      newObject.realtor = _id;
      addRealtor({ variables: { record: newObject } });
    } else if (dialogType === 'deleteDeposits') {
      let selectedDocument = documents.find((document: IDocuments) => document.name === 'Deposit Amendment');
      if (filteredDeal.pendingDeposits.length > 0) {
        let pending = filteredDeal.pendingDeposits.map((pendingDeposits: IDealDeposit) => {
          return pendingDeposits._id;
        });
        await removeDeposits({ variables: { filter: { depositId: pending } } });
      }
      if (selectedDocument) {
        deleteDocument({ variables: { _id: selectedDocument._id, deleteFile: true, deal: filteredDeal._id } });
        storeDispatch(showSuccessSnackbar(`Document has been deleted`));
      }
      setPendingDeposits([]);
      setDocument(null);
      setDialogOpen(false);
    }
  };

  const openDialog = async (type: string) => {
    await setDialogType(type);
    await setDialogOpen(true);
  };

  const handleIndexChange = (index: number) => {
    setNumIndex(index);
  };

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(!checked);
  };

  const dialogContent = () => {
    if (dialogType === 'addRealtor') {
      return realtorLoading ? (
        <LoadingWrapper title="Realtors is loading..." modal={false} />
      ) : (
        <div>
          <FormControl
            sx={{
              width: '100%',
              '& .MuiFormLabel-asterisk': {
                color: 'red',
              },
            }}
          >
            <Autocomplete
              sx={{
                width: '100%',
                '& .MuiFormLabel-asterisk': {
                  color: 'red',
                },
              }}
              id={'search'}
              disableClearable
              freeSolo={false}
              options={realtors}
              getOptionLabel={(option: any) => `${option.firstName} ${option.lastName} ${option.email}`}
              // getOptionSelected={(option, value) => option === value}
              onChange={(e, values) => setSelectedRealtor(values)}
              renderInput={(params) => (
                <TextField required={false} {...params} size="small" label={'Search By Name or Email'} margin="normal" />
              )}
            />
          </FormControl>
        </div>
      );
    }
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setDialogType('');
  };

  const dialogBox = () => {
    let removeButton = 'Cancel';
    let successButton = 'Yes, I would like to rescind this deal.';
    let title = 'Are you sure you would like to rescind?';
    if (dialogType === 'transfer') {
      successButton = 'Create Unit Transfer Amendment';
      title = 'Are you sure you would like to transfer?';
    }

    return (
      <CustomDialog
        handleClose={() => closeDialog()}
        handleCloseRemove={() => closeDialog()}
        handleCloseSuccess={handleCloseSuccess}
        open={dialogOpen}
        removeButton={removeButton}
        successButton={successButton}
        secondOption={dialogType === 'cancel' ? 'Void and Recreate' : null}
        dialogContent={dialogContent()}
        dialogTitle={title}
        checked={checked}
        maxWidth={'xl'}
      />
    );
  };

  const tabList = ['Active Deal', 'Floorplan', 'Notes', 'History', 'Adjustments', 'Construction', 'Decor', 'Assignment', 'Leasing'];

  return (
    <div>
      {loading || unitLoading ? (
        <Box
          sx={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            '-webkit-transform': 'translate(-50%, -50%)',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <LoadingLogo />
        </Box>
      ) : (
        <>
          {dialogBox()}
          <Box
            sx={{
              backgroundColor:
                (filteredDeal && !filteredDeal.cancelled?.dateCancelled && !filteredDeal.rescission?.dateRescinded) || !filteredDeal
                  ? '#412920'
                  : 'darkred',
              color: '#fff',
              padding: 2,
            }}
          >
            <div>
              <h1 style={{ margin: 0 }}>Suite {unit.suite}</h1>
              {filteredDeal && (filteredDeal.cancelled?.dateCancelled || filteredDeal.rescission?.dateRescinded) ? (
                <div style={{ color: '#fff', fontSize: '22px' }}>
                  <strong>
                    <em>You are currently viewing a collapsed or rescinded deal.</em>
                  </strong>
                </div>
              ) : null}
            </div>
            <div>
              <StepProgressBar percent={calculatePercent(unit.status)} steps={statusList} />
            </div>
            <div className="dropdown">
              <Dropdown
                title={'Select an Option'}
                menuList={tabList}
                name={'options'}
                handleSelect={(e: any) => handleTabChange(e)}
                value={tabList[tab]}
              />
            </div>
          </Box>
          <Box sx={{ p: 0 }}>
            <ul
              className={`${
                (filteredDeal && !filteredDeal.cancelled?.dateCancelled && !filteredDeal.rescission?.dateRescinded) || !filteredDeal
                  ? 'tabs group tabColor'
                  : 'tabs group cancelledColor'
              }`}
            >
              {tabList.map((tabName: string, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => setTab(index)}
                    className={`${
                      tab === index ? 'active' : tabName === 'Assignment' && assignment && assignment.current ? 'assignment' : ''
                    }`}
                  >
                    <span>{tabName}</span>
                  </li>
                );
              })}
            </ul>
            <TabPanel
              sx={{
                '& .MuiBox-root': {
                  padding: '12px 0px',
                },
              }}
              value={tab}
              index={0}
            >
              <DealInfo
                openDialog={openDialog}
                checked={checked}
                handleCheckChange={handleCheckChange}
                handleIndexChange={handleIndexChange}
              />
            </TabPanel>
            <TabPanel
              sx={{
                '& .MuiBox-root': {
                  padding: '12px 0px',
                },
              }}
              value={tab}
              index={1}
            >
              <Floorplan merge={floorPlan} />
            </TabPanel>
            <TabPanel
              sx={{
                '& .MuiBox-root': {
                  padding: '12px 0px',
                },
              }}
              value={tab}
              index={2}
            >
              <Comments />
            </TabPanel>
            <TabPanel
              sx={{
                '& .MuiBox-root': {
                  padding: '12px 0px',
                },
              }}
              value={tab}
              index={3}
            >
              <UnitHistory unit={unit} />
            </TabPanel>
            <TabPanel
              sx={{
                '& .MuiBox-root': {
                  padding: '12px 0px',
                },
              }}
              value={tab}
              index={4}
            >
              <Adjustments />
            </TabPanel>
            <TabPanel
              sx={{
                '& .MuiBox-root': {
                  padding: '12px 0px',
                },
              }}
              value={tab}
              index={5}
            >
              <Construction />
            </TabPanel>
            <TabPanel
              sx={{
                '& .MuiBox-root': {
                  padding: '12px 0px',
                },
              }}
              value={tab}
              index={6}
            >
              <ColourSelection
                client={false}
                unit={unit}
                filteredDeal={filteredDeal}
                project={project}
                updateDealDocuments={updateDealDocuments}
                documents={documents}
                setDocuments={setDocuments}
              />
            </TabPanel>
            <TabPanel
              sx={{
                '& .MuiBox-root': {
                  padding: '12px 0px',
                },
              }}
              value={tab}
              index={7}
            >
              <Assignment />
            </TabPanel>
            <TabPanel
              sx={{
                '& .MuiBox-root': {
                  padding: '12px 0px',
                },
              }}
              value={tab}
              index={8}
            >
              <LeaseProvider>
                <Leasing />
              </LeaseProvider>
            </TabPanel>
          </Box>
        </>
      )}
    </div>
  );
};

const ADDREALTOR = gql`
  mutation dealRealtorCreateOne($record: CreateOneDealRealtorInput!) {
    dealRealtorCreateOne(record: $record) {
      record {
        _id
        firstName
        lastName
        brokerage
        email
        streetAddress
        city
        province
        country
        postalCode
        brokeragePhone
        directPhone
        brokerageFax
        coopRates {
          type
          days
          date
          description
          percentage
          fixedAmount
        }
      }
    }
  }
`;

const DELETEREALTORDEAL = gql`
  mutation dealUpdateById($_id: MongoID!, $record: UpdateByIdDealInput!) {
    dealUpdateById(_id: $_id, record: $record) {
      record {
        realtor {
          _id
        }
      }
    }
  }
`;

const DELETEDOCUMENT = gql`
  mutation documentDeleteById($_id: MongoID!, $deleteFile: Boolean!) {
    documentDeleteById(_id: $_id, deleteFile: $deleteFile) {
      name
      status
      _id
    }
  }
`;

const UPDATEUNIT = gql`
  mutation unitUpdateById($_id: MongoID!, $record: UpdateByIdUnitInput!) {
    unitUpdateById(_id: $_id, record: $record) {
      record {
        _id
        suite
        unit
        level
        modelType
        basePrice
        size
        status
        getUrl
        putUrl
        bathroom
        exposure
        status
        outdoorSize
        outdoorType
        unitType
        originalPrice
        basePrice
        allocation {
          _id
          fullName
        }
        history {
          type
          description
          timestamp
          user {
            _id
            fullName
          }
          _id
        }
      }
    }
  }
`;

export default UnitMain;
