import { useMemo, useState } from 'react';
import { Box } from '@mui/material';
import BasicTabs from '../../common/Tabs';
import Summary from './Summary';
import Agreements from './Agreements';
import Report from './Report';
import Units from './Units';
import AvailableSuites from './AvailableSuites';
import ExpiredListings from './ExpiredListings';

const Leasing = () => {
  const [value, setValue] = useState<number>(0);

  const tabs = useMemo(() => {
    return [
      {
        label: 'Summary',
        component: <Summary />,
      },
      {
        label: 'Reports',
        component: <Report />,
      },
      {
        label: 'Agreements',
        component: <Agreements />,
      },
      {
        label: 'Available',
        component: <AvailableSuites />,
      },
      {
        label: 'Expired Listings',
        component: <ExpiredListings />,
      },
      {
        label: 'Units',
        component: <Units />,
      },
    ];
  }, []);

  return (
    <Box sx={{ mt: 2 }}>
      <BasicTabs color="#00142a" tabs={tabs} sub={false} value={value} setValue={setValue} />
    </Box>
  );
};

export default Leasing;
