import { useState, useMemo, useEffect } from 'react';
import { gql, useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import { Container } from '../../../commonStyles';
import BasicTabs from '../../common/Tabs';
import StandardTable from '../../tables/StandardTable';
import { numToCurrency } from '../../../utils/Functions';

const Summary = () => {
  const [value, setValue] = useState<number>(0);
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    if (!value) {
      getSummaries({ variables: { type: 'tenancy' } });
    } else if (value === 1) {
      getSummaries({ variables: { type: 'listing' } });
    } else if (value === 2) {
      getSummaries({ variables: { type: 'management' } });
    }
  }, [value]);

  const [getSummaries, { loading: previewLoading }] = useLazyQuery(GETSUMMARIES, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      setData(data.leaseSummaries);
    },
    onError: (err: any) => {
      console.log(err, 'err');
    },
  });

  const tenancyColumns = useMemo(() => {
    return [
      {
        Header: 'Project',
        accessor: (rowData: any) => {
          return <strong>{rowData._id}</strong>;
        },
      },
      {
        Header: 'Active Completed Leases',
        accessor: (rowData: any) => {
          return <strong>{rowData.completedActiveSum}</strong>;
        },
      },
      {
        Header: 'Active Pending Leases',
        accessor: (rowData: any) => {
          return <strong>{rowData.pendingActiveSum}</strong>;
        },
      },
      {
        Header: 'Past Completed Leases',
        accessor: (rowData: any) => {
          return <strong>{rowData.completedInactiveSum}</strong>;
        },
      },
      {
        Header: 'Total Lease Amount',
        accessor: (rowData: any) => numToCurrency.format(rowData.amountSum),
      },
    ];
  }, []);

  const listingColumns = useMemo(() => {
    return [
      {
        Header: 'Project',
        accessor: (rowData: any) => {
          return <strong>{rowData._id}</strong>;
        },
      },
      {
        Header: 'Active Completed Listings',
        accessor: (rowData: any) => {
          return <strong>{rowData.completedActiveSum}</strong>;
        },
      },
      {
        Header: 'Active Pending Listings',
        accessor: (rowData: any) => {
          return <strong>{rowData.pendingActiveSum}</strong>;
        },
      },
      {
        Header: 'Past Completed Listings',
        accessor: (rowData: any) => {
          return <strong>{rowData.completedInactiveSum}</strong>;
        },
      },
      {
        Header: 'Total Listing Amount',
        accessor: (rowData: any) => numToCurrency.format(rowData.amountSum),
      },
    ];
  }, []);

  const tabs = useMemo(() => {
    return [
      {
        label: 'Tenancy',
        component: (
          <Box sx={{ p: 2 }}>
            <StandardTable columns={tenancyColumns} data={data} />
          </Box>
        ),
      },
      {
        label: 'Listing',
        component: (
          <Box sx={{ p: 2 }}>
            <StandardTable columns={listingColumns} data={data} />
          </Box>
        ),
      },
      {
        label: 'Rental Management',
        component: (
          <Box sx={{ p: 2 }}>
            <StandardTable columns={tenancyColumns} data={data} />
          </Box>
        ),
      },
    ];
  }, [data, tenancyColumns]);

  return (
    <Container sx={{ backgroundColor: '#fff', pt: 0, px: 0 }}>
      <BasicTabs color="secondary.main" tabs={tabs} sub={false} value={value} setValue={setValue} />
    </Container>
  );
};

const GETSUMMARIES = gql`
  query leaseSummaries($type: String!) {
    leaseSummaries(type: $type) {
      _id
      pendingActiveSum
      completedActiveSum
      completedInactiveSum
      amountSum
    }
  }
`;

export default Summary;
