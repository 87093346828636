import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery, useMutation, useSubscription, useLazyQuery } from '@apollo/client';
import { IUnit, IUnitDataGridCard } from '../types/unit';
import { IDeal, IPurchaserInfo, IRealtorsArray, IRealtorInfo } from '../types/CreateDealForm';
import { useSelector } from 'react-redux';
import { selectProject } from '../features/project/projectSlice';
import { IAssignment } from '../types/assignment';

const UnitContext = React.createContext<any>(null);

const UnitProvider = (props: any) => {
  const { unitid } = useParams();
  const project = useSelector(selectProject);
  const [unit, setUnit] = useState<Partial<IUnit>>({});
  const [pastDeals, setPastDeals] = useState<IDeal[]>([]);
  const [filteredDeal, setFilteredDeal] = useState<any>(null);
  const [originalPurchasers, setOriginalPurchasers] = useState<IPurchaserInfo[]>([]);
  const [realtors, setRealtors] = useState<IRealtorInfo | {}>({});
  const [assignment, setAssignment] = useState<IAssignment | null>(null);

  const { loading: unitLoading } = useQuery<IUnitDataGridCard>(GETUNIT, {
    variables: { _id: unitid },
    onCompleted: (data) => {
      setUnit(data.unitById);
    },
  });
  const unitId: any = unit._id;

  const { loading } = useQuery(GETDEAL, {
    fetchPolicy: 'cache-first',
    variables: {
      filter: {
        unit: unitid,
        project: project._id,
        AND: [{ cancelled: { dateCancelled: null } }, { draft: false }, { rescission: { dateRescinded: null } }],
      },
    },
    onCompleted: (data) => {
      setFilteredDeal(data.dealOne);
      if (data.dealOne) {
        setOriginalPurchasers(data.dealOne.purchasers);
      }
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  useQuery(ASSIGNMENT, {
    fetchPolicy: 'cache-first',
    variables: { filter: { project: project._id, unit: unitid, current: true } },
    onCompleted: (data) => {
      setAssignment(data.assignmentOne);
    },
  });

  const [getRealtors, { loading: realtorLoading }] = useLazyQuery<IRealtorsArray>(REALTORS, {
    onCompleted: (data) => setRealtors(data.realtorMany),
  });

  useSubscription(DEALSUBSCRIPTION, {
    variables: { projectId: project._id },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      let selectedDeal = pastDeals?.find((deal: IDeal) => data.logDeal._id === deal._id);
      if (selectedDeal) {
        if (selectedDeal.cancelled.dateCancelled) {
          setFilteredDeal(null);
        }
      }
    },
  });

  useSubscription(UNITSUBSCRIPTION, {
    variables: { projectId: project._id },
    onData: async ({ subscriptionData: { data } }: any) => {
      if (unitId === data._id) {
        setUnit(data.logUnit);
      }
    },
  });

  const [updatePendingDeposit] = useMutation(UPDATEPENDING, {
    onCompleted: (data) => {
      setFilteredDeal({
        ...filteredDeal,
        pendingDeposits: data.dealUpdateById.record.pendingDeposits,
        pendingOptions: data.dealUpdateById.record.pendingOptions,
        pendingUnit: data.dealUpdateById.record.pendingUnit,
      });
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateDealDocuments, { loading: dealUpdateLoading }] = useMutation(UPDATEDEALDOCUMENTS, {
    onCompleted: (data) => {
      if (data.dealUpdateById.record.pendingDeposits.length) {
        setFilteredDeal({
          ...filteredDeal,
          pendingDeposits: data.dealUpdateById.record.pendingDeposits,
          pendingOptions: data.dealUpdateById.record.pendingOptions,
          pendingUnit: data.dealUpdateById.record.pendingUnit,
        });
      }
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [removeDeposits] = useMutation(REMOVEDEPOSITS, {
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  return unitLoading ? null : (
    <UnitContext.Provider
      value={{
        unit,
        setUnit,
        pastDeals,
        setPastDeals,
        filteredDeal,
        setFilteredDeal,
        loading,
        unitLoading,
        updatePendingDeposit,
        updateDealDocuments,
        dealUpdateLoading,
        removeDeposits,
        document,
        originalPurchasers,
        realtors,
        getRealtors,
        assignment,
        setAssignment,
        realtorLoading,
      }}
    >
      {props.children}
    </UnitContext.Provider>
  );
};

const DEALSUBSCRIPTION = gql`
  subscription logDeal($projectId: String!) {
    logDeal(projectId: $projectId) {
      _id
      project {
        _id
        decorModels {
          modelType
          allowed
        }
        portal {
          primaryColor
        }
      }
      unit {
        _id
        status
      }
      purchasers {
        _id
        email
        firstName
        lastName
        fullName
        corp
        sin
        dob
        streetAddress
        city
        province
        country
        postalCode
        occupation
        employer
        directors
        businessNumber
        signingOfficers {
          fullName
          dob
          sin
          primaryPhone
          streetAddress
          email
        }
        purchaserType
        primaryPhone
        idType
        idNumber
        idExpiry
        proofExpiry
        proofNumber
        proofType
        unit
        idJurisdiction
        getUrl
        putUrl
      }
      realtor {
        _id
        email
        firstName
        lastName
        brokerage
        streetAddress
        city
        province
        country
        postalCode
        brokeragePhone
        brokerageFax
        directPhone
        coopRates {
          type
          days
          date
          description
          percentage
          fixedAmount
        }
      }
      depositName
      deposit {
        _id
        name
        amount
        dueDate
        accountNumber
        chequeNumber
        clearedDate
        chequeDate
        chequeType
        chequeAmount
        deal {
          _id
        }
      }
      pendingDeposits {
        _id
        name
        amount
        dueDate
        accountNumber
        chequeNumber
        chequeAmount
        chequeDate
        chequeType
        deal {
          _id
        }
      }
      joinWaitlist
      options {
        name
        purchaseAmount
        amount
      }
      documents {
        _id
        name
        project {
          _id
        }
        status
        isAPS
        type
        dsEnvelopeId
        getUrl
        createdAt
        updatedAt
      }
      basePrice
      totalPrice
      tags
      mortgage {
        revisions
        lender
        amount
        getUrl
        putUrl
        uploadDate
      }
      debit {
        name
        institution
        branch
        account
        accountHolders {
          fullName
          email
        }
      }
      solicitor {
        solicitor
        firm
        streetAddress
        city
        province
        postalCode
        email
        primaryPhone
      }
      rescission {
        dateRescinded
        reason
      }
      cancelled {
        dateCancelled
        reason
        type
      }
      salesRep {
        _id
        fullName
      }
      auditor {
        _id
        fullName
      }
      auditDate
      draft
      preparedDate
      signDate
      executeDate
      firmDate
      createdAt
    }
  }
`;

const UNITSUBSCRIPTION = gql`
  subscription logUnit($projectId: String!) {
    logUnit(projectId: $projectId) {
      _id
      suite
      modelType
      unitType
      bathroom
      size
      outdoorType
      basePrice
      originalPrice
      tier
      level
      exposure
      outdoorSize
      notes
      col
      row
      allocation {
        _id
        fullName
      }
      allocatedDate
      tempAllocation
      status
      getUrl
      history {
        type
        description
        timestamp
        user {
          _id
          fullName
        }
        _id
      }
    }
  }
`;

const GETUNIT = gql`
  query unitById($_id: MongoID!) {
    unitById(_id: $_id) {
      _id
      suite
      unit
      level
      modelType
      modelName
      basePrice
      size
      status
      getUrl
      putUrl
      bathroom
      rental
      exposure
      status
      outdoorSize
      outdoorType
      maintenanceFee
      unitType
      originalPrice
      basePrice
      custom
      tier
      type
      firstTentativeOccupancy
      finalTentativeOccupancy
      firmOccupancy
      outsideOccupancy
      allocation {
        _id
        fullName
      }
      history {
        type
        description
        timestamp
        user {
          _id
          fullName
        }
        _id
      }
      upgradeSetOne {
        date
        salesRep {
          _id
          fullName
        }
        collectionTemplate {
          _id
          name
          upgradeTemplates {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            schemes
            unitsAllowed
            modelsAllowed
            sessions
            freeUpgrades
            upgradeType
          }
          unitsAllowed
          modelsAllowed
        }
        upgradeSet {
          _id
          name
          price
          mainCategory
          subCategory
          quantity
          upgradeTemplate {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            unitsAllowed
            modelsAllowed
            freeUpgrades
            upgradeType
            getUrl
          }
        }
      }
      upgradeSetTwo {
        date
        salesRep {
          _id
          fullName
        }
        collectionTemplate {
          _id
          name
          upgradeTemplates {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            schemes
            unitsAllowed
            modelsAllowed
            sessions
            freeUpgrades
            upgradeType
          }
          unitsAllowed
          modelsAllowed
        }
        upgradeSet {
          _id
          name
          price
          mainCategory
          subCategory
          quantity
          upgradeTemplate {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            unitsAllowed
            modelsAllowed
            freeUpgrades
            upgradeType
            getUrl
          }
        }
      }
      upgradeSetThree {
        date
        salesRep {
          _id
          fullName
        }
        collectionTemplate {
          _id
          name
          upgradeTemplates {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            schemes
            unitsAllowed
            modelsAllowed
            sessions
            freeUpgrades
            upgradeType
          }
          unitsAllowed
          modelsAllowed
        }
        upgradeSet {
          _id
          name
          price
          mainCategory
          subCategory
          quantity
          upgradeTemplate {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            unitsAllowed
            modelsAllowed
            freeUpgrades
            upgradeType
            getUrl
          }
        }
      }
    }
  }
`;

const GETDEAL = gql`
  query dealOne($filter: FilterFindOneDealInput) {
    dealOne(filter: $filter) {
      _id
      project {
        _id
        decorModels {
          modelType
          allowed
        }
        tags
      }
      unit {
        _id
        status
        rental
        modelType
        unitType
      }
      purchasers {
        _id
        email
        firstName
        lastName
        identifications {
          _id
          name
          getUrl
        }
        corp
        sin
        dob
        streetAddress
        city
        province
        country
        postalCode
        occupation
        employer
        directors
        businessNumber
        signingOfficers {
          fullName
          dob
          sin
          primaryPhone
          streetAddress
          email
        }
        purchaserType
        primaryPhone
        idType
        idNumber
        idExpiry
        proofExpiry
        proofNumber
        proofType
        unit
        idJurisdiction
        getUrl
        putUrl
      }
      realtor {
        _id
        email
        firstName
        lastName
        fullName
        brokerage
        streetAddress
        city
        province
        country
        postalCode
        brokeragePhone
        brokerageFax
        directPhone
        coopRates {
          type
          days
          date
          description
          percentage
          fixedAmount
        }
      }
      depositName
      pendingBasePrice
      pendingRental
      deposit {
        _id
        name
        amount
        dueDate
        accountNumber
        chequeNumber
        clearedDate
        chequeDate
        chequeType
        chequeAmount
        deal {
          _id
        }
      }
      additionalDeposits {
        _id
        name
        amount
        dueDate
        accountNumber
        chequeNumber
        chequeDate
        chequeType
        chequeAmount
        deal {
          _id
        }
      }
      pendingDeposits {
        _id
        name
        amount
        dueDate
        accountNumber
        chequeNumber
        chequeAmount
        chequeDate
        chequeType
        deal {
          _id
        }
      }
      pendingOptions {
        name
        purchaseAmount
        amount
      }
      pendingUnit {
        basePrice
        suite
        _id
      }
      joinWaitlist
      options {
        name
        purchaseAmount
        amount
      }
      documents {
        _id
        name
        project {
          _id
        }
        status
        isAPS
        type
        dsEnvelopeId
        getUrl
        createdAt
        updatedAt
      }
      basePrice
      totalPrice
      tags
      mortgage {
        _id
        revisions
        lender
        amount
        getUrl
        putUrl
        uploadDate
      }
      debit {
        name
        institution
        branch
        account
        accountHolders {
          fullName
          email
        }
      }
      solicitor {
        solicitor
        firm
        streetAddress
        city
        province
        postalCode
        email
        primaryPhone
      }
      miscellaneous {
        _id
        name
        getUrl
      }
      rescission {
        dateRescinded
        reason
      }
      cancelled {
        dateCancelled
        type
        reason
      }
      salesRep {
        _id
        fullName
      }
      depositImages {
        _id
        name
        getUrl
      }
      thirdParty {
        _id
        fullName
        streetAddress
        dob
        primaryPhone
        email
        occupation
        corpNumber
        relationship
      }
      worksheet {
        _id
        status
      }
      adjustments {
        name
        type
        adjustmentType
        percentage
        value
        description
        approval
      }
      construction {
        name
        type
        value
        description
        approval
        requiresChange
        tbd
        completed
      }
      upgradeSetOne {
        date
        salesRep {
          _id
          fullName
        }
        collectionTemplate {
          _id
          name
          upgradeTemplates {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            schemes
            unitsAllowed
            modelsAllowed
            sessions
            freeUpgrades
            upgradeType
          }
          unitsAllowed
          modelsAllowed
        }
        upgradeSet {
          _id
          name
          price
          mainCategory
          subCategory
          quantity
          upgradeTemplate {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            unitsAllowed
            modelsAllowed
            freeUpgrades
            upgradeType
            getUrl
          }
        }
      }
      upgradeSetTwo {
        date
        salesRep {
          _id
          fullName
        }
        collectionTemplate {
          _id
          name
          upgradeTemplates {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            schemes
            unitsAllowed
            modelsAllowed
            sessions
            freeUpgrades
            upgradeType
          }
          unitsAllowed
          modelsAllowed
        }
        upgradeSet {
          _id
          name
          price
          mainCategory
          subCategory
          quantity
          upgradeTemplate {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            unitsAllowed
            modelsAllowed
            freeUpgrades
            upgradeType
            getUrl
          }
        }
      }
      upgradeSetThree {
        date
        salesRep {
          _id
          fullName
        }
        collectionTemplate {
          _id
          name
          upgradeTemplates {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            schemes
            unitsAllowed
            modelsAllowed
            sessions
            freeUpgrades
            upgradeType
          }
          unitsAllowed
          modelsAllowed
        }
        upgradeSet {
          _id
          name
          price
          mainCategory
          subCategory
          quantity
          upgradeTemplate {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            unitsAllowed
            modelsAllowed
            freeUpgrades
            upgradeType
            getUrl
          }
        }
      }
      draft
      preparedDate
      signDate
      executeDate
      firmDate
      auditor {
        _id
        fullName
      }
      auditDate
      createdAt
    }
  }
`;

const UPDATEPENDING = gql`
  mutation dealUpdateById($_id: MongoID!, $record: UpdateByIdDealInput!) {
    dealUpdateById(_id: $_id, record: $record) {
      record {
        pendingDeposits {
          _id
          name
          amount
          dueDate
          accountNumber
          chequeNumber
          chequeAmount
          chequeDate
          chequeType
          deal {
            _id
          }
        }
        pendingOptions {
          name
          purchaseAmount
          amount
        }
        pendingUnit {
          _id
          suite
          basePrice
          status
        }
      }
    }
  }
`;

const UPDATEDEALDOCUMENTS = gql`
  mutation dealUpdateById($_id: MongoID!, $record: UpdateByIdDealInput!) {
    dealUpdateById(_id: $_id, record: $record) {
      record {
        pendingDeposits {
          _id
          name
          amount
          dueDate
          accountNumber
          chequeNumber
          chequeAmount
          chequeDate
          chequeType
          deal {
            _id
          }
        }
        pendingOptions {
          name
          purchaseAmount
          amount
        }
        pendingUnit {
          _id
          suite
          basePrice
          status
        }
        documents {
          _id
        }
      }
    }
  }
`;

const REMOVEDEPOSITS = gql`
  mutation depositRemoveMany($filter: FilterRemoveManyDepositInput!) {
    depositRemoveMany(filter: $filter) {
      numAffected
    }
  }
`;

const REALTORS = gql`
  query realtorMany($filter: FilterFindManyRealtorInput) {
    realtorMany(filter: $filter, limit: 10000) {
      _id
      email
      firstName
      lastName
      brokerage
      streetAddress
      city
      province
      country
      postalCode
      brokeragePhone
      brokerageFax
      directPhone
    }
  }
`;

const ASSIGNMENT = gql`
  query assignmentOne($filter: FilterFindOneAssignmentInput) {
    assignmentOne(filter: $filter) {
      _id
      project {
        _id
      }
      unit {
        _id
      }
      signDate
      purchasers {
        _id
        firstName
        lastName
        fullName
      }
      current
    }
  }
`;

export { UnitContext, UnitProvider };
