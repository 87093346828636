import { useMemo, useContext, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import Dropzone, { useDropzone } from 'react-dropzone';
import { Box, Grid, Button } from '@mui/material';

import { baseStyle, activeStyle, acceptStyle, rejectStyle } from '../../../utils/Constants';
import { useAppDispatch } from '../../../app/hooks';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../features/snackbar/snackbarSlice';
import PdfCard from '../../common/PdfCard';
import { IMedia } from '../../../types/project';
import { ILease } from '../../../types/lease';
import { FlexEnd } from '../../../commonStyles';

const Miscellaneous = (props: ChildProps) => {
  const { lease } = props;
  const [miscellaneous, setMiscellaneous] = useState(lease.uploads);
  const storeDispatch = useAppDispatch();
  const { isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/gif, image/jpeg, image/png, application/pdf',
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const [createMedia] = useMutation(CREATELEASEMEDIA, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Media has been uploaded!'));
      setMiscellaneous(data.createLeaseMedia.uploads);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [deleteMedia] = useMutation(DELETELEASEMEDIA, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Media has been deleted!'));
      setMiscellaneous(data.deleteLeaseMedia.uploads);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [renameMedia] = useMutation(RENAMEMEDIA, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar(`Renamed to ${data.leaseRename}`));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const handleDrop = async (acceptedFiles: any) => {
    if (acceptedFiles.length === 0) {
      storeDispatch(showErrorSnackbar('This file type is not allowed'));
      return;
    }

    const files = acceptedFiles;
    let allFiles: any[] = [];
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        let filePromise = new Promise((resolve) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(files[i]);
          fileReader.onloadend = async () =>
            resolve({
              url: fileReader.result,
              file: files[i],
            });
        });
        allFiles.push(filePromise);
      }
    }
    Promise.all(allFiles).then((fileContents) => {
      setMiscellaneous([...miscellaneous, ...fileContents]);
    });
  };

  const handleEditDocuments = (value: string, id: string, numIndex: number) => {
    if (miscellaneous[numIndex].file) {
      let images = miscellaneous.map((image: any, index: number) => {
        if (numIndex === index) {
          const newFile = new File([image.file], value, { type: image.file.type });
          return {
            ...image,
            file: newFile,
          };
        } else return image;
      });
      setMiscellaneous(images);
    } else {
      renameMedia({
        variables: { _id: lease._id, project: lease.project._id, originalName: miscellaneous[numIndex].name, name: value },
      }).then((res: any) => {
        let images = miscellaneous.map((image: any, index: number) => {
          if (numIndex === index) {
            return {
              ...image,
              name: res.data.leaseRename,
            };
          } else return image;
        });
        setMiscellaneous(images);
      });
    }
  };

  const deletePdfs = (id: number, title: string, numIndex: number) => {
    if (id) {
      deleteMedia({ variables: { _id: lease._id, documentId: id, deleteFile: true } });
    } else {
      let removePdf = miscellaneous.filter((pdf: IMedia, index: number) => numIndex !== index);
      setMiscellaneous(removePdf);
    }
  };

  const handleSave = async () => {
    if (miscellaneous.length > 0) {
      await miscellaneous.forEach(async (file: any) => {
        if (file.file) {
          await createMedia({ variables: { id: lease._id, name: file.file.name, file: file.file } });
        }
      });
    }
  }

  return (
    <div>
      <div>
        <Dropzone onDrop={(files) => handleDrop(files)} accept="image/gif, image/jpg, image/jpeg, image/png, application/pdf">
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Drag and Drop or Upload Images</p>
              </div>
            </section>
          )}
        </Dropzone>
        <Box>
          <Grid container spacing={2}>
            {miscellaneous.length > 0
              ? miscellaneous.map((attachment: any, index: number) => {
                  return (
                    <Grid key={index} item lg={3} md={4} sm={6} xs={12}>
                      <PdfCard
                        file={attachment.getUrl ? attachment.getUrl : attachment.url}
                        title={attachment.name ? attachment.name : attachment.file.name}
                        id={attachment._id}
                        handleDelete={deletePdfs}
                        download={true}
                        index={index}
                        handleEdit={handleEditDocuments}
                      />
                    </Grid>
                  );
                })
              : null}
          </Grid>
        </Box>
        {miscellaneous.length ? 
          <FlexEnd>
            <Button onClick={() => handleSave()} variant="contained" color="primary">
              Save
            </Button>
          </FlexEnd>
        : null}
      </div>
    </div>
  );
};

interface ChildProps {
  lease: ILease;
}

const RENAMEMEDIA = gql`
  mutation leaseRename($_id: MongoID!, $project: MongoID!, $originalName: String!, $name: String!) {
    leaseRename(_id: $_id, project: $project, originalName: $originalName, name: $name)
  }
`;

const CREATELEASEMEDIA = gql`
  mutation createLeaseMedia($id: MongoID!, $name: String!, $file: Upload!) {
    createLeaseMedia(id: $id, name: $name, file: $file) {
      uploads {
        name
        getUrl
      }
    }
  }
`;

const DELETELEASEMEDIA = gql`
  mutation deleteLeaseMedia($_id: MongoID!, $documentId: MongoID!, $deleteFile: Boolean!) {
    deleteLeaseMedia(_id: $_id, documentId: $documentId, deleteFile: $deleteFile) {
      uploads {
        name
        getUrl
      }
    }
  }
`;

export default Miscellaneous;
