import { useState, useEffect, useMemo } from 'react';
import { useLazyQuery, gql } from '@apollo/client';
import { Box, Button } from '@mui/material';

import { IUser } from '../../types/user';
import StandardTable from '../tables/StandardTable';
import { convertAllDates } from '../../utils/Functions';
import BasicTabs from '../common/BasicTabs';
import { FlexEnd } from '../../commonStyles';
import { useAppDispatch } from '../../app/hooks';
import { showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';
import LoadingLogo from '../common/LoadingLogo';

const UserStats = () => {
  const storeDispatch = useAppDispatch();
  const [type, setType] = useState<string>('Admin');
  const [users, setUsers] = useState<IUser[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [value, setValue] = useState(0);
  const [count, setCount] = useState(0);

  const [getUsers, { loading }] = useLazyQuery(GETUSERS, {
    onCompleted: (data) => {
      setUsers(data.userPagination.items);
      setCount(data.userPagination.count);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [downloadLoginReport] = useLazyQuery(LOGINREPORT, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('An email will be sent to you shortly.'));
    },
    onError: (err) => {
      console.log(err);
    },
  });

  useEffect(() => {
    getUsers({ variables: { filter: { type: type, locked: false, isActive: true }, page: pageNumber, perPage: 25, sort: 'LOGIN_ASC' } });
  }, [type, pageNumber]);

  const handleValue = (value: number) => {
    if (value === 0) {
      setType('Admin');
    }
    if (value === 1) {
      setType('Sales');
    }
    if (value === 2) {
      setType('Manager');
    }
    if (value === 3) {
      setType('Developer');
    }
    if (value === 4) {
      setType('Marketing');
    }
    if (value === 5) {
      setType('Realtor');
    }
    setValue(value);
  };

  const columns = useMemo(() => {
    return [
      {
        Header: 'User',
        accessor: (rowData: any) => rowData.fullName,
      },
      {
        Header: 'Date of Login',
        accessor: (rowData: any) =>
          rowData.lastLogin && rowData.lastLogin.length ? convertAllDates(rowData.lastLogin[rowData.lastLogin.length - 1], 'PPpp') : 'N/A',
      },
    ];
  }, []);

  const tabs = [
    {
      label: 'Admin',
      component: (
        <Box sx={{ mt: 2 }}>
          <StandardTable count={count} data={users} columns={columns} />
        </Box>
      ),
    },
    {
      label: 'Sales',
      component: (
        <Box sx={{ mt: 2 }}>
          <StandardTable count={count} data={users} columns={columns} />
        </Box>
      ),
    },
    {
      label: 'Managers',
      component: (
        <Box sx={{ mt: 2 }}>
          <StandardTable count={count} data={users} columns={columns} />
        </Box>
      ),
    },
    {
      label: 'Developers',
      component: (
        <Box sx={{ mt: 2 }}>
          <StandardTable count={count} data={users} columns={columns} />
        </Box>
      ),
    },
    {
      label: 'Marketing',
      component: (
        <Box sx={{ mt: 2 }}>
          <StandardTable count={count} data={users} columns={columns} />
        </Box>
      ),
    },
    {
      label: 'Realtors',
      component: (
        <Box sx={{ mt: 2 }}>
          <StandardTable count={count} data={users} columns={columns} />
        </Box>
      ),
    },
  ];

  return loading ? (
    <Box
      sx={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        '-webkit-transform': 'translate(-50%, -50%)',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <LoadingLogo />
    </Box>
  ) : (
    <Box>
      <FlexEnd sx={{ mb: 2 }}>
        <Button color="primary" variant="contained" onClick={() => downloadLoginReport({ variables: { date: new Date() } })}>
          Download Pay Period
        </Button>
      </FlexEnd>
      <BasicTabs tabs={tabs} sub={true} value={value} setValue={handleValue} color={'#fff'} />
      <Box sx={{ textAlign: 'center', mt: 2 }}>
        <Button disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}>
          {'<'}
        </Button>
        <span>{pageNumber}</span>
        <Button disabled={pageNumber === Math.ceil(count / 20)} onClick={() => setPageNumber(pageNumber + 1)}>
          {'>'}
        </Button>
      </Box>
    </Box>
  );
};

const GETUSERS = gql`
  query userPagination($filter: FilterFindManyUserInput, $page: Int!, $perPage: Int!, $sort: SortFindManyUserInput) {
    userPagination(filter: $filter, page: $page, perPage: $perPage, sort: $sort) {
      items {
        _id
        fullName
        email
        type
        locked
        lastLogin
      }
      count
    }
  }
`;

const LOGINREPORT = gql`
  query userLoginReport($date: Date) {
    userLoginReport(date: $date)
  }
`;

export default UserStats;
