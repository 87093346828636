import { useState, useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TextField, Box, Button, Typography, Divider } from '@mui/material';
import { numToCurrency, downloadExcel, downloadPdf } from '../../utils/Functions';
import { Flex } from '../../commonStyles';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import StandardTable from '../tables/StandardTable';
import { IDeal, IPurchaserInfo, IRealtorInfo } from '../../types/CreateDealForm';
import BasicTabs from '../common/BasicTabs';
import { IComment } from '../../types/comment';
import { GlobalModal } from '../../features/modal/Modal';
import { useAppDispatch } from '../../app/hooks';
import { handleModal } from '../../features/modal/modalSlice';

const MortgageReport = () => {
  const project = useSelector(selectProject);
  const storeDispatch = useAppDispatch();
  const [dateStart, setDateStart] = useState<Date | null>(new Date(new Date().setHours(0, 0, 0, 0)));
  const [dateEnd, setDateEnd] = useState<Date | null>(new Date());
  const [mortgages, setMortgages] = useState<IDeal[]>([]);
  const [completeMortgages, setCompleteMortgages] = useState<number>(0);
  const [incompleteMortgages, setIncompleteMortgages] = useState<number>(0);
  const [conditionals, setConditionals] = useState<number>(0);
  const [conditionalMortgages, setConditionalMortgages] = useState<number>(0);
  const [firms, setFirms] = useState<number>(0);
  const [firmMortgages, setFirmMortgages] = useState<number>(0);
  const [value, setValue] = useState(0);

  const [incompleteMortgageDeals, setIncompleteMortgageDeals] = useState<IDeal[]>([]);
  const [missingMortgageDeals, setMissingMortgageDeals] = useState<IDeal[]>([]);

  const [getMortgageSummary] = useLazyQuery(MORTGAGE, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setCompleteMortgages(data.getMortgagesReceived.completeMortgages);
      setIncompleteMortgages(data.getMortgagesReceived.incompleteMortgages);
      setFirms(data.getMortgagesReceived.firms);
      setFirmMortgages(data.getMortgagesReceived.firmMortgages);
      setConditionals(data.getMortgagesReceived.conditionals);
      setConditionalMortgages(data.getMortgagesReceived.conditionalMortgages);
    },
  });

  const [getMortgageDeals] = useLazyQuery(MORTGAGEDEALS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {},
  });

  useEffect(() => {
    getMortgageSummary({ variables: { projects: [project._id], dateStart, dateEnd } });
    getMortgageDeals({ variables: { project: project._id, type: 'incomplete' } }).then((res) => {
      setIncompleteMortgageDeals(res.data.getMortgageDeals);
    });
  }, []);

  useEffect(() => {
    if (value) {
      getMortgageDeals({ variables: { project: project._id, type: 'missing' } }).then((res) => {
        setMissingMortgageDeals(res.data.getMortgageDeals);
      });
    }
  }, [value]);

  const handleMortgageSummary = () => {
    getMortgageSummary({ variables: { projects: [project._id], dateStart, dateEnd } });
  };

  const columns = useMemo(() => {
    return [
      {
        Header: 'Suite',
        accessor: (rowData: any) => {
          return (
            <Link
              rel="noopener noreferrer"
              target="_blank"
              style={{ textDecoration: 'none', color: 'inherit' }}
              to={`/${project._id}/dashboard/${rowData.unit._id}`}
            >
              <strong>{rowData.unit.suite}</strong>
            </Link>
          );
        },
      },
      {
        Header: 'Status',
        accessor: (rowData: any) => rowData.unit.status,
      },
      {
        Header: 'Purchaser',
        accessor: (rowData: any) => {
          return rowData.purchasers.map((purchaser: IPurchaserInfo) => {
            return (
              <div>{purchaser.fullName} ({purchaser.email})</div>
            )
          })
        },
      },
      {
        Header: 'Realtor',
        accessor: (rowData: any) => {
          return rowData.realtor.map((realtor: IRealtorInfo) => {
            return (
              <div>{realtor.fullName} ({realtor.email})</div>
            )
          })
        },
      },
      {
        Header: 'Mortgage File',
        accessor: (rowData: any) => {
          if (rowData.mortgage.getUrl) {
            return (
              <a style={{ color: '#000' }} href={rowData.mortgage.getUrl} target="_blank" rel="noopener noreferrer">
                View Pdf
              </a>
            );
          } else {
            return 'No File Uploaded';
          }
        },
      },
      {
        Header: 'Mortgage Amount',
        accessor: (rowData: any) => (rowData.mortgage.amount ? numToCurrency.format(rowData.mortgage.amount) : 'Missing'),
      },
      {
        Header: 'Mortgage Lender',
        accessor: (rowData: any) => (rowData.mortgage.lender ? rowData.mortgage.lender : 'Missing'),
      },
      {
        Header: 'Mortgage Revisions',
        accessor: (rowData: any) => (rowData.mortgage.revisions.length ? rowData.mortgage.revisions.join(', ') : 'No Revisions'),
      },
      {
        Header: 'Notes',
        accessor: (rowData: any) =>
          rowData.comments.length ? rowData.comments.map((comment: IComment) => comment.comment).join(', ') : 'No Notes',
      },
    ];
  }, [project._id]);

  const download = (type: string, data: any) => {
    let headers = [
      {
        label: 'Suite',
        id: 'suite',
      },
      {
        label: 'Status',
        id: 'status',
      },
      {
        label: 'Purchasers',
        id: 'purchasers'
      },
      {
        label: 'Realtors',
        id: 'realtors'
      },
      {
        label: 'Mortgage File',
        id: 'file',
      },
      {
        label: 'Mortgage Amount',
        id: 'amount',
      },
      {
        label: 'Mortgage Lender',
        id: 'lender',
      },
      {
        label: 'Mortgage Revisions',
        id: 'revisions',
      },
      {
        label: 'Notes',
        id: 'notes',
      },
    ];

    let widths = {
      suite: 15,
      status: 15,
      purchasers: 15,
      realtors: 15,
      file: 15,
      amount: 15,
      lender: 15,
      revisions: 15,
      notes: 15,
    };

    let pdfWidths = {
      suite: 200,
      status: 200,
      purchasers: 200,
      realtors: 200,
      file: 200,
      amount: 200,
      lender: 200,
      revisions: 200,
      notes: 200,
    };

    let newData = data.map((data: any) => {
      return {
        suite: data.unit.suite,
        status: data.unit.status,
        purchasers: data.purchasers.map((purchaser: IPurchaserInfo) => `${purchaser.fullName} (${purchaser.email})`).join(', '),
        realtors: data.realtor.map((realtor: IRealtorInfo) => `${realtor.fullName} (${realtor.email})`).join(', '),
        file: data.mortgage.getUrl ? 'Received' : 'Missing',
        amount: data.mortgage.amount ? `${numToCurrency.format(data.mortgage.amount)}` : 'Missing',
        lender: data.mortgage.lender ? `${data.mortgage.lender}` : 'Missing',
        revisions: data.mortgage.revisions.length ? data.mortgage.revisions.join(', ') : 'No Revisions',
        notes: data.comments.length ? data.comments.join(', ') : 'No Notes',
      };
    });

    let sheetTitle = `${project.name} - Mortgages`;

    if (type === 'excel') {
      downloadExcel([newData], [headers], [], [[widths]], [sheetTitle], sheetTitle);
    } else {
      downloadPdf([newData], [headers], [], [pdfWidths], [sheetTitle], sheetTitle);
    }
  };

  const tabs = [
    {
      label: 'Incomplete Mortgages',
      component: (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h2" sx={{ mb: 2 }}>
            <strong>All Incomplete Mortgages</strong>
          </Typography>
          <StandardTable
            count={incompleteMortgageDeals.length}
            download={download}
            data={incompleteMortgageDeals}
            columns={columns}
            handleGlobalFilterValue={true}
          />
        </Box>
      ),
    },
    {
      label: 'Missing Mortgages',
      component: (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h2" sx={{ mb: 2 }}>
            <strong>All Missing Mortgages</strong>
          </Typography>
          <StandardTable
            count={missingMortgageDeals.length}
            download={download}
            data={missingMortgageDeals}
            columns={columns}
            handleGlobalFilterValue={true}
          />
        </Box>
      ),
    },
  ];

  const handleMortgage = (type: string) => {
    getMortgageDeals({ variables: { project: project._id, type: type, dateStart, dateEnd } }).then((res) => {
      storeDispatch(handleModal(true));
      setMortgages(res.data.getMortgageDeals);
    });
  };

  return (
    <Box sx={{ p: 2 }}>
      <GlobalModal>
        <StandardTable count={mortgages.length} download={download} data={mortgages} columns={columns} handleGlobalFilterValue={true} />
      </GlobalModal>
      <Typography sx={{ mb: 2 }} variant="h2">
        <strong>Mortgage Report</strong>
      </Typography>
      <Flex sx={{ mb: 2 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box sx={{ mr: 2 }}>
            <DateTimePicker
              label={'Start Date (YYYY/MM/DD)'}
              value={dateStart}
              onChange={(newValue) => {
                setDateStart(newValue);
              }}
            />
          </Box>
          <Box sx={{ mr: 2 }}>
            <DateTimePicker
              label={'End Date (YYYY/MM/DD)'}
              value={dateEnd}
              onChange={(newValue) => {
                setDateEnd(newValue);
              }}
            />
          </Box>
          <Button variant="contained" color="primary" onClick={() => handleMortgageSummary()}>
            Search
          </Button>
        </LocalizationProvider>
      </Flex>
      <Box>
        Total Mortgages Received:{' '}
        <Box sx={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => handleMortgage('total')}>
          <strong>{completeMortgages + incompleteMortgages}</strong>
        </Box>
      </Box>
      <Box>
        Completed Mortgages Received:{' '}
        <Box sx={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => handleMortgage('complete')}>
          <strong>{completeMortgages}</strong>
        </Box>
      </Box>
      <Box>
        Mortgages Received That Need Revision:{' '}
        <Box sx={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => handleMortgage('incomplete')}>
          <strong>{incompleteMortgages}</strong>
        </Box>
      </Box>
      <Divider sx={{ my: 1 }} />
      <Box>
        Total of Conditional Units with Completed Mortgages:{' '}
        {conditionals
          ? `${conditionalMortgages} / ${conditionals} (
        ${((conditionalMortgages / conditionals) * 100).toFixed(2)}%)`
          : '0'}
      </Box>
      <Box>
        Total of Firm Units with Completed Mortgages:{' '}
        {firms
          ? `${firmMortgages} / ${firms} (
        ${((firmMortgages / firms) * 100).toFixed(2)}%)`
          : '0'}
      </Box>
      <Box>
        Total of Sold Units with Completed Mortgages: <strong>{firmMortgages + conditionalMortgages}</strong>
      </Box>
      <Box sx={{ mt: 2 }}>
        <BasicTabs tabs={tabs} sub={true} value={value} setValue={setValue} color={'#fff'} />
      </Box>
    </Box>
  );
};

const MORTGAGE = gql`
  query getMortgagesReceived($projects: [MongoID], $dateStart: Date!, $dateEnd: Date!) {
    getMortgagesReceived(projects: $projects, dateStart: $dateStart, dateEnd: $dateEnd) {
      incompleteMortgages
      completeMortgages
      firms
      firmMortgages
      conditionals
      conditionalMortgages
    }
  }
`;

const MORTGAGEDEALS = gql`
  query getMortgageDeals($project: MongoID!, $dateStart: Date, $dateEnd: Date, $type: String!) {
    getMortgageDeals(project: $project, dateStart: $dateStart, dateEnd: $dateEnd, type: $type) {
      _id
      unit {
        _id
        suite
        status
      }
      purchasers {
        fullName
        email
      }
      realtor {
        fullName
        email
      }
      project {
        _id
      }
      comments {
        _id
        comment
      }
      mortgage {
        revisions
        lender
        amount
        getUrl
        uploadDate
      }
    }
  }
`;

export default MortgageReport;
