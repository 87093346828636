import { useState, useContext, useMemo } from 'react';
import { Buffer } from 'buffer';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import { subYears } from 'date-fns';
import { gql, useMutation } from '@apollo/client';
import { Box, Button, Grid } from '@mui/material';

import CustomDialog from '../common/CustomDialog';
import { IPurchaserInfo, IIdentification, ISigningOfficer } from '../../types/CreateDealForm';
import { CreateDealContext } from '../../context/CreateDealContext';
import SwitchButton from '../common/formControls/SwitchButton';
import PurchaserForm from '../common/forms/PurchaserForm';
import PdfCard from '../common/PdfCard';
import { FlexBetween } from '../../commonStyles';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import { useAppDispatch } from '../../app/hooks';
import { showErrorSnackbar, showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';
import { formatPhoneNumber, formatSin } from '../../utils/Functions';
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from '../../css/dropzone';

const PurchasersInfo = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();

  const { type, pInfo } = props;
  const { purchaserInfo, setPurchaserInfo, showForm, setShowForm, purchaserCount, setPurchaserCount, purchaserId, setPurchaserId } =
    useContext(CreateDealContext);
  const project = useSelector(selectProject);
  const [errors, setErrors] = useState<any>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [fileImage, setFileImage] = useState<any>(pInfo.getUrl);
  const [file, setFile] = useState<any>(null);

  const { isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/jpeg, image/png, application/pdf',
  });

  const handleDrop = (acceptedFiles: any, idType: string) => {
    if (acceptedFiles.length === 0) {
      storeDispatch(showErrorSnackbar('This file type is not allowed'));
      return;
    }
    const file = acceptedFiles[0];
    const fileReader = new FileReader();
    if (file) {
      fileReader.readAsDataURL(file);
    }
    fileReader.onloadend = async () => {
      if (idType === 'identification') {
        let purchaserArray = purchaserInfo.map((purchaser: IPurchaserInfo, index: number) => {
          if (index === type) {
            return {
              ...purchaser,
              identifications: [
                ...purchaser.identifications,
                {
                  url: fileReader.result,
                  file: file,
                  name: file.name,
                },
              ],
            };
          } else {
            return purchaser;
          }
        });
        setPurchaserInfo(purchaserArray);
      } else if (idType === 'proof') {
        setFileImage(fileReader.result);
        setFile(acceptedFiles[0]);
      }
    };
  };

  const [saveNewPurchaser] = useMutation(CREATEPURCHASERUPLOAD, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Purchaser Information has been saved!'));
      setPurchaserCount(purchaserCount + 1);
      setPurchaserId([...purchaserId, data.purchaserCreateOne._id]);
      setFile(null);
      setShowForm(!showForm);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(`This purchaser has already been created`));
    },
  });

  const [uploadID] = useMutation(UPLOADID, {
    onCompleted: (data) => {
      let purchaserArray = purchaserInfo.map((purchaser: IPurchaserInfo, index: number) => {
        if (index === type) {
          return { ...purchaser, identifications: data.uploadID.identifications };
        } else {
          return purchaser;
        }
      });
      setPurchaserInfo(purchaserArray);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [deleteId] = useMutation(DELETEID, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Document has been deleted!'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updatePurchaser] = useMutation(UPDATEPURCHASER, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Purchaser Information has been updated!'));
      setShowForm(!showForm);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(`This purchaser has already been created`));
    },
  });

  const handleDelete = async (numIndex: number, id: string) => {
    if (pInfo.identifications[numIndex]._id) {
      if (pInfo.identifications.length === 1) {
        storeDispatch(showErrorSnackbar('Purchaser must have at least 1 photo identification'));
        return;
      }
      deleteId({
        variables: { _id: purchaserId[type], documentId: pInfo.identifications[numIndex]._id, deleteFile: true, type: 'identification' },
      });
    } else {
      let newIdentification = pInfo.identifications.filter((identification: IIdentification, index: number) => index !== numIndex);
      let purchaserArray = purchaserInfo.map((purchaser: IPurchaserInfo, index: number) => {
        if (index === type) {
          return { ...purchaser, identifications: newIdentification };
        } else {
          return purchaser;
        }
      });
      setPurchaserInfo(purchaserArray);
    }
  };

  const handleTextInput = async (event: any) => {
    if (event && event.target.id === 'streetAddress') {
      let purchaserArray = await purchaserInfo.map((purchaser: IPurchaserInfo, index: number) => {
        if (index === type) {
          return { ...purchaser, [event.target.id]: event.target.value };
        } else {
          return purchaser;
        }
      });
      setPurchaserInfo(purchaserArray);
    }

    if (event && event.target.name) {
      let value = event.target.value;
      if (event.target.name === 'primaryPhone') {
        value = formatPhoneNumber(event.target.value);
      }
      if (event.target.name === 'sin') {
        value = formatSin(event.target.value);
      }
      let purchaserArray = await purchaserInfo.map((purchaser: IPurchaserInfo, index: number) => {
        if (index === type) {
          return { ...purchaser, [event.target.name]: value };
        } else {
          return purchaser;
        }
      });
      setPurchaserInfo(purchaserArray);
    }
  };

  const handleSigningOfficerInput = async (event: any, numIndex: number) => {
    if (event && event.target.name) {
      let purchaserArray = purchaserInfo.map((purchaser: IPurchaserInfo, index: number) => {
        if (index === type) {
          let newSigningOfficer = purchaser.signingOfficers.map((signingOfficer: ISigningOfficer, index: number) => {
            if (index === numIndex) {
              return {
                ...signingOfficer,
                [event.target.name]: event.target.value,
              };
            } else return signingOfficer;
          });
          return {
            ...purchaser,
            signingOfficers: newSigningOfficer,
          };
        } else {
          return purchaser;
        }
      });
      setPurchaserInfo(purchaserArray);
    }
  };

  const handleDeleteSigningOfficer = (numIndex: number) => {
    let purchaserArray = purchaserInfo.map((purchaser: IPurchaserInfo, index: number) => {
      if (purchaser.signingOfficers.length === 1) {
        storeDispatch(showErrorSnackbar('Must have at least 1 signing officer'));
        return purchaser;
      } else if (index === type) {
        let deleteSigningOfficer = purchaser.signingOfficers.filter((signingOfficer: ISigningOfficer, index: number) => numIndex !== index);
        return {
          ...purchaser,
          signingOfficers: deleteSigningOfficer,
        };
      } else {
        return purchaser;
      }
    });
    setPurchaserInfo(purchaserArray);
  };

  const addSigningOfficer = async (numIndex: number) => {
    let purchaserArray = purchaserInfo.map((purchaser: IPurchaserInfo, index: number) => {
      if (index === numIndex) {
        return {
          ...purchaser,
          signingOfficers: [
            ...purchaser.signingOfficers,
            {
              fullName: '',
              dob: subYears(new Date(), 18),
              streetAddress: '',
              primaryPhone: '',
              email: '',
              sin: '',
            },
          ],
        };
      } else return purchaser;
    });
    setPurchaserInfo(purchaserArray);
  };

  const handleDob = (newValue: any, id: number, type: number) => {
    let datePickerArray = purchaserInfo.map((purchaser: IPurchaserInfo, index: number) => {
      if (index === type) {
        let newSigningOfficer = purchaser.signingOfficers.map((signingOfficer: ISigningOfficer, index: number) => {
          if (index === id) {
            return {
              ...signingOfficer,
              dob: newValue,
            };
          } else return signingOfficer;
        });
        return {
          ...purchaser,
          signingOfficers: newSigningOfficer,
        };
      } else {
        return purchaser;
      }
    });
    if (newValue == 'Invalid Date') {
      setErrors('Invalid Date');
      return;
    } else {
      setErrors(null);
    }
    setPurchaserInfo(datePickerArray);
  };

  const handleDetails = async (city: string, province: string, postalCode: string, country: string, streetAddress: string) => {
    let purchaserArray = await purchaserInfo.map((purchaser: IPurchaserInfo, index: number) => {
      if (index === type) {
        return { ...purchaser, city: city, province: province, postalCode: postalCode, country: country, streetAddress: streetAddress };
      } else {
        return purchaser;
      }
    });
    setPurchaserInfo(purchaserArray);
  };

  const handleStreetAddress = async (value: string, type: number) => {
    let purchaserArray = await purchaserInfo.map((purchaser: IPurchaserInfo, index: number) => {
      if (index === type) {
        return { ...purchaser, streetAddress: value };
      } else {
        return purchaser;
      }
    });
    setPurchaserInfo(purchaserArray);
  };

  const handleIdTypeInput = (event: any, idType: string) => {
    let idTypeArray = purchaserInfo.map((purchaser: IPurchaserInfo, index: number) => {
      if (index === type) {
        return { ...purchaser, [idType]: event.target.innerText };
      } else {
        return purchaser;
      }
    });
    setPurchaserInfo(idTypeArray);
  };

  const handleIdIssuedByInput = (event: any) => {
    let idIssuedArray = purchaserInfo.map((purchaser: IPurchaserInfo, index: number) => {
      if (index === type) {
        return { ...purchaser, idJurisdiction: event.target.innerText };
      } else {
        return purchaser;
      }
    });
    setPurchaserInfo(idIssuedArray);
  };

  const handleEndvestorInput = (event: any) => {
    let idIssuedArray = purchaserInfo.map((purchaser: IPurchaserInfo, index: number) => {
      if (index === type) {
        return { ...purchaser, purchaserType: event.target.innerText };
      } else {
        return purchaser;
      }
    });
    setPurchaserInfo(idIssuedArray);
  };

  const handleDateChange = (newValue: any, id: string, type: number) => {
    let datePickerArray = purchaserInfo.map((purchaser: IPurchaserInfo, index: number) => {
      if (index === type) {
        return { ...purchaser, [id]: newValue };
      } else {
        return purchaser;
      }
    });
    if (newValue == 'Invalid Date') {
      setErrors('Invalid Date');
      return;
    } else {
      setErrors(null);
    }
    setPurchaserInfo(datePickerArray);
  };

  const handleCorpChange = () => {
    let corpValue = purchaserInfo.map((purchaser: IPurchaserInfo, index: number) => {
      if (index === type) {
        return {
          ...purchaser,
          corp: !purchaser.corp,
          signingOfficers: purchaser.corp
            ? []
            : [
                {
                  fullName: '',
                  dob: subYears(new Date(), 18),
                  streetAddress: '',
                  email: '',
                  primaryPhone: '',
                  sin: '',
                },
              ],
        };
      } else {
        return purchaser;
      }
    });
    setPurchaserInfo(corpValue);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (pInfo.identifications.length === 0) {
      storeDispatch(showErrorSnackbar('Purchaser Image has not been uploaded'));
      return;
    }
    if (!purchaserInfo[type].corp) {
      if (new Date(purchaserInfo[type].dob) > subYears(new Date(), 18)) {
        storeDispatch(showErrorSnackbar('Purchaser is under 18'));
        return;
      }
    }
    if (errors) {
      storeDispatch(showErrorSnackbar('Invalid Date'));
      return;
    }

    if (purchaserId[type]) {
      const { _id, getUrl, putUrl, identifications, ...newObject } = purchaserInfo[type];
      newObject.dob = new Date(newObject.dob);
      newObject.idExpiry = new Date(newObject.idExpiry);
      newObject.proofExpiry = newObject.idExpiry ? new Date(newObject.idExpiry) : null;

      // Proof of Address
      if (file) {
        let buffer: any;
        if (fileImage.indexOf('application/pdf') !== -1) {
          buffer = Buffer.from(fileImage.replace(/^data:application\/\w+;base64,/, ''), 'base64');
        } else {
          buffer = Buffer.from(fileImage.replace(/^data:image\/\w+;base64,/, ''), 'base64');
        }

        const options = {
          headers: { 'Content-Type': `${file.type}` },
          maxContentLength: Infinity,
          maxBodyLength: Infinity,
        };
        await axios.put(putUrl, buffer, options);
      }

      if (pInfo.identifications.length > 0) {
        await pInfo.identifications.forEach(async (file: any) => {
          if (file.file) {
            await uploadID({ variables: { id: purchaserId[type], name: file.file.name, file: file.file } });
          }
        });
        updatePurchaser({ variables: { _id: purchaserId[type], record: newObject } });
      } else {
        updatePurchaser({ variables: { _id: purchaserId[type], record: newObject } });
      }
    } else {
      let originalId = [...pInfo.identifications];
      let newId = await pInfo.identifications.map((identification: any) => identification.file);
      let identificationName = pInfo.identifications.map((identification: any) => {
        return {
          name: identification.name,
        };
      });
      purchaserInfo[type].identifications = identificationName;
      saveNewPurchaser({ variables: { record: purchaserInfo[type], proof: file, files: newId } }).then((res) => {
        if (res.errors) {
          let purchaserIdentification = purchaserInfo.map((purchaser: IPurchaserInfo, index: number) => {
            if (index === type) {
              return {
                ...purchaser,
                identifications: originalId,
              };
            } else {
              return purchaser;
            }
          });
          setPurchaserInfo(purchaserIdentification);
        }
      });
    }
  };

  const handleCloseSuccess = () => {
    let removePurchaser = purchaserInfo.filter((data: IPurchaserInfo, index: number) => type !== index);
    let removePurchaserId = purchaserId.filter((data: any, index: number) => type !== index);
    if (removePurchaser.length === 0) {
      setPurchaserInfo([
        ...removePurchaser,
        {
          project: project._id,
          corp: false,
          firstName: '',
          lastName: '',
          identifications: [],
          dob: subYears(new Date(), 18),
          streetAddress: '',
          city: '',
          province: '',
          country: '',
          postalCode: '',
          idType: "Driver's Licence",
          proofType: '',
          proofNumber: '',
          proofExpiry: null,
          idNumber: '',
          idJurisdiction: 'Ontario',
          idExpiry: new Date(),
          sin: '',
          primaryPhone: '',
          email: '',
          occupation: '',
          employer: '',
          directors: '',
          businessNumber: '',
          signingOfficers: [],
          purchaserType: 'Investor',
        },
      ]);
      setPurchaserCount(0);
      setPurchaserId([]);
    } else {
      setPurchaserInfo(removePurchaser);
      setPurchaserId(removePurchaserId);
    }
    setShowForm(!showForm);
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div>
      <CustomDialog
        handleClose={() => setDialogOpen(false)}
        handleCloseRemove={() => setDialogOpen(false)}
        handleCloseSuccess={handleCloseSuccess}
        open={dialogOpen}
        removeButton={'No, I would like to continue'}
        successButton={'Yes, I would like to go back'}
        dialogContent={'All information will be deleted if you would like to go back.'}
        dialogTitle={'Go back to previous page?'}
      />
      <SwitchButton state={pInfo.corp} handleChange={handleCorpChange} title={'Corporation'} />
      <form onSubmit={handleSubmit} autoComplete="off">
        <Dropzone onDrop={(files) => handleDrop(files, 'identification')} accept="image/jpg, image/jpeg, image/png, application/pdf">
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Drag and Drop or Upload Driver's Licence/Corporation Documents</p>
              </div>
            </section>
          )}
        </Dropzone>
        <div>
          <Grid
            container
            spacing={2}
            style={{
              margin: 0,
              width: '100%',
            }}
          >
            {pInfo.identifications.length > 0
              ? pInfo.identifications.map((attachment: any, index: number) => {
                  return (
                    <Grid key={index} item lg={3} md={4} sm={6} xs={12}>
                      <PdfCard
                        file={attachment.getUrl ? attachment.getUrl : attachment.url}
                        title={attachment.name ? attachment.name : attachment.file.name}
                        id={index}
                        handleDelete={handleDelete}
                        download={true}
                        index={index}
                      />
                    </Grid>
                  );
                })
              : null}
          </Grid>
        </div>
        <PurchaserForm
          pInfo={pInfo}
          handleTextInput={handleTextInput}
          handleDateChange={handleDateChange}
          handleEndvestorInput={handleEndvestorInput}
          handleIdTypeInput={handleIdTypeInput}
          handleIdIssuedByInput={handleIdIssuedByInput}
          handleStreetAddress={handleStreetAddress}
          handleDetails={handleDetails}
          handleDeleteSigningOfficer={handleDeleteSigningOfficer}
          handleSigningOfficerInput={handleSigningOfficerInput}
          addSigningOfficer={addSigningOfficer}
          handleDob={handleDob}
          type={type}
        />
        {pInfo.idType === 'Passport' ||
        pInfo.idType === 'Permanent Resident Card' ||
        pInfo.idType === 'Citizenship Card' ||
        pInfo.idType === 'Identity Card' ? (
          <Box sx={{ mt: 1 }}>
            <p>
              <em>Please add a Proof of Address for all identification without an address</em>
            </p>
          </Box>
        ) : null}
        <FlexBetween sx={{ mt: 2 }}>
          {purchaserId[type] ? (
            <Button color="success" variant="contained" type="submit" id={'update'}>
              Update Purchaser
            </Button>
          ) : (
            <Button id="save" type="submit" color="success" variant="contained">
              Save New Purchaser
            </Button>
          )}
          <Button onClick={() => setDialogOpen(true)} variant="contained">
            Cancel
          </Button>
        </FlexBetween>
      </form>
    </div>
  );
};

interface ChildProps {
  type: number;
  pInfo: IPurchaserInfo;
}

const CREATEPURCHASERUPLOAD = gql`
  mutation purchaserCreateOne($record: CreateOnePurchaserInput!, $proof: Upload, $files: [Upload!]) {
    purchaserCreateOne(record: $record, proof: $proof, files: $files) {
      _id
      project {
        _id
      }
      identifications {
        _id
        name
        getUrl
      }
      email
      firstName
      lastName
      sin
      dob
      streetAddress
      city
      province
      country
      postalCode
      occupation
      employer
      purchaserType
      primaryPhone
      idType
      idNumber
      idExpiry
      proofType
      proofNumber
      proofExpiry
      unit
      idJurisdiction
      corp
      getUrl
    }
  }
`;

const UPLOADID = gql`
  mutation uploadID($id: MongoID!, $name: String!, $file: Upload!) {
    uploadID(id: $id, name: $name, file: $file) {
      identifications {
        _id
        name
        getUrl
      }
    }
  }
`;

const UPDATEPURCHASER = gql`
  mutation purchaserUpdateById($_id: MongoID!, $record: UpdateByIdPurchaserInput!) {
    purchaserUpdateById(_id: $_id, record: $record) {
      record {
        email
        firstName
        lastName
        sin
        dob
        identifications {
          _id
          name
          getUrl
        }
        streetAddress
        city
        province
        country
        postalCode
        occupation
        employer
        directors
        businessNumber
        signingOfficers {
          fullName
          dob
          sin
          primaryPhone
          streetAddress
          email
        }
        purchaserType
        primaryPhone
        idType
        idNumber
        idExpiry
        unit
        proofType
        proofNumber
        proofExpiry
        idJurisdiction
        putUrl
      }
    }
  }
`;

const DELETEID = gql`
  mutation deletePurchaserId($_id: MongoID!, $documentId: MongoID!, $deleteFile: Boolean!, $type: String!) {
    deletePurchaserId(_id: $_id, documentId: $documentId, deleteFile: $deleteFile, type: $type) {
      identifications {
        _id
        name
        getUrl
        putUrl
      }
      getUrl
    }
  }
`;

const DELETEPROOF = gql`
  mutation deleteProof($_id: MongoID!, $deleteFile: Boolean!) {
    deleteProof(_id: $_id, deleteFile: $deleteFile) {
      getUrl
    }
  }
`;
export default PurchasersInfo;
