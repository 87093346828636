import { useContext, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import Dropzone from 'react-dropzone';
import { Box, Grid, Button, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../../../app/hooks';
import { selectProject } from '../../../../features/project/projectSlice';
import { selectUser } from '../../../../features/auth/authSlice';
import { showSuccessSnackbar, showErrorSnackbar } from '../../../../features/snackbar/snackbarSlice';
import { accessAllowed } from '../../../../features/project/projectHooks';
import { UnitContext } from '../../../../context/UnitContext';
import { chequeTypes } from '../../../../utils/Constants';
import { IDealDeposit } from '../../../../types/CreateDealForm';
import DealDeposits from '../../DealDeposits';
import PdfCard from '../../../common/PdfCard';
import { numToCurrency } from '../../../../utils/Functions';
import { FlexBetween, FlexEnd, Flex } from '../../../../commonStyles';
import LoadingWrapper from '../../../common/LoadingWrapper';
import PendingDeposits from './PendingDeposits';
import { DealContext } from '../../../../context/DealContext';
import { IDocuments } from '../../../../types/docusign';

const DepositInfo = (props: ChildProps) => {
  const { handleDrop, style, handleDepositSubmit } = props;
  const { filteredDeal, setFilteredDeal } = useContext(UnitContext);
  const { setUnitTransferTotal, document, setDocument, pendingDeposits, setPendingDeposits } = useContext(DealContext);
  const storeDispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const project = useSelector(selectProject);

  const [errors, setErrors] = useState<any>(null);
  const [disabled, setDisabled] = useState<boolean>(true);

  // Queries and Mutations

  const [createDepositImages, { loading: savingCheques }] = useMutation(DEPOSITIMAGES, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar(`Deposit Images have been uploaded`));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [deleteCheques] = useMutation(DELETECHEQUES, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar(`Cheque Image has been deleted!`));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [moveDocuments] = useMutation(MOVEDOCUMENTS, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar(`Cheque Image has been moved to Miscellaneous!`));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [renameDepositImage] = useMutation(RENAMEDEPOSIT, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar(`Renamed to ${data.depositImageRename}`));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  // Functions

  const handleDepositsArray = (array: IDealDeposit[], type: string) => {
    if (type === 'additional') {
      setFilteredDeal({
        ...filteredDeal,
        additionalDeposits: array,
      });
    } else if (type === 'pending') {
      setFilteredDeal({
        ...filteredDeal,
        pendingDeposits: array,
      });
    } else if (type === 'current') {
      setFilteredDeal({
        ...filteredDeal,
        deposit: array,
      });
    }
  };

  const handleDelete = async (numIndex: number, id: string) => {
    if (filteredDeal.depositImages[numIndex]._id) {
      await deleteCheques({
        variables: { _id: filteredDeal._id, documentId: filteredDeal.depositImages[numIndex]._id, deleteFile: true, type: 'cheques' },
      });
    }
    let filterDepositImages = filteredDeal.depositImages.filter((depositImages: any, index: number) => {
      return index !== numIndex;
    });
    setFilteredDeal({
      ...filteredDeal,
      depositImages: filterDepositImages,
    });
  };

  const handleMoveImage = async (numIndex: number, id: string) => {
    if (filteredDeal.depositImages[numIndex]._id) {
      await moveDocuments({
        variables: {
          _id: filteredDeal._id,
          documentId: filteredDeal.depositImages[numIndex]._id,
          current: 'cheques',
          destination: 'miscellaneous',
        },
      }).then((res) => {
        let filterDepositImages = filteredDeal.depositImages.filter((depositImages: any, index: number) => {
          return index !== numIndex;
        });
        setFilteredDeal({
          ...filteredDeal,
          depositImages: filterDepositImages,
          miscellaneous: res.data.moveDocuments.miscellaneous,
        });
      });
    }
  };

  const saveDepositImages = async () => {
    if (filteredDeal.depositImages.length > 0) {
      if (filteredDeal.depositImages.some((file: any) => file.file)) {
        await filteredDeal.depositImages.forEach((file: any, index: number) => {
          if (file.file) {
            createDepositImages({ variables: { id: filteredDeal._id, name: file.file.name, file: file.file } });
          }
        });
      } else return storeDispatch(showErrorSnackbar(`No Uploaded Deposit Images`));
    }
  };

  const tooltip = () => {
    return (
      <Flex sx={{ flexDirection: 'column' }}>
        {chequeTypes.map((type: any) => {
          return (
            <Flex sx={{ flexWrap: 'wrap', textAlign: 'left', mt: 1 }}>
              <Box
                sx={{
                  width: '25px',
                  height: '15px',
                  border: '1px solid #000',
                  alignSelf: 'center',
                  marginRight: '10px',
                  backgroundColor: type.color,
                }}
              ></Box>
              <Box style={{ textAlign: 'left', marginRight: '10px' }}>{type.value}</Box>
            </Flex>
          );
        })}
      </Flex>
    );
  };

  const handleEdit = (value: string, numIndex: number) => {
    if (filteredDeal.depositImages[numIndex].file) {
      let images = filteredDeal.depositImages.map((image: any, index: number) => {
        if (numIndex === index) {
          const newFile = new File([image.file], value, { type: image.file.type });
          return {
            ...image,
            file: newFile,
          };
        } else return image;
      });
      setFilteredDeal({
        ...filteredDeal,
        depositImages: images,
      });
    } else {
      renameDepositImage({
        variables: { _id: filteredDeal._id, project: project._id, originalName: filteredDeal.depositImages[numIndex].name, name: value },
      }).then((res: any) => {
        let images = filteredDeal.depositImages.map((image: any, index: number) => {
          if (numIndex === index) {
            return {
              ...image,
              name: res.data.depositImageRename,
            };
          } else return image;
        });
        setFilteredDeal({
          ...filteredDeal,
          depositImages: images,
        });
      });
    }
  };

  const addPendingDeposits = (type: string = '') => {
    if (type) {
      setDocument('Deposits');
    }
    setUnitTransferTotal(filteredDeal.basePrice);
    setPendingDeposits([
      ...pendingDeposits,
      {
        name: 'Initial',
        accountNumber: '',
        amount: 0,
        chequeDate: null,
        chequeType: 'replacement',
        deal: filteredDeal._id,
        dueDate: new Date(),
      },
    ]);
  };

  const editDeposits = () => {
    let aps = filteredDeal.documents.find(
      (document: IDocuments) => document.type === 'APS' && document.status === 'Completed' && !document.dsEnvelopeId
    );
    if (aps || !filteredDeal.documents.length) {
      return true;
    } else return false;
  };

  return savingCheques ? (
    <LoadingWrapper title="Saving Deposit Images" modal={true} height={'120vh'} />
  ) : (
    <div>
      <form onSubmit={handleDepositSubmit} autoComplete="off">
        {filteredDeal.deposit ? (
          <>
            <h3
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ mr: 1 }}>
                  <em>Current Deposits</em>
                </Box>
                <Tooltip title={tooltip()}>
                  <InfoIcon sx={{ alignSelf: 'center' }} />
                </Tooltip>
              </Box>
              {editDeposits() ? (
                <Box>
                  <EditIcon onClick={() => setDisabled(!disabled)} sx={{ cursor: 'pointer' }} />
                </Box>
              ) : null}
            </h3>
            <DealDeposits
              deposit={filteredDeal.deposit}
              basePrice={filteredDeal!.totalPrice!}
              handleDepositsArray={handleDepositsArray}
              depositType={'current'}
              disable={disabled}
              setErrors={setErrors}
            />
            {filteredDeal.pendingDeposits.length > 0 ? (
              <div>
                <h3
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                  }}
                >
                  <em>Pending Deposits</em>
                </h3>
                <em>
                  These deposits are currently pending until the Deposit/Options Amendment has been executed. After the amendment has been
                  executed, it will replace the deposits above. If you would like to remove these deposits, please delete the Deposit/Option
                  Amendment in the amendments section
                </em>
                {filteredDeal.pendingBasePrice ? (
                  <Box sx={{ my: 2 }}>
                    Pending Base Price Change: <strong>{numToCurrency.format(filteredDeal.pendingBasePrice)}</strong>
                  </Box>
                ) : null}
                <DealDeposits
                  deposit={filteredDeal.pendingDeposits}
                  basePrice={filteredDeal!.totalPrice!}
                  handleDepositsArray={handleDepositsArray}
                  depositType={'pending'}
                  disable={true}
                  setErrors={setErrors}
                />
              </div>
            ) : null}
            {filteredDeal.additionalDeposits.length > 0 ? (
              <>
                <h3>Additional Deposits</h3>
                <DealDeposits
                  deposit={filteredDeal.additionalDeposits}
                  basePrice={filteredDeal!.totalPrice!}
                  handleDepositsArray={handleDepositsArray}
                  depositType={'additional'}
                  disable={true}
                  setErrors={setErrors}
                />
              </>
            ) : null}
            {accessAllowed(user, project._id, 'editDeals') &&
            !filteredDeal.cancelled.dateCancelled &&
            !filteredDeal.rescission.dateRescinded ? (
              <FlexBetween
                sx={{
                  flexDirection: 'row-reverse',
                  mt: 2,
                }}
              >
                <Button type="submit" name={'update'} color="success" variant="contained">
                  Save Deposit Information
                </Button>
              </FlexBetween>
            ) : null}
            <h2>Deposit Images</h2>
            <Box sx={{ mb: 3 }}>Upload any Cheque or Wire Transfers here</Box>
            <div>
              <Dropzone onDrop={(files) => handleDrop(files, 0, 'deposit')} accept="image/jpg, image/jpeg, image/png, application/pdf">
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps({ style })}>
                      <input {...getInputProps()} />
                      <p>Drag and Drop or Upload Deposit Images</p>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            <div>
              <Grid container spacing={2}>
                {filteredDeal.depositImages.length > 0
                  ? filteredDeal.depositImages.map((attachment: any, index: number) => {
                      return (
                        <Grid key={index} item lg={3} md={4} sm={6} xs={12}>
                          <PdfCard
                            file={attachment.getUrl ? attachment.getUrl : attachment.url}
                            title={attachment.name ? attachment.name : attachment.file.name}
                            id={index}
                            handleDelete={handleDelete}
                            download={true}
                            index={index}
                            handleMoveImage={handleMoveImage}
                            handleEdit={handleEdit}
                          />
                        </Grid>
                      );
                    })
                  : null}
              </Grid>
              {accessAllowed(user, project._id, 'editDeals') ? (
                <FlexEnd
                  sx={{
                    flexWrap: 'wrap',
                    '@media (max-width: 600px)': {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <FlexBetween sx={{ flexDirection: 'row-reverse' }}>
                    <Button onClick={() => saveDepositImages()} name={'update'} color="success" variant="contained">
                      Upload Cheque Images
                    </Button>
                  </FlexBetween>
                </FlexEnd>
              ) : null}
            </div>
          </>
        ) : null}
      </form>
      {accessAllowed(user, project._id, 'editDeals') && !filteredDeal.cancelled.dateCancelled && !filteredDeal.rescission.dateRescinded ? (
        <Box sx={{ mt: 2 }}>
          {pendingDeposits.length > 0 && document === 'Deposits' ? (
            <PendingDeposits errors={errors} setErrors={setErrors} />
          ) : pendingDeposits.length === 0 ? (
            <Button
              onClick={() => {
                addPendingDeposits('Document');
              }}
              name={'update'}
              color="primary"
              variant="contained"
            >
              Create Deposit Amendment
            </Button>
          ) : null}
        </Box>
      ) : null}
    </div>
  );
};

interface ChildProps {
  handleDrop: any;
  style: any;
  openDialog: any;
  depositList: any;
  handleDepositSubmit: any;
  selectedDeposit: any;
  setSelectedDeposit: any;
}

const DELETECHEQUES = gql`
  mutation deleteSupportDocuments($_id: MongoID!, $documentId: MongoID!, $deleteFile: Boolean!, $type: String!) {
    deleteSupportDocuments(_id: $_id, documentId: $documentId, deleteFile: $deleteFile, type: $type) {
      mortgage {
        lender
        amount
      }
      miscellaneous {
        _id
        name
        getUrl
      }
    }
  }
`;

const MOVEDOCUMENTS = gql`
  mutation moveDocuments($_id: MongoID!, $documentId: MongoID!, $current: String!, $destination: String!) {
    moveDocuments(_id: $_id, documentId: $documentId, current: $current, destination: $destination) {
      depositImages {
        getUrl
        name
      }
      miscellaneous {
        _id
        name
        getUrl
      }
    }
  }
`;

const DEPOSITIMAGES = gql`
  mutation depositImageUpload($id: MongoID!, $name: String!, $file: Upload!) {
    depositImageUpload(id: $id, name: $name, file: $file) {
      depositImages {
        _id
        name
        getUrl
      }
    }
  }
`;

const RENAMEDEPOSIT = gql`
  mutation depositImageRename($_id: MongoID!, $project: MongoID!, $originalName: String!, $name: String!) {
    depositImageRename(_id: $_id, project: $project, originalName: $originalName, name: $name)
  }
`;

export default DepositInfo;
