import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Buffer } from 'buffer';
import _ from 'lodash';
import Dropzone from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { gql, useMutation, useLazyQuery, useSubscription, useQuery } from '@apollo/client';
import {
  Box,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  TextField,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';

import { selectProject } from '../../../../features/project/projectSlice';
import { selectUser } from '../../../../features/auth/authSlice';
import { useAppDispatch } from '../../../../app/hooks';
import { showSuccessSnackbar, showErrorSnackbar } from '../../../../features/snackbar/snackbarSlice';
import { accessAllowed } from '../../../../features/project/projectHooks';
import { useCreateActivity } from '../../../../features/activity/activityHooks';
import { UnitContext } from '../../../../context/UnitContext';
import TextInput from '../../../common/formControls/TextInput';
import Dropdown from '../../../common/formControls/Dropdown';
import { IDocuments } from '../../../../types/docusign';
import { IDealRealtor } from '../../../../types/CreateDealForm';
import { mergeType } from '../../../../utils/Constants';
import { IMerge, IMergeData } from '../../../../types/merge';
import { IExecutor } from '../../../../types/project';
import { createPdf, getMergeValues, signField, envelopeIdRegex, downloadDistributionFiles } from '../../../../utils/Functions';
import CustomDialog from '../../../common/CustomDialog';
import PdfCard from '../../../common/PdfCard';
import { FlexBetween } from '../../../../commonStyles';
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from '../../../../utils/Constants';
import EnvelopeAccordion from './EnvelopeAccordion';
import LoadingLogo from '../../../common/LoadingLogo';
import { DealContext } from '../../../../context/DealContext';

const Envelopes = () => {
  const { setFilteredDeal, filteredDeal, unit, updateDealDocuments, dealUpdateLoading } = useContext(UnitContext);
  const { setDocument, setPendingDeposits, setPendingOptions } = useContext(DealContext);
  const createActivity = useCreateActivity();
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);

  const [merges, setMerges] = useState<IMerge[]>([]);
  const [selectedMerge, setSelectedMerge] = useState<any[]>([]);
  const [coopMerge, setCoopMerge] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogType, setDialogType] = useState<string>('');
  const [selectedDocument, setSelectedDocument] = useState<IDocuments | null>(null);
  const [executor, setExecutor] = useState<IExecutor | null>(project.executors ? project.executors[0] : null);
  const [title, setTitle] = useState<string>('');
  const [documentType, setDocumentType] = useState<string>('');
  const [envelopeId, setEnvelopeId] = useState<string>('');
  const [fileImage, setFileImage] = useState<any>('');
  const [file, setFile] = useState<any>(null);
  const [auditDate, setAuditDate] = useState<Date | null>(filteredDeal && filteredDeal.auditDate ? filteredDeal.auditDate : null);
  const [documentName, setDocumentName] = useState<string>('');
  const [selectedDocuments, setSelectedDocuments] = useState<IDocuments[]>([]);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);

  const { isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/jpeg, image/png',
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  useSubscription(UPDATED_DOCUMENT, {
    variables: { projectId: project._id },
    onSubscriptionData: ({
      subscriptionData: {
        data: { updatedDocument },
      },
    }) => {
      const newDocuments = _.cloneDeep(filteredDeal.documents);
      const foundDocument = newDocuments.find((nd: any) => nd._id === updatedDocument._id);
      if (foundDocument) {
        foundDocument.status = updatedDocument.status;
      }
      setFilteredDeal({
        ...filteredDeal,
        documents: newDocuments,
      });
    },
  });

  const [getDocument] = useLazyQuery(GETPREVIEWDATA, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [getMerges] = useLazyQuery<IMergeData>(MERGETEMPLATES, {
    variables: {
      filter: { project: project._id, excludeTypes: ['Floorplan', 'UnitTransfer', 'Options', 'Deposit', 'Archive', 'Coop', 'Lease'] },
    },
    onCompleted: (data) => {
      setMerges(data.mergeTemplateMany);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [deleteDepositAmendment, { loading: deleteDocumentLoading }] = useMutation(DELETEDOCUMENT, {
    onCompleted: (data) => {
      let removedDocument = filteredDeal.documents.filter((document: IDocuments) => {
        return data.deleteDepositAmendment._id !== document._id;
      });
      if (
        data.deleteDepositAmendment.type === 'Deposit' ||
        data.deleteDepositAmendment.type === 'Options' ||
        data.deleteDepositAmendment.type === 'UnitTransfer'
      ) {
        setFilteredDeal({
          ...filteredDeal,
          pendingOptions: [],
          pendingUnit: null,
          pendingDeposits: [],
          documents: removedDocument,
        });
        setPendingDeposits([]);
        setPendingOptions([]);
      } else {
        setFilteredDeal({
          ...filteredDeal,
          documents: removedDocument,
        });
      }
      storeDispatch(showSuccessSnackbar(`Document has been deleted/voided!`));
      createActivity({
        project: project._id,
        user: user._id,
        deal: filteredDeal._id,
        title: `Delete Document`,
        content: `Document ${data.deleteDepositAmendment.name} has been deleted and void. The status was ${data.deleteDepositAmendment.status}`,
      });
      setDocument(null);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [createDocument, { loading: createDocumentLoading }] = useMutation(CREATEDOCUMENT, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar(`Document has been created!`));
      let documentIds = filteredDeal.documents.map((document: IDocuments) => {
        return document._id;
      });
      updateDealDocuments({
        variables: { _id: filteredDeal._id, record: { documents: [...documentIds, data.documentCreateOne._id] } },
      });
      setFilteredDeal({
        ...filteredDeal,
        documents: [...filteredDeal.documents, data.documentCreateOne],
      });
      setTitle('');
      setDocumentType('');
      setEnvelopeId('');
      setFileImage(null);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [createMultipleDocument, { loading: createMultipleDocumentLoading }] = useMutation(CREATEMULTIPLEDOCUMENT, {
    onCompleted: (data) => {
      setDialogType('');
      setDialogOpen(false);
      storeDispatch(showSuccessSnackbar(`Document has been created!`));
      let documentIds = filteredDeal.documents.map((document: IDocuments) => {
        return document._id;
      });
      updateDealDocuments({
        variables: { _id: filteredDeal._id, record: { documents: [...documentIds, data.createEnvelopeMultipleDocuments._id] } },
      });
      setFilteredDeal({
        ...filteredDeal,
        documents: [...filteredDeal.documents, data.createEnvelopeMultipleDocuments],
      });
      setTitle('');
      setDocumentType('');
      setFileImage(null);
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.toString()));
      console.log(err, 'err');
    },
  });

  const [sendDocument, { loading: sendDocumentLoading }] = useMutation(SENDDOCUMENT, {
    onCompleted: (data) => {
      const documentsClone = [...filteredDeal.documents];
      const updatedDocument = documentsClone.find((doc) => doc._id === data.documentCreateEnvelope._id);
      updatedDocument.status = data.documentCreateEnvelope.status;
      updatedDocument.dsEnvelopeId = data.documentCreateEnvelope.dsEnvelopeId;
      setFilteredDeal({
        ...filteredDeal,
        documents: documentsClone,
      });
      createActivity({
        project: project._id,
        user: user._id,
        deal: filteredDeal._id,
        title: `Document Sent!`,
        content: `Document ${data.documentCreateEnvelope.name} has been sent!`,
      });
      storeDispatch(showSuccessSnackbar(`Document has been sent!`));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(err.toString()));
      console.log(err, 'err');
    },
  });

  const [sendDocumentToExecutor, { loading: sendingDocumentToExecutor }] = useMutation(SENDDOCUMENTTOEXECUTOR, {
    onCompleted: (data) => {
      const documentsClone = _.cloneDeep(filteredDeal.documents);
      const updatedDocument = documentsClone.find((doc: any) => doc._id === data.documentSendToExecutor._id);
      updatedDocument.status = data.documentSendToExecutor.status;
      createActivity({
        project: project._id,
        user: user._id,
        deal: filteredDeal._id,
        title: `Document Sent To Executor`,
        content: `Document ${data.documentSendToExecutor.name} has been sent to the Executor ${executor?.name}`,
      });
      storeDispatch(showSuccessSnackbar(`Document has been sent to Executor ${executor?.name}`));
      setExecutor(null);
      setFilteredDeal({
        ...filteredDeal,
        documents: documentsClone,
      });
      setDialogType('');
      setDialogOpen(false);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  // Audit
  const [updateDealSales] = useMutation(UPDATEDEALINFO, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Audit Information Updated'));
      createActivity({
        project: project._id,
        user: user._id,
        deal: filteredDeal._id,
        title: `Deal Information`,
        content: `${filteredDeal.auditDate ? `${unit.suite}'s has been audit has been removed` : `${unit.suite}'s has been audited`}`,
      });
      setFilteredDeal({
        ...filteredDeal,
        auditor: filteredDeal.auditor,
        auditDate: filteredDeal.auditDate,
      });
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  useEffect(() => {
    if (selectedMerge.length) {
      let merge = merges.find((merge: IMerge) => merge.name === selectedMerge[0]);
      if (merge && merge.type === 'Coop') {
        setCoopMerge(true);
      }
    } else {
      setCoopMerge(false);
    }
  }, [selectedMerge]);

  const handleAudit = (e: any) => {
    if (filteredDeal.auditor) {
      setAuditDate(null);
      updateDealSales({
        variables: {
          _id: filteredDeal._id,
          record: {
            auditor: null,
            auditDate: null,
          },
        },
      });
    } else {
      setAuditDate(new Date());
      updateDealSales({
        variables: {
          _id: filteredDeal._id,
          record: {
            auditor: user._id,
            auditDate: new Date(),
          },
        },
      });
    }
  };

  const openDialog = async (type: string, document: IDocuments | null = null) => {
    if (type === 'create') {
      getMerges();
    }
    if (type === 'execute' || type === 'sendEnvelope' || type === 'deleteDocument') {
      setSelectedDocument(document);
    }
    await setDialogType(type);
    await setDialogOpen(true);
  };

  const setBackgroundColor = (status: string) => {
    if (status === 'Received') {
      return {
        background: 'rgb(230, 184, 183)',
      };
    } else if (status === 'Signed') {
      return {
        background: 'rgb(233 204 255)',
      };
    } else if (status === 'Verified') {
      return {
        background: 'rgb(0, 176, 240)',
      };
    } else if (status === 'Completed') {
      return {
        background: 'rgb(146, 208, 80)',
      };
    } else return;
  };

  const executorList = () => {
    let executorNames = project.executors.map((executor: IExecutor, index: number) => {
      return executor.name;
    });
    return executorNames;
  };

  const handleExecutorDropdown = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
    let executor = project?.executors.find((executor: IExecutor) => executor.name === event.target.value);
    setExecutor(executor!);
  };

  const handleDrop = (acceptedFiles: any) => {
    const file = acceptedFiles[0];
    const fileReader = new FileReader();
    if (file) {
      fileReader.readAsDataURL(file);
    }
    fileReader.onloadend = async () => {
      setFileImage(fileReader.result);
      setFile(acceptedFiles[0]);
      setEnvelopeId('');
    };
  };

  const dialogContent = () => {
    if (dialogType === 'create') {
      return (
        <Box sx={{ mt: 1 }}>
          <Autocomplete
            multiple
            options={merges.map((option: IMerge) => option.name)}
            getOptionLabel={(option: string) => option}
            isOptionEqualToValue={(option, value) => option === value}
            disableClearable={false}
            freeSolo={false}
            value={selectedMerge}
            onChange={(event: any, newValue: any | null) => {
              setSelectedMerge(newValue.map((option: string) => option));
            }}
            renderInput={(params) => <TextField {...params} label="Merge Templates" size="medium" />}
          />
          {selectedMerge.length > 2 ? (
            <Box>
              <TextField
                sx={{ mt: 2 }}
                title={'Envelope Name'}
                name={'unitNumber'}
                fullWidth
                value={documentName}
                label={'Envelope Name'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDocumentName(e.target.value)}
              />
            </Box>
          ) : null}
        </Box>
      );
    } else if (dialogType === 'execute') {
      return (
        <div>
          <Dropdown
            title={'Executor'}
            menuList={executorList()}
            name={'executors'}
            handleSelect={(e: any) => handleExecutorDropdown(e)}
            value={executor ? executor.name : ''}
          />
        </div>
      );
    } else if (dialogType === 'sendEnvelope' || dialogType === 'deleteDocument') {
      return (
        <div>
          <strong>{selectedDocument?.name!}</strong>
        </div>
      );
    } else if (dialogType === 'upload') {
      return (
        <div>
          <Box sx={{ my: 2 }}>
            <TextInput
              title={'Document Name'}
              id={'title'}
              handleTextInput={(e: any) => setTitle(e.target.value)}
              value={title}
              required={true}
            />
          </Box>
          <Box sx={{ my: 2 }}>
            <TextField
              error={envelopeIdRegex(envelopeId)}
              title={'Envelope ID'}
              label={'Envelope ID'}
              id={'envelope'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEnvelopeId(e.target.value)}
              value={envelopeId}
              helperText={envelopeIdRegex(envelopeId) ? 'Incorrect Format' : ''}
              fullWidth
            />
          </Box>
          <Box
            sx={{
              mb: 1,
              '& .MuiAccordionSummary-content': {
                display: 'block',
              },
            }}
          >
            <Dropdown
              id={'type'}
              title={'Type'}
              menuList={mergeType}
              name={'type'}
              handleSelect={(e: any) => setDocumentType(e.target.value)}
              value={documentType}
            />
          </Box>
          {!envelopeId ? (
            <div>
              {fileImage ? (
                <div>
                  <Grid item xs={12}>
                    <PdfCard file={fileImage} title={title} id={'0'} handleDelete={() => setFileImage(null)} download={true} />
                  </Grid>
                </div>
              ) : (
                <Dropzone onDrop={handleDrop} accept="application/pdf">
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps({ style })}>
                        <input {...getInputProps()} />
                        <p>Drag and Drop or Upload a Document (.pdf)</p>
                      </div>
                    </section>
                  )}
                </Dropzone>
              )}
            </div>
          ) : null}
        </div>
      );
    } else if (dialogType === 'download') {
      return (
        <div>
          <Box sx={{ my: 2 }}>
            {filteredDeal.documents.filter((document: IDocuments) => document.status === "Completed").map((document: IDocuments, index: number) => {
              return (
                <Box>
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={selectedDocuments.some((selectedDocument: any) => document._id === selectedDocument._id)}
                        onChange={(e: any) => {
                          if (selectedDocuments.some((selectedDocument: any) => document._id === selectedDocument._id)) {
                            setSelectedDocuments(selectedDocuments.filter((selected: any) => selected._id !== document._id));
                          } else {
                            setSelectedDocuments([...selectedDocuments, document!]);
                          }
                        }}
                      />
                    }
                    label={`${document.name}`}
                  />
                </Box>
              );
            })}
          </Box>
        </div>
      );
    }
  };

  const handleCloseSuccess = async () => {
    if (dialogType === 'execute') {
      sendToExecutor();
    } else if (dialogType === 'upload') {
      const args = {
        status: 'Completed',
        name: title,
        project: project._id,
        deal: filteredDeal._id,
        isAPS: false,
        dsEnvelopeId: envelopeId ? envelopeId : null,
        type: documentType,
        mergeTemplate: null,
      };
      setDialogOpen(false);
      if (envelopeId && !envelopeIdRegex(envelopeId)) {
        createDocument({ variables: { record: args } });
      } else if (file) {
        createDocument({ variables: { record: args, file: file } });
      }
    } else if (dialogType === 'sendEnvelope') {
      handleSendDocument(selectedDocument!);
    } else if (dialogType === 'deleteDocument') {
      removeDocuments(selectedDocument?._id!, selectedDocument?.type!);
    } else if (dialogType === 'download') {
      let documents = [];
      setDownloadLoading(true);
      setDialogOpen(false);
      setDialogType('');
      documents = await Promise.all(
        selectedDocuments.map(async (document: any) => {
          let blob = await getDocument({ variables: { _id: document._id } }).then((resp) => {
            if (resp.data.documentById.previewData) {
              const binaryData = Buffer.from(resp.data.documentById.previewData, 'binary');
              const blob = new Blob([binaryData], { type: 'application/pdf' });
              return blob;
            }
          });

          return {
            pdf: blob ? blob : null,
            name: document.name,
            dsEnvelopeId: document.dsEnvelopeId,
          };
        })
      );

      let files = [
        {
          _id: unit.suite,
          suite: unit.suite,
          documents: documents,
          mortgage: null,
          depositImages: [],
          purchasers: [],
        },
      ];

      await downloadDistributionFiles(files, `${project.name} - Suite ${unit.suite}`);
      setDownloadLoading(false);
    } else {
      if (selectedMerge.length === 1) {
        let selectedMerges = merges.filter((merge: IMerge) => selectedMerge.some((mergeName: string) => mergeName === merge.name));

        let pdf = await createPdf(
          selectedMerges[0],
          project,
          unit,
          filteredDeal.purchasers,
          filteredDeal.deposit,
          filteredDeal.options,
          filteredDeal.realtor[0],
          null,
          null,
          null,
          filteredDeal
        );

        let sign = selectedMerges[0].signFields.map((sign: any) => {
          return {
            index: sign.index,
            key: sign.key,
            pageNumber: sign.pageNumber,
            x: sign.x,
            y: sign.y,
          };
        });

        let mergeName = selectedMerges[0].name;

        const args = {
          status: 'Prepared',
          name: mergeName,
          project: project._id,
          deal: filteredDeal._id,
          signFields: sign,
          // signers,
          isAPS: false,
          type: selectedMerges[0].type,
          mergeTemplate: selectedMerges[0] ? selectedMerges[0]._id : null,
        };
        setDialogOpen(false);
        createDocument({ variables: { record: args, file: pdf } });
      } else if (selectedMerge.length > 1) {
        const mergedPdf = await PDFDocument.create();
        let selectedMerges = merges.filter((merge: IMerge) => selectedMerge.some((mergeName: string) => mergeName === merge.name));

        let documents = [];
        for (const document of selectedMerges) {
          let autoMergeTemplate = merges.find((mergeTemplate: IMerge) => mergeTemplate.name === document.name);

          let autoDocument = await fetch(autoMergeTemplate?.getUrl!).then(async (res) => await res.arrayBuffer());
          const documentPdf = await PDFDocument.load(autoDocument);
          let helveticaFont = await documentPdf.embedFont(StandardFonts.Helvetica);

          let pages = documentPdf.getPages();
          await autoMergeTemplate?.mergeFields.forEach(async (mergeField: any) => {
            let mergePages = pages[mergeField.pageNumber];
            let mergeValue = getMergeValues(
              mergeField,
              project,
              unit,
              null,
              filteredDeal.purchasers,
              filteredDeal.deposit,
              filteredDeal.options,
              filteredDeal.realtor[0],
              null,
              null,
              null,
              null,
              filteredDeal
            );

            if (
              mergeField.key === 'P1Check' ||
              (mergeField.key === 'P2Check' && filteredDeal.purchasers.length > 1) ||
              (mergeField.key === 'P3Check' && filteredDeal.purchasers.length > 2) ||
              (mergeField.key === 'P4Check' && filteredDeal.purchasers.length > 3)
            ) {
              mergePages.drawLine({
                start: { x: mergeField.x + 3, y: mergePages.getHeight() - mergeField.y - 3 },
                end: { x: mergeField.x + 8, y: mergePages.getHeight() - mergeField.y + 4 },
                thickness: 1,
                color: rgb(0, 0, 0),
                opacity: 0.75,
              });
              mergePages.drawLine({
                start: { x: mergeField.x, y: mergePages.getHeight() - mergeField.y + 1 },
                end: { x: mergeField.x + 3, y: mergePages.getHeight() - mergeField.y - 3 },
                thickness: 1,
                color: rgb(0, 0, 0),
                opacity: 0.75,
              });
            } else if (
              mergeField.key !== 'P1Check' &&
              mergeField.key !== 'P2Check' &&
              mergeField.key !== 'P3Check' &&
              mergeField.key !== 'P4Check'
            ) {
              mergePages.drawText(mergeValue, {
                x: mergeField.x,
                y: mergePages.getHeight() - mergeField.y - 3,
                size: mergeField.fontSize,
                font: helveticaFont,
                color: rgb(0, 0, 0),
              });
            }
          });

          let copiedPages = await mergedPdf.copyPages(documentPdf, documentPdf.getPageIndices());
          copiedPages.forEach((page: any) => mergedPdf.addPage(page));

          const documentDoc = await documentPdf.save();

          let mergeSignFields = autoMergeTemplate?.signFields.map((signFields: any, index: number) => {
            return {
              index: signFields.index,
              key: signFields.key,
              pageNumber: signFields.pageNumber,
              x: Math.round(signFields.x),
              y: Math.round(signFields.y),
            };
          });

          let blob = await new Blob([documentDoc]);
          let file = await new File([blob], `${document.name}.pdf`, { type: 'application/pdf' });

          documents.push({
            file: file,
            name: document.name,
            mergeTemplate: document._id,
            signFields: mergeSignFields,
            isAPS: false,
            type: 'Other',
          });
        }

        createMultipleDocument({
          variables: {
            project: project._id,
            deal: filteredDeal._id,
            documents: documents,
            documentName: selectedMerge.length > 2 ? documentName : '',
          },
        });
        setDialogOpen(false);
      }
    }
    setSelectedMerge([]);
  };

  const handleCloseSecond = async () => {
    if (!selectedMerge.length) return storeDispatch(showErrorSnackbar('No Merge Templates Selected'));
    const mergedPdf = await PDFDocument.create();

    for (const document of selectedMerge) {
      let autoMergeTemplate = await merges.find((mergeTemplate: IMerge) => mergeTemplate.name === document);

      let autoDocument = await fetch(autoMergeTemplate?.getUrl!).then(async (res) => await res.arrayBuffer());
      const documentPdf = await PDFDocument.load(autoDocument);
      let helveticaFont = await documentPdf.embedFont(StandardFonts.Helvetica);

      let pages = await documentPdf.getPages();
      await autoMergeTemplate?.mergeFields.forEach(async (mergeField: any) => {
        let mergePages = pages[mergeField.pageNumber];
        let mergeValue = getMergeValues(
          mergeField,
          project,
          unit,
          null,
          filteredDeal.purchasers,
          filteredDeal.deposit,
          filteredDeal.options,
          filteredDeal.realtor[0],
          null,
          null,
          null,
          null,
          filteredDeal
        );

        if (
          mergeField.key === 'P1Check' ||
          (mergeField.key === 'P2Check' && filteredDeal.purchasers.length > 1) ||
          (mergeField.key === 'P3Check' && filteredDeal.purchasers.length > 2) ||
          (mergeField.key === 'P4Check' && filteredDeal.purchasers.length > 3)
        ) {
          mergePages.drawLine({
            start: { x: mergeField.x + 3, y: mergePages.getHeight() - mergeField.y - 3 },
            end: { x: mergeField.x + 8, y: mergePages.getHeight() - mergeField.y + 4 },
            thickness: 1,
            color: rgb(0, 0, 0),
            opacity: 0.75,
          });
          mergePages.drawLine({
            start: { x: mergeField.x, y: mergePages.getHeight() - mergeField.y + 1 },
            end: { x: mergeField.x + 3, y: mergePages.getHeight() - mergeField.y - 3 },
            thickness: 1,
            color: rgb(0, 0, 0),
            opacity: 0.75,
          });
        } else if (
          mergeField.key !== 'P1Check' &&
          mergeField.key !== 'P2Check' &&
          mergeField.key !== 'P3Check' &&
          mergeField.key !== 'P4Check'
        ) {
          mergePages.drawText(mergeValue, {
            x: mergeField.x,
            y: mergePages.getHeight() - mergeField.y - 3,
            size: mergeField.fontSize,
            font: helveticaFont,
            color: rgb(0, 0, 0),
          });
        }
      });

      await autoMergeTemplate?.signFields.forEach(async (mergeField: any) => {
        let mergePages = pages[mergeField.pageNumber];
        let mergeValue = getMergeValues(
          mergeField,
          project,
          unit,
          null,
          filteredDeal.purchasers,
          filteredDeal.deposit,
          filteredDeal.options,
          filteredDeal.realtor[0],
          null,
          null,
          null,
          null,
          filteredDeal
        );

        await mergePages.drawText(`${signField(mergeField.index)}${mergeValue}`, {
          x: mergeField.x,
          y: mergePages.getHeight() - mergeField.y - 3,
          size: 8,
          font: helveticaFont,
          color: rgb(1, 0, 0),
        });
      });

      let copiedPages = await mergedPdf.copyPages(documentPdf, documentPdf.getPageIndices());
      await copiedPages.forEach((page: any) => mergedPdf.addPage(page));
    }

    let pdf = await mergedPdf.save();

    const blob = await new Blob([pdf]);
    const fileUrl = window.URL.createObjectURL(blob);

    let alink = document.createElement('a');
    alink.href = fileUrl;
    alink.download = `${selectedMerge.map((merge: any) => merge).join(' - ')}.pdf`;
    alink.click();
  };

  const removeDocuments = async (documentId: string, type: string) => {
    setDialogOpen(false);
    deleteDepositAmendment({ variables: { dealId: filteredDeal._id, type: type, documentId: documentId } });
  };

  const sendToExecutor = () => {
    sendDocumentToExecutor({
      variables: {
        documentId: selectedDocument!._id,
        executor,
      },
    });
    setDialogOpen(false);
  };

  const handleSendDocument = async (document: IDocuments) => {
    let dealRealtorId = null;
    let selectedRealtor = filteredDeal.realtor.find((realtor: IDealRealtor) => document.name.includes(`${realtor.fullName}`));
    if (selectedRealtor && document.type === 'Coop') {
      dealRealtorId = selectedRealtor._id;
    }

    sendDocument({
      variables: {
        documentId: document._id,
        dealRealtorId: dealRealtorId,
      },
    });
    setDialogOpen(false);
  };

  const dialogBox = () => {
    let removeButton = 'Cancel';
    let successButton = selectedMerge.length > 1 ? 'Send combined document' : 'Yes, I would like to create this amendment';
    let title = 'Please select an amendment you would like to create.';
    if (dialogType === 'execute') {
      successButton = 'Send to Executor';
      title = 'Please select an Executor to send Document';
    }
    if (dialogType === 'upload') {
      successButton = 'Upload Document';
      title = 'Upload a Document';
    }
    if (dialogType === 'download') {
      successButton = 'Download Documents';
      title = 'Select Documents';
    }
    if (dialogType === 'sendEnvelope') {
      successButton = 'Send Envelope';
      title = 'Are you sure you would like to send this envelope?';
    }
    if (dialogType === 'deleteDocument') {
      successButton = 'Delete/Void Document';
      title = 'Are you sure you would like to delete/void this document?';
    }

    return (
      <CustomDialog
        handleClose={() => setDialogOpen(false)}
        handleCloseRemove={() => setDialogOpen(false)}
        handleCloseSuccess={handleCloseSuccess}
        handleCloseSecond={handleCloseSecond}
        open={dialogOpen}
        removeButton={removeButton}
        secondOption={dialogType === 'create' ? 'Preview' : ''}
        successButton={successButton}
        dialogContent={dialogContent()}
        dialogTitle={title}
      />
    );
  };

  return (
    <Box
      sx={{
        '& .MuiAccordion-root': {
          border: '1px solid #000',
          padding: '4px',
        },
        '& .MuiAccordionDetails-root': {
          display: 'block',
        },
      }}
    >
      {dialogBox()}
      <FlexBetween>
        <h2 style={{ margin: 0 }}>Documents</h2>
        {user.type !== 'Developer' ? (
          <Box sx={{ mb: 1, alignSelf: 'center' }}>
            <FormControlLabel
              onChange={handleAudit}
              control={<Checkbox checked={auditDate ? true : false} color="primary" />}
              label={'RDS Accounting Review'}
            />
          </Box>
        ) : null}
      </FlexBetween>
      {filteredDeal ? (
        createDocumentLoading ||
        sendDocumentLoading ||
        dealUpdateLoading ||
        createMultipleDocumentLoading ||
        deleteDocumentLoading ||
        downloadLoading ? (
          <Box
            sx={{
              textAlign: 'center',
            }}
          >
            <LoadingLogo />
          </Box>
        ) : (
          <>
            {filteredDeal.documents.length > 0 ? (
              <div>
                {filteredDeal.documents.map((document: IDocuments, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <Accordion
                        sx={{
                          ...setBackgroundColor(document.status),
                          mb: 1,
                        }}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                          <div>
                            <h3>{document.name}</h3>
                            <div>
                              Status: <strong>{document.status}</strong>
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <EnvelopeAccordion doc={document} sendingDocumentToExecutor={sendingDocumentToExecutor} openDialog={openDialog} />
                        </AccordionDetails>
                      </Accordion>
                    </React.Fragment>
                  );
                })}
              </div>
            ) : null}
            {accessAllowed(user, project._id, 'editDeals') &&
            !filteredDeal.cancelled.dateCancelled &&
            !filteredDeal.rescission.dateRescinded ? (
              <FlexBetween sx={{ mt: 2 }}>
                <FlexBetween>
                  <div>
                    <Button onClick={() => openDialog('create')} color="primary" variant="contained">
                      Create a Document
                    </Button>
                  </div>
                </FlexBetween>
                <FlexBetween>
                  {filteredDeal.documents.some((document: IDocuments) => document.status === "Completed") ? 
                    <Button sx={{ mr: 1 }} onClick={() => openDialog('download')} color="primary" variant="contained">
                      Download Documents
                    </Button>
                  : null}
                  <Button onClick={() => openDialog('upload')} color="primary" variant="contained">
                    Upload a Document
                  </Button>
                </FlexBetween>
              </FlexBetween>
            ) : null}
          </>
        )
      ) : (
        <div>
          <em>There are no documents.</em>
        </div>
      )}
    </Box>
  );
};

const CREATEDOCUMENT = gql`
  mutation documentCreateOne($record: CreateOneDocumentInput, $file: Upload) {
    documentCreateOne(record: $record, file: $file) {
      _id
      name
      project {
        _id
      }
      type
      getUrl
      status
      isAPS
      dsEnvelopeId
    }
  }
`;

const CREATEMULTIPLEDOCUMENT = gql`
  mutation createEnvelopeMultipleDocuments($project: MongoID!, $deal: MongoID!, $documents: [NewDocumentInput], $documentName: String) {
    createEnvelopeMultipleDocuments(project: $project, deal: $deal, documents: $documents, documentName: $documentName) {
      _id
      name
      project {
        _id
      }
      type
      getUrl
      status
      isAPS
      dsEnvelopeId
    }
  }
`;

const DELETEDOCUMENT = gql`
  mutation deleteDepositAmendment($type: String!, $dealId: MongoID!, $documentId: MongoID!) {
    deleteDepositAmendment(type: $type, dealId: $dealId, documentId: $documentId) {
      _id
      name
      project {
        _id
      }
      status
      isAPS
      type
      dsEnvelopeId
    }
  }
`;

const SENDDOCUMENT = gql`
  mutation documentCreateEnvelope($documentId: MongoID!, $dealRealtorId: MongoID, $leaseId: MongoID) {
    documentCreateEnvelope(documentId: $documentId, sendOut: true, dealRealtorId: $dealRealtorId, leaseId: $leaseId) {
      name
      _id
      status
      dsEnvelopeId
    }
  }
`;

const SENDDOCUMENTTOEXECUTOR = gql`
  mutation documentSentToExecutor($documentId: MongoID!, $executor: executorData) {
    documentSendToExecutor(documentId: $documentId, executor: $executor) {
      _id
      status
      name
    }
  }
`;

const UPDATED_DOCUMENT = gql`
  subscription updatedDocument($projectId: String!) {
    updatedDocument(projectId: $projectId) {
      _id
      name
      status
    }
  }
`;

const UPDATEDEALINFO = gql`
  mutation dealUpdateById($_id: MongoID!, $record: UpdateByIdDealInput!) {
    dealUpdateById(_id: $_id, record: $record) {
      record {
        auditor {
          _id
          fullName
        }
        auditDate
      }
    }
  }
`;

const MERGETEMPLATES = gql`
  query mergeTemplateMany($filter: FilterFindManyMergeTemplateInput) {
    mergeTemplateMany(filter: $filter, limit: 10000) {
      _id
      project {
        _id
      }
      name
      mergeFields {
        key
        index
        pageNumber
        x
        y
        fontSize
        format
        wrap
      }
      signFields {
        key
        index
        pageNumber
        x
        y
      }
      default
      type
      getUrl
      putUrl
    }
  }
`;

const GETPREVIEWDATA = gql`
  query documentById($_id: MongoID!) {
    documentById(_id: $_id) {
      _id
      previewData
    }
  }
`;

export default Envelopes;
