import { useState, useEffect, useMemo } from 'react';
import { gql, useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

import { IDocuments } from '../../../types/docusign';
import StandardTable from '../../tables/StandardTable';
import { Container } from '../../../commonStyles';
import { convertAllDates, numToCurrency } from '../../../utils/Functions';
import { IPurchaserInfo } from '../../../types/CreateDealForm';

const ExpiredListings = () => {
  const [data, setData] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    getListings({ variables: { search, page, type: 'expired' } });
  }, [search, page]);

  const [getListings, { loading }] = useLazyQuery(GETEXPIREDLISTINGS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      setData(data.getListings.items);
      setCount(data.getListings.count);
    },
    onError: (err: any) => {
      console.log(err, 'err');
    },
  });

  const handleGlobalFilterValue = (value: string) => {
    setPage(1);
    setSearch(value);
  };

  const listingColumns = useMemo(() => {
    return [
      {
        Header: 'Project',
        accessor: (rowData: any) =>
          rowData.project && rowData.project.name === 'Leasing' ? rowData.unit.projectName : rowData.project.name,
      },
      {
        Header: 'Suite',
        accessor: (rowData: any) => {
          return (
            <Link
              style={{ textDecoration: 'none', color: 'inherit' }}
              rel="noopener noreferrer"
              target="_blank"
              to={`/${rowData.project._id}/dashboard/${rowData.unit._id}`}
            >
              <strong style={{ cursor: 'pointer' }}>{rowData.unit.suite}</strong>
            </Link>
          );
        },
      },
      {
        Header: 'Signed Listing Agreement',
        accessor: (rowData: any) => {
          if (rowData.documents.length) {
            let completedListing = rowData.documents.find(
              (document: IDocuments) => document.type === 'Listing' && document.status === 'Completed'
            );
            if (completedListing) {
              return 'Signed';
            } else return 'Not Signed';
          } else return 'Not Signed';
        },
      },
      {
        Header: 'Listing Start Date',
        accessor: (rowData: any) => (rowData.listing ? convertAllDates(rowData.listing.dateStart, 'PP') : ''),
      },
      {
        Header: 'Listing End Date',
        accessor: (rowData: any) => (rowData.listing ? convertAllDates(rowData.listing.dateEnd, 'PP') : ''),
      },
      {
        Header: 'Amount',
        accessor: (rowData: any) => (rowData.listing ? numToCurrency.format(rowData.listing.amount) : ''),
      },
      {
        Header: 'Landlords',
        accessor: (rowData: any) => {
          if (rowData.landlords && rowData.landlords.length) {
            return rowData.landlords.map((purchaser: IPurchaserInfo) => purchaser.fullName).join(', ');
          } else return '-';
        },
      },
      {
        Header: 'MLS#',
        accessor: (rowData: any) => (rowData.listing ? rowData.listing.mls : ''),
      },
    ];
  }, []);

  return (
    <Container sx={{ backgroundColor: '#fff', pt: 0, px: 0 }}>
      <Box sx={{ p: 2 }}>
        <StandardTable
          loading={loading}
          columns={listingColumns}
          data={data}
          handleGlobalFilterValue={handleGlobalFilterValue}
          count={count}
        />
      </Box>
    </Container>
  );
};

const GETEXPIREDLISTINGS = gql`
  query getListings($search: String, $page: Int!, $type: String!) {
    getListings(search: $search, page: $page, type: $type) {
      count
      items {
        _id
        active
        project {
          _id
          name
        }
        unit {
          _id
          suite
          projectName
        }
        documents {
          type
          status
        }
        landlords {
          fullName
        }
        listing {
          _id
          dateStart
          dateEnd
          amount
          mls
        }
      }
    }
  }
`;

export default ExpiredListings;
