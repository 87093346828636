import { useContext, useState } from 'react';
import { Box, Button } from '@mui/material';
import { gql, useQuery, useMutation } from '@apollo/client';
import Tenancy from './Tenancy';
import Listing from './Listing';
import Management from './Management';
import Summary from './Summary';
import { IUser, IUserArray } from '../../../types/user';
import { FlexEnd } from '../../../commonStyles';
import { LeaseContext } from '../../../context/LeaseContext';
import CustomDialog from '../../common/CustomDialog';
import { ILease } from '../../../types/lease';

const LeaseInformation = (props: ChildProps) => {
  const { agreementType } = props;
  const { lease, leases, setLeases, setLease, setAgreementType } = useContext(LeaseContext);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [users, setUsers] = useState<IUser[]>([]);

  useQuery<IUserArray>(GETSALES, {
    variables: { filter: { OR: [{ type: 'Sales' }, { type: 'Manager' }], locked: false } },
    onCompleted: (data) => {
      setUsers(data.userMany);
    },
  });

  const [archiveLease] = useMutation(ARCHIVELEASE, {
    onCompleted: (data: any) => {
      let allLeases = leases.map((lease: ILease) => {
        if (lease._id === data.leaseUpdateById.record._id) {
          return {
            ...lease,
            active: false,
          };
        } else return lease;
      });
      setAgreementType('');
      setLease(null);
      setLeases(allLeases);
    },
    onError: (err: any) => {
      console.log(err, 'err');
    },
  });

  const agreementView = (type: string) => {
    switch (type) {
      case 'summary':
        return (
          <Box>
            <Summary lease={lease} />
            <FlexEnd sx={{ mt: 2 }}>
              <Button onClick={() => setDialogOpen(true)} variant="contained" color="error">
                Archive Lease
              </Button>
            </FlexEnd>
          </Box>
        );
      case 'tenancy':
        return <Tenancy users={users} />;
      case 'listing':
        return <Listing users={users} />;
      case 'rentalManagement':
        return <Management users={users} />;
    }
  };

  const dialogContent = () => {
    return <Box sx={{ mb: 2 }}>Are you sure you would like to archive this lease?</Box>;
  };

  return (
    <Box>
      <CustomDialog
        handleClose={() => setDialogOpen(false)}
        handleCloseRemove={() => setDialogOpen(false)}
        handleCloseSuccess={() => archiveLease({ variables: { _id: lease._id, record: { active: false } } })}
        open={dialogOpen}
        removeButton={'No'}
        successButton={'Yes'}
        dialogContent={dialogContent()}
        dialogTitle={'Archive Lease'}
      />
      {agreementView(agreementType)}
    </Box>
  );
};

interface ChildProps {
  agreementType: string;
}

const GETSALES = gql`
  query userMany($filter: FilterFindManyUserInput) {
    userMany(filter: $filter) {
      _id
      fullName
    }
  }
`;

const ARCHIVELEASE = gql`
  mutation leaseUpdateById($_id: MongoID!, $record: UpdateByIdLeaseInput!) {
    leaseUpdateById(_id: $_id, record: $record) {
      record {
        _id
      }
    }
  }
`;

export default LeaseInformation;
