import { Box, Tabs, Tab } from "@mui/material";
import { styled } from "@mui/material/styles";

export const SectionContainer = styled(Box)`
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 8px;
  padding: 15px;
`;

export const SettingContainer = styled(Box)`
  background-color: #fff;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 15px;
`;

export const Container = styled(Box)`
  border: 1px solid #000;
  padding: 20px 20px;
  position: relative;
  borderRadius: 8px;
  marginBottom: 20px;
`;

export const Flex = styled(Box)`
  display: flex;
`;

export const FlexEnd = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;

export const FlexBetween = styled(Box)`
  display: flex;
  justify-content: space-between;
  "@media (max-width:600px)": {
    flex-direction: column;
  }
`;

// Sub Tabs

export const SubTabs = styled(Tabs)`
  background-color: #fff;
  border-bottom: none;
  &.MuiTabScrollButton-root: {
    background-color: red,
  },
`;

export const SubTab = styled(Tab)`
  background-color: #fff;
  border: 1px solid #000;
  color: #000;
  &.Mui-selected {
    background-color:#00142a;
  },
  &.MuiButtonBase-root-MuiTabScrollButton-root.Mui-disabled: {
    background-color: #e5e5e5,
  },
`;
