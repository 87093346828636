import React, { useState, useReducer, useContext, useEffect } from 'react';
import { Box, Typography, Button, FormControlLabel, Checkbox, TextField, Grid, InputAdornment, Radio, RadioGroup } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { IUser } from '../../../types/user';
import { purchasersReducer } from '../../../utils/Reducers';
import { Container, Flex, FlexEnd } from '../../../commonStyles';
import { LeaseContext } from '../../../context/LeaseContext';
import Purchasers from './Purchasers';
import Dropdown from '../../common/formControls/Dropdown';
import { daysOfMonth, tenancyLength } from '../../../utils/Constants';
import { IPurchaserInfo } from '../../../types/CreateDealForm';
import CustomDialog from '../../common/CustomDialog';
import { IDocuments } from '../../../types/docusign';
import { useAppDispatch } from '../../../app/hooks';
import { showErrorSnackbar } from '../../../features/snackbar/snackbarSlice';

export const utilitiesReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'ADD':
      return [
        ...state,
        {
          custom: true,
          name: 'Other',
          answer: [],
        },
      ];
    case 'UPDATE':
      return state.map((state: any, index: number) => {
        if (index === action.payload.index) {
          return action.payload.value;
        } else return state;
      });
    case 'UPDATEALL':
      return action.payload;
    case 'DELETE':
      return state.filter((state: any, index: number) => index !== action.payload.index);
    default:
      throw new Error();
  }
};

const Tenancy = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const { saveTenancy, deleteTenancy, lease } = useContext(LeaseContext);
  const [tenantsState, tenantsDispatch] = useReducer(purchasersReducer, lease && lease.tenancy ? lease.tenancy.tenants : []);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [tenancyType, setTenancyType] = useState<string>('Fixed Length of Time');
  const [tenancyDateStart, setTenancyDateStart] = useState<Date | null>(
    lease && lease.tenancy ? new Date(lease.tenancy.dateStart) : new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [tenancyDateEnd, setTenancyDateEnd] = useState<Date | null>(
    lease && lease.tenancy ? new Date(lease.tenancy.dateEnd) : new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [amount, setAmount] = useState<string>(lease && lease.tenancy ? lease.tenancy.amount : '');
  const [payableTo, setPayableTo] = useState<string>(lease && lease.tenancy ? lease.tenancy.payable : '');
  const [paymentDay, setPaymentDay] = useState<string>(lease && lease.tenancy ? lease.tenancy.paymentDay : 'First');
  const [paymentType, setPaymentType] = useState<string>(lease && lease.tenancy ? lease.tenancy.paymentType : 'Monthly');
  const [paymentMethod, setPaymentMethod] = useState<string>(
    lease && lease.tenancy ? lease.tenancy.paymentMethod : 'Post-dated cheques or pre-authorized payments pending landlord approval'
  );
  const [partialRentAmount, setPartialRentAmount] = useState<string>(
    lease && lease.tenancy && lease.tenancy.partialRentAmount ? lease.tenancy.partialRentAmount : ''
  );
  const [partialRentPaymentDate, setPartialRentPaymentDate] = useState<Date | null>(
    lease && lease.tenancy ? new Date(lease.tenancy.partialRentPaymentDate) : null
  );
  const [partialRentStartDate, setPartialRentStartDate] = useState<Date | null>(
    lease && lease.tenancy ? new Date(lease.tenancy.partialRentStartDate) : null
  );
  const [partialRentEndDate, setPartialRentEndDate] = useState<Date | null>(
    lease && lease.tenancy ? new Date(lease.tenancy.partialRentEndDate) : null
  );
  const [utilitiesState, utilitiesDispatch] = useReducer(
    utilitiesReducer,
    lease && lease.tenancy
      ? lease.tenancy.utilities
      : [
          {
            name: 'Gas',
            answer: ['No'],
          },
          {
            name: 'Air Conditioning',
            answer: ['No'],
          },
          {
            name: 'Additional Storage Space',
            answer: ['Yes'],
          },
          {
            name: 'On-Site Laundry',
            answer: ['Yes', 'No Charge'],
          },
          {
            name: 'Guest Parking',
            answer: ['Yes', 'No Charge'],
          },
        ]
  );
  const [parkingDescription, setParkingDescription] = useState<string>(
    lease && lease.tenancy ? lease.tenancy.parkingDescription : 'Parking Locker Level & Unit - TBD'
  );
  const [lockerDescription, setLockerDescription] = useState<string>(
    lease && lease.tenancy ? lease.tenancy.lockerDescription : 'Storage Locker Level & Unit - TBD'
  );
  const [electricity, setElectricity] = useState<string>(lease && lease.tenancy ? lease.tenancy.electricity : 'Tenant');
  const [heat, setHeat] = useState<string>(lease && lease.tenancy ? lease.tenancy.heat : 'Tenant');
  const [water, setWater] = useState<string>(lease && lease.tenancy ? lease.tenancy.water : 'Water');
  const [rentDiscount, setRentDiscount] = useState<boolean>(lease && lease.tenancy && lease.tenancy.rentDiscount ? true : false);
  const [rentDiscountDescription, setRentDiscountDescription] = useState<string>(
    lease && lease.tenancy && lease.tenancy.rentDiscount ? lease.tenancy.rentDiscount : ''
  );
  const [rentDepositAmount, setRentDepositAmount] = useState<string>(
    lease && lease.tenancy && lease.tenancy.rentDepositAmount
      ? lease.tenancy.rentDepositAmount
      : lease.tenancy && lease.tenancy.amount
      ? lease.tenancy.amount
      : ''
  );
  const [rentDeposit, setRentDeposit] = useState<boolean>(lease && lease.tenancy && rentDepositAmount ? true : false);
  const [keyDepositAmount, setKeyDepositAmount] = useState<string>(
    lease && lease.tenancy && lease.tenancy.keyDepositAmount ? lease.tenancy.keyDepositAmount : '250'
  );
  const [keyDeposit, setKeyDeposit] = useState<string>(lease && lease.tenancy ? lease.tenancy.keyDeposit : '');
  const [smokingRules, setSmokingRules] = useState<boolean>(
    lease && lease.tenancy && lease.tenancy.smokingRules ? lease.tenancy.smokingRules : true
  );
  const [smokingRulesDescription, setSmokingRulesDescription] = useState<string>(
    lease && lease.tenancy && lease.tenancy.smokingRulesDescription ? lease.tenancy.smokingRulesDescription : 'No smoking within the suite.'
  );

  useEffect(() => {
    if (lease.landlords.length) {
      setPayableTo(lease.landlords.map((purchaser: IPurchaserInfo) => `${purchaser.firstName} ${purchaser.lastName}`).join(' & '));
    }
  }, [lease.landlords]);

  const handleUtilitiesChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    custom: boolean = false,
    index: number,
    name: string
  ) => {
    let utility = utilitiesState.find((utility: IUtility) => utility.name === name);

    let answers = utility.answer;

    if (utility) {
      if (answers.includes(event.target.name)) {
        answers = answers.filter((answer: string) => answer !== event.target.name);
      } else {
        answers = [...answers, event.target.name];
      }
    }

    await utilitiesDispatch({
      type: 'UPDATE',
      payload: {
        index: index,
        value: {
          name: name,
          answer: answers,
          custom: custom,
        },
      },
    });
  };

  const handleDeleteTenancy = () => {
    if (lease.documents.find((document: IDocuments) => document.type === 'Tenancy'))
      return storeDispatch(showErrorSnackbar('There is currently a Tenancy Document. Please delete the envelope to proceed.'));
    deleteTenancy(lease);
  };

  return (
    <Box>
      <CustomDialog
        handleClose={() => setDialogOpen(false)}
        handleCloseRemove={() => setDialogOpen(false)}
        handleCloseSuccess={() => handleDeleteTenancy()}
        open={dialogOpen}
        removeButton={'Cancel'}
        successButton={'Yes, I would like to delete Tenancy Profile'}
        dialogContent={<Box sx={{ my: 2 }}>Are you sure you would like to delete the Tenancy Profile?</Box>}
        dialogTitle={'Delete Tenancy Profile'}
      />
      {lease && lease.management ? (
        <FlexEnd sx={{ mt: 2 }}>
          <Button variant="contained" color="error" onClick={() => setDialogOpen(true)}>
            Delete Tenancy
          </Button>
        </FlexEnd>
      ) : null}
      <Container sx={{ mt: 2 }}>
        <Purchasers title="Tenants" purchasersState={tenantsState} dispatch={tenantsDispatch} />
      </Container>
      <form
        onSubmit={(e: any) =>
          saveTenancy(
            e,
            tenantsState,
            tenancyType,
            tenancyDateStart,
            tenancyDateEnd,
            amount,
            payableTo,
            paymentDay,
            paymentType,
            paymentMethod,
            partialRentAmount,
            partialRentPaymentDate,
            partialRentStartDate,
            partialRentEndDate,
            utilitiesState,
            parkingDescription,
            lockerDescription,
            electricity,
            water,
            heat,
            rentDiscountDescription,
            rentDepositAmount,
            keyDepositAmount,
            keyDeposit,
            smokingRules,
            smokingRulesDescription
          )
        }
      >
        <Container sx={{ mt: 2 }}>
          <Typography variant={'h5'}>Term of Tenancy Agreement</Typography>
          <Grid spacing={2} container sx={{ mt: 2 }}>
            <Grid item xs={12} sm={6} md={4}>
              <Dropdown
                required
                title={'Tenancy Length'}
                menuList={tenancyLength}
                name={'tenancyType'}
                handleSelect={(e: any) => setTenancyType(e.target.value)}
                value={tenancyType}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={'Tenancy Start Date'}
                  value={tenancyDateStart}
                  onChange={(newValue) => {
                    setTenancyDateStart(newValue);
                  }}
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>
            {tenancyType === 'Fixed Length of Time' ? (
              <Grid item xs={12} sm={6} md={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={'Tenancy End Date'}
                    value={tenancyDateEnd}
                    onChange={(newValue) => {
                      setTenancyDateEnd(newValue);
                    }}
                    sx={{ width: '100%' }}
                  />
                </LocalizationProvider>
              </Grid>
            ) : null}
          </Grid>
        </Container>
        <Container sx={{ mt: 2 }}>
          <Typography variant={'h5'}>Rent</Typography>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                title={'Rent Amount'}
                name={'amount'}
                fullWidth
                value={amount}
                label={'Rent Amount'}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAmount(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                title={'Rent Payable To'}
                name={'payableTo'}
                fullWidth
                value={payableTo}
                label={'Rent Payable To'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPayableTo(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Dropdown
                required
                title={'Payment Day of Month'}
                menuList={daysOfMonth}
                name={'paymentDay'}
                handleSelect={(e: any) => setPaymentDay(e.target.value)}
                value={paymentDay}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Dropdown
                required
                title={'Payment Type'}
                menuList={['Monthly', 'Weekly', 'Other']}
                name={'paymentType'}
                handleSelect={(e: any) => setPaymentType(e.target.value)}
                value={paymentType}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                title={'Payment Method'}
                name={'paymentMethod'}
                fullWidth
                multiline
                rows={3}
                required
                value={paymentMethod}
                label={'Payment Method'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPaymentMethod(e.target.value)}
              />
            </Grid>
          </Grid>
        </Container>
        <Container sx={{ mt: 2 }}>
          <Typography variant={'h5'}>Partial Rent (Optional)</Typography>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                title={'Partial Rent Amount'}
                name={'partialRentAmount'}
                fullWidth
                value={partialRentAmount}
                label={'Partial Rent Amount'}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPartialRentAmount(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={'Partial Rent Payment Date'}
                  value={partialRentPaymentDate}
                  onChange={(newValue) => {
                    setPartialRentPaymentDate(newValue);
                  }}
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={4}></Grid>
            <Grid item xs={12} sm={6} md={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={'Partial Rent Start Date'}
                  value={partialRentStartDate}
                  onChange={(newValue) => {
                    setPartialRentStartDate(newValue);
                  }}
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={'Partial Rent End Date'}
                  value={partialRentEndDate}
                  onChange={(newValue) => {
                    setPartialRentEndDate(newValue);
                  }}
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Container>
        <Container sx={{ mt: 2 }}>
          <Typography variant={'h5'}>Services and Utilities</Typography>
          <Grid sx={{ mt: 2 }} container spacing={2}>
            {utilitiesState.map((utility: IUtility, index: number) => {
              return (
                <React.Fragment key={index}>
                  {utility.custom ? (
                    <Grid item xs={12} sm={6} md={2}>
                      <TextField
                        title={'Service/Utility Name'}
                        name={'name'}
                        fullWidth
                        value={utility.name}
                        label={'Service/Utility Name'}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          utilitiesDispatch({
                            type: 'UPDATE',
                            payload: {
                              index: index,
                              value: {
                                name: e.target.value,
                                answer: utility.answer,
                                custom: true,
                              },
                            },
                          })
                        }
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={6} md={2}>
                      {utility.name}
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} md={2}>
                    <FormControlLabel
                      name="Yes"
                      control={
                        <Checkbox
                          id={`${index}`}
                          checked={utility.answer.includes('Yes') ? true : false}
                          onChange={(e, value) => handleUtilitiesChange(e, utility.custom, index, utility.name)}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={'Yes'}
                      labelPlacement="end"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <FormControlLabel
                      name="No"
                      control={
                        <Checkbox
                          id={`${index}`}
                          checked={utility.answer.includes('No') ? true : false}
                          onChange={(e, value) => handleUtilitiesChange(e, utility.custom, index, utility.name)}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={'No'}
                      labelPlacement="end"
                    />
                  </Grid>
                  {utility.name === 'On-Site Laundry' || utility.name === 'Guest Parking' ? (
                    <>
                      <Grid item xs={12} sm={6} md={2}>
                        <FormControlLabel
                          name="No Charge"
                          control={
                            <Checkbox
                              checked={utility.answer.includes('No Charge') ? true : false}
                              id={`${index}`}
                              onChange={(e, value) => handleUtilitiesChange(e, utility.custom, index, utility.name)}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          }
                          label={'No Charge'}
                          labelPlacement="end"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <FormControlLabel
                          name="Pay Per Use"
                          control={
                            <Checkbox
                              checked={utility.answer.includes('Pay Per Use') ? true : false}
                              id={`${index}`}
                              onChange={(e, value) => handleUtilitiesChange(e, utility.custom, index, utility.name)}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          }
                          label={'Pay Per Use'}
                          labelPlacement="end"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}></Grid>
                    </>
                  ) : (
                    <Grid item xs={6}></Grid>
                  )}
                </React.Fragment>
              );
            })}
          </Grid>
          <FlexEnd>
            <Button onClick={() => utilitiesDispatch({ type: 'ADD' })} variant="contained" color="primary">
              Add Service / Utility
            </Button>
          </FlexEnd>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                title={'Parking Description'}
                name={'paymentMethod'}
                fullWidth
                multiline
                rows={3}
                required
                value={parkingDescription}
                label={'Parking Description'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setParkingDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                title={'Locker Description'}
                name={'paymentMethod'}
                fullWidth
                multiline
                rows={3}
                required
                value={lockerDescription}
                label={'Locker Description'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLockerDescription(e.target.value)}
              />
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            <Box>
              <Typography>
                <strong>Electricity</strong>
              </Typography>
              <RadioGroup
                onChange={(e) => setElectricity(e.target.value)}
                aria-labelledby="demo-radio-buttons-group-electricity"
                value={electricity}
                name="radio-buttons-group"
                row
              >
                <FormControlLabel value="Landlord" control={<Radio />} label="Landlord" />
                <FormControlLabel value="Tenant" control={<Radio />} label="Tenant" />
              </RadioGroup>
            </Box>
            <Typography>
              <strong>Heat</strong>
            </Typography>
            <RadioGroup
              onChange={(e) => setHeat(e.target.value)}
              aria-labelledby="demo-radio-buttons-group-heat"
              value={heat}
              name="radio-buttons-group"
              row
            >
              <FormControlLabel value="Landlord" control={<Radio />} label="Landlord" />
              <FormControlLabel value="Tenant" control={<Radio />} label="Tenant" />
            </RadioGroup>
            <Typography>
              <strong>Water</strong>
            </Typography>
            <RadioGroup
              onChange={(e) => setWater(e.target.value)}
              aria-labelledby="demo-radio-buttons-group-water"
              value={water}
              name="radio-buttons-group"
              row
            >
              <FormControlLabel value="Landlord" control={<Radio />} label="Landlord" />
              <FormControlLabel value="Tenant" control={<Radio />} label="Tenant" />
            </RadioGroup>
          </Box>
        </Container>
        <Container sx={{ mt: 2 }}>
          <Typography variant={'h5'}>Rent Discount</Typography>
          <Grid sx={{ mt: 2 }} container spacing={2}>
            <Grid item xs={12} sm={3}>
              <FormControlLabel
                onChange={(e) => setRentDiscount(!rentDiscount)}
                value={rentDiscount}
                control={<Checkbox checked={rentDiscount} />}
                label="Rent Discount"
              />
            </Grid>
            {rentDiscount ? (
              <Grid item xs={12}>
                <TextField
                  title={'Rent Discount Description'}
                  name={'keyDeposit'}
                  fullWidth
                  multiline
                  rows={3}
                  value={rentDiscountDescription}
                  label={'Rent Discount Description'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRentDiscountDescription(e.target.value)}
                />
              </Grid>
            ) : null}
          </Grid>
        </Container>
        <Container sx={{ mt: 2 }}>
          <Typography variant={'h5'}>Rent Deposit</Typography>
          <Grid sx={{ mt: 2 }} container spacing={2}>
            <Grid item xs={12} sm={3}>
              <FormControlLabel
                value={rentDeposit}
                onChange={(e) => setRentDeposit(!rentDeposit)}
                control={<Checkbox checked={rentDeposit} />}
                label="Rent Deposit"
              />
            </Grid>
            {rentDeposit ? (
              <Grid item xs={12}>
                <TextField
                  title={'Rent Deposit Amount'}
                  name={'rentDepositAmount'}
                  fullWidth
                  value={rentDepositAmount}
                  label={'Rent Deposit Amount'}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRentDepositAmount(e.target.value)}
                />
              </Grid>
            ) : null}
          </Grid>
        </Container>
        <Container sx={{ mt: 2 }}>
          <Typography variant={'h5'}>Key Deposit (Optional)</Typography>
          <Grid sx={{ mt: 2 }} container spacing={2}>
            <Grid item xs={12} sm={3}>
              <TextField
                title={'Key Deposit Amount'}
                name={'keyDepositAmount'}
                fullWidth
                type="number"
                value={keyDepositAmount}
                label={'Key Deposit Amount'}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setKeyDepositAmount(e.target.value)}
              />
            </Grid>
            {keyDepositAmount ? (
              <Grid item xs={12}>
                <TextField
                  title={'Key Deposit Details'}
                  name={'keyDeposit'}
                  fullWidth
                  multiline
                  rows={3}
                  value={keyDeposit}
                  label={'Key Deposit Details'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setKeyDeposit(e.target.value)}
                />
              </Grid>
            ) : null}
          </Grid>
        </Container>
        <Container sx={{ mt: 2 }}>
          <Typography variant={'h5'}>Smoking</Typography>
          <Grid sx={{ mt: 2 }} container spacing={2}>
            <Grid item xs={12} sm={3}>
              <FormControlLabel
                name="Yes"
                control={
                  <Checkbox
                    id={`smokingRules`}
                    checked={smokingRules ? smokingRules : false}
                    onChange={(e, value) => {
                      setSmokingRules(value);
                    }}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label={'Smoking Rules'}
                labelPlacement="end"
              />
            </Grid>
            {smokingRules ? (
              <Grid item xs={12}>
                <TextField
                  title={'Smoking Rules Description'}
                  name={'smokingRulesDescription'}
                  fullWidth
                  multiline
                  rows={3}
                  value={smokingRulesDescription}
                  label={'Smoking Rules Description'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSmokingRulesDescription(e.target.value)}
                />
              </Grid>
            ) : null}
          </Grid>
        </Container>
        <FlexEnd sx={{ mt: 2 }}>
          <Button type="submit" variant="contained" color="success">
            Save Tenancy Agreement
          </Button>
        </FlexEnd>
      </form>
    </Box>
  );
};

interface ChildProps {
  users: IUser[];
}

export interface IUtility {
  name: string;
  answer: string[];
  custom: boolean;
}

export default Tenancy;
