import { useState, useMemo } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import Dropzone from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import { subYears } from 'date-fns';
import { Box, Autocomplete, TextField, Typography, Button, Divider, Grid } from '@mui/material';
import { FlexBetween } from '../../../commonStyles';
import { useAppDispatch } from '../../../app/hooks';
import { selectProject } from '../../../features/project/projectSlice';
import { IPurchaserInfo, IPurchaser, IPurchasersArray, IIdentification, ISigningOfficer } from '../../../types/CreateDealForm';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../features/snackbar/snackbarSlice';
import { FlexEnd } from '../../../commonStyles';
import { capitalizeFirstLetter, formatPhoneNumber, formatSin } from '../../../utils/Functions';
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from '../../../css/dropzone';
import PdfCard from '../../common/PdfCard';
import PurchaserForm from '../../common/forms/PurchaserForm';

const Purchasers = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const { dispatch, purchasersState, title } = props;
  const [random, setRandom] = useState<number>(Math.floor(Math.random() * 1000000 + 1));
  const [searchInfo, setSearchInfo] = useState<IPurchaserInfo[]>([]);
  const [errors, setErrors] = useState<any>(null);

  const { isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/jpeg, image/png, application/pdf',
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const [getPurchaser] = useLazyQuery<IPurchaser>(GETPURCHASER, {
    onCompleted: (data) => {
      setRandom(Math.floor(Math.random() * 1000000 + 1));
      if (purchasersState.map((purchaser: IPurchaserInfo) => purchaser._id).includes(data.purchaserById._id)) {
        storeDispatch(showErrorSnackbar('This purchaser has already been selected'));
        return;
      }
      dispatch({
        type: 'ADD',
        payload: data.purchaserById,
      });
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [getAllPurchasers, { loading: purchaserLoading }] = useLazyQuery<IPurchasersArray>(PURCHASERS, {
    variables: { filter: { project: project._id } },
    onCompleted: (data) => {
      setSearchInfo(data.purchaserMany);
    },
  });

  const [deleteId] = useMutation(DELETEID, {
    onCompleted: (data) => {
      let index = purchasersState.findIndex((purchaser: IPurchaserInfo) => purchaser._id === data.deletePurchaserId._id);
      dispatch({
        type: 'UPDATE',
        payload: { index: index, field: 'identifications', value: data.deletePurchaserId.identifications },
      });
      storeDispatch(showSuccessSnackbar('Document has been deleted!'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [saveNewPurchaser] = useMutation(CREATEPURCHASERUPLOAD, {
    onCompleted: (data) => {
      let index = purchasersState.findIndex((purchaser: IPurchaserInfo) => !purchaser._id);
      dispatch({
        type: 'UPDATE',
        payload: { index: index, field: '_id', value: data.purchaserCreateOne._id },
      });
      storeDispatch(showSuccessSnackbar('Purchaser Information has been saved!'));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(`An Input is Missing`));
    },
  });

  const [uploadID] = useMutation(UPLOADID, {
    onCompleted: (data) => {
      let index = purchasersState.findIndex((purchaser: IPurchaserInfo) => purchaser._id === data.uploadID._id);
      dispatch({
        type: 'UPDATE',
        payload: { index: index, field: 'identifications', value: data.uploadID.identifications },
      });
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updatePurchaser] = useMutation(UPDATEPURCHASER, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Purchaser Information has been updated!'));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(`An Input is Missing`));
    },
  });

  const handleSubmit = async (event: any, index: number) => {
    event.preventDefault();
    if (purchasersState[index].identifications.length === 0) {
      storeDispatch(showErrorSnackbar('Purchaser Image has not been uploaded'));
      return;
    }
    if (!purchasersState[index].corp) {
      if (purchasersState[index].dob && new Date(purchasersState[index].dob!) > subYears(new Date(), 18)) {
        storeDispatch(showErrorSnackbar('Purchaser is under 18'));
        return;
      }
    }
    if (errors) {
      storeDispatch(showErrorSnackbar('Invalid Date'));
      return;
    }

    if (purchasersState[index]._id) {
      const { _id, getUrl, putUrl, identifications, ...newObject } = purchasersState[index];
      newObject.dob = new Date(newObject.dob!);
      newObject.idExpiry = new Date(newObject.idExpiry!);

      if (purchasersState[index].identifications.length > 0) {
        await purchasersState[index].identifications.forEach(async (file: any) => {
          if (file.file) {
            await uploadID({ variables: { id: purchasersState[index]._id, name: file.file.name, file: file.file } });
          }
        });
        updatePurchaser({ variables: { _id: purchasersState[index]._id, record: newObject } });
      } else {
        updatePurchaser({ variables: { _id: purchasersState[index]._id, record: newObject } });
      }
    } else {
      let newId = await purchasersState[index].identifications.map((identification: any) => identification.file);
      let identificationName = purchasersState[index].identifications.map((identification: any) => {
        return {
          name: identification.name,
        };
      });
      purchasersState[index].identifications = identificationName;
      saveNewPurchaser({ variables: { record: purchasersState[index], proof: null, files: newId } });
    }
  };

  const handleSearchInput = (event: any, values: IPurchaserInfo) => {
    getPurchaser({ variables: { _id: values._id } });
  };

  const handleTextInput = async (event: any, numIndex: number) => {
    if (event && event.target.id === 'streetAddress') {
      dispatch({ type: 'UPDATE', payload: { index: numIndex, field: 'streetAddress', value: event.target.value } });
    }
    if (event && event.target.name) {
      let value = event.target.value;
      if (event.target.name === 'primaryPhone') {
        value = formatPhoneNumber(event.target.value);
      }
      if (event.target.name === 'sin') {
        value = formatSin(event.target.value);
      }
      dispatch({ type: 'UPDATE', payload: { index: numIndex, field: event.target.name, value: value } });
    }
  };

  const handleDateChange = (newValue: any, id: string, numIndex: number) => {
    if (newValue == 'Invalid Date') {
      setErrors('Invalid Date');
      return;
    } else {
      setErrors(null);
    }
    dispatch({ type: 'UPDATE', payload: { index: numIndex, field: id, value: newValue } });
  };

  const handleEndvestorInput = (event: any, numIndex: number) => {
    dispatch({ type: 'UPDATE', payload: { index: numIndex, field: 'purchaserType', value: event.target.innerText } });
  };

  const handleIdTypeInput = (event: any, numIndex: number) => {
    dispatch({ type: 'UPDATE', payload: { index: numIndex, field: 'idType', value: event.target.innerText } });
  };

  const handleIdIssuedByInput = (event: any, numIndex: number) => {
    dispatch({ type: 'UPDATE', payload: { index: numIndex, field: 'idJurisdiction', value: event.target.innerText } });
  };

  const handleStreetAddress = async (value: string, numIndex: number) => {
    dispatch({ type: 'UPDATE', payload: { index: numIndex, field: 'streetAddress', value: value } });
  };

  const handleDrop = (acceptedFiles: any, numIndex: number) => {
    if (acceptedFiles.length === 0) {
      storeDispatch(showErrorSnackbar('This file type is not allowed'));
      return;
    }
    const file = acceptedFiles[0];
    const fileReader = new FileReader();
    if (file) {
      fileReader.readAsDataURL(file);
    }
    fileReader.onloadend = async () => {
      dispatch({
        type: 'UPDATE',
        payload: {
          index: numIndex,
          field: 'identifications',
          value: [
            ...purchasersState[numIndex].identifications,
            {
              url: fileReader.result,
              file: file,
              name: file.name,
            },
          ],
        },
      });
    };
  };

  const handleDeleteId = async (numIndex: number, id: string, idIndex: number) => {
    if (purchasersState[numIndex].identifications[idIndex]._id) {
      if (purchasersState[numIndex].identifications.length === 1) {
        storeDispatch(showErrorSnackbar('Purchaser must have at least 1 photo identification'));
        return;
      }
      deleteId({
        variables: {
          _id: purchasersState[numIndex]._id,
          documentId: purchasersState[numIndex].identifications[idIndex]._id,
          deleteFile: true,
          type: 'identification',
        },
      });
    } else {
      let newIdentification = purchasersState[numIndex].identifications.filter(
        (identification: IIdentification, index: number) => index !== idIndex
      );
      dispatch({
        type: 'UPDATE',
        payload: { index: numIndex, field: 'identifications', value: newIdentification },
      });
    }
  };

  const handleDetails = async (
    city: string,
    province: string,
    postalCode: string,
    country: string,
    streetAddress: string,
    numIndex: number
  ) => {
    dispatch({
      type: 'UPDATEMULTIPLE',
      payload: {
        index: numIndex,
        values: [
          {
            city: city,
            province: province,
            postalCode: postalCode,
            country: country,
            streetAddress: streetAddress,
          },
        ],
      },
    });
  };

  const handleDeleteSigningOfficer = (officerIndex: number, numIndex: number) => {
    let purchaserArray = purchasersState.map((purchaser: IPurchaserInfo, index: number) => {
      if (purchaser.signingOfficers.length === 1) {
        storeDispatch(showErrorSnackbar('Must have at least 1 signing officer'));
        return purchaser;
      } else if (index === numIndex) {
        let deleteSigningOfficer = purchaser.signingOfficers.filter(
          (signingOfficer: ISigningOfficer, index: number) => officerIndex !== index
        );
        return {
          ...purchaser,
          signingOfficers: deleteSigningOfficer,
        };
      } else {
        return purchaser;
      }
    });
    dispatch({
      type: 'UPDATE',
      payload: { index: numIndex, field: 'signingOfficers', value: purchaserArray[numIndex].signingOfficers },
    });
  };

  const handleSigningOfficerInput = async (event: any, officerIndex: number, numIndex: number) => {
    if (event && event.target.name) {
      let purchaserArray = purchasersState.map((purchaser: IPurchaserInfo, index: number) => {
        if (index === numIndex) {
          let newSigningOfficer = purchaser.signingOfficers.map((signingOfficer: ISigningOfficer, index: number) => {
            if (index === officerIndex) {
              return {
                ...signingOfficer,
                [event.target.name]: event.target.value,
              };
            } else return signingOfficer;
          });
          return {
            ...purchaser,
            signingOfficers: newSigningOfficer,
          };
        } else {
          return purchaser;
        }
      });
      dispatch({
        type: 'UPDATE',
        payload: { index: numIndex, field: 'signingOfficers', value: purchaserArray[numIndex].signingOfficers },
      });
    }
  };

  const addSigningOfficer = async (numIndex: number) => {
    let purchaserArray = purchasersState.map((purchaser: IPurchaserInfo, index: number) => {
      if (index === numIndex) {
        return {
          ...purchaser,
          signingOfficers: [
            ...purchaser.signingOfficers,
            {
              fullName: '',
              dob: subYears(new Date(), 18),
              streetAddress: '',
              primaryPhone: '',
              email: '',
              sin: '',
            },
          ],
        };
      } else return purchaser;
    });
    dispatch({
      type: 'UPDATE',
      payload: { index: numIndex, field: 'signingOfficers', value: purchaserArray[numIndex].signingOfficers },
    });
  };

  const handleDob = (id: number, numIndex: number) => (event: any, date: Date | null) => {
    if (event == 'Invalid Date') {
      setErrors(event);
      return;
    } else {
      setErrors(null);
    }
    let datePickerArray = purchasersState.map((purchaser: IPurchaserInfo, index: number) => {
      if (index === numIndex) {
        let newSigningOfficer = purchaser.signingOfficers.map((signingOfficer: ISigningOfficer, index: number) => {
          if (index === id) {
            return {
              ...signingOfficer,
              dob: date,
            };
          } else return signingOfficer;
        });
        return {
          ...purchaser,
          signingOfficers: newSigningOfficer,
        };
      } else {
        return purchaser;
      }
    });
    dispatch({
      type: 'UPDATE',
      payload: { index: numIndex, field: 'signingOfficers', value: datePickerArray[numIndex].signingOfficers },
    });
  };

  return (
    <div>
      <Typography variant={'h5'}>{title}</Typography>
      <FlexBetween>
        <Autocomplete
          key={random}
          sx={{
            width: '250px',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            fontSize: '14px',
          }}
          id={'search'}
          disableClearable
          noOptionsText={purchaserLoading ? `Loading...` : 'No Options'}
          freeSolo={false}
          options={searchInfo}
          getOptionLabel={(option: any) => `${option.firstName} ${option.lastName} ${option.email}`}
          onChange={handleSearchInput}
          renderInput={(params) => (
            <TextField
              onFocus={() => (!purchaserLoading ? getAllPurchasers() : null)}
              required={false}
              {...params}
              size="small"
              label={'Search By Name or Email'}
              margin="normal"
            />
          )}
        />
      </FlexBetween>
      {purchasersState.map((purchaser: IPurchaserInfo, index: number) => {
        return (
          <Box sx={{ mt: 2 }} key={index}>
            <form id="purchaserForm" onSubmit={(e) => handleSubmit(e, index)} autoComplete="off">
              <Divider />
              <FlexBetween sx={{ my: 2 }}>
                <Typography gutterBottom variant="h6" component="div">
                  <strong>
                    {purchaser.firstName} {purchaser.lastName}
                  </strong>
                </Typography>
              </FlexBetween>
              <Dropzone onDrop={(files) => handleDrop(files, index)} accept="image/jpg, image/jpeg, image/png, application/pdf">
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps({ style })}>
                      <input {...getInputProps()} />
                      <p>Drag and Drop or Upload Driver's Licence/Corporation Documents</p>
                    </div>
                  </section>
                )}
              </Dropzone>
              <div>
                <Grid
                  container
                  spacing={2}
                  style={{
                    margin: 0,
                    width: '100%',
                  }}
                >
                  {purchaser.identifications.length > 0
                    ? purchaser.identifications.map((attachment: any, numIndex: number) => {
                        return (
                          <Grid key={numIndex} item lg={3} md={4} sm={6} xs={12}>
                            <PdfCard
                              file={attachment.getUrl ? attachment.getUrl : attachment.url}
                              title={attachment.name ? attachment.name : attachment.file.name}
                              id={index}
                              handleDelete={handleDeleteId}
                              download={true}
                              index={numIndex}
                            />
                          </Grid>
                        );
                      })
                    : null}
                </Grid>
              </div>
              <PurchaserForm
                pInfo={purchaser}
                handleTextInput={handleTextInput}
                handleDateChange={handleDateChange}
                handleEndvestorInput={handleEndvestorInput}
                handleIdTypeInput={handleIdTypeInput}
                handleIdIssuedByInput={handleIdIssuedByInput}
                handleStreetAddress={handleStreetAddress}
                handleDetails={handleDetails}
                handleDeleteSigningOfficer={handleDeleteSigningOfficer}
                handleSigningOfficerInput={handleSigningOfficerInput}
                addSigningOfficer={addSigningOfficer}
                handleDob={handleDob}
                type={index}
                formType={"lease"}
              />
              <FlexEnd>
                <Button form="purchaserForm" type="submit" sx={{ mr: 1 }} variant="contained" color="success">
                  Save
                </Button>
                <Button
                  onClick={() =>
                    dispatch({
                      type: 'DELETE',
                      payload: { index: index },
                    })
                  }
                  variant="contained"
                  color="error"
                >
                  Delete
                </Button>
              </FlexEnd>
            </form>
          </Box>
        );
      })}
      <Divider sx={{ mt: 2 }} />
      <FlexEnd sx={{ mt: 2 }}>
        <Button
          onClick={() =>
            dispatch({
              type: 'ADDNEW',
              payload: project._id,
            })
          }
          color="primary"
          variant="contained"
        >
          Add New {capitalizeFirstLetter(title)}
        </Button>
      </FlexEnd>
    </div>
  );
};

interface ChildProps {
  purchasersState: IPurchaserInfo[];
  dispatch: any;
  title: string;
}

const PURCHASERS = gql`
  query purchaserMany($filter: FilterFindManyPurchaserInput) {
    purchaserMany(filter: $filter, limit: 10000) {
      _id
      email
      firstName
      lastName
      corp
    }
  }
`;

const GETPURCHASER = gql`
  query purchaserById($_id: MongoID!) {
    purchaserById(_id: $_id) {
      _id
      email
      firstName
      lastName
      corp
      sin
      dob
      identifications {
        _id
        name
        getUrl
      }
      streetAddress
      city
      province
      country
      postalCode
      occupation
      employer
      directors
      signingOfficers {
        fullName
        dob
        sin
        primaryPhone
        streetAddress
        email
      }
      purchaserType
      primaryPhone
      idType
      idNumber
      idExpiry
      unit
      idJurisdiction
      getUrl
      putUrl
    }
  }
`;

const DELETEID = gql`
  mutation deletePurchaserId($_id: MongoID!, $documentId: MongoID!, $deleteFile: Boolean!, $type: String!) {
    deletePurchaserId(_id: $_id, documentId: $documentId, deleteFile: $deleteFile, type: $type) {
      _id
      identifications {
        _id
        name
        getUrl
        putUrl
      }
      getUrl
    }
  }
`;

const CREATEPURCHASERUPLOAD = gql`
  mutation purchaserCreateOne($record: CreateOnePurchaserInput!, $proof: Upload, $files: [Upload!]) {
    purchaserCreateOne(record: $record, proof: $proof, files: $files) {
      _id
      project {
        _id
      }
      identifications {
        _id
        name
        getUrl
      }
      email
      firstName
      lastName
      sin
      dob
      streetAddress
      city
      province
      country
      postalCode
      occupation
      employer
      purchaserType
      primaryPhone
      idType
      idNumber
      idExpiry
      unit
      idJurisdiction
      corp
      getUrl
    }
  }
`;

const UPDATEPURCHASER = gql`
  mutation purchaserUpdateById($_id: MongoID!, $record: UpdateByIdPurchaserInput!) {
    purchaserUpdateById(_id: $_id, record: $record) {
      record {
        email
        firstName
        lastName
        sin
        dob
        identifications {
          _id
          name
          getUrl
        }
        streetAddress
        city
        province
        country
        postalCode
        occupation
        employer
        directors
        signingOfficers {
          fullName
          dob
          sin
          primaryPhone
          streetAddress
          email
        }
        purchaserType
        primaryPhone
        idType
        idNumber
        idExpiry
        unit
        idJurisdiction
        putUrl
      }
    }
  }
`;

const UPLOADID = gql`
  mutation uploadID($id: MongoID!, $name: String!, $file: Upload!) {
    uploadID(id: $id, name: $name, file: $file) {
      _id
      identifications {
        _id
        name
        getUrl
      }
    }
  }
`;

export default Purchasers;
