import { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { Grid, Modal, Fade, Box, Typography, AppBar, ToggleButtonGroup, ToggleButton, Button, Tabs, Tab } from '@mui/material';

import { IOption, IProject } from '../../types/project';
import { IUnit } from '../../types/unit';
import {
  numToCurrency,
  numberWithCommas,
  downloadExcel,
  downloadMultiplePdf,
  capitalizeFirstLetter,
  downloadPdf,
} from '../../utils/Functions';
import EnhancedTable from './EnhancedTable';
import OptionsEnhancedTable from './OptionsEnhancedTable';
import DecorEnhancedTable from './DecorEnhancedTable';
import UnitModal from './UnitModal';
import InventoryModal from './InventoryModal';
import TotalTable from './TotalTable';
import {
  dashboardTableTitle,
  pdfOptionWidths,
  pdfTotalWidths,
  pdfUnitWidths,
  unitColumns,
  optionColumns,
  optionWidths,
  unitWidths,
  totalColumns,
  totalWidths,
  adjustmentColumns,
  adjustmentWidths,
  pdfAdjustmentWidths,
} from '../../utils/Constants';
import { GlobalModal } from '../../features/modal/Modal';
import { handleModal } from '../../features/modal/modalSlice';
import StandardTable from '../tables/StandardTable';
import { useAppDispatch } from '../../app/hooks';
import { IUnitData } from '../../types/unit';
import AdjustmentTable from './AdjustmentTable';
import { showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';

const ChartComponent = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const { statusData, project } = props;

  const [optionsData, setOptionsData] = useState<any>(null);
  const [decorData, setDecorData] = useState<any>([]);
  const [adjustmentData, setAdjustmentData] = useState<any>(null);
  const [unitType, setUnitType] = useState<string>('residential');
  const [tierType, setTierType] = useState<string>('Overall');
  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalType, setModalType] = useState<string>('');
  const [units, setUnits] = useState<IUnit[]>([]);

  const [unitsByStatus, setUnitsByStatus] = useState<IUnit[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const [dealOptions, setDealOptions] = useState<any[]>([]);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [optionOpen, setOptionOpen] = useState<boolean>(false);

  const { loading } = useQuery(GETOPTIONSTATS, {
    fetchPolicy: 'network-only',
    skip: !project.options.length,
    variables: { project: project._id },
    onCompleted: (data) => {
      setOptionsData(data.getOptionsCount);
    },
  });

  useQuery(DECOR, {
    skip: project._id === 'Combined' || !project._id || !project.decorModels.length,
    variables: { project: project._id, dateStart: new Date('2022-01-01'), dateEnd: new Date('2030-01-01') },
    onCompleted: (data) => {
      let decorArray = [];
      if (data.getDecorSummary.decorSummary.upgradeSetOneTotal.revenue) {
        decorArray.push({ name: 'UPG Session 1', ...data.getDecorSummary.decorSummary.upgradeSetOneTotal });
      }
      if (data.getDecorSummary.decorSummary.upgradeSetTwoTotal.revenue) {
        decorArray.push({ name: 'UPG Session 2', ...data.getDecorSummary.decorSummary.upgradeSetTwoTotal });
      }
      if (data.getDecorSummary.decorSummary.upgradeSetThreeTotal.revenue) {
        decorArray.push({ name: 'UPG Session 3', ...data.getDecorSummary.decorSummary.upgradeSetThreeTotal });
      }

      if (decorArray.length) {
        decorArray.push({
          name: 'Total',
          count: decorArray.reduce((a: any, b: any) => {
            return a + b.count;
          }, 0),
          creditCount: decorArray.reduce((a: any, b: any) => {
            return a + b.creditCount;
          }, 0),
          creditSum: decorArray.reduce((a: any, b: any) => {
            return a + b.creditSum;
          }, 0),
          deviationCount: decorArray.reduce((a: any, b: any) => {
            return a + b.deviationCount;
          }, 0),
          deviationSum: decorArray.reduce((a: any, b: any) => {
            return a + b.deviationSum;
          }, 0),
          revenue: decorArray.reduce((a: any, b: any) => {
            return a + b.revenue;
          }, 0),
          totalCost: decorArray.reduce((a: any, b: any) => {
            return a + b.totalCost;
          }, 0),
        });
      }
      setDecorData(decorArray);
    },
  });

  const { loading: adjustmentLoading } = useQuery(ADJUSTMENTS, {
    skip: project && !project._id,
    variables: { project: project._id },
    onCompleted: (data) => {
      setAdjustmentData(data.getAdjustmentSummary);
    },
  });

  const [getUnits, { loading: unitLoading }] = useLazyQuery<IUnitData>(UNITS, {
    fetchPolicy: 'network-only',
    onCompleted: (data: any) => {
      setUnits(data.getUnits);
    },
  });

  const [getAdjustmentUnits, { loading: adjustmentUnitLoading }] = useLazyQuery<IUnitData>(ADJUSTMENTUNITS, {
    fetchPolicy: 'network-only',
    onCompleted: (data: any) => {
      storeDispatch(showSuccessSnackbar('An email has been sent to you'));
    },
  });

  const pdfTitles = [
    `${project.name} Sales Summary`,
    `${project.name} Inventory Summary`,
    `${project.name} Total Unit Summary`,
    `${project.name} Options Summary`,
    `${project.name} Overall Total Summary`,
  ];

  const unitModalColumns = useMemo(() => {
    return [
      {
        Header: 'Suite',
        accessor: (rowData: any) => {
          return (
            <Link
              rel="noopener noreferrer"
              target="_blank"
              style={{ textDecoration: 'none', color: 'inherit' }}
              to={`/${project._id}/dashboard/${rowData._id}`}
            >
              <strong>{rowData.suite}</strong>
            </Link>
          )
        },
      },
      {
        Header: 'Base Price',
        accessor: (rowData: any) => numToCurrency.format(rowData.basePrice),
      },
      {
        Header: 'Realtor',
        accessor: (rowData: any) => {
          if (rowData.status === 'D') {
            if (rowData.allocation) {
              return rowData.allocation.fullName;
            } else if (rowData.tempAllocation) {
              return rowData.tempAllocation;
            } else return '';
          } else if (rowData.status === 'F' || rowData.status === 'C' || rowData.status === 'O') {
            return rowData.deal && rowData.deal.realtor.length ? rowData.deal.realtor[0].fullName : 'Public';
          } else return rowData.deal && rowData.deal.realtor.length ? rowData.deal.realtor[0].fullName : '';
        },
      },
      {
        Header: 'Unit Type',
        accessor: (rowData: any) => rowData.unitType,
      },
      {
        Header: 'Exposure',
        accessor: (rowData: any) => rowData.exposure,
      },
      {
        Header: 'Model Type',
        accessor: (rowData: any) => rowData.modelType,
      },
      {
        Header: 'Size',
        accessor: (rowData: any) => rowData.size,
      },
      {
        Header: 'Bathrooms',
        accessor: (rowData: any) => rowData.bathroom,
      },
      {
        Header: 'PPSF',
        accessor: (rowData: any) => numToCurrency.format(rowData.basePrice / rowData.size),
      },
    ];
  }, []);

  const downloadAll = (type: string) => {
    let totals = handleTotalData();
    totals = handleTotalData().map((total: any) => {
      return {
        ...total,
        overallSoldRevenue: numToCurrency.format(total.overallSoldRevenue),
        overallSoldRevenueNetHst: numToCurrency.format(total.overallSoldRevenueNetHst),
        soldOptionsRevenue: numToCurrency.format(total.soldOptionsRevenue),
        soldUnitRevenue: numToCurrency.format(total.soldUnitRevenue),
        soldUnitRevenueNetHst: numToCurrency.format(total.soldUnitRevenueNetHst),
      };
    });

    let excelData = [handleUnitsData('sold'), handleUnitsData('pending'), handleUnitsData('total'), totals];

    let excelHeaders: any = [unitColumns, unitColumns, unitColumns, totalColumns];

    let excelWidths: any = [unitWidths, unitWidths, unitWidths, totalWidths];

    let pdfWidths: any = [pdfUnitWidths, pdfUnitWidths, pdfUnitWidths, pdfTotalWidths];

    if (optionsData) {
      let options = handleOptionsData();
      options = handleOptionsData().map((option: any) => {
        return {
          ...option,
          availableRevenue: numToCurrency.format(option.availableRevenue),
          availableRevenueNetHst: numToCurrency.format(option.availableRevenueNetHst),
          totalPPSF: numToCurrency.format(option.totalPPSF),
          totalRevenue: numToCurrency.format(option.totalRevenue),
          totalRevenueNetHst: numToCurrency.format(option.totalRevenueNetHst),
        };
      });

      excelData.splice(excelData.length - 1, 0, options);
      excelHeaders.splice(excelHeaders.length - 1, 0, optionColumns);
      excelWidths.splice(excelWidths.length - 1, 0, optionWidths);
      pdfWidths.splice(pdfWidths.length - 1, 0, pdfOptionWidths);
      dashboardTableTitle.splice(dashboardTableTitle.length - 1, 0, 'Options Summary');
    }

    if (adjustmentData) {
      let adjustments = handleAdjustmentData().map((adjustment: any) => {
        return {
          ...adjustment,
          amount: numToCurrency.format(adjustment.amount),
        };
      });
      excelData.splice(excelData.length - 1, 0, adjustments);
      excelHeaders.splice(excelHeaders.length - 1, 0, adjustmentColumns);
      excelWidths.splice(excelWidths.length - 1, 0, adjustmentWidths);
      pdfWidths.splice(pdfWidths.length - 1, 0, pdfAdjustmentWidths);
      dashboardTableTitle.splice(dashboardTableTitle.length - 1, 0, 'Adjustments Summary');
    }

    if (type === 'excel') {
      downloadExcel(excelData, excelHeaders, [], [excelWidths], dashboardTableTitle, `${project.name} Summaries.xlsx`);
    } else {
      downloadMultiplePdf(excelData, excelHeaders, [], pdfWidths, pdfTitles, `${project.name} Summaries.pdf`, project.logoGetUrl);
    }
  };

  const handleChange = (e: any, value: string) => {
    if (value !== null) {
      setUnitType(value);
    }
  };

  const handleTierChange = (e: any, value: string) => {
    setTierType(value);
  };

  const handleUnitsData = (type: string) => {
    let data = unitType === 'residential' ? statusData?.statusTotals : statusData.commercialTotals;

    if (unitType === 'residential') {
      if (tierType !== 'Overall') {
        data = statusData.statusTiers.filter((tier: any) => tier.name === tierType);
      }
    }
    if (unitType === 'commercial') {
      if (tierType !== 'Overall') {
        data = statusData.commercialTiers.filter((tier: any) => tier.name === tierType);
      }
    }

    if (type === 'sold') {
      data = data.filter(
        (unit: any) =>
          unit._id === 'F' || unit._id === 'C' || unit._id === 'PF' || unit._id === 'BHHS' || unit._id === 'BHL' || unit._id === 'BRS'
      );
    } else if (type === 'pending') {
      data = data.filter(
        (unit: any) =>
          unit._id !== 'F' && unit._id !== 'C' && unit._id !== 'PF' && unit._id !== 'BHHS' && unit._id !== 'BHL' && unit._id !== 'BRS'
      );
    }

    let allStatusData: any[] = data.map((unit: any) => {
      return {
        status: unit._id,
        count: `${unit.count} (${((unit.count / statusData.statusTotals.reduce((acc: any, o: any) => acc + o?.count, 0)) * 100).toFixed(
          1
        )}%)`,
        size: `${numberWithCommas(unit.size)} (${(
          (unit.size / statusData.statusTotals.reduce((acc: any, o: any) => acc + parseInt(o.size), 0)) *
          100
        ).toFixed(1)}%)`,
        revenue: numToCurrency.format(unit.revenue),
        revenueNet: numToCurrency.format(unit.revenueNet),
        ppsf: numToCurrency.format(unit.revenue / unit.size),
        ppsfNet: numToCurrency.format(unit.revenueNet / unit.size),
      };
    });

    let totals = {
      status: 'Total',
      count: `${data.reduce((acc: any, o: any) => acc + o?.count, 0)} (${(
        (data.reduce((acc: any, o: any) => acc + o?.count, 0) / statusData.statusTotals.reduce((acc: any, o: any) => acc + o?.count, 0)) *
        100
      ).toFixed(2)}%)`,
      size: `${numberWithCommas(data.reduce((acc: any, o: any) => acc + o?.size, 0))} (${(
        (data.reduce((acc: any, o: any) => acc + o?.size, 0) / statusData.statusTotals.reduce((acc: any, o: any) => acc + o?.size, 0)) *
        100
      ).toFixed(1)}%)`,
      revenue: `${numToCurrency.format(data.reduce((acc: any, o: any) => acc + o?.revenue, 0))} (${(
        (data.reduce((acc: any, o: any) => acc + o?.revenue, 0) /
          statusData.statusTotals.reduce((acc: any, o: any) => acc + o?.revenue, 0)) *
        100
      ).toFixed(1)}%)`,
      revenueNet: `${numToCurrency.format(data.reduce((acc: any, o: any) => acc + o?.revenueNet, 0))}`,
      ppsf: data.length
        ? numToCurrency.format(data.reduce((acc: any, o: any) => acc + o?.revenue, 0) / data.reduce((acc: any, o: any) => acc + o?.size, 0))
        : '$0',
      ppsfNet: data.length
        ? numToCurrency.format(
            data.reduce((acc: any, o: any) => acc + o?.revenueNet, 0) / data.reduce((acc: any, o: any) => acc + o?.size, 0)
          )
        : '$0',
    };

    // return statusData
    return [...allStatusData, totals];
  };

  // Adjustment Data

  const handleAdjustmentData = () => {
    if (adjustmentData) {
      let data = adjustmentData.residential;
      if (unitType === 'commercial') {
        data = adjustmentData.commercial;
      }
      return data;
    } else return [];
  };

  const handleOptionsData = () => {
    let data = optionsData.residential;
    if (unitType === 'commercial') {
      data = optionsData.commercial;
    }

    let size = unitType === 'residential' ? statusData?.statusTotals : statusData.commercialTotals;

    if (project.options.length && !data.length) {
      data = project.options.map((option: IOption) => {
        return {
          optionId: option.name,
          size: 0,
          sumPendingAmount: 0,
          sumPendingPurchaseAmount: 0,
          sumSoldAmount: 0,
          sumSoldPurchaseAmount: 0,
          sumTotalAmount: option.purchaseAmount! * option.amount!,
          sumTotalPurchaseAmount: 0,
        };
      });
    }

    if (!project.options.length && data.length) return [];

    let allOptionsData: any[] = data
      .map((options: any) => {
        let projectOptions = project.options.filter((option: IOption) => option.type === unitType);
        let totalAvailable: any = projectOptions.find((option: IOption) => option.name === options.optionId);

        if (totalAvailable) {
          return {
            option: options.optionId,
            currentPrice: numToCurrency.format(totalAvailable.price),
            totalOptions: totalAvailable.totalAvailable,
            available: totalAvailable.totalAvailable - options.sumPendingPurchaseAmount - options.sumSoldPurchaseAmount,
            availableRevenue:
              (totalAvailable.totalAvailable - options.sumPendingPurchaseAmount - options.sumSoldPurchaseAmount) * totalAvailable.price,
            availableRevenueNetHst:
              (totalAvailable.totalAvailable - options.sumPendingPurchaseAmount - options.sumSoldPurchaseAmount) *
              totalAvailable.price! *
              0.93,
            pendingOptions: `${options.sumPendingPurchaseAmount} (${
              totalAvailable.totalAvailable ? ((options.sumPendingPurchaseAmount / totalAvailable.totalAvailable) * 100).toFixed(2) : '0.00'
            }%)`,
            soldOptions: `${options.sumSoldPurchaseAmount} (${
              totalAvailable.totalAvailable ? ((options.sumSoldPurchaseAmount / totalAvailable.totalAvailable) * 100).toFixed(2) : '0.00'
            }%)`,
            combinedOptions: `${options.sumPendingPurchaseAmount + options.sumSoldPurchaseAmount} (${
              totalAvailable.totalAvailable
                ? (((options.sumPendingPurchaseAmount + options.sumSoldPurchaseAmount) / totalAvailable.totalAvailable) * 100).toFixed(2)
                : '0.00'
            }%)`,
            pendingRevenue: `${numToCurrency.format(options.sumPendingAmount)} (${
              totalAvailable.price
                ? ((options.sumPendingAmount / (totalAvailable.totalAvailable * totalAvailable.price)) * 100).toFixed(2)
                : '0.00'
            }%)`,
            soldRevenue: `${numToCurrency.format(options.sumSoldAmount)} (${
              totalAvailable.price
                ? ((options.sumSoldAmount / (totalAvailable.totalAvailable * totalAvailable.price)) * 100).toFixed(2)
                : '0.00'
            }%)`,
            combinedRevenue: `${numToCurrency.format(options.sumSoldAmount + options.sumPendingAmount)} (${
              totalAvailable.price
                ? (
                    ((options.sumSoldAmount + options.sumPendingAmount) / (totalAvailable.totalAvailable * totalAvailable.price)) *
                    100
                  ).toFixed(2)
                : '0.00'
            }%)`,
            totalPPSF:
              (options.sumSoldAmount +
                options.sumPendingAmount +
                (totalAvailable.totalAvailable - options.sumPendingPurchaseAmount - options.sumSoldPurchaseAmount) * totalAvailable.price) /
              size.reduce((acc: any, o: any) => acc + o?.size, 0),
            totalRevenue:
              options.sumSoldAmount +
              options.sumPendingAmount +
              (totalAvailable.totalAvailable - options.sumPendingPurchaseAmount - options.sumSoldPurchaseAmount) * totalAvailable.price,
            totalRevenueNetHst:
              (options.sumSoldAmount + options.sumPendingAmount) * 0.93 +
              (totalAvailable.totalAvailable - options.sumPendingPurchaseAmount - options.sumSoldPurchaseAmount) *
                totalAvailable.price! *
                0.93,
          };
        }
      })
      .filter((option: any) => option !== undefined);

    let totals: IOptionsData = {
      option: 'Total',
      currentPrice: '',
      totalOptions: allOptionsData.reduce((acc, o) => acc + o?.totalOptions, 0),
      available: allOptionsData.reduce((acc, o) => acc + o?.available, 0),
      availableRevenue: allOptionsData.reduce((acc, o) => acc + o?.availableRevenue, 0),
      availableRevenueNetHst: allOptionsData.reduce((acc, o) => acc + o?.availableRevenueNetHst, 0),
      pendingOptions: data.reduce((acc: any, o: any) => acc + o?.sumPendingPurchaseAmount, 0),
      soldOptions: data.reduce((acc: any, o: any) => acc + o?.sumSoldPurchaseAmount, 0),
      combinedOptions: data.reduce((acc: any, o: any) => acc + o?.sumSoldPurchaseAmount + o?.sumPendingPurchaseAmount, 0),
      pendingRevenue: numToCurrency.format(data.reduce((acc: any, o: any) => acc + o?.sumPendingAmount, 0)),
      soldRevenue: numToCurrency.format(data.reduce((acc: any, o: any) => acc + o?.sumSoldAmount, 0)),
      combinedRevenue: numToCurrency.format(data.reduce((acc: any, o: any) => acc + o?.sumSoldAmount + o?.sumPendingAmount, 0)),
      totalPPSF: allOptionsData.reduce((acc, o) => acc + o?.totalPPSF, 0),
      totalRevenue: allOptionsData.reduce((acc, o) => acc + o?.totalRevenue, 0),
      totalRevenueNetHst: allOptionsData.reduce((acc, o) => acc + o?.totalRevenueNetHst, 0),
    };

    return [...allOptionsData, totals];
  };

  const handleTotalData = () => {
    let soldResidentialUnits = statusData?.statusTotals.filter(
      (status: any) =>
        status._id === 'F' ||
        status._id === 'C' ||
        status._id === 'PF' ||
        status._id === 'BHHS' ||
        status._id === 'BHL' ||
        status._id === 'BRS'
    );
    let soldCommercialUnits = statusData?.commercialTotals.filter(
      (status: any) =>
        status._id === 'F' ||
        status._id === 'C' ||
        status._id === 'PF' ||
        status._id === 'BHHS' ||
        status._id === 'BHL' ||
        status._id === 'BRS'
    );

    let commercialOptions: any[] = [];
    let residentialOptions: any[] = [];

    let projectResidentialOptions = project.options.filter((option: IOption) => option.type === 'residential');
    let projectCommercialOptions = project.options.filter((option: IOption) => option.type === 'commercial');

    if (optionsData) {
      if (optionsData.residential.length) {
        residentialOptions = optionsData.residential;
      } else {
        residentialOptions = project.options
          .filter((option: any) => option.type === 'residential')
          .map((option: any) => {
            return {
              optionId: option.name,
              sumPendingAmount: 0,
              sumPendingPurchaseAmount: 0,
              sumSoldAmount: 0,
              sumSoldPurchaseAmount: 0,
              sumTotalAmount: option.price * option.totalAvailable,
              sumTotalPurchaseAmount: 0,
            };
          });
      }
      if (optionsData.commercial.length) {
        commercialOptions = optionsData.commercial;
      } else {
        commercialOptions = project.options
          .filter((option: any) => option.type === 'commercial')
          .map((option: any) => {
            return {
              optionId: option.name,
              sumPendingAmount: 0,
              sumPendingPurchaseAmount: 0,
              sumSoldAmount: 0,
              sumSoldPurchaseAmount: 0,
              sumTotalAmount: option.price * option.totalAvailable,
              sumTotalPurchaseAmount: 0,
            };
          });
      }
    }

    // All Units
    let residentialSize = statusData.allTotals.reduce((acc: any, o: any) => acc + parseInt(o.size), 0);
    let commercialSize = statusData.commercialTotals.reduce((acc: any, o: any) => acc + parseInt(o.size), 0);

    // Sold Units
    let soldResidentialRevenue = soldResidentialUnits?.reduce((acc: any, o: any) => acc + parseInt(o.revenue), 0);
    let soldCommercialRevenue = soldCommercialUnits.reduce((acc: any, o: any) => acc + o?.revenue, 0);
    let soldResidentialNetHST = soldResidentialUnits?.reduce((acc: any, o: any) => acc + o.revenueNet, 0);
    let soldCommercialNetHST = soldCommercialUnits.reduce((acc: any, o: any) => acc + o?.revenue, 0);

    // Sold Revenue
    let soldResidentialOptionsRevenue = residentialOptions.reduce((acc: any, o: any) => acc + o?.sumSoldAmount, 0);
    let soldCommercialOptionsRevenue = commercialOptions.reduce((acc: any, o: any) => acc + o?.sumSoldAmount, 0);
    let soldResidentialOptionsRevenueNetHST = residentialOptions.reduce((acc: any, o: any) => acc + o?.sumSoldAmount, 0) * 0.93;
    let soldCommercialOptionsRevenueNetHST = commercialOptions.reduce((acc: any, o: any) => acc + o?.sumSoldAmount, 0);

    // Option Totals

    let residentialTotalOptionsRevenue = residentialOptions.reduce((acc: any, o: any) => {
      let selectedOption = projectResidentialOptions.find((option: any) => option.name === o.optionId && option.type === 'residential');
      if (selectedOption) {
        return (
          acc +
          (o.sumSoldAmount +
            o.sumPendingAmount +
            (selectedOption.totalAvailable - o.sumPendingPurchaseAmount - o.sumSoldPurchaseAmount) * selectedOption.price)
        );
      } else {
        return acc;
      }
    }, 0);

    let commercialTotalOptionsRevenue = commercialOptions.reduce((acc: any, o: any) => {
      let selectedOption = projectCommercialOptions.find((option: any) => option.name === o.optionId && option.type === 'commercial');
      if (selectedOption) {
        return (
          acc +
          (o.sumSoldAmount +
            o.sumPendingAmount +
            (selectedOption.totalAvailable - o.sumPendingPurchaseAmount - o.sumSoldPurchaseAmount) * selectedOption.price)
        );
      } else {
        return acc;
      }
    }, 0);

    // Overall Total
    let overallTotalResidential = statusData.statusTotals.reduce((acc: any, o: any) => acc + parseInt(o?.revenue), 0);
    let overallTotalResidentialNetHst = statusData.statusTotals.reduce((acc: any, o: any) => acc + o?.revenueNet, 0);
    let overallTotalCommercial = statusData?.commercialTotals.reduce((acc: any, o: any) => acc + parseInt(o?.revenue), 0);

    // Adjustments
    let residentialAdjustments = 0;
    let residentialAdjustmentsNetHst = 0;
    let residentialOverallRevenueAdjustments = 0;
    let residentialOverallRevenueAdjustmentsNetHst = 0;

    let commercialAdjustments = 0;
    let commercialAdjustmentsNetHst = 0;
    let commercialOverallRevenueAdjustments = 0;
    let commercialOverallRevenueAdjustmentsNetHst = 0;

    if (adjustmentData) {
      if (adjustmentData.residential.length) {
        residentialAdjustments =
          (adjustmentData.residential.reduce((a: any, b: any) => a + b.amount, 0) -
            adjustmentData.residential[adjustmentData.residential.length - 1].amount) *
          1.13;

        residentialAdjustmentsNetHst =
          adjustmentData.residential.reduce((a: any, b: any) => a + b.amount, 0) -
          adjustmentData.residential[adjustmentData.residential.length - 1].amount;
        residentialOverallRevenueAdjustments =
          overallTotalResidential +
          residentialTotalOptionsRevenue +
          residentialAdjustments

        residentialOverallRevenueAdjustmentsNetHst =
          overallTotalResidentialNetHst +
          residentialTotalOptionsRevenue * 0.93 +
          (adjustmentData.residential.reduce((a: any, b: any) => a + b.amount, 0) -
            adjustmentData.residential[adjustmentData.residential.length - 1].amount);
      }

      if (adjustmentData.commercial.length) {
        commercialAdjustments =
          (adjustmentData.commercial.reduce((a: any, b: any) => a + b.amount, 0) -
            adjustmentData.commercial[adjustmentData.commercial.length - 1].amount) *
          1.13;
        commercialAdjustmentsNetHst =
          (adjustmentData.commercial.reduce((a: any, b: any) => a + b.amount, 0) -
            adjustmentData.commercial[adjustmentData.commercial.length - 1].amount) *
          0.93;
        commercialOverallRevenueAdjustments =
          overallTotalCommercial +
          commercialTotalOptionsRevenue +
          commercialAdjustments
        commercialOverallRevenueAdjustmentsNetHst =
          overallTotalCommercial +
          commercialTotalOptionsRevenue +
          (adjustmentData.commercial.reduce((a: any, b: any) => a + b.amount, 0) -
            adjustmentData.commercial[adjustmentData.commercial.length - 1].amount);
      }
    }

    return [
      {
        type: 'Residential',
        soldUnitRevenue: soldResidentialRevenue,
        soldOptionsRevenue: soldResidentialOptionsRevenue,
        soldUnitRevenueNetHst: soldResidentialNetHST,
        overallSoldRevenue: soldResidentialRevenue + soldResidentialOptionsRevenue,
        overallSoldRevenueNetHst: soldResidentialNetHST + soldResidentialOptionsRevenueNetHST,
        overallTotalRevenuePPSF: residentialSize ? (overallTotalResidential + residentialTotalOptionsRevenue) / residentialSize : 0,
        overallTotalRevenue: overallTotalResidential + residentialTotalOptionsRevenue,
        overallTotalRevenueNetHST: overallTotalResidentialNetHst + residentialTotalOptionsRevenue * 0.93,
        adjustments: residentialAdjustments,
        adjustmentsNetHST: residentialAdjustmentsNetHst,
        overallRevenueAdjustments: residentialOverallRevenueAdjustments,
        overallRevenueAdjustmentsNetHst: residentialOverallRevenueAdjustmentsNetHst,
      },
      {
        type: 'Commercial',
        soldUnitRevenue: soldCommercialRevenue,
        soldOptionsRevenue: soldCommercialOptionsRevenue,
        soldUnitRevenueNetHst: soldCommercialNetHST,
        overallSoldRevenue: soldCommercialRevenue + soldCommercialOptionsRevenue,
        overallSoldRevenueNetHst: soldCommercialNetHST + soldCommercialOptionsRevenueNetHST,
        overallTotalRevenuePPSF: commercialSize ? (overallTotalCommercial + commercialTotalOptionsRevenue) / commercialSize : 0,
        overallTotalRevenue: overallTotalCommercial + commercialTotalOptionsRevenue,
        overallTotalRevenueNetHST: overallTotalCommercial + commercialTotalOptionsRevenue,
        adjustments: commercialAdjustments,
        adjustmentsNetHST: commercialAdjustmentsNetHst,
        overallRevenueAdjustments: commercialOverallRevenueAdjustments,
        overallRevenueAdjustmentsNetHst: commercialOverallRevenueAdjustmentsNetHst,
      },
      {
        type: 'Total',
        soldUnitRevenue: soldCommercialRevenue + soldResidentialRevenue,
        soldOptionsRevenue: soldCommercialOptionsRevenue + soldResidentialOptionsRevenue,
        soldUnitRevenueNetHst: soldCommercialNetHST + soldResidentialNetHST,
        overallSoldRevenue: soldCommercialRevenue + soldCommercialOptionsRevenue + soldResidentialRevenue + soldResidentialOptionsRevenue,
        overallSoldRevenueNetHst:
          soldCommercialNetHST + soldCommercialOptionsRevenueNetHST + soldResidentialNetHST + soldResidentialOptionsRevenueNetHST,
        overallTotalRevenuePPSF:
          (overallTotalResidentialNetHst + residentialTotalOptionsRevenue * 0.93 + overallTotalCommercial + commercialTotalOptionsRevenue) /
          (residentialSize + commercialSize),
        overallTotalRevenue:
          overallTotalResidential + residentialTotalOptionsRevenue + overallTotalCommercial + commercialTotalOptionsRevenue,
        overallTotalRevenueNetHST:
          Math.round(overallTotalResidentialNetHst) +
          Math.round(residentialTotalOptionsRevenue * 0.93) +
          overallTotalCommercial +
          commercialTotalOptionsRevenue,
        adjustments: residentialAdjustments + commercialAdjustments,
        adjustmentsNetHST: residentialAdjustmentsNetHst + commercialAdjustmentsNetHst,
        overallRevenueAdjustments: residentialOverallRevenueAdjustments + commercialOverallRevenueAdjustments,
        overallRevenueAdjustmentsNetHst: residentialOverallRevenueAdjustmentsNetHst + commercialOverallRevenueAdjustmentsNetHst,
      },
    ];
  };

  const openModal = (type: string, title: string, status: string) => {
    if (type === 'unit') {
      if (status !== 'Total') {
        getUnits({ variables: { statuses: [status], type: unitType, project: project._id, searchType: 'dashboardSummary' } });
      }
    }
    setModalType(type);
    setModalTitle(title);
    storeDispatch(handleModal(true));
  };

  const download = (type: string, data: any) => {
    let unitHeaders = [
      {
        label: 'Suite',
        id: 'suite',
      },
      {
        label: 'Base Price',
        id: 'basePrice',
      },
      {
        label: 'Unit Type',
        id: 'unitType',
      },
      {
        label: 'Exposure',
        id: 'exposure',
      },
      {
        label: 'Model Type',
        id: 'modelType',
      },
      {
        label: 'Size',
        id: 'size',
      },
      {
        label: 'Bathrooms',
        id: 'bathroom',
      },
      {
        label: 'PPSF',
        id: 'ppsf',
      },
    ];

    let widths = {
      suite: 15,
      basePrice: 15,
      unitType: 15,
      exposure: 15,
      modelType: 15,
      size: 15,
      bathroom: 15,
      ppsf: 15,
    };

    let pdfWidths = {
      suite: 200,
      basePrice: 200,
      unitType: 200,
      exposure: 200,
      modelType: 200,
      size: 200,
      bathroom: 200,
      ppsf: 200,
    };

    let updatedData = data.map((data: IUnit) => {
      return {
        ...data,
        ppsf: numToCurrency.format(data.basePrice / data.size),
      };
    });

    let sheetTitle = `${project.name} - ${capitalizeFirstLetter(modalTitle)}`;

    if (type === 'excel') {
      downloadExcel([updatedData], [unitHeaders], [], [[widths]], [sheetTitle], sheetTitle);
    } else {
      downloadPdf([updatedData], [unitHeaders], [], [pdfWidths], [sheetTitle], sheetTitle);
    }
  };

  const downloadAdjustments = (type: string, data: any) => {
    getAdjustmentUnits({
      variables: {
        project: project._id,
      },
    });
    // let unitHeaders = [
    //   {
    //     label: 'Suite',
    //     id: 'suite',
    //   },
    //   {
    //     label: 'Status',
    //     id: 'status',
    //   },
    //   {
    //     label: 'Firm Date',
    //     id: 'firmDate',
    //   },
    //   {
    //     label: 'Unit Type',
    //     id: 'unitType',
    //   },
    // ];

    // let widths = {
    //   suite: 15,
    //   basePrice: 15,
    //   modelType: 15,
    //   name: 15,
    //   value: 15,
    //   description: 15,
    //   percentage: 15,
    // };

    // let pdfWidths = {
    //   suite: 200,
    //   basePrice: 200,
    //   modelType: 200,
    //   name: 200,
    //   value: 200,
    //   description: 200,
    //   percentage: 200,
    // };

    // let updatedData = data.map((data: any) => {
    //   return {
    //     ...data,
    //     basePrice: numToCurrency.format(data.basePrice),
    //     value: numToCurrency.format(data.value),
    //     percentage: data.percentage ? data.percentage : 'N/A',
    //   };
    // });

    // let sheetTitle = `${project.name} - ${capitalizeFirstLetter(modalTitle)}`;

    // if (type === 'excel') {
    //   downloadExcel([updatedData], [unitHeaders], [], [[widths]], [sheetTitle], sheetTitle);
    // } else {
    //   downloadPdf([updatedData], [unitHeaders], [], [pdfWidths], [sheetTitle], sheetTitle);
    // }
  };

  const handleModalContent = () => {
    switch (modalType) {
      case 'unit':
        return (
          <Typography gutterBottom variant="h5">
            <Box sx={{ mb: 2 }}>{capitalizeFirstLetter(modalTitle)}</Box>
            <StandardTable download={download} columns={unitModalColumns} loading={unitLoading} data={units} />
          </Typography>
        );
      default:
        return;
    }
  };

  return (
    <Box
      sx={{
        pt: 4,
        backgroundColor: '#f5f5f5',
        px: 2,
      }}
    >
      <GlobalModal>{handleModalContent()}</GlobalModal>
      {statusData && statusData.statusTiers.length ? (
        <>
        <Box>
          <Typography variant="h5" gutterBottom component="div">
            Sales Summary
          </Typography>
        </Box>
        <Box
          sx={{
            pt: 2,
            display: 'flex',
            flexDirection: {
              xs: 'column',
              sm: 'row',
            },
            justifyContent: 'space-between',
          }}
        >
          {statusData?.checkCommercial! ? (
            <Box>
              <ToggleButtonGroup
                value={unitType}
                onChange={handleChange}
                exclusive
                aria-label="text alignment"
                sx={{
                  '& .MuiToggleButton-root.Mui-selected': {
                    backgroundColor: '#00142a',
                    color: '#fff',
                  },
                  '& .MuiToggleButton-root.Mui-selected:hover': {
                    backgroundColor: '#00142a',
                    color: '#fff',
                  },
                }}
              >
                <ToggleButton value="residential" aria-label="centered">
                  Residential
                </ToggleButton>
                <ToggleButton value="commercial" aria-label="centered">
                  Commercial
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          ) : null}
          <Box
            sx={{
              ml: {
                xs: 'none',
                sm: 'auto',
              },
              my: {
                sm: 0,
                xs: 2,
              },
              mb: {
                sm: statusData && statusData.statusTiers.length ? 2 : 0,
              },
            }}
          >
            <Button style={{ marginRight: '10px' }} variant="contained" color="primary" onClick={() => downloadAll('pdf')}>
              Download all as PDF
            </Button>
            <Button variant="contained" color="primary" onClick={() => downloadAll('excel')}>
              Download all as Excel
            </Button>
          </Box>
        </Box>
        </>
      ) : null}
      {statusData && statusData.statusTiers.length ? (
        <AppBar position="sticky">
          <Tabs value={tierType} scrollButtons="auto" onChange={handleTierChange} aria-label="scrollable auto tabs unit type">
            {[...new Set(statusData.statusTiers.map((item: any) => item.name))].map((tier: any, index: number) => {
              return <Tab key={index} value={tier} label={tier} />;
            })}
            ;
          </Tabs>
        </AppBar>
      ) : null}
      {statusData ? (
        <Grid sx={{ pb: 2 }} container>
          {/* units with status firm & conditional */}
          <Grid item xs={12}>
            <EnhancedTable
              rows={handleUnitsData('sold')}
              type={'sales'}
              columnHeaders={unitColumns}
              columnWidths={unitWidths}
              pdfWidths={pdfUnitWidths}
              openModal={openModal}
              project={project}
            />
          </Grid>
          {/* units with all other status */}
          <Typography sx={{ my: 3 }} variant="h5" gutterBottom component="div">
            Inventory Summary
          </Typography>
          <Grid item xs={12}>
            <EnhancedTable
              type={'inventory'}
              rows={handleUnitsData('pending')}
              columnHeaders={unitColumns}
              columnWidths={unitWidths}
              pdfWidths={pdfUnitWidths}
              openModal={openModal}
              project={project}
            />
          </Grid>
          {/* total for all units */}
          <Grid item xs={12}>
            <Typography sx={{ my: 3 }} variant="h5" gutterBottom component="div">
              All Unit Summary
            </Typography>
            <EnhancedTable
              type={'total'}
              rows={handleUnitsData('total')}
              columnHeaders={unitColumns}
              columnWidths={unitWidths}
              pdfWidths={pdfUnitWidths}
              openModal={openModal}
              project={project}
            />
          </Grid>
          {/* total for all parking */}
          {!loading ? (
            <>
              {optionsData ? (
                <>
                  <Typography sx={{ my: 3 }} variant="h5" gutterBottom component="div">
                    Options Summary
                  </Typography>
                  <Grid item xs={12}>
                    <OptionsEnhancedTable optionsData={handleOptionsData()} />
                  </Grid>
                </>
              ) : null}
              {decorData.length ? (
                <>
                  <Typography sx={{ my: 3 }} variant="h5" gutterBottom component="div">
                    Decor Summary
                  </Typography>
                  <Grid item xs={12}>
                    <DecorEnhancedTable decorData={decorData} />
                  </Grid>
                </>
              ) : null}
              {project.adjustments.length ? (
                <>
                  <Typography sx={{ my: 3 }} variant="h5" gutterBottom component="div">
                    Adjustments Summary
                  </Typography>
                  <Grid item xs={12}>
                    <AdjustmentTable
                      openModal={openModal}
                      data={handleAdjustmentData()}
                      unitType={unitType}
                      loading={adjustmentLoading}
                      download={downloadAdjustments}
                    />
                  </Grid>
                </>
              ) : null}
              {/* grand total */}
              {optionsData ? (
                <>
                  <Typography sx={{ my: 3 }} variant="h5" gutterBottom component="div">
                    Total Summary
                  </Typography>
                  <Grid item xs={12}>
                    <TotalTable totalData={handleTotalData()} unitType={unitType} adjustments={adjustmentData} />
                  </Grid>
                </>
              ) : null}
            </>
          ) : null}
        </Grid>
      ) : null}
      <Modal
        open={open}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          m: 3,
        }}
        onClose={() => setOpen(false)}
      >
        <Fade in={open}>
          <UnitModal pageTitle={`${selectedStatus} Units`} units={unitsByStatus} />
        </Fade>
      </Modal>
      <Modal
        open={optionOpen}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          m: 3,
        }}
        onClose={() => setOptionOpen(false)}
      >
        <Fade in={optionOpen}>
          <InventoryModal
            pageTitle={`${selectedOption}`}
            tableData={dealOptions}
            tableTitles={['Amount Purchased', 'Total Amount', 'Date Sold']}
          />
        </Fade>
      </Modal>
    </Box>
  );
};

interface ChildProps {
  statusData: any;
  project: IProject;
}

interface IOptionsData {
  option: string;
  currentPrice: string;
  totalOptions: number;
  available: number;
  availableRevenue: number;
  availableRevenueNetHst: number;
  pendingOptions: number;
  soldOptions: number;
  combinedOptions: number;
  pendingRevenue: string;
  soldRevenue: string;
  combinedRevenue: string;
  totalPPSF: number;
  totalRevenue: number;
  totalRevenueNetHst: number;
}

const GETOPTIONSTATS = gql`
  query getOptionsCount($project: MongoID!) {
    getOptionsCount(project: $project) {
      all {
        optionId
        sumTotalAmount
        sumTotalPurchaseAmount
        sumPendingAmount
        sumSoldAmount
        sumPendingPurchaseAmount
        sumSoldPurchaseAmount
        size
      }
      residential {
        optionId
        sumTotalAmount
        sumTotalPurchaseAmount
        sumPendingAmount
        sumSoldAmount
        sumPendingPurchaseAmount
        sumSoldPurchaseAmount
        size
      }
      commercial {
        optionId
        sumTotalAmount
        sumTotalPurchaseAmount
        sumPendingAmount
        sumSoldAmount
        sumPendingPurchaseAmount
        sumSoldPurchaseAmount
        size
      }
    }
  }
`;

const DECOR = gql`
  query getDecorSummary($project: MongoID!, $dateStart: Date!, $dateEnd: Date!) {
    getDecorSummary(project: $project, dateStart: $dateStart, dateEnd: $dateEnd) {
      decorSummary {
        upgradeSetOneTotal {
          count
          creditSum
          creditCount
          deviationSum
          deviationCount
          revenue
          totalCost
        }
        upgradeSetTwoTotal {
          count
          creditSum
          creditCount
          deviationSum
          deviationCount
          revenue
          totalCost
        }
        upgradeSetThreeTotal {
          count
          creditSum
          creditCount
          deviationSum
          deviationCount
          revenue
          totalCost
        }
      }
    }
  }
`;

const ADJUSTMENTS = gql`
  query getAdjustmentSummary($project: MongoID!) {
    getAdjustmentSummary(project: $project) {
      residential {
        name
        count
        amount
        type
      }
      commercial {
        name
        count
        amount
        type
      }
    }
  }
`;

const ADJUSTMENTUNITS = gql`
  query getAdjustmentUnits($project: MongoID!) {
    getAdjustmentUnits(project: $project)
  }
`;

const UNITS = gql`
  query getUnits($project: MongoID!, $searchType: String!, $type: String!, $statuses: [String]) {
    getUnits(project: $project, searchType: $searchType, type: $type, statuses: $statuses) {
      _id
      deal {
        realtor {
          fullName
        }
      }
      allocation {
        fullName
      }
      tempAllocation
      status
      suite
      modelType
      unitType
      bathroom
      size
      outdoorType
      basePrice
      exposure
    }
  }
`;

export default ChartComponent;
