import { useState, useMemo } from 'react';
import { subYears } from 'date-fns';
import { gql, useMutation } from '@apollo/client';
import { Box, Grid, Button, Typography } from '@mui/material';
import Dropzone, { useDropzone } from 'react-dropzone';
import { IPurchaserInfo } from '../../types/CreateDealForm';
import PurchaserForm from '../common/forms/PurchaserForm';
import PdfCard from '../common/PdfCard';
import { FlexBetween } from '../../commonStyles';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import { useAppDispatch } from '../../app/hooks';
import { showSuccessSnackbar, showErrorSnackbar } from '../../features/snackbar/snackbarSlice';
import { formatSin, formatPhoneNumber } from '../../utils/Functions';
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from '../../utils/Constants';

const AddPurchaser = () => {
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const [purchaser, setPurchaser] = useState<IPurchaserInfo>({
    project: project._id,
    corp: false,
    firstName: '',
    lastName: '',
    identifications: [],
    dob: null,
    streetAddress: '',
    city: '',
    province: '',
    country: '',
    postalCode: '',
    idType: "Driver's Licence",
    idNumber: '',
    idJurisdiction: 'Ontario',
    idExpiry: null,
    proofExpiry: null,
    proofNumber: '',
    proofType: '',
    sin: '',
    primaryPhone: '',
    email: '',
    occupation: '',
    employer: '',
    directors: '',
    businessNumber: '',
    signingOfficers: [],
    purchaserType: 'Investor',
  });
  const [file, setFile] = useState<any>(null);
  const [fileImage, setFileImage] = useState<any>('');
  const [errors, setErrors] = useState<any>({});

  const { isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/jpeg, image/png, application/pdf',
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const [saveNewPurchaser] = useMutation(CREATEPURCHASERUPLOAD, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Purchaser has been created'));
      setPurchaser({
        project: project._id,
        corp: false,
        firstName: '',
        lastName: '',
        identifications: [],
        dob: subYears(new Date(), 18),
        streetAddress: '',
        city: '',
        province: '',
        country: '',
        postalCode: '',
        idType: "Driver's Licence",
        idNumber: '',
        idJurisdiction: 'Ontario',
        idExpiry: new Date(),
        proofExpiry: null,
        proofNumber: '',
        proofType: '',
        sin: '',
        primaryPhone: '',
        email: '',
        occupation: '',
        employer: '',
        directors: '',
        businessNumber: '',
        signingOfficers: [],
        purchaserType: 'Investor',
      });
      setFile(null);
      setFileImage('');
    },
    onError: (err) => {
      console.log(err, 'error');
      storeDispatch(showErrorSnackbar('Purchaser has already been created'));
    },
  });

  const handlePurchaserSubmit = (e: any) => {
    e.preventDefault();
    if (purchaser.identifications.length === 0) {
      storeDispatch(showErrorSnackbar('Purchaser Image has not been uploaded'));
      return;
    }
    if (!purchaser.corp) {
      if (purchaser.dob && new Date(purchaser.dob) > subYears(new Date(), 18)) {
        storeDispatch(showErrorSnackbar('Purchaser cannot be under 18'));
        return;
      }
    }
    if (errors) {
      storeDispatch(showErrorSnackbar('Invalid Date'));
      return;
    }
    let newId = purchaser.identifications.map((identification: any) => identification.file);
    let identificationName = purchaser.identifications.map((identification: any) => {
      return {
        name: identification.name,
      };
    });
    purchaser.identifications = identificationName;
    saveNewPurchaser({ variables: { record: purchaser, proof: file, files: newId } });
  };

  const handleDrop = (acceptedFiles: any) => {
    if (acceptedFiles.length === 0) {
      storeDispatch(showErrorSnackbar('This file type is not allowed'));
      return;
    }
    const file = acceptedFiles[0];
    const fileReader = new FileReader();
    if (file) {
      fileReader.readAsDataURL(file);
    }
    fileReader.onloadend = async () => {
      let identificationsArray = [...purchaser.identifications];
      identificationsArray.push({
        url: fileReader.result,
        file: file,
        name: file.name,
      });
      setPurchaser({
        ...purchaser,
        identifications: identificationsArray,
      });
    };
  };

  const handleSigningOfficerInput = () => {
    console.log('input');
  };

  const handleDob = () => {
    console.log('dob');
  };

  const addSigningOfficer = () => {
    console.log('add');
  };

  const handleStreetAddress = async (value: string, type: number) => {
    setPurchaser({
      ...purchaser,
      streetAddress: value,
    });
  };

  const handleTextInput = async (event: any) => {
    if (event && event.target.id === 'streetAddress') {
      setPurchaser({
        ...purchaser,
        [event.target.id]: event.target.value,
      });
    } else if (event && event.target.name) {
      let value = event.target.value;
      if (event.target.name === 'primaryPhone') {
        value = formatPhoneNumber(event.target.value);
      }
      if (event.target.name === 'sin') {
        value = formatSin(event.target.value);
      }
      setPurchaser({
        ...purchaser,
        [event.target.name]: value,
      });
    }
  };

  const handleDateChange = (newValue: any, id: string, type: number) => {
    if (newValue == 'Invalid Date') {
      setErrors('Invalid Date');
      return;
    } else {
      setErrors(null);
    }
    setPurchaser({
      ...purchaser,
      [id]: newValue,
    });
  };

  const handleEndvestorInput = (event: any) => {
    setPurchaser({
      ...purchaser,
      purchaserType: event.target.innerText,
    });
  };

  const handleIdTypeInput = (event: any, idType: string) => {
    setPurchaser({
      ...purchaser,
      [idType]: event.target.innerText,
    });
  };

  const handleIdIssuedByInput = (event: any) => {
    setPurchaser({
      ...purchaser,
      idJurisdiction: event.target.innerText,
    });
  };

  const handleDetails = async (city: string, province: string, postalCode: string, country: string, address: string) => {
    setPurchaser({
      ...purchaser,
      streetAddress: address,
      city: city,
      country: country,
      province: province,
      postalCode: postalCode,
    });
  };

  const handleDelete = (event: any) => {
    let removeIdentification = purchaser.identifications.filter((id: any, index: number) => index !== event);
    setPurchaser({
      ...purchaser,
      identifications: removeIdentification,
    });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography sx={{ mb: 2 }} variant={'h5'}>
        <strong>Create Purchaser</strong>
      </Typography>
      <Box
        sx={{
          border: '1px solid #000',
          padding: '20px',
          position: 'relative',
          borderRadius: '8px',
        }}
      >
        <form onSubmit={handlePurchaserSubmit} autoComplete="off">
          <Dropzone onDrop={handleDrop} accept="image/jpg, image/jpeg, image/png, application/pdf">
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps({ style })}>
                  <input {...getInputProps()} />
                  <p>Drag and Drop or Upload Driver's Licence/Corporation Documents</p>
                </div>
              </section>
            )}
          </Dropzone>
          <div>
            <Grid
              container
              spacing={2}
              style={{
                margin: 0,
                width: '100%',
              }}
            >
              {purchaser.identifications.length > 0
                ? purchaser.identifications.map((attachment: any, index: number) => {
                    return (
                      <Grid key={index} item lg={3} md={4} sm={6} xs={12}>
                        <PdfCard
                          file={attachment.getUrl ? attachment.getUrl : attachment.url}
                          title={attachment.name ? attachment.name : attachment.file.name}
                          id={index}
                          handleDelete={handleDelete}
                          download={true}
                          index={index}
                        />
                      </Grid>
                    );
                  })
                : null}
            </Grid>
          </div>
          <PurchaserForm
            pInfo={purchaser}
            handleTextInput={handleTextInput}
            handleDateChange={handleDateChange}
            handleEndvestorInput={handleEndvestorInput}
            handleIdTypeInput={handleIdTypeInput}
            handleIdIssuedByInput={handleIdIssuedByInput}
            handleDetails={handleDetails}
            handleStreetAddress={handleStreetAddress}
            handleSigningOfficerInput={handleSigningOfficerInput}
            handleDob={handleDob}
            addSigningOfficer={addSigningOfficer}
            controlled={true}
          />
          <FlexBetween
            sx={{
              flexDirection: 'row-reverse',
              mt: 3,
            }}
          >
            <Button type="submit" color="success" variant="contained">
              Create Purchaser
            </Button>
          </FlexBetween>
        </form>
      </Box>
    </Box>
  );
};

const CREATEPURCHASERUPLOAD = gql`
  mutation purchaserCreateOne($record: CreateOnePurchaserInput!, $proof: Upload, $files: [Upload!]) {
    purchaserCreateOne(record: $record, proof: $proof, files: $files) {
      _id
      project {
        _id
      }
      email
      firstName
      lastName
      sin
      dob
      streetAddress
      city
      province
      country
      postalCode
      occupation
      employer
      directors
      businessNumber
      signingOfficers {
        fullName
        dob
        sin
        primaryPhone
        streetAddress
        email
      }
      purchaserType
      primaryPhone
      idType
      idNumber
      idExpiry
      proofExpiry
      proofNumber
      proofType
      unit
      idJurisdiction
      corp
    }
  }
`;

export default AddPurchaser;
