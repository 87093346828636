import { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import { Grid, TextField, Autocomplete, Button, FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';
import { Container, Flex } from '../../../commonStyles';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../features/auth/authSlice';
import { IProject } from '../../../types/project';
import { ILease } from '../../../types/lease';
import { convertAllDates } from '../../../utils/Functions';
import StandardTable from '../../tables/StandardTable';
import { IPurchaserInfo } from '../../../types/CreateDealForm';

const Units = () => {
  const user = useSelector(selectUser);
  const [projects, setProjects] = useState<IProject[]>([]);
  const [search, setSearch] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [active, setActive] = useState<string>('Yes');
  const [leases, setLeases] = useState<ILease[]>([]);
  const [count, setCount] = useState<number>(0);

  const [getLease, { loading }] = useLazyQuery(GETLEASE, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLeases(data.leasePagination.items);
      setCount(data.leasePagination.count);
    },
  });

  useEffect(() => {
    getLease({
      variables: {
        filter: {
          projects: user.projectAccess.map((project: any) => {
            return project.project._id;
          }),
          active: true,
        },
        page: pageNumber,
        perPage: 25,
      },
    });
  }, []);

  const searchFilter = () => {
    let query: any = {
      projects: projects.length
        ? projects.map((project: IProject) => project._id)
        : user.projectAccess.map((project: any) => {
            return project.project._id;
          }),
    };

    if (active === 'Yes') {
      query.active = true;
    }
    if (active === 'No') {
      query.active = false;
    }

    if (search) {
      query.search = search;
    }

    getLease({
      variables: {
        filter: query,
        page: pageNumber,
        perPage: 25,
      },
    });
  };

  const columns = useMemo(() => {
    return [
      {
        Header: 'Project',
        accessor: (rowData: any) => rowData.project.name,
      },
      {
        Header: 'Suite',
        accessor: (rowData: any) => {
          return (
            <Link
              style={{ textDecoration: 'none', color: 'inherit' }}
              rel="noopener noreferrer"
              target="_blank"
              to={`/${rowData.project?._id}/dashboard/${rowData.unit._id}`}
            >
              <strong style={{ cursor: 'pointer' }}>{rowData.unit.suite}</strong>
            </Link>
          );
        },
      },
      {
        Header: 'Landlords',
        accessor: (rowData: any) => rowData.landlords.map((landlord: IPurchaserInfo) => landlord.fullName).join(', '),
      },
      {
        Header: 'Tenancy',
        accessor: (rowData: any) => {
          let tenancy = rowData.documents.find((document: any) => document.type === 'Tenancy');
          if (tenancy) {
            return `${tenancy.status}`;
          } else return <strong>No Tenancy Document Sent</strong>;
        },
      },
      {
        Header: 'Tenancy Length',
        accessor: (rowData: any) => {
          if (rowData.tenancy) {
            return `${convertAllDates(rowData.tenancy.dateStart, 'PP')} - ${convertAllDates(rowData.tenancy.dateEnd, 'PP')}`;
          } else return;
        },
      },
      {
        Header: 'Listing',
        accessor: (rowData: any) => {
          let listing = rowData.documents.find((document: any) => document.type === 'Listing');
          if (listing) {
            return `${listing.status}`;
          } else return <strong>No Listing Document Sent</strong>;
        },
      },
      {
        Header: 'Listing Start Date',
        accessor: (rowData: any) => {
          let tenancy = rowData.documents.find((document: any) => document.type === 'Tenancy');
          if (tenancy) {
            return `${tenancy.status}`;
          } else return '';
        },
      },
      {
        Header: 'Management',
        accessor: (rowData: any) => {
          let management = rowData.documents.find((document: any) => document.type === 'Management');
          if (management) {
            return `${management.status}`;
          } else return '';
        },
      },
    ];
  }, []);

  return (
    <Container sx={{ backgroundColor: '#fff', pt: 0, px: 0 }}>
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12} sm={3}>
          <Autocomplete
            multiple
            options={[user?.projectAccess!.map((projectAccess: any) => projectAccess?.project!)].flat()}
            freeSolo={false}
            value={projects}
            isOptionEqualToValue={(option, value) => option === value}
            getOptionLabel={(option: any) => option.name}
            onChange={(e, value) => {
              setPageNumber(0);
              setProjects(value);
            }}
            renderInput={(params) => <TextField {...params} label="Projects" />}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            title={'Search Purchaser Name or Suite'}
            name={'search'}
            fullWidth
            value={search}
            label={'Search Purchaser Name or Suite'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl required fullWidth>
            <InputLabel id="id-select-label">Active</InputLabel>
            <Select
              labelId="id-label"
              id="active"
              value={active}
              label="Active"
              name="active"
              onChange={(e: any) => setActive(e.target.value)}
              required={true}
            >
              <MenuItem value={'Yes'}>Yes</MenuItem>
              <MenuItem value={'No'}>No</MenuItem>
              <MenuItem value={'Both'}>Both</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button variant="contained" color="primary" onClick={() => searchFilter()}>
            Search
          </Button>
        </Grid>
      </Grid>
      <Box>
        <StandardTable data={leases} columns={columns} />
      </Box>
    </Container>
  );
};

const GETLEASE = gql`
  query leasePagination($filter: FilterFindManyLeaseInput, $page: Int!, $perPage: Int!, $sort: SortFindManyLeaseInput) {
    leasePagination(filter: $filter, page: $page, perPage: $perPage, sort: $sort) {
      items {
        unit {
          _id
          suite
        }
        project {
          name
          _id
        }
        tenancy {
          dateStart
          dateEnd
          tenants {
            firstName
            lastName
            fullName
          }
        }
        listing {
          dateStart
          dateEnd
        }
        landlords {
          _id
          fullName
        }
        documents {
          type
          status
        }
        management {
          rec
        }
      }
      count
    }
  }
`;

export default Units;
