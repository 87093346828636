import { useContext } from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import { LeaseContext } from '../../../context/LeaseContext';
import { IPurchaserInfo } from '../../../types/CreateDealForm';
import { convertAllDates, numToCurrency } from '../../../utils/Functions';
import { ILease } from '../../../types/lease';

const Summary = (props: ChildProps) => {
  const { lease } = props;
  const { setAgreementType } = useContext(LeaseContext);

  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        {lease.management ? (
          <Grid sx={{ cursor: 'pointer' }} onClick={() => setAgreementType('rentalManagement')} item md={4} xs={12}>
            <Paper sx={{ p: 2, height: '100%', backgroundColor: '#def1fd' }} elevation={20}>
              <Typography sx={{ mb: 2 }} variant={'h4'}>
                <strong>Rental Management</strong>
              </Typography>
              <Box>
                <strong>{lease.management.rec ? 'REC' : 'Not REC'}</strong>
              </Box>
              <Box>{lease.management.insurance ? 'Insurance' : 'No Insurance'}</Box>
              <Box>Inspection: {lease.management.inspection ? lease.management.inspection : ''}</Box>
              <Box>Lease Renewal: {lease.management.leaseRenewal ? lease.management.leaseRenewal : ''}</Box>
              <Box>Re Rental Fee: {lease.management.reRentalFee ? lease.management.reRentalFee : ''}</Box>
              <Box>Startup Fee: {lease.management.startupFee ? lease.management.startupFee : ''}</Box>
            </Paper>
          </Grid>
        ) : null}
        {lease.listing ? (
          <Grid sx={{ cursor: 'pointer' }} onClick={() => setAgreementType('listing')} item md={4} xs={12}>
            <Paper sx={{ p: 2, height: '100%', backgroundColor: '#e1fff9' }} elevation={20}>
              <Typography sx={{ mb: 2 }} variant={'h4'}>
                <strong>Listing Agreement</strong>
              </Typography>
              <Box>
                Listing Period:{' '}
                <strong>
                  {lease.listing.dateStart && lease.listing.dateEnd
                    ? `${convertAllDates(lease.listing.dateStart, 'PP')} - ${convertAllDates(lease.listing.dateEnd, 'PP')}`
                    : ''}
                </strong>
              </Box>
              <Box>Listing Commission: {lease.listing.listing ? lease.listing.listing : ''}</Box>
              <Box>Cooperating Commission: {lease.listing.cooperating ? lease.listing.cooperating : ''}</Box>
              <Box>
                Lease Amount: <strong>{numToCurrency.format(lease.listing.amount)}</strong>
              </Box>
              <Box>Holdover: {lease.listing.holdover}</Box>
            </Paper>
          </Grid>
        ) : null}
        {lease.tenancy ? (
          <Grid sx={{ cursor: 'pointer' }} onClick={() => setAgreementType('tenancy')} item md={4} xs={12}>
            <Paper sx={{ p: 2, height: '100%', backgroundColor: '#ffece1' }} elevation={12}>
              <Typography sx={{ mb: 2 }} variant={'h4'}>
                <strong>Tenancy</strong>
              </Typography>
              <Box sx={{ mb: 1 }}>
                {lease.tenancy.tenants.map((purchaser: IPurchaserInfo, index: number) => {
                  return (
                    <Box>
                      Tenants {index + 1}: {purchaser.firstName} {purchaser.lastName}
                    </Box>
                  );
                })}
              </Box>
              <Box>
                <Box>
                  Tenancy Period:{' '}
                  <strong>
                    {lease.tenancy.dateStart ? `${convertAllDates(lease.tenancy.dateStart, 'PP')}` : null}{' '}
                    {lease.tenancy.dateEnd ? `- ${convertAllDates(lease.tenancy.dateEnd, 'PP')}` : null}
                  </strong>
                </Box>
                <Box>
                  Rent Amount: <strong>{numToCurrency.format(lease.tenancy.amount)}</strong>
                </Box>
                <Box>Payment Day: {lease.tenancy.paymentDay}</Box>
                <Box>Payment Type: {lease.tenancy.paymentType}</Box>
                <Box>Parking: {lease.tenancy.parkingDescription}</Box>
                <Box>Locker: {lease.tenancy.lockerDescription}</Box>
              </Box>
            </Paper>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

interface ChildProps {
  lease: ILease;
}

export default Summary;
