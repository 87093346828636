/* Dependency Imports */
import { useState } from 'react';
import { useTable, useSortBy, defaultColumn } from 'react-table';
import {
  Box,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  CircularProgress,
  Typography,
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import TableViewIcon from '@mui/icons-material/TableView';
import Skeleton from '@mui/material/Skeleton';
import { Flex, FlexBetween } from '../../commonStyles';

/* Project Imports */

const StandardTable = ({ columns, data, loading, handleGlobalFilterValue, count, download, text, minWidth, title }: any) => {
  let initialState = {
    title: title,
  };
  /* Hooks */
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data, defaultColumn, initialState },
    useSortBy
  );

  /* States */

  const [searchValue, setSearchValue] = useState<string>('');

  /* Queries/Mutations */

  /* Functions */

  const removeFilter = () => {
    setSearchValue('');
    handleGlobalFilterValue('');
  };

  return (
    <TableContainer sx={{ height: '100%' }} component={Paper} elevation={8}>
      {!handleGlobalFilterValue && !download && !title ? null : (
        <Toolbar sx={{ width: '100%' }}>
          <FlexBetween
            sx={{
              alignItems: {
                sm: 'center',
                xs: 'left',
              },
              display: 'flex',
              width: '100%',
              flexDirection: {
                xs: 'column',
                sm: 'row',
              },
              mt: {
                sm: 0,
                xs: 1,
              },
            }}
          >
            <Flex>
              {title ? (
                <Box sx={{ alignSelf: 'center', mr: 2 }}>
                  <Typography variant="h3">
                    <strong>{title}</strong>
                  </Typography>
                </Box>
              ) : null}
              {handleGlobalFilterValue ? (
                <>
                  <InputBase
                    sx={{ borderBottom: '1px solid #000' }}
                    value={searchValue}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        handleGlobalFilterValue(searchValue);
                      }
                    }}
                    onChange={(e) => {
                      setSearchValue(e.target.value); // Set undefined to remove the filter entirely
                    }}
                    placeholder={`${count} records...`}
                    inputProps={{ 'aria-label': 'search' }}
                  />
                  <SearchIcon
                    onClick={() => handleGlobalFilterValue(searchValue)}
                    sx={{ alignSelf: 'center', height: '100%', mx: 1.5, cursor: handleGlobalFilterValue ? 'pointer' : 'auto' }}
                  />
                  <FilterAltOffIcon
                    onClick={() => removeFilter()}
                    sx={{ alignSelf: 'center', height: '100%', cursor: handleGlobalFilterValue ? 'pointer' : 'auto' }}
                  />
                </>
              ) : null}
            </Flex>
            {download ? (
              <Box
                sx={{
                  ml: {
                    sm: 'auto',
                    xs: 'none',
                  },
                  mt: {
                    sm: 0,
                    xs: 2,
                  },
                }}
              >
                <PictureAsPdfIcon sx={{ mr: 2, cursor: 'pointer', color: 'error.main' }} onClick={() => download('pdf', data)} />
                <TableViewIcon sx={{ cursor: 'pointer', color: 'success.main' }} onClick={() => download('excel', data)} />
              </Box>
            ) : null}
          </FlexBetween>
        </Toolbar>
      )}
      <Table {...getTableProps()} sx={{ minWidth: minWidth ? 650 : 'auto', position: 'relative' }}>
        <TableHead sx={{ position: 'sticky', top: 0 }}>
          {headerGroups.map((headerGroup: any) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <TableCell
                  sx={{ verticalAlign: 'top', backgroundColor: 'primary.main', color: '#fff' }}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  {column.id !== 'selection' ? (
                    <TableSortLabel active={column.isSorted} direction={column.isSortedDesc ? 'desc' : 'asc'} />
                  ) : null}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        {loading ? (
          <TableBody {...getTableBodyProps()}>
            <TableRow>
              <TableCell colSpan={columns.length} sx={{ textAlign: 'center' }}>
                <div>
                  <CircularProgress />
                </div>
                <div>
                  <em>Loading...</em>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody {...getTableBodyProps()}>
            {rows.length > 0 ? (
              <>
                {rows.map((row: any) => {
                  prepareRow(row);
                  return (
                    <TableRow {...row.getRowProps()}>
                      {row.cells.map((cell: any) => {
                        return loading ? (
                          <TableCell {...cell.getCellProps()}>
                            <Skeleton animation="wave" variant="text" height={30} />
                          </TableCell>
                        ) : (
                          <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} sx={{ textAlign: 'center' }}>
                  {text ? text : 'No Results Found'}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

/* Types */

/* GQL */

export default StandardTable;
