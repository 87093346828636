import { useState, useReducer, useContext } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box, Typography, Button, TextField, Grid, InputAdornment } from '@mui/material';
import { IUser } from '../../../types/user';
import { Container, FlexEnd } from '../../../commonStyles';
import { LeaseContext } from '../../../context/LeaseContext';
import Dropdown from '../../common/formControls/Dropdown';
import CustomDialog from '../../common/CustomDialog';
import { IDocuments } from '../../../types/docusign';
import { useAppDispatch } from '../../../app/hooks';
import { showErrorSnackbar } from '../../../features/snackbar/snackbarSlice';

export const handleCommission = (commission: string, type: string) => {
  if (type === 'words') {
    if (commission.includes('+')) {
      return commission.split('+')[0].trim();
    } else return commission;
  } else if (type === 'amount') {
    if (commission.includes('+')) {
      return Number(
        commission
          .split('+')[1]
          .trim()
          .replace(/[^0-9.-]+/g, '')
      ).toString();
    } else return '';
  }
};

const Listing = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const { users } = props;
  const { saveListing, deleteListing, lease } = useContext(LeaseContext);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dateStart, setDateStart] = useState<Date | null>(
    lease && lease.listing ? new Date(lease.listing.dateStart) : new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [dateEnd, setDateEnd] = useState<Date | null>(
    lease && lease.listing ? new Date(lease.listing.dateEnd) : new Date(new Date().setDate(new Date().getDate() + 60))
  );
  const [amount, setAmount] = useState<string>(lease && lease.listing ? lease.listing.amount : '');
  const [executor, setExecutor] = useState<IUser | null>(lease && lease.listing ? lease.listing.executor : null);
  const [listingWords, setListingWords] = useState<string>(
    lease && lease.listing ? handleCommission(lease.listing.listing, 'words')! : `One Month's Rent`
  );
  const [listingAmount, setListingAmount] = useState<string>(
    lease && lease.listing ? handleCommission(lease.listing.listing, 'amount')! : ''
  );
  const [cooperatingWords, setCooperatingWords] = useState<string>(
    lease && lease.listing ? handleCommission(lease.listing.cooperating, 'words')! : `Half Month's Rent`
  );
  const [cooperatingAmount, setCooperatingAmount] = useState<string>(
    lease && lease.listing ? handleCommission(lease.listing.cooperating, 'amount')! : ''
  );
  const [holdover, setHoldover] = useState<string>(lease && lease.listing ? lease.listing.holdover : '90');
  const [mls, setMls] = useState<string>(lease && lease.listing ? lease.listing.mls : '');

  const handleDropdownInput = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
    let selectedUser = users.find((user: IUser) => user.fullName === event.target.value);
    if (selectedUser) {
      setExecutor(selectedUser);
    }
  };

  const handleDeleteListing = () => {
    if (lease.documents.find((document: IDocuments) => document.type === 'Listing'))
      return storeDispatch(showErrorSnackbar('There is currently a Listing Envelope. Please delete the envelope to proceed.'));
    deleteListing(lease);
  };

  return (
    <Box>
      <CustomDialog
        handleClose={() => setDialogOpen(false)}
        handleCloseRemove={() => setDialogOpen(false)}
        handleCloseSuccess={() => handleDeleteListing()}
        open={dialogOpen}
        removeButton={'Cancel'}
        successButton={'Yes, I would like to delete this Listing Profile'}
        dialogContent={<Box sx={{ my: 2 }}>Are you sure you would like to delete the Listing Profile?</Box>}
        dialogTitle={'Delete Listing Profile'}
      />
      {lease && lease.management ? (
        <FlexEnd sx={{ mt: 2 }}>
          <Button variant="contained" color="error" onClick={() => setDialogOpen(true)}>
            Delete Management
          </Button>
        </FlexEnd>
      ) : null}
      <form
        onSubmit={(e: any) =>
          saveListing(e, dateStart, dateEnd, amount, executor, listingWords, listingAmount, cooperatingWords, cooperatingAmount, holdover, mls)
        }
      >
        <Container sx={{ mt: 2 }}>
          <Typography variant={'h5'}>Miscellaneous</Typography>
          <Grid spacing={2} container>
            <Grid item xs={12} sm={6} md={4} sx={{ mt: 2 }}>
              <TextField
                title={'Lease Amount'}
                name={'amount'}
                label="Lease Amount"
                type="number"
                fullWidth
                onChange={(e: any) => setAmount(e.target.value)}
                value={amount}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                required={true}
                placeholder={'Lease Amount'}
              />
            </Grid>
          </Grid>
          <Grid spacing={2} container>
            <Grid item xs={12} sm={6} md={4} sx={{ mt: 2 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={'Listing Start Date (YYYY/MM/DD)'}
                  value={dateStart}
                  onChange={(newValue) => {
                    setDateStart(newValue);
                  }}
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={4} sx={{ mt: 2 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={'Listing End Date (YYYY/MM/DD)'}
                  value={dateEnd}
                  onChange={(newValue) => {
                    setDateEnd(newValue);
                  }}
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid spacing={2} container>
            <Grid item xs={12} sm={6} md={4} sx={{ mt: 2 }}>
              <Dropdown
                title={`Listing Brokerage Commission Month's Rent`}
                menuList={[`One Month's Rent`, `Half Month's Rent`]}
                name={'listingWords'}
                handleSelect={(e: any) => setListingWords(e.target.value)}
                value={listingWords}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} sx={{ mt: 2 }}>
              <TextField
                title={`Listing Brokerage Additional Commission`}
                name={`listingAmount`}
                label="Listing Brokerage Additional Commission"
                fullWidth
                type="number"
                onChange={(e: any) => setListingAmount(e.target.value)}
                value={listingAmount}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                placeholder={`Listing Brokerage Additional Commission`}
              />
            </Grid>
          </Grid>
          <Grid spacing={2} container>
            <Grid item xs={12} sm={6} md={4} sx={{ mt: 2 }}>
              <Dropdown
                title={`Cooperating Brokerage Commission Month's Rent`}
                menuList={[`One Month's Rent`, `Half Month's Rent`]}
                name={'cooperatingWords'}
                handleSelect={(e: any) => setCooperatingWords(e.target.value)}
                value={cooperatingWords}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} sx={{ mt: 2 }}>
              <TextField
                title={`Cooperating Brokerage Additional Commission`}
                name={`cooperatingAmount`}
                label="Cooperating Brokerage Additional Commission"
                fullWidth
                type="number"
                onChange={(e: any) => setCooperatingAmount(e.target.value)}
                value={cooperatingAmount}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                placeholder={`Cooperating Brokerage Additional Commission`}
              />
            </Grid>
          </Grid>
          <Grid spacing={2} container>
            <Grid item xs={12} sm={6} md={4} sx={{ mt: 2 }}>
              <TextField
                title={'Holdover Days'}
                name={'holdover'}
                label="Holdover"
                onChange={(e: any) => setHoldover(e.target.value)}
                value={holdover}
                fullWidth
                required={true}
                placeholder={'Holdover'}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} sx={{ mt: 2 }}>
              <TextField
                title={'MLS#'}
                name={'mls'}
                label="MLS#"
                onChange={(e: any) => setMls(e.target.value)}
                value={mls}
                fullWidth
                placeholder={'MLS#'}
              />
            </Grid>
          </Grid>
          <Grid spacing={2} container>
            <Grid item xs={12} sm={6} md={4} sx={{ mt: 2 }}>
              <Dropdown
                required
                title={'User to execute'}
                menuList={users.map((user: IUser) => user.fullName)}
                name={'executor'}
                handleSelect={(e: any) => handleDropdownInput(e)}
                value={executor ? executor.fullName : ''}
              />
            </Grid>
          </Grid>
        </Container>
        <FlexEnd sx={{ mt: 2 }}>
          <Button type="submit" variant="contained" color="success">
            Save Listing Agreement
          </Button>
        </FlexEnd>
      </form>
    </Box>
  );
};

interface ChildProps {
  users: IUser[];
}

export default Listing;
