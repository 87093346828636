import { useState, useContext } from 'react';
import { Box, Select, MenuItem, ToggleButtonGroup, ToggleButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Flex, FlexBetween } from '../../../commonStyles';
import LeaseInformation from './LeaseInformation';
import LeaseMenu from './LeaseMenu';
import LeaseList from './LeaseList';
import { camelToTitle } from '../../../utils/Functions';
import { LeaseContext } from '../../../context/LeaseContext';
import Documents from './Documents';
import Miscellaneous from './Miscellaneous';
import Landlords from './Landlords';

const Leasing = () => {
  const { leases, lease, setAgreementType, agreementType } = useContext(LeaseContext);
  const [tabValue, setTabValue] = useState<string>('current');

  const handleTabChange = (e: any, value: string) => {
    if (value !== null) {
      setTabValue(value);
    }
  };

  const handleAgreementType = (type: string) => {
    setAgreementType(type);
  };

  return (
    <div>
      <Flex>
        {tabValue === 'previous' ? (
          <ArrowBackIcon
            sx={{ cursor: 'pointer', mr: 1, alignSelf: 'center' }}
            onClick={() => {
              setTabValue('current');
            }}
          />
        ) : null}
        <h2>{agreementType ? `${camelToTitle(agreementType)}` : 'Leasing'}</h2>
      </Flex>
      <FlexBetween>
        {leases.length ? (
          <ToggleButtonGroup
            value={tabValue}
            exclusive
            onChange={handleTabChange}
            aria-label="text alignment"
            sx={{
              '& .MuiToggleButton-root.Mui-selected': {
                backgroundColor: '#00142a',
                color: '#fff',
              },
              '& .MuiToggleButton-root.Mui-selected:hover': {
                backgroundColor: '#00142a',
                color: '#fff',
              },
            }}
          >
            {lease ? (
              <ToggleButton value="current" aria-label="centered">
                Summary
              </ToggleButton>
            ) : null}
            {lease ? (
              <ToggleButton value="landlords" aria-label="right aligned">
                Landlords
              </ToggleButton>
            ) : null}
            {lease ? (
              <ToggleButton value="documents" aria-label="right aligned">
                Documents
              </ToggleButton>
            ) : null}
            {lease ? (
              <ToggleButton value="miscellaneous" aria-label="right aligned">
                Miscellaneous
              </ToggleButton>
            ) : null}
            <ToggleButton value="previous" aria-label="right aligned">
              Past Leases
            </ToggleButton>
          </ToggleButtonGroup>
        ) : null}
        {(agreementType || lease) && tabValue === 'current' ? (
          <Select
            labelId="agreement"
            id="agreement-select"
            value={agreementType}
            onChange={(e: any) => setAgreementType(e.target.value)}
            sx={{ ml: 'auto' }}
          >
            {lease ? <MenuItem value={'summary'}>Overall Summary</MenuItem> : null}
            <MenuItem value={'tenancy'}>Tenancy Agreement</MenuItem>
            <MenuItem value={'listing'}>Listing Agreement</MenuItem>
            <MenuItem value={'rentalManagement'}>Rental Management Agreement</MenuItem>
          </Select>
        ) : null}
      </FlexBetween>
      {tabValue === 'current' ? (
        lease && !lease.management && !lease.tenancy && !lease.listing && agreementType === 'summary' ? (
          <LeaseMenu handleAgreementType={handleAgreementType} />
        ) : lease && agreementType ? (
          <LeaseInformation agreementType={agreementType} />
        ) : (
          <Landlords />
        )
      ) : null}
      {tabValue === 'landlords' ? <Landlords /> : null}
      {tabValue === 'documents' && lease ? <Documents /> : null}
      {tabValue === 'miscellaneous' && lease ? <Miscellaneous lease={lease} /> : null}
      {tabValue === 'previous' ? (
        leases.length ? (
          <LeaseList />
        ) : (
          <Box sx={{ mt: 2 }}>
            <em>There are no previous leases</em>
          </Box>
        )
      ) : null}
    </div>
  );
};

export default Leasing;
