export const baseStyle = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: 'rgb(199 199 199)',
  borderStyle: 'dashed',
  backgroundColor: 'rgb(241 241 241)',
  color: '#00142a',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  margin: '20px 0',
  cursor: 'pointer',
};

export const activeStyle = {
  borderColor: '#2196f3',
};

export const acceptStyle = {
  borderColor: '#00e676',
};

export const rejectStyle = {
  borderColor: '#ff1744',
};