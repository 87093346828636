import { useState, useReducer } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useSelector } from 'react-redux';
import { Box, Button, Grid, TextField, FormControl, InputLabel, MenuItem, Select, InputAdornment, Autocomplete } from '@mui/material';

import { adjustmentsReducer } from '../../../features/project/projectHooks';
import { selectProject, setProject } from '../../../features/project/projectSlice';
import { adjustmentTypes, conditionFields, numberConditions, wordConditions } from '../../../utils/Constants';
import { IAdjustmentCondition, IProjectAdjustment } from '../../../types/project';
import { camelToTitle } from '../../../utils/Functions';

import { FlexBetween, FlexEnd } from '../../../commonStyles';
import { useAppDispatch } from '../../../app/hooks';
import { IUnitData } from '../../../types/unit';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../features/snackbar/snackbarSlice';
import { DeleteForever } from '@mui/icons-material';

const Adjustments = () => {
  const project = useSelector(selectProject);
  const storeDispatch = useAppDispatch();

  const [modelTypes, setModelTypes] = useState<string[]>([]);
  const [unitTypes, setUnitTypes] = useState<string[]>([]);
  const [adjustments, setAdjustments] = useState(project?.adjustments);
  const [adjustmentsState, adjustmentsDispatch] = useReducer(adjustmentsReducer, adjustments);

  useQuery<IUnitData>(UNITS, {
    variables: { filter: { project: project._id } },
    onCompleted: (data) => {
      const uniqueModels = [...new Set(data.unitMany.map((item) => item.modelType))];
      const uniqueUnitTypes = [...new Set(data.unitMany.map((item) => item.unitType))];

      setModelTypes(uniqueModels);
      setUnitTypes(uniqueUnitTypes);
    },
  });

  const [updateProject] = useMutation(UPDATEPROJECT, {
    onCompleted: (data) => {
      storeDispatch(setProject(data.projectUpdateById.record));
      storeDispatch(showSuccessSnackbar('Adjustments has been updated!'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  // Assignment and Levies

  const handleAdd = () => {
    adjustmentsDispatch({ type: 'ADD' });
  };

  const handleAdjustments = async (event: any, numIndex: number) => {
    let value = event.target.value;
    if (event.target.name === 'amount') {
      const regex = /^[0-9\b]+$/;
      if (event.target.value === '' || regex.test(event.target.value)) {
        value = parseInt(event.target.value, 10);
      } else return;
    }
    adjustmentsDispatch({ type: 'UPDATE', payload: { index: numIndex, field: event.target.name, value: value } });
  };

  const saveAdjustments = (event: any) => {
    event.preventDefault();
    updateProject({
      variables: {
        _id: project?._id,
        record: {
          adjustments: adjustmentsState,
        },
      },
    });
  };

  const addCondition = (conditionField: string, conditions: IAdjustmentCondition[], numIndex: number) => {
    if (!conditionField) return storeDispatch(showErrorSnackbar('No Condition Field selected'));
    let condition;
    if (conditionField === 'netHstBasePrice' || conditionField === 'size') {
      condition = {
        value: 0,
        condition: '',
        amount: 0,
      };
    }

    if (
      conditionField === 'unitTypes' ||
      conditionField === 'modelTypes' ||
      conditionField === 'maintenance' ||
      conditionField === 'rental'
    ) {
      condition = {
        values: [],
        value: null,
        condition: '',
        amount: 0,
      };
    }

    adjustmentsDispatch({ type: 'UPDATE', payload: { index: numIndex, field: 'conditions', value: [...conditions, condition] } });
  };

  const handleConditions = (e: any, adjustmentIndex: number, conditionIndex: number) => {
    let conditions = adjustmentsState[adjustmentIndex].conditions.map((condition: IAdjustmentCondition, index: number) => {
      if (index === conditionIndex) {
        return {
          ...condition,
          [e.target.name]: e.target.name === 'value' || e.target.name === 'amount' ? parseInt(e.target.value, 10) : e.target.value,
        };
      } else return condition;
    });
    adjustmentsDispatch({ type: 'UPDATE', payload: { index: adjustmentIndex, field: 'conditions', value: conditions } });
  };

  const handleSelectConditions = (value: any, adjustmentIndex: number, conditionIndex: number) => {
    let conditions = adjustmentsState[adjustmentIndex].conditions.map((condition: IAdjustmentCondition, index: number) => {
      if (index === conditionIndex) {
        return {
          ...condition,
          values: value,
        };
      } else return condition;
    });
    adjustmentsDispatch({ type: 'UPDATE', payload: { index: adjustmentIndex, field: 'conditions', value: conditions } });
  };

  const handleDeleteCondition = (adjustmentIndex: number, conditionIndex: number) => {
    let deleteCondition = adjustmentsState[adjustmentIndex].conditions.filter(
      (condition: IAdjustmentCondition, index: number) => index !== conditionIndex
    );
    adjustmentsDispatch({ type: 'UPDATE', payload: { index: adjustmentIndex, field: 'conditions', value: deleteCondition } });
  };

  const connector = (conditionField: string) => {
    if (conditionField === 'netHstBasePrice' || conditionField === 'size') {
      return 'is';
    } else {
      return '';
    }
  };

  return (
    <Box>
      <form onSubmit={saveAdjustments}>
        <h2>Adjustments</h2>
        {adjustmentsState.map((adjustment: IProjectAdjustment, index: number) => {
          return (
            <Box
              key={index}
              sx={{
                border: '1px solid #000',
                padding: '30px 20px',
                position: 'relative',
                borderRadius: '8px',
                marginBottom: '20px',
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    title={'Name'}
                    name={'name'}
                    onChange={(e: any) => handleAdjustments(e, index)}
                    value={adjustment.name}
                    required={true}
                    placeholder={'Adjustment Name'}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel id="id-display-label">Adjustment Type</InputLabel>
                    <Select
                      required
                      value={adjustment.type}
                      label="Adjustment Type"
                      name="type"
                      onChange={(e: any) => handleAdjustments(e, index)}
                    >
                      {adjustmentTypes.map((display: string) => {
                        return <MenuItem value={display}>{camelToTitle(display)}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel id="id-display-label">Condition Field</InputLabel>
                    <Select
                      required
                      value={adjustment.conditionField}
                      label="Condition Field"
                      name="conditionField"
                      onChange={(e: any) => handleAdjustments(e, index)}
                    >
                      {conditionFields.map((display: string) => {
                        return <MenuItem value={display}>{camelToTitle(display)}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {adjustment.conditions.map((condition: IAdjustmentCondition, numIndex: number) => {
                return (
                  <Box sx={{ mt: 2 }} key={numIndex}>
                    <h3>Condition {numIndex + 1}</h3>
                    <Box sx={{ fontSize: '1.2rem', alignItems: 'center', display: 'flex' }}>
                      <Box sx={{ display: 'inline-block' }}>
                        {camelToTitle(
                          adjustment.conditionField === 'maintenance' ||
                            adjustment.conditionField === 'unitTypes' ||
                            adjustment.conditionField === 'rental'
                            ? 'unitTypes'
                            : adjustment.conditionField
                        )}{' '}
                        {connector(adjustment.conditionField)}
                      </Box>
                      <FormControl required sx={{ width: '200px', mx: 2 }}>
                        <InputLabel id="id-display-label">Condition</InputLabel>
                        <Select
                          required
                          value={condition.condition}
                          label="Condition"
                          name="condition"
                          onChange={(e: any) => handleConditions(e, index, numIndex)}
                        >
                          {adjustment.conditionField === 'netHstBasePrice' || adjustment.conditionField === 'size'
                            ? numberConditions.map((display: string) => {
                                return <MenuItem value={display}>{camelToTitle(display)}</MenuItem>;
                              })
                            : wordConditions.map((display: string) => {
                                return <MenuItem value={display}>{camelToTitle(display)}</MenuItem>;
                              })}
                        </Select>
                      </FormControl>
                      {adjustment.conditionField === 'unitTypes' ||
                      adjustment.conditionField === 'maintenance' ||
                      adjustment.conditionField === 'rental' ? (
                        <Autocomplete
                          multiple
                          options={['All', ...unitTypes.sort()]}
                          getOptionLabel={(option: string) => option}
                          isOptionEqualToValue={(option, value) => option === value}
                          disableClearable={false}
                          freeSolo={false}
                          value={condition.values}
                          onChange={(event: any, newValue: any | null) => {
                            if (newValue.includes('All')) {
                              handleSelectConditions(['All'], index, numIndex);
                            } else {
                              handleSelectConditions(newValue, index, numIndex);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField sx={{ width: '300px' }} {...params} label="Unit Types Allowed" size="medium" />
                          )}
                        />
                      ) : null}
                      {adjustment.conditionField === 'modelTypes' ? (
                        <Autocomplete
                          multiple
                          options={['All', ...modelTypes.sort()]}
                          getOptionLabel={(option: string) => option}
                          isOptionEqualToValue={(option, value) => option === value}
                          disableClearable={false}
                          freeSolo={false}
                          value={condition.values}
                          onChange={(event: any, newValue: any | null) => {
                            if (newValue.includes('All')) {
                              handleSelectConditions(['All'], index, numIndex);
                            } else {
                              handleSelectConditions(newValue, index, numIndex);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField sx={{ width: '300px' }} {...params} label="Model Types Allowed" size="medium" />
                          )}
                        />
                      ) : null}
                      {adjustment.conditionField === 'netHstBasePrice' || adjustment.conditionField === 'size' ? (
                        <TextField
                          title={'Value'}
                          name={'value'}
                          type="number"
                          onChange={(e: any) => handleConditions(e, index, numIndex)}
                          value={condition.value}
                          required={true}
                          placeholder={'Value'}
                          sx={{ width: '200px' }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                        />
                      ) : null}
                      <Box sx={{ display: 'inline-block', mx: 2 }}>
                        then{' '}
                        {adjustment.conditionField === 'maintenance' || adjustment.conditionField === 'rental'
                          ? 'number of free months is'
                          : 'value is'}
                      </Box>
                      <TextField
                        title={'Amount'}
                        name={'amount'}
                        type="number"
                        onChange={(e: any) => handleConditions(e, index, numIndex)}
                        value={condition.amount}
                        required={true}
                        placeholder={'Amount'}
                        sx={{ width: '200px' }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {adjustment.conditionField !== 'maintenance' && adjustment.conditionField !== 'rental' ? '$' : ''}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Box sx={{ ml: 2 }}>
                        <DeleteForever sx={{ cursor: 'pointer', color: 'red' }} onClick={() => handleDeleteCondition(index, numIndex)} />
                      </Box>
                    </Box>
                  </Box>
                );
              })}
              <Box sx={{ mt: 2 }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => addCondition(adjustment.conditionField, adjustment.conditions, index)}
                >
                  Add Condition
                </Button>
              </Box>
              <FlexEnd>
                <Button
                  color="error"
                  variant="contained"
                  onClick={() => adjustmentsDispatch({ type: 'DELETE', payload: { index: index } })}
                >
                  Delete Adjustment
                </Button>
              </FlexEnd>
            </Box>
          );
        })}
        <FlexBetween>
          <Button onClick={() => handleAdd()} color="primary" variant="contained">
            Add Adjustment
          </Button>
          {adjustmentsState.length ? (
            <Button type="submit" color="success" variant="contained">
              Save Adjustments
            </Button>
          ) : null}
        </FlexBetween>
      </form>
    </Box>
  );
};

const UNITS = gql`
  query unitMany($filter: FilterFindManyUnitInput) {
    unitMany(filter: $filter, limit: 10000) {
      _id
      suite
      modelType
      unitType
    }
  }
`;

const UPDATEPROJECT = gql`
  mutation projectUpdateById($_id: MongoID!, $record: UpdateByIdProjectInput!) {
    projectUpdateById(_id: $_id, record: $record) {
      record {
        _id
        name
        developerName
        email
        trackingPhone
        addresses {
          streetAddress
          city
          province
          postalCode
        }
        options {
          name
          totalAvailable
          price
          allowedUnits
          type
        }
        depositStructures {
          name
          deposits {
            name
            type
            amount
            daysDue
            dateType
            dueDate
          }
          default
        }
        mergeTemplates {
          name
          mergeTemplate {
            name
            _id
          }
          apsTemplates {
            name
            apsTemplate {
              _id
              name
            }
            pageNumber
            attachToAps
          }
        }
        status {
          name
          code
          color
        }
        executors {
          name
          email
        }
        mortgageLimit
        mortgageMinDay
        hideOccupancy
        defaultZeroValue
        lawyer
        tagLine
        salesOffice
        adjustments {
          name
          type
        }
        commissionIncludeOptions
        emailTemplates {
          _id
          name
          subject
          html
        }
        logoGetUrl
        logoPutUrl
        imageGetUrl
        imagePutUrl
        coopStructures {
          name
          coopRates {
            type
            days
            date
            description
            percentage
            fixedAmount
          }
        }
        firstTentativeOccupancy
        finalTentativeOccupancy
        firmOccupancy
        outsideOccupancy
        active
        decorModels {
          modelType
          allowed
        }
        portal {
          primaryColor
        }
        combinedProjects {
          _id
          name
        }
      }
    }
  }
`;

export default Adjustments;
