import { useState, useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TextField, Box, Button, Grid, Paper } from '@mui/material';

const RentalManagement = () => {
  const [dateStart, setDateStart] = useState<Date | null>(new Date(new Date().setHours(0, 0, 0, 0)));
  const [dateEnd, setDateEnd] = useState<Date | null>(new Date());

  const [getListingSummary, { loading }] = useLazyQuery(DEALS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onCompleted: (data) => {
      console.log(data, 'data');
    },
  });

  // useEffect(() => {
  //   getListingSummary({ variables: { projects: [], dateStart, dateEnd } });
  // }, []);

  const handleListing = () => {
    getListingSummary({ variables: { projects: [], dateStart, dateEnd } });
  };

  return (
    <Box>
      <Box sx={{ p: 2 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={4}>
              <DateTimePicker
                label={'Start Date (YYYY/MM/DD)'}
                value={dateStart}
                onChange={(newValue) => {
                  setDateStart(newValue);
                }}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DateTimePicker
                label={'End Date (YYYY/MM/DD)'}
                value={dateEnd}
                onChange={(newValue) => {
                  setDateEnd(newValue);
                }}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button sx={{ height: '100%' }} variant="contained" color="primary" onClick={() => handleListing()}>
                Search
              </Button>
            </Grid>
          </Grid>
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

const DEALS = gql`
  query getListingSummary($projects: [MongoID], $dateStart: Date!, $dateEnd: Date!) {
    getListingSummary(projects: $projects, dateStart: $dateStart, dateEnd: $dateEnd) {
      deals {
        project {
          _id
          name
          createdAt
          portal {
            primaryColor
          }
        }
        cancelled
        rescission
        newSales
        newDeals
        statuses {
          _id
          count
          size
          revenue
          revenueNet
        }
      }
    }
  }
`;

export default RentalManagement;