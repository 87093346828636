import React from 'react';
import { Box, Typography, Grid, TextField, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { subYears } from 'date-fns';

import { formatPhoneNumber, formatSin } from '../../utils/Functions';
import { IUnitMarketing } from './FloorplanContainer';
import GooglePlaces from '../common/formControls/GooglePlaces';
import { idTypes, idIssuedBy, endvestor } from '../../utils/Constants';
import { IPurchaserInfo } from '../../types/CreateDealForm';

const PurchaserInformation = (props: ChildProps) => {
  const { purchaser, index, dispatch } = props;

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'UPDATE',
      payload: {
        index: index,
        field: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleDropdown = (e: SelectChangeEvent) => {
    dispatch({
      type: 'UPDATE',
      payload: {
        index: index,
        field: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleDetails = async (city: string, province: string, postalCode: string, country: string) => {
    dispatch({
      type: 'UPDATEADDRESS',
      payload: {
        index: index,
        value: {
          city: city,
          province: province,
          postalCode: postalCode,
          country: country,
        },
      },
    });
  };

  const handleStreetAddress = async (value: string, type: number) => {
    dispatch({
      type: 'UPDATE',
      payload: {
        index: index,
        field: 'streetAddress',
        value: value,
      },
    });
  };

  const handleDobChange = (date: Date | null) => {
    dispatch({
      type: 'UPDATE',
      payload: {
        index: index,
        field: 'dob',
        value: date,
      },
    });
  };

  const handleExpiryChange = (date: Date | null) => {
    dispatch({
      type: 'UPDATE',
      payload: {
        index: index,
        field: 'idExpiry',
        value: date,
      },
    });
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography component={'div'} variant={'h5'} gutterBottom>
        <strong>Purchaser Information</strong>
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={4} md={3}>
          <TextField
            title={'First Name'}
            label={'First Name'}
            name={'firstName'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInput(e)}
            value={purchaser.firstName}
            required={true}
            placeholder={'First Name'}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <TextField
            title={'Last Name'}
            label={'Last Name'}
            name={'lastName'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInput(e)}
            value={purchaser.lastName}
            required={true}
            placeholder={'Last Name'}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <TextField
            type={'email'}
            title={'Email'}
            label={'Email'}
            name={'email'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInput(e)}
            value={purchaser.email}
            required={true}
            placeholder={'Email'}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <TextField
            title={'Phone Number'}
            label={'Phone Number'}
            name={'primaryPhone'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInput(e)}
            value={formatPhoneNumber(purchaser.primaryPhone)}
            required={true}
            placeholder={'Phone Number'}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={['year', 'month', 'day']}
              label={'DOB (YYYY/MM/DD)'}
              value={purchaser.dob ? new Date(purchaser.dob) : null}
              onChange={handleDobChange}
              maxDate={!purchaser.corp ? subYears(new Date(), 18) : new Date()}
              sx={{ width: '100%'}}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <TextField
            title={'Unit/Apt'}
            label={'Unit/Apt'}
            name={'unit'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInput(e)}
            value={purchaser.unit}
            placeholder={'Unit/Apt'}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <GooglePlaces
            disabled={false}
            name={'streetAddress'}
            handleDetails={handleDetails}
            handleStreetAddress={handleStreetAddress}
            address={purchaser.streetAddress}
            type={index}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <TextField
            title={'City'}
            label={'City'}
            name={'city'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInput(e)}
            value={purchaser.city}
            required={true}
            placeholder={'City'}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <TextField
            title={'Province'}
            label={'Province'}
            name={'province'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInput(e)}
            value={purchaser.province}
            required={true}
            placeholder={'Province'}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <TextField
            title={'Country'}
            label={'Country'}
            name={'country'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInput(e)}
            value={purchaser.country}
            required={true}
            placeholder={'Country'}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <TextField
            title={'Postal Code'}
            label={'Postal Code'}
            name={'postalCode'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInput(e)}
            value={purchaser.postalCode}
            required={true}
            placeholder={'Postal Code'}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <FormControl fullWidth>
            <InputLabel id="id-select-label">ID Type</InputLabel>
            <Select
              labelId="id-label"
              id="id-select"
              value={purchaser.idType}
              label="ID Type"
              name="idType"
              onChange={handleDropdown}
              required={true}
            >
              {idTypes.map((idType: string) => {
                return <MenuItem value={idType}>{idType}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <TextField
            title={'ID Number'}
            label={'ID Number'}
            name={'idNumber'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInput(e)}
            value={purchaser.idNumber}
            required={true}
            placeholder={'ID Number'}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={['year', 'month', 'day']}
              label={'ID Expiry (YYYY/MM/DD)'}
              value={purchaser.idExpiry ? new Date(purchaser.idExpiry) : null}
              onChange={handleExpiryChange}
              sx={{ width: '100%'}}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <FormControl fullWidth>
            <InputLabel id="id-select-issue">ID Issued By</InputLabel>
            <Select
              labelId="id-issued"
              id="id-select"
              value={purchaser.idJurisdiction}
              label="ID Issued By"
              name="idJurisdiction"
              onChange={handleDropdown}
              required={true}
            >
              {idIssuedBy.map((idIssuedBy: string) => {
                return <MenuItem value={idIssuedBy}>{idIssuedBy}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <TextField
            title={'SIN'}
            label={'SIN'}
            name={'sin'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInput(e)}
            value={formatSin(purchaser.sin)}
            placeholder={'SIN'}
            sx={{ width: '100%' }}
            inputProps={{ maxLength: 11 }}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <TextField
            title={'Occupation'}
            label={'Occupation'}
            name={'occupation'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInput(e)}
            value={purchaser.occupation}
            placeholder={'Occupation'}
            sx={{ width: '100%' }}
            required
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <TextField
            title={'Employer'}
            label={'Employer'}
            name={'employer'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInput(e)}
            value={purchaser.employer}
            placeholder={'Employer'}
            sx={{ width: '100%' }}
            required
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <FormControl fullWidth>
            <InputLabel id="id-select-endvestor">Investor/End User</InputLabel>
            <Select
              labelId="id-investor"
              id="id-select"
              value={purchaser.purchaserType}
              label="Investor/End User"
              name="purchaserType"
              onChange={handleDropdown}
              required={true}
            >
              {endvestor.map((endvestor: string) => {
                return <MenuItem value={endvestor}>{endvestor}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

interface ChildProps {
  unit: IUnitMarketing;
  purchaser: IPurchaserInfo;
  index: number;
  dispatch: any;
}

export default PurchaserInformation;
