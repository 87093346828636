import { useState, useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import { Box, Typography, Grid } from '@mui/material';
import { numToCurrency, sortNestedSuites, downloadExcel, downloadPdf } from '../../utils/Functions';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import StandardTable from '../tables/StandardTable';
import BasicTabs from '../common/BasicTabs';
import { IOption } from '../../types/project';
import { GlobalModal } from '../../features/modal/Modal';
import { IDeal } from '../../types/CreateDealForm';
import { useAppDispatch } from '../../app/hooks';
import { handleModal } from '../../features/modal/modalSlice';
import { IPurchaserInfo } from '../../types/CreateDealForm';

const OptionReport = () => {
  const project = useSelector(selectProject);
  const storeDispatch = useAppDispatch();
  const [value, setValue] = useState(0);
  const [type, setType] = useState<string>(project && project.options.length ? project.options[0].name : '');
  const [title, setTitle] = useState<string>('');
  // Data States
  const [soldUnitTypes, setSoldUnitTypes] = useState<ISoldUnitTypes[]>([]);
  const [soldWhileElgible, setSoldWhileElgible] = useState<IOptionCount[]>([]);
  const [presaleWhileElgible, setPresaleWhileElgible] = useState<IOptionCount[]>([]);
  const [soldWhileInelgible, setSoldWhileInelgible] = useState<IOptionCount[]>([]);
  const [availableUnits, setAvailableUnits] = useState<IOptionCount[]>([]);
  const [miscellaneous, setMiscellaneous] = useState<IMiscellaneous[]>([]);

  const [deals, setDeals] = useState<IDeal[]>([]);

  const [getOptionDeals] = useLazyQuery(OPTIONDEALS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setDeals(sortNestedSuites([...data.getOptionDeals], 'unit', 'suite'));
      storeDispatch(handleModal(true));
    },
  });

  const [getOptionsSummary] = useLazyQuery(OPTIONS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      let selectedOption = project.options.find((option: IOption) => option.name === type);
      let soldUnitTypes = [...data.getOptionsSummary.soldUnitTypes].sort((a: any, b: any) => a.unitType.localeCompare(b.unitType));
      soldUnitTypes = [
        ...soldUnitTypes,
        {
          unitType: 'Total',
          optionAmount: soldUnitTypes.reduce((a, b) => a + b.optionAmount, 0),
          optionPurchaseAmount: soldUnitTypes.reduce((a, b) => a + b.optionPurchaseAmount, 0),
        },
      ];

      let soldWhileElgible = [...data.getOptionsSummary.soldWhileElgible].sort((a: any, b: any) => a._id.localeCompare(b._id));
      soldWhileElgible = [
        ...soldWhileElgible,
        {
          _id: 'Total',
          count: soldWhileElgible.reduce((a, b) => a + b.count, 0),
        },
      ];

      let presaleWhileElgible = [...data.getOptionsSummary.presaleWhileElgible].sort((a: any, b: any) => a._id.localeCompare(b._id));
      presaleWhileElgible = [
        ...presaleWhileElgible,
        {
          _id: 'Total',
          count: presaleWhileElgible.reduce((a, b) => a + b.count, 0),
        },
      ];

      let soldWhileInelgible = [...data.getOptionsSummary.soldWhileInelgible].sort((a: any, b: any) => a._id.localeCompare(b._id));
      soldWhileInelgible = [
        ...soldWhileInelgible,
        {
          _id: 'Total',
          count: soldWhileInelgible.reduce((a, b) => a + b.count, 0),
        },
      ];

      let availableUnits = [...data.getOptionsSummary.availableUnits].sort((a: any, b: any) => a._id.localeCompare(b._id));
      availableUnits = [
        ...availableUnits,
        {
          _id: 'Total',
          count: availableUnits.reduce((a, b) => a + b.count, 0),
        },
      ];

      let miscellaneous: any[] = [];

      if (selectedOption) {
        miscellaneous = [
          {
            _id: 'Total Available',
            count: selectedOption?.totalAvailable - soldUnitTypes[soldUnitTypes.length - 1].optionPurchaseAmount,
          },
          {
            _id: 'Total Sold',
            count: soldUnitTypes[soldUnitTypes.length - 1].optionPurchaseAmount,
          },
          {
            _id: 'Overall Total',
            count: selectedOption?.totalAvailable,
          },
          {
            _id: 'Surplus',
            count:
              selectedOption?.totalAvailable -
              soldUnitTypes[soldUnitTypes.length - 1].optionPurchaseAmount -
              availableUnits[availableUnits.length - 1].count -
              presaleWhileElgible[presaleWhileElgible.length - 1].count,
          },
          {
            _id: '(O, S, C) Surplus Parking Inclusive',
            count:
              selectedOption?.totalAvailable -
              soldUnitTypes[soldUnitTypes.length - 1].optionPurchaseAmount -
              availableUnits[availableUnits.length - 1].count,
          },
        ];
      }

      setSoldUnitTypes(soldUnitTypes);
      setSoldWhileElgible(soldWhileElgible);
      setPresaleWhileElgible(presaleWhileElgible);
      setSoldWhileInelgible(soldWhileInelgible);
      setAvailableUnits(availableUnits);
      setMiscellaneous(miscellaneous);
    },
  });

  useEffect(() => {
    if (type) {
      getOptionsSummary({ variables: { project: project._id, type: type } });
    }
  }, [type]);

  const viewDetails = async (unitType: string, header: string) => {
    let searchType = '';
    if (header === '(O, S, C) Sold Without, While Elgible') {
      searchType = 'presaleWithoutElgible';
    } else if (header === '(F) Sold Without, While Elgible') {
      searchType = 'soldWithoutElgible';
    } else if (header === '(O, S, C, F) Sold, While Inelgible') {
      searchType = 'soldInelgible';
    } else if (header === `${type} Sold`) {
      searchType = 'sold';
    }
    if (!searchType) return;
    setTitle(`${header} - ${unitType}`);
    await getOptionDeals({ variables: { project: project._id, unitType, searchType, type } });
  };

  const unitTypeColumns = useMemo(() => {
    return [
      {
        Header: 'Unit Type',
        accessor: (rowData: any) => {
          return (
            <Box sx={{ cursor: 'pointer' }}>
              <strong>{rowData.unitType}</strong>
            </Box>
          );
        },
        Cell: (props: any) => {
          return <Box onClick={() => viewDetails(props.row.original.unitType, props.state.title)}>{props.value}</Box>;
        },
      },
      {
        Header: 'Total Revenue',
        accessor: (rowData: any) =>
          rowData.unitType === 'Total' ? (
            <strong>{numToCurrency.format(rowData.optionAmount)}</strong>
          ) : (
            numToCurrency.format(rowData.optionAmount)
          ),
      },
      {
        Header: 'Count',
        accessor: (rowData: any) =>
          rowData.unitType === 'Total' ? <strong>{rowData.optionPurchaseAmount}</strong> : rowData.optionPurchaseAmount,
      },
    ];
  }, [type]);

  const optionsCountColumns = useMemo(() => {
    return [
      {
        Header: 'Title',
        accessor: (rowData: any) => {
          return (
            <Box sx={{ cursor: 'pointer' }}>
              <strong>{rowData._id}</strong>
            </Box>
          );
        },
        Cell: (props: any) => {
          return <Box onClick={() => viewDetails(props.row.original._id, props.state.title)}>{props.value}</Box>;
        },
      },
      {
        Header: 'Count',
        accessor: (rowData: any) => (rowData._id === 'Total' ? <strong>{rowData.count}</strong> : rowData.count),
      },
    ];
  }, [type]);

  const dealColumns = useMemo(() => {
    let columns = [
      {
        Header: 'Suite',
        accessor: (rowData: any) => {
          return (
            <Link style={{ textDecoration: 'none', color: '#000' }} to={`/${project._id}/dashboard/${rowData.unit._id}`}>
              <strong>{rowData.unit.suite}</strong>
            </Link>
          );
        },
      },
      {
        Header: 'Base Price',
        accessor: (rowData: any) => numToCurrency.format(rowData.basePrice),
      },
      {
        Header: 'Unit Type',
        accessor: (rowData: any) => rowData.unit.unitType,
      },
      {
        Header: 'Exposure',
        accessor: (rowData: any) => rowData.unit.exposure,
      },
      {
        Header: 'Model Type',
        accessor: (rowData: any) => rowData.unit.modelType,
      },
      {
        Header: 'Size',
        accessor: (rowData: any) => rowData.unit.size,
      },
      {
        Header: 'PPSF',
        accessor: (rowData: any) => numToCurrency.format(rowData.unit.basePrice / rowData.unit.size),
      },
      {
        Header: 'Realtor',
        accessor: (rowData: any) => (rowData.realtor.length ? rowData.realtor[0].fullName : 'Public'),
      },
      {
        Header: 'Purchaser Full Name',
        accessor: (rowData: any) =>
          rowData.purchasers.map((purchaser: IPurchaserInfo) => `${purchaser.firstName} ${purchaser.lastName}`).join(', '),
      },
      {
        Header: 'Purchaser Email',
        accessor: (rowData: any) => rowData.purchasers.map((purchaser: IPurchaserInfo) => `${purchaser.email}`).join(', '),
      },
      {
        Header: 'Purchaser Phone',
        accessor: (rowData: any) => rowData.purchasers.map((purchaser: IPurchaserInfo) => `${purchaser.primaryPhone}`).join(', '),
      },
    ];

    return columns;
  }, []);

  const tabs = project.options.map((option: any) => {
    return {
      label: `${option.name}`,
      component: (
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} sm={6} lg={4}>
            <StandardTable title={`${type} Sold`} data={soldUnitTypes} columns={unitTypeColumns} />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <StandardTable title={`(O, S, C) Sold Without, While Elgible`} data={presaleWhileElgible} columns={optionsCountColumns} />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <StandardTable title={`(F) Sold Without, While Elgible`} data={soldWhileElgible} columns={optionsCountColumns} />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <StandardTable title={`(O, S, C, F) Sold, While Inelgible`} data={soldWhileInelgible} columns={optionsCountColumns} />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <StandardTable title={`Available Units Still Elgible`} data={availableUnits} columns={optionsCountColumns} />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <StandardTable title={`Miscellaneous`} data={miscellaneous} columns={optionsCountColumns} />
          </Grid>
        </Grid>
      ),
    };
  });

  const download = (type: string, data: any) => {
    let headers = [
      {
        label: 'Suite',
        id: 'suite',
      },
      {
        label: 'Base Price',
        id: 'basePrice',
      },
      {
        label: 'Unit Type',
        id: 'unitType',
      },
      {
        label: 'Exposure',
        id: 'exposure',
      },
      {
        label: 'Model Type',
        id: 'modelType',
      },
      {
        label: 'Size',
        id: 'size',
      },
      {
        label: 'Realtor',
        id: 'realtor',
      },
      {
        label: 'Purchaser Full Names',
        id: 'purchaserFullNames',
      },
      {
        label: 'Purchaser Emails',
        id: 'purchaserEmails',
      },
      {
        label: 'Purchaser Phone',
        id: 'purchaserPhones',
      },
      {
        label: 'PPSF',
        id: 'ppsf',
      },
    ];

    let widths = {
      suite: 15,
      basePrice: 15,
      unitType: 15,
      exposure: 15,
      modelType: 15,
      size: 15,
      realtor: 15,
      purchaserFullNames: 15,
      purchaserEmails: 15,
      purchaserPhones: 15,
      ppsf: 15,
    };

    let pdfWidths = {
      suite: 200,
      basePrice: 200,
      unitType: 200,
      exposure: 200,
      modelType: 200,
      size: 200,
      realtor: 200,
      purchaserFullNames: 200,
      purchaserEmails: 200,
      purchaserPhones: 200,
      ppsf: 200,
    };

    let newData = data.map((data: any) => {
      return {
        ...data,
        suite: data.unit.suite,
        basePrice: data.basePrice ? numToCurrency.format(data.basePrice) : numToCurrency.format(data.unit.basePrice),
        unitType: data.unit.unitType,
        exposure: data.unit.exposure,
        modelType: data.unit.modelType,
        size: data.unit.size,
        realtor: data.realtor.length ? data.realtor[0].fullName : 'Public',
        purchaserFullNames: data.purchasers.map((purchaser: IPurchaserInfo) => `${purchaser.firstName} ${purchaser.lastName}`).join(', '),
        purchaserEmails: data.purchasers.map((purchaser: IPurchaserInfo) => `${purchaser.email}`).join(', '),
        purchaserPhones: data.purchasers.map((purchaser: IPurchaserInfo) => `${purchaser.primaryPhone}`).join(', '),
        ppsf: numToCurrency.format(data.basePrice / data.unit.size),
      };
    });

    let sheetTitle = `${project.name} - ${title}`;

    if (type === 'excel') {
      downloadExcel([newData], [headers], [], [[widths]], [sheetTitle], sheetTitle);
    } else {
      downloadPdf([newData], [headers], [], [pdfWidths], [sheetTitle], sheetTitle);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <GlobalModal>
        <Typography variant="h2" sx={{ mb: 2 }}>
          <strong>{title}</strong>
        </Typography>
        <StandardTable data={deals} columns={dealColumns} download={download} />
      </GlobalModal>
      <Typography sx={{ mb: 2 }} variant="h2">
        <strong>Options Report</strong>
      </Typography>
      <Box>
        <BasicTabs tabs={tabs} value={value} setValue={setValue} color={'#00142a'} setLabel={setType} />
      </Box>
    </Box>
  );
};

interface ISoldUnitTypes {
  unitType: string;
  optionAmount: number;
  optionPurchaseAmount: number;
}

interface IOptionCount {
  _id: string;
  count: number;
}

interface IMiscellaneous {}

const OPTIONS = gql`
  query getOptionsSummary($project: MongoID!, $type: String!) {
    getOptionsSummary(project: $project, type: $type) {
      soldUnitTypes {
        unitType
        optionAmount
        optionPurchaseAmount
      }
      soldWhileElgible {
        _id
        count
      }
      presaleWhileElgible {
        _id
        count
      }
      soldWhileInelgible {
        _id
        count
      }
      availableUnits {
        _id
        count
      }
    }
  }
`;

const OPTIONDEALS = gql`
  query getOptionDeals($project: MongoID!, $unitType: String!, $searchType: String!, $type: String!) {
    getOptionDeals(project: $project, unitType: $unitType, searchType: $searchType, type: $type) {
      realtor {
        fullName
      }
      unit {
        _id
        suite
        modelType
        unitType
        bathroom
        size
        outdoorType
        basePrice
        exposure
      }
      basePrice
      rescission {
        dateRescinded
      }
      cancelled {
        dateCancelled
      }
      purchasers {
        firstName
        lastName
        email
        primaryPhone
      }
    }
  }
`;

export default OptionReport;
