import { useMemo } from 'react';
import { Box, Paper } from '@mui/material';

import StandardTable from '../tables/StandardTable';
import { capitalizeFirstLetter, downloadExcel, downloadPdf } from '../../utils/Functions';
import { IProject } from '../../types/project';

const EnhancedTable = (props: ChildProps) => {
  const { rows, type, columnHeaders, columnWidths, pdfWidths, openModal, project } = props;

  const download = (downloadType: string, data: any = null) => {
    let sheetTitle = `${project.name} ${capitalizeFirstLetter(type)}`;

    if (downloadType === 'excel') {
      downloadExcel([rows], [columnHeaders], [], [[columnWidths]], [sheetTitle], sheetTitle);
    } else {
      downloadPdf([rows], [columnHeaders], [], [pdfWidths], [sheetTitle], sheetTitle);
    }
  };

  const columns = useMemo(() => {
    return [
      {
        Header: 'Status',
        accessor: (rowData: any) => {
          return (
            <Box onClick={() => openModal('unit', `${type} - ${rowData.status}`, rowData.status)}>
              <strong style={{ cursor: 'pointer' }}>{rowData.status}</strong>
            </Box>
          );
        },
      },
      {
        Header: 'Number of Suites',
        accessor: (rowData: any) => {
          return <Box sx={{ fontWeight: rowData.status === 'Total' ? 'bold' : 'normal' }}>{rowData.count}</Box>;
        },
      },
      {
        Header: 'Size (sq/ft)',
        accessor: (rowData: any) => {
          return <Box sx={{ fontWeight: rowData.status === 'Total' ? 'bold' : 'normal' }}>{rowData.size}</Box>;
        },
      },
      {
        Header: 'Revenue',
        accessor: (rowData: any) => {
          return <Box sx={{ fontWeight: rowData.status === 'Total' ? 'bold' : 'normal' }}>{rowData.revenue}</Box>;
        },
      },
      {
        Header: 'Revenue Net',
        accessor: (rowData: any) => {
          return <Box sx={{ fontWeight: rowData.status === 'Total' ? 'bold' : 'normal' }}>{rowData.revenueNet}</Box>;
        },
      },
      {
        Header: 'PPSF',
        accessor: (rowData: any) => {
          return <Box sx={{ fontWeight: rowData.status === 'Total' ? 'bold' : 'normal' }}>{rowData.ppsf}</Box>;
        },
      },
      {
        Header: 'PPSF Net',
        accessor: (rowData: any) => {
          return <Box sx={{ fontWeight: rowData.status === 'Total' ? 'bold' : 'normal' }}>{rowData.ppsfNet}</Box>;
        },
      },
    ];
  }, []);

  return (
    <Paper elevation={5}>
      <StandardTable data={rows} columns={columns} download={download} />
    </Paper>
  );
};

interface ChildProps {
  rows: any[];
  type: string;
  columnHeaders: any[];
  columnWidths: any;
  pdfWidths: any;
  openModal: any;
  project: IProject;
}

export default EnhancedTable;
