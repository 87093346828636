import { useEffect, useState, useMemo, Dispatch, SetStateAction } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import Dropzone, { useDropzone } from 'react-dropzone';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useSelector } from 'react-redux';
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Autocomplete,
  Grid,
  FormControlLabel,
  Checkbox,
  Button,
} from '@mui/material';

import { IAttachment, ITask } from '../../types/task';
import { IUser } from '../../types/user';
import { IProjectAccess } from '../../types/user';
import { selectUser } from '../../features/auth/authSlice';
import { baseStyle, activeStyle, acceptStyle, rejectStyle, taskLabels } from '../../utils/Constants';
import { useAppDispatch } from '../../app/hooks';
import { showErrorSnackbar, showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';
import PdfCard from '../common/PdfCard';
import { FlexBetween } from '../../commonStyles';
import TextEditor from '../common/textEditor/TextEditor';

const TaskDetails = (props: ChildProps) => {
  const user = useSelector(selectUser);
  const storeDispatch = useAppDispatch();
  const { task, setModalOpen, createTask, updateTask, deleteAttachment } = props;
  const [selectedProject, setSelectedProject] = useState<string>('');
  const [users, setUsers] = useState<IUser[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>(task ? task.description : '');
  const [labels, setLabels] = useState<string[]>([]);
  const [dueDate, setDueDate] = useState<Date | null>(null);
  const [attachments, setAttachments] = useState<any[]>([]);
  const [pasteImage, setPasteImage] = useState<string>('');

  const { isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/gif, image/jpeg, image/png, application/pdf',
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const [getUsers] = useLazyQuery(GETUSERS, {
    onCompleted: (data) => {
      let sortedUser = [...data.userMany].sort((a, b) => a.fullName.localeCompare(b.fullName));
      setUsers(sortedUser);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [updateAttachments] = useMutation(UPDATEATTACHMENTS, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Updated!'));
    },
    onError: (err) => {
      console.log(err);
    },
  });

  useEffect(() => {
    if (task) {
      setSelectedProject(task.project ? task.project._id : '');
      setSelectedUsers(task.to);
      setTitle(task.title);
      setDescription(task.description);
      setLabels(task.labels);
      setDueDate(task.dueDate!);
      setAttachments(task.attachments!);
    }
  }, [task]);

  useEffect(() => {
    if (selectedProject) {
      getUsers({
        variables: {
          filter: { OR: [{ type: 'Sales' }, { type: 'Manager' }, { type: 'Admin' }], usersByProject: selectedProject, locked: false },
        },
      });
      setSelectedUsers([]);
    } else {
      getUsers({
        variables: { filter: { OR: [{ type: 'Sales' }, { type: 'Manager' }, { type: 'Admin' }], locked: false } },
      });
    }
  }, [selectedProject, getUsers]);

  const handleDrop = (acceptedFiles: any) => {
    if (acceptedFiles.length === 0) {
      storeDispatch(showErrorSnackbar('This file type is not allowed'));
      return;
    }
    const files = acceptedFiles;
    const fileReader = new FileReader();
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        fileReader.readAsDataURL(files[i]);
        fileReader.onloadend = async () => {
          setAttachments([
            ...attachments,
            {
              url: fileReader.result,
              file: files[i],
              name: files[i].name,
            },
          ]);
        };
      }
    }
  };

  const handleDelete = (id: number, title: string, numIndex: number) => {
    if (id) {
      deleteAttachment({ variables: { _id: task?._id, attachmentId: id, deleteFile: true } }).then((res: any) => {
        if (res.data.deleteAttachment) {
          setAttachments(res.data.deleteAttachment.attachments);
        }
      });
    } else {
      let removedAttachments = attachments.filter((id: any, index: number) => numIndex !== index);
      setAttachments(removedAttachments);
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!selectedUsers.length) return storeDispatch(showErrorSnackbar('No users assigned'));

    let taskDescription = description;

    if (pasteImage) {
      taskDescription = taskDescription.replace(/src="(.*?)"/g, `src="${pasteImage}"`);
    }

    if (task) {
      for (const file of attachments) {
        if (file.file) {
          await updateAttachments({ variables: { id: task._id, name: file.file.name, file: file.file } });
        }
      }

      await updateTask({
        variables: {
          _id: task._id,
          record: {
            project: selectedProject ? selectedProject : null,
            title: title,
            status: 'newTasks',
            type: 'app',
            description: taskDescription,
            from: user._id,
            to: selectedUsers.map((user: IUser) => user._id),
            labels: labels,
            dueDate: dueDate,
          },
        },
      }).then((res: any) => {
        if (res.data) {
          storeDispatch(showSuccessSnackbar('Task Updated!'));
          setModalOpen(false);
        }
      });
    } else {
      await createTask({
        variables: {
          record: {
            project: selectedProject ? selectedProject : null,
            title: title,
            status: 'newTasks',
            type: 'app',
            description: taskDescription,
            from: user._id,
            to: selectedUsers.map((user: IUser) => user._id),
            labels: labels,
            dueDate: dueDate,
            attachments: attachments.map((attachment: IAttachment) => {
              return { name: attachment.name };
            }),
          },
          files: attachments.length ? attachments.map((attachment: any) => attachment.file) : [],
        },
      });
      setModalOpen(false);
    }
  };

  const handleEditorChange = (text: string, index: number, html: string) => {
    setDescription(html);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h2">
        <strong>{task ? 'Edit Task' : 'Create Task'}</strong>
      </Typography>
      <FormControl sx={{ width: '100%', mt: 2 }}>
        <InputLabel id="demo-simple-select-project">Project</InputLabel>
        <Select
          label="Project"
          sx={{ width: '100%' }}
          labelId="demo-simple-select-project"
          id="demo-simple-project"
          value={selectedProject ? selectedProject : ''}
          onChange={(e: any) => {
            setSelectedProject(e.target.value);
          }}
        >
          {user?.projectAccess.map((projectAccess: IProjectAccess, index: number) => {
            return (
              <MenuItem key={index} value={projectAccess.project._id}>
                {projectAccess.project.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl required sx={{ width: '100%', mt: 2 }}>
        <Autocomplete
          multiple
          id="task-users"
          options={users}
          freeSolo={false}
          getOptionLabel={(option: any) => option.fullName}
          isOptionEqualToValue={(option, value) => option === value}
          value={selectedUsers}
          onChange={(e, value) => setSelectedUsers(value)}
          renderInput={(params) => <TextField {...params} variant="outlined" label="Assign Users to Task" />}
        />
      </FormControl>
      <TextField
        sx={{ mt: 2 }}
        title={'Title of Task'}
        name={'title'}
        fullWidth
        value={title}
        label={'Title of Task'}
        required
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
      />
      <Box sx={{ mt: 2 }}>
        <TextEditor initContent={description} index={0} handleChange={handleEditorChange} setPasteImage={setPasteImage} />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography variant="h3">
          <strong>Labels</strong>
        </Typography>
        <Grid container spacing={2}>
          {taskLabels.map((taskLabel: string, index: number) => {
            return (
              <Grid key={index} item xs={12} md={3}>
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={labels.map((label: string) => label).includes(taskLabel)}
                      onChange={(e: any) => {
                        if (labels.includes(taskLabel)) {
                          setLabels(labels.filter((selected: any) => selected !== taskLabel));
                        } else {
                          setLabels([...labels, taskLabel]);
                        }
                      }}
                    />
                  }
                  label={taskLabel}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Box sx={{ mt: 2 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box sx={{ mr: 2 }}>
            <DateTimePicker
              label={'Due Date'}
              value={dueDate}
              onChange={(newValue) => {
                setDueDate(newValue);
              }}
            />
          </Box>
        </LocalizationProvider>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography variant="h3">
          <strong>Attachments</strong>
        </Typography>
        <div>
          <Dropzone onDrop={(files) => handleDrop(files)} accept="image/gif, image/jpg, image/jpeg, image/png, application/pdf">
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps({ style })}>
                  <input {...getInputProps()} />
                  <p>Drag and Drop or Click to Upload</p>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
        <div>
          <Grid container spacing={2}>
            {attachments.length > 0
              ? attachments.map((attachment: any, index: number) => {
                  return (
                    <Grid key={index} item lg={3} md={4} sm={6} xs={12}>
                      <PdfCard
                        file={attachment.getUrl ? attachment.getUrl : attachment.url}
                        title={attachment.name ? attachment.name : attachment.file.name}
                        id={attachment._id}
                        handleDelete={handleDelete}
                        download={true}
                        index={index}
                      />
                    </Grid>
                  );
                })
              : null}
          </Grid>
        </div>
      </Box>
      <FlexBetween sx={{ mt: 2 }}>
        <Button onClick={() => setModalOpen(false)} variant="contained" color="info">
          Cancel
        </Button>
        <Button type="submit" variant="contained" color="success">
          {task ? 'Update' : 'Create'}
        </Button>
      </FlexBetween>
    </form>
  );
};

interface ChildProps {
  task: ITask | null;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  createTask: any;
  updateTask: any;
  deleteAttachment: any;
}

const GETUSERS = gql`
  query userMany($filter: FilterFindManyUserInput!) {
    userMany(filter: $filter) {
      _id
      email
      fullName
    }
  }
`;

const UPDATEATTACHMENTS = gql`
  mutation addAttachments($id: MongoID!, $name: String!, $file: Upload!) {
    addAttachments(id: $id, name: $name, file: $file) {
      _id
      attachments {
        _id
        name
        getUrl
      }
    }
  }
`;

export default TaskDetails;
