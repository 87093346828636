import { useState, useContext } from 'react';
import { withRouter } from '../../utils/WithRouter';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Box, IconButton, Button, Menu, MenuItem } from '@mui/material';
import FlagRounded from '@mui/icons-material/FlagRounded';
import { IComment, ICommentOne } from '../../types/comment';
import { numToCurrency, downloadImages } from '../../utils/Functions';
import PDFGenerator from './PDFGenerator';
import { Flex } from '../../commonStyles';
import { useSelector } from 'react-redux';
import { selectProject } from '../../features/project/projectSlice';
import { selectUser } from '../../features/auth/authSlice';
import { accessAllowed } from '../../features/project/projectHooks';
import { useAppDispatch } from '../../app/hooks';
import { showSuccessSnackbar, showErrorSnackbar } from '../../features/snackbar/snackbarSlice';
import { IUnitDataGridCard, IUnitHistory } from '../../types/unit';
import LoadingWrapper from './LoadingWrapper';
import { handleModal } from '../../features/modal/modalSlice';
import { PriceGridContext } from '../../context/PriceGridContext';

const FloorplanModal = () => {
  const { setModalType, setUnit, unit, draft, units, setUnits } = useContext(PriceGridContext);

  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [latestComment, setLatestComment] = useState<IComment | null>(null);

  const { loading } = useQuery<IUnitDataGridCard>(GETUNIT, {
    variables: { _id: unit._id },
    onCompleted: (data) => {
      setUnit({
        ...unit,
        getUrl: data.unitById.getUrl,
      });
    },
  });

  const [shareFloorplan] = useLazyQuery(SHAREFLOORPLAN, {
    fetchPolicy: 'no-cache',
    variables: { filter: { project: project._id, modelType: unit?.modelType } },
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateUnit] = useMutation(UPDATEUNIT, {
    onCompleted: (data) => {
      let updatedSelectedUnits = units.map((displayedUnit: any) => {
        if (displayedUnit._id === data.unitUpdateById.record._id) {
          return {
            ...displayedUnit,
            flag: data.unitUpdateById.record.flag,
          };
        } else return displayedUnit;
      });

      setUnit(data.unitUpdateById.record);
      setUnits(updatedSelectedUnits);
      storeDispatch(showSuccessSnackbar('Unit Updated!'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [setPrepared] = useMutation(UPDATEUNIT, {
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  useQuery<ICommentOne>(GETCOMMENT, {
    variables: { filter: { unit: unit?._id }, sort: '_ID_DESC' },
    onCompleted: (data) => {
      setLatestComment(data.commentOne);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFlag = (id: string) => {
    updateUnit({
      variables: {
        _id: id,
        record: {
          flag: !unit?.flag,
        },
      },
    });
    setAnchorEl(null);
  };

  // Creating a deal
  const updateStatus = () => {
    setModalType('');
    storeDispatch(handleModal(false));
    if (unit?.status !== 'P') {
      setPrepared({
        variables: {
          _id: unit?._id,
          record: {
            status: 'P',
            history: [
              ...unit.history.map((history: IUnitHistory) => {
                return {
                  ...history,
                  user: history.user ? history.user._id : null,
                };
              }),
              {
                type: 'Status',
                description: `Status has been changed from ${unit?.status} to P`,
                timestamp: new Date(),
                user: user._id,
              },
            ],
          },
        },
      });
    }
    setAnchorEl(null);
  };

  const handleFloorplan = (type: string) => {
    shareFloorplan().then((res) => {
      if (res.data.marketingFloorPlansOne) {
        if (type === 'share') {
          let marketing = [
            {
              ...unit,
              marketingFloorPlan: res.data.marketingFloorPlansOne.pdfGetUrl
                ? res.data.marketingFloorPlansOne.pdfGetUrl
                : res.data.marketingFloorPlansOne.getUrl,
            },
          ];
          downloadImages(marketing, `${project.name} - ${unit?.suite}`, project._id, project.email, project.name);
        } else if (type === 'download') {
          const element = document.createElement('a');
          element.download = res.data.marketingFloorPlansOne.pdfGetUrl
            ? res.data.marketingFloorPlansOne.pdfGetUrl
            : res.data.marketingFloorPlansOne.getUrl;
          element.href = res.data.marketingFloorPlansOne.pdfGetUrl
            ? res.data.marketingFloorPlansOne.pdfGetUrl
            : res.data.marketingFloorPlansOne.getUrl;
          element.target = '_blank';
          element.click();
        }
      } else {
        storeDispatch(showErrorSnackbar('No Marketing Floorplan Uploaded'));
      }
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        paddingTop: '10px',
        paddingBottom: '40px',
        paddingRight: '30px',
        paddingLeft: '30px',
        margin: '0 auto',
        border: unit?.flag ? '3px solid red' : '3px solid black',
        '@media (max-height: 600px)': {
          height: '560px',
        },
        '@media (max-width: 500px)': {
          width: '280px',
          overflowY: 'auto',
        },
      }}
    >
      {!loading ? (
        <>
          <Flex>
            <Box
              sx={{
                margin: '0 auto',
                marginRight: 'auto',
              }}
            >
              <h1>Suite {unit?.suite}</h1>
            </Box>
            <Box
              sx={{
                marginTop: '10px',
              }}
            >
              <IconButton aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
                <FlagRounded color="primary" fontSize="large" />
              </IconButton>
              <Menu id="fade-menu" anchorEl={anchorEl} keepMounted open={open} onClose={() => setAnchorEl(null)}>
                <MenuItem onClick={() => handleFlag(unit?._id!)}>{!unit?.flag ? 'Flag Unit' : 'Unflag Unit'}</MenuItem>
                {!draft ? (
                  <Link to={`/${project._id}/dashboard/${unit?._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <MenuItem onClick={() => storeDispatch(handleModal(false))}>View Unit Details</MenuItem>
                  </Link>
                ) : null}
              </Menu>
            </Box>
          </Flex>
          <Flex
            sx={{
              '@media (max-width: 600px)': {
                flexDirection: 'column',
              },
            }}
          >
            <Flex
              sx={(theme) => ({
                maxHeight: '540px',
                maxWidth: '500px',
              })}
            >
              {unit?.getUrl ? <PDFGenerator merge={unit.getUrl} type={'url'} floorPlan={true} height="100%" /> : null}
            </Flex>
            <Box
              sx={(theme) => ({
                [theme.breakpoints.down('sm')]: {
                  marginLeft: 0,
                  width: 'auto',
                },
                ml: 2,
              })}
            >
              <h3
                style={{
                  margin: '8px 0',
                }}
              >
                Suite Information
              </h3>
              <Box sx={{ my: 1 }}>
                Suite Number: <strong>{unit?.suite}</strong>
              </Box>
              <Box sx={{ my: 1 }}>
                Legal Level: <strong>{unit?.level}</strong>
              </Box>
              <Box sx={{ my: 1 }}>
                Legal Unit: <strong>{unit?.unit}</strong>
              </Box>
              <Box sx={{ my: 1 }}>
                Base Price: <strong>{numToCurrency.format(unit?.basePrice)}</strong>
              </Box>
              <Box sx={{ my: 1 }}>
                Model Type: <strong>{unit?.modelType}</strong>
              </Box>
              <Box sx={{ my: 1 }}>
                Unit Type: <strong>{unit?.unitType}</strong>
              </Box>
              <Box sx={{ my: 1 }}>
                Number of Bathrooms: <strong>{unit?.bathroom}</strong>
              </Box>
              <Box sx={{ my: 1 }}>
                Size: <strong>{unit?.size} sqft</strong>
              </Box>
              <Box sx={{ my: 1 }}>
                Outdoor Size (SF): <strong>{unit?.outdoorSize}</strong>
              </Box>
              <Box sx={{ my: 1 }}>
                Outdoor Type: <strong>{unit?.outdoorType}</strong>
              </Box>
              <Box sx={{ my: 1 }}>
                Price Per Square Foot: &nbsp;<strong>{numToCurrency.format(unit?.basePrice / unit?.size!)}</strong>
              </Box>
              <Box sx={{ my: 1 }}>
                Status: <strong>{unit?.status}</strong>
              </Box>
              <Box sx={{ my: 1 }}>
                Allocation:{' '}
                <strong>{unit?.allocation ? unit?.allocation.fullName : unit?.tempAllocation ? unit?.tempAllocation : 'None'}</strong>
              </Box>

              <div style={{ margin: '10px 0 10px 0' }}>
                {!draft ? (
                  <Button sx={{ mr: 1 }} variant="contained" color="primary" onClick={() => handleFloorplan('share')}>
                    Share
                  </Button>
                ) : null}
                {!draft ? (
                  <Button sx={{ mr: 1 }} variant="contained" color="primary" onClick={() => handleFloorplan('download')}>
                    Marketing
                  </Button>
                ) : null}
                {accessAllowed(user, project?._id!, 'createDeal') && !draft ? (
                  unit?.status !== 'O' &&
                  unit?.status !== 'C' &&
                  unit?.status !== 'F' &&
                  unit?.status !== 'S' &&
                  unit?.status !== 'P' &&
                  unit?.status !== 'UT' ? (
                    <Link
                      to={`/${project._id}/dashboard/create-deal/${unit?._id}/form`}
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      <Button variant="contained" color="primary" onClick={() => updateStatus()}>
                        New Deal
                      </Button>
                    </Link>
                  ) : (
                    <Link to={`/${project._id}/dashboard/${unit?._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                      <Button variant="contained" color="primary">
                        View Active Deal
                      </Button>
                    </Link>
                  )
                ) : null}
              </div>
              {latestComment ? (
                <Box sx={{ my: 1 }}>
                  <div>
                    <em>{latestComment.comment}</em>
                  </div>
                  <Box
                    sx={{
                      marginTop: '20px',
                      textAlign: 'right',
                    }}
                  >
                    - {latestComment.user.fullName}
                  </Box>
                </Box>
              ) : (
                <Box sx={{ my: 1 }}>
                  <em>There are currently no notes for this unit</em>
                </Box>
              )}
            </Box>
          </Flex>
        </>
      ) : (
        <Box sx={{ width: '600px' }}>
          <LoadingWrapper title="Loading..." modal={false} />
        </Box>
      )}
    </Box>
  );
};

const GETUNIT = gql`
  query unitById($_id: MongoID!) {
    unitById(_id: $_id) {
      suite
      getUrl
    }
  }
`;

const UPDATEUNIT = gql`
  mutation unitUpdateById($_id: MongoID!, $record: UpdateByIdUnitInput!) {
    unitUpdateById(_id: $_id, record: $record) {
      record {
        _id
        suite
        modelType
        unitType
        bathroom
        size
        outdoorType
        basePrice
        level
        exposure
        outdoorSize
        col
        row
        status
        getUrl
        flag
        tempAllocation
        history {
          type
          description
          timestamp
          user {
            _id
            fullName
          }
          _id
        }
        stackRow
        worksheets {
          _id
        }
      }
    }
  }
`;

const GETCOMMENT = gql`
  query commentOne($filter: FilterFindOneCommentInput, $sort: SortFindOneCommentInput) {
    commentOne(filter: $filter, sort: $sort) {
      _id
      user {
        _id
        fullName
      }
      comment
      createdAt
      updatedAt
    }
  }
`;

const SHAREFLOORPLAN = gql`
  query marketingFloorPlansOne($filter: FilterFindOnemarketingFloorPlansInput) {
    marketingFloorPlansOne(filter: $filter) {
      _id
      getUrl
      pdfGetUrl
      project {
        _id
      }
      modelType
      size
    }
  }
`;

export default withRouter(FloorplanModal);
