import React from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ICoopRate } from '../../types/project';
import TextInput from '../common/formControls/TextInput';
import { coopTypes } from '../../utils/Constants';
import { DeleteForever } from '@mui/icons-material';

const Coop = (props: ChildProps) => {
  const { handleCoopType, handleCoopInput, handleCoopDate, handleDeleteCoop, coopRate, index, structureIndex } = props;

  return (
    <>
      <Grid item xs={12} sm={2}>
        <FormControl fullWidth>
          <InputLabel id="id-coop-type-label">Coop Type</InputLabel>
          <Select
            required
            value={coopRate.type}
            labelId="id-coop-type-label"
            id="id-coop-type"
            label="Coop Type"
            name="type"
            onChange={(e: any) => handleCoopType(e, index, 'text', structureIndex)}
          >
            {coopTypes.map((type: string, index: number) => {
              return (
                <MenuItem key={index} value={type}>
                  {type}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextInput
          name={'description'}
          title={'Description'}
          label={'Description'}
          handleTextInput={(e: any) => handleCoopInput(e, index, 'text', structureIndex)}
          value={coopRate.description}
          required={true}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextInput
          name={'days'}
          type={'number'}
          title={'Number of Days'}
          label={'Number of Days'}
          handleTextInput={(e: any) => handleCoopInput(e, index, 'number', structureIndex)}
          value={coopRate.days}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={'Date'}
            value={coopRate.date ? coopRate.date : null}
            onChange={(newValue) => handleCoopDate(newValue, index, structureIndex)}
            sx={{ width: '100%'}}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextInput
          name={'fixedAmount'}
          type={'number'}
          title={'Fixed Amount'}
          label={'name'}
          adornment={'$'}
          handleTextInput={(e: any) => handleCoopInput(e, index, 'number', structureIndex)}
          value={coopRate.fixedAmount}
        />
      </Grid>
      <Grid item xs={12} sm={1}>
        <TextInput
          name={'percentage'}
          type={'number'}
          title={'Percentage'}
          label={'name'}
          adornment={'%'}
          handleTextInput={(e: any) => handleCoopInput(e, index, 'number', structureIndex)}
          value={coopRate.percentage}
          required={true}
        />
      </Grid>
      <Grid item xs={12} sm={1} md={1} lg={1} xl={1} sx={{ cursor: 'pointer', textAlign: 'center', alignSelf: 'center' }}>
        <DeleteForever color="error" onClick={() => handleDeleteCoop(index, structureIndex)} />
      </Grid>
    </>
  );
};

interface ChildProps {
  coopRate: ICoopRate;
  handleDeleteCoop: any;
  handleCoopInput: any;
  handleCoopType: any;
  handleCoopName?: any;
  handleCoopDate: any;
  index: number;
  structureIndex?: number;
}

export default Coop;
