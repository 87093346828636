import React, { useState, useContext, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { addDays, addYears } from 'date-fns';
import { Box, Grid, Button, Checkbox, FormControlLabel } from '@mui/material';
import PageTitle from '../../common/PageTitle';
import { IOption, IDeposits, IDepositStructures } from '../../../types/project';
import { IDealOption, IDeal, IDealArray } from '../../../types/CreateDealForm';
import { CreateDealContext } from '../../../context/CreateDealContext';
import DepositStructure from '../DepositStructure';
import TextInput from '../../common/formControls/TextInput';
import Dropdown from '../../common/formControls/Dropdown';
import { optionsLeft, numToCurrency } from '../../../utils/Functions';
import { FlexBetween } from '../../../commonStyles';
import { useSelector } from 'react-redux';
import { selectProject } from '../../../features/project/projectSlice';
import { handleModal } from '../../../features/modal/modalSlice';
import { useAppDispatch } from '../../../app/hooks';
import { GlobalModal } from '../../../features/modal/Modal';

const SuiteStep = () => {
  const {
    suiteInfo,
    setSuiteInfo,
    activeStep,
    setActiveStep,
    selectedUnit,
    depositType,
    setDepositType,
    selectedDeposit,
    setSelectedDeposit,
    rental,
    setRental,
  } = useContext(CreateDealContext);
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const [totalPrice, setTotalPrice] = useState<number>(selectedUnit.basePrice);
  const [deals, setDeals] = useState<IDeal[]>([]);
  const [excludeOptions, setExcludeOptions] = useState<boolean>(false);

  // Dynamic data that will be changed when creating a project and determining how much parking/locker is allowed //

  useQuery<IDealArray>(GETDEALS, {
    variables: { filter: { project: project._id } },
    onCompleted: (data) => {
      setDeals(data.dealMany);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  useEffect(() => {
    initialDeposit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getDepositStructure();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDeposit, totalPrice, excludeOptions]);

  useEffect(() => {
    getTotalAmount();
    if (selectedUnit.hasOwnProperty('rental') && selectedUnit.rental !== null) {
      setRental(
        suiteInfo.options.reduce((a: any, b: any) => {
          return a + b.rental;
        }, selectedUnit.rental)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suiteInfo.options]);

  const getTotalAmount = async () => {
    if (suiteInfo.options.length >= 1) {
      let total = await suiteInfo.options.reduce((a: IOption, b: any) => {
        return a + b.amount;
      }, 0);
      setTotalPrice(selectedUnit.basePrice + total);
    } else if (suiteInfo.options.length === 0) {
      setTotalPrice(selectedUnit.basePrice);
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    let dealDeposit = await depositType.map((deposit: IDeposits, index: number) => {
      let date: any = new Date();
      if (deposit.dateType === 'Number of Days Due') {
        date = addDays(index && depositType[0].dueDate ? new Date(depositType[0].dueDate) : new Date(), deposit.daysDue);
      } else if (deposit.dateType === 'Specific Date') {
        date = deposit.dueDate;
      } else if (deposit.dateType === 'Floating Date') {
        date = selectedUnit.firmOccupancy
          ? selectedUnit.firmOccupancy
          : project.firmOccupancy
          ? project.firmOccupancy
          : addYears(new Date(), 5);
      } else if (deposit.dateType === 'Firm Occupancy') {
        date = selectedUnit.firmOccupancy
          ? selectedUnit.firmOccupancy
          : project.firmOccupancy
          ? project.firmOccupancy
          : addYears(new Date(), 5);
      } else if (deposit.dateType === 'Final Tentative Occupancy') {
        date = selectedUnit.finalTentativeOccupancy
          ? selectedUnit.finalTentativeOccupancy
          : project.finalTentativeOccupancy
          ? project.finalTentativeOccupancy
          : addYears(new Date(), 5);
      }
      return {
        name: deposit.name,
        amount: deposit.amount,
        dueDate: date,
        accountNumber: '',
        chequeDate: null,
        dateType: deposit.dateType,
      };
    });

    setSuiteInfo({ ...suiteInfo, deposit: dealDeposit });
    setActiveStep(activeStep + 1);
  };

  const handleTextInput = (event: any, numIndex: number, type: string) => {
    let depositArray = depositType.map((depositStructure: IDeposits, index: number) => {
      if (numIndex === index) {
        if (event.target.name === 'amount') {
          return {
            ...depositStructure,
            [event.target.name]: parseFloat(event.target.value),
            percentage: ((event.target.value / totalPrice) * 100).toFixed(2),
          };
        } else if (event.target.name === 'percentage') {
          return {
            ...depositStructure,
            [event.target.name]: event.target.value,
            amount: Math.ceil((totalPrice * event.target.value) / 100),
          };
        } else {
          return { ...depositStructure, [event.target.name]: event.target.value };
        }
      } else {
        return depositStructure;
      }
    });
    setDepositType(depositArray);
  };

  const handleDropdownInput = (event: React.ChangeEvent<{ name?: string; value: unknown }>, numIndex: number) => {
    let name = event.target.name as keyof typeof suiteInfo.deposit;
    let depositArray = depositType.map((depositStructure: IDepositStructures, index: number) => {
      if (numIndex === index) {
        if (event.target.value === 'Balance to 1.5%') {
          return { ...depositStructure, [name]: event.target.value, amount: Math.ceil(totalPrice * 0.015 - 10000) };
        } else if (event.target.value === 'Balance to 2.5%') {
          return { ...depositStructure, [name]: event.target.value, amount: Math.ceil(totalPrice * 0.025 - 10000) };
        } else if (event.target.value === 'Balance to 3%') {
          return { ...depositStructure, [name]: event.target.value, amount: Math.ceil(totalPrice * 0.03 - 10000) };
        } else if (event.target.value === 'Balance to 5%') {
          return { ...depositStructure, [name]: event.target.value, amount: Math.ceil(totalPrice * 0.05 - 10000) };
        } else if (event.target.value === 'Balance to 7.5%') {
          return { ...depositStructure, [name]: event.target.value, amount: Math.ceil(totalPrice * 0.075 - 10000) };
        } else if (event.target.value === 'Balance to 10%') {
          return { ...depositStructure, [name]: event.target.value, amount: Math.ceil(totalPrice * 0.1 - 10000) };
        } else if (event.target.value === 'Balance to 15%') {
          return { ...depositStructure, [name]: event.target.value, amount: Math.ceil(totalPrice * 0.15 - 10000) };
        } else return { ...depositStructure, [name]: event.target.value };
      } else {
        return depositStructure;
      }
    });
    setDepositType(depositArray);
  };

  const handleDelete = (numIndex: number) => {
    let depositArray = depositType.filter((depositStructure: IDepositStructures, index: number) => numIndex !== index);
    setDepositType(depositArray);
  };

  const handleOptionDropdown = (event: any, numIndex: number) => {
    let filteredOption = project.options.filter((option: IOption, index: number) => option.name === event.target.name);
    if (!filteredOption[0].allowedUnits.includes(selectedUnit.unitType) && parseInt(event.target.value, 10) !== 0) {
      storeDispatch(handleModal(true));
    }
    if (suiteInfo.options.some((option: IOption) => option.name === event.target.name)) {
      if (event.target.value === 0) {
        let removeOption = suiteInfo.options.filter((data: IDealOption, index: number) => data.name !== event.target.name);
        setSuiteInfo({
          ...suiteInfo,
          options: removeOption,
        });
      } else {
        let optionsArray = suiteInfo.options.map((option: IOption, index: number) => {
          if (option.name === event.target.name) {
            return {
              ...option,
              purchaseAmount: parseFloat(event.target.value),
              amount: event.target.value * filteredOption[0].price,
              rental: filteredOption[0].rental ? event.target.value * filteredOption[0].rental : 0,
            };
          } else return option;
        });
        setSuiteInfo({
          ...suiteInfo,
          options: optionsArray,
        });
      }
    } else {
      if (project.options) {
        setSuiteInfo({
          ...suiteInfo,
          options: [
            ...suiteInfo.options,
            {
              name: event.target.name,
              purchaseAmount: parseFloat(event.target.value),
              amount: event.target.value * filteredOption[0].price,
              rental: filteredOption[0].rental ? event.target.value * filteredOption[0].rental : 0,
            },
          ],
        });
      } else return;
    }
  };

  const handleOptionInput = (event: any, optionName: string) => {
    let optionsArray = suiteInfo.options.map((option: IOption, index: number) => {
      if (option.name === optionName) {
        return {
          ...option,
          amount: parseFloat(event.target.value),
        };
      } else {
        return option;
      }
    });
    setSuiteInfo({
      ...suiteInfo,
      options: optionsArray,
    });
  };

  const optionValue = (name: string, type: string) => {
    let filteredOption = suiteInfo.options.filter((option: IOption, index: number) => option.name === name);
    if (filteredOption.length > 0) {
      return filteredOption[0][type];
    } else {
      return 0;
    }
  };

  const handleDateChange = (newValue: any, numIndex: number, structureIndex = null, type: string) => {
    let depositArray = depositType.map((deposit: IDeposits, index: number) => {
      if (structureIndex === index) {
        return { ...deposit, [type]: newValue };
      } else {
        return deposit;
      }
    });
    setDepositType(depositArray);
  };

  const depositList = () => {
    let depositNames = project.depositStructures.map((deposit: any, index: number) => {
      return deposit.name;
    });
    return depositNames;
  };

  const initialDeposit = () => {
    let selectedDeposit = project.depositStructures.filter((depositStructure: IDepositStructures, index: number) => {
      return depositStructure.default === true;
    });
    setSelectedDeposit(selectedDeposit[0].name);
  };

  const displaySelectedDeposit = () => {
    if (depositType.length > 0) {
      return (
        <DepositStructure
          type={'deal'}
          deposit={depositType}
          handleTextInput={handleTextInput}
          handleDropdownInput={handleDropdownInput}
          handleDateChange={handleDateChange}
          handleDelete={handleDelete}
          project={project}
          unit={selectedUnit}
        />
      );
    } else {
      return null;
    }
  };

  const getDepositStructure = () => {
    if (selectedDeposit) {
      let selectedDepositStructure: any;
      selectedDepositStructure = project.depositStructures.find(
        (depositStructure: IDepositStructures) => selectedDeposit === depositStructure.name
      );
      selectedDepositStructure = selectedDepositStructure.deposits;

      let depositData = selectedDepositStructure.map((data: IDeposits, index: number) => {
        let dealTotalPrice = totalPrice;
        if (excludeOptions) {
          dealTotalPrice = selectedUnit.basePrice;
        }
        const balanceFunction = (index: number) => {
          let sum = 0;
          for (let i = 0; i < index; i++) {
            if (selectedDepositStructure[i].type === 'Fixed') {
              sum += selectedDepositStructure[i].amount;
            }
          }
          return sum;
        };

        if (data.dateType === 'Firm Occupancy') {
          let dueDate = new Date();
          if (selectedUnit && selectedUnit.firmOccupancy) {
            dueDate = selectedUnit.firmOccupancy ? new Date(selectedUnit.firmOccupancy) : new Date(addYears(new Date(), 5));
          } else if (project && project.firmOccupancy) {
            dueDate = project.firmOccupancy ? new Date(project.firmOccupancy) : new Date(addYears(new Date(), 5));
          }
          return {
            ...data,
            percentage: `${data.amount}`,
            amount:
              depositType.length > 0 ? Math.ceil(dealTotalPrice * (data?.amount! / 100)) : Math.ceil((dealTotalPrice * data.amount) / 100),
            dueDate: dueDate,
          };
        }
        if (data.dateType === 'Final Tentative Occupancy') {
          let dueDate = new Date();
          if (selectedUnit && selectedUnit.finalTentativeOccupancy) {
            dueDate = selectedUnit.finalTentativeOccupancy
              ? new Date(selectedUnit.finalTentativeOccupancy)
              : new Date(addYears(new Date(), 5));
          } else if (project && project.finalTentativeOccupancy) {
            dueDate = project.finalTentativeOccupancy ? new Date(project.finalTentativeOccupancy) : new Date(addYears(new Date(), 5));
          }

          return {
            ...data,
            percentage: `${data.amount}`,
            amount:
              depositType.length > 0 ? Math.ceil(dealTotalPrice * (data?.amount! / 100)) : Math.ceil((dealTotalPrice * data.amount) / 100),
            dueDate: dueDate,
          };
        }

        if (data.type === 'Fixed' && data.dateType === 'Purchaser Signing Date') {
          return {
            ...data,
            dueDate: new Date(),
            percentage: ((data.amount / dealTotalPrice) * 100).toFixed(2),
          };
        } else if (data.type === 'Fixed') {
          return {
            ...data,
            percentage: ((data.amount / dealTotalPrice) * 100).toFixed(2),
          };
        } else if (data.type === 'Balance to 1.5%') {
          return {
            ...data,
            percentage: (((Math.round(dealTotalPrice * 0.015) - balanceFunction(index)) / dealTotalPrice) * 100).toFixed(2),
            amount: Math.ceil(dealTotalPrice * 0.015 - balanceFunction(index)),
          };
        } else if (data.type === 'Balance to 2.5%') {
          return {
            ...data,
            percentage: (((Math.round(dealTotalPrice * 0.025) - balanceFunction(index)) / dealTotalPrice) * 100).toFixed(2),
            amount: Math.ceil(dealTotalPrice * 0.025 - balanceFunction(index)),
          };
        } else if (data.type === 'Balance to 3%') {
          return {
            ...data,
            percentage: (((Math.round(dealTotalPrice * 0.03) - balanceFunction(index)) / dealTotalPrice) * 100).toFixed(2),
            amount: Math.ceil(dealTotalPrice * 0.03 - balanceFunction(index)),
          };
        } else if (data.type === 'Balance to 5%') {
          return {
            ...data,
            percentage: (((Math.round(dealTotalPrice * 0.05) - balanceFunction(index)) / dealTotalPrice) * 100).toFixed(2),
            amount: Math.ceil(dealTotalPrice * 0.05 - balanceFunction(index)),
          };
        } else if (data.type === 'Balance to 7.5%') {
          return {
            ...data,
            percentage: (((Math.round(dealTotalPrice * 0.075) - balanceFunction(index)) / dealTotalPrice) * 100).toFixed(2),
            amount: Math.ceil(dealTotalPrice * 0.075 - balanceFunction(index)),
          };
        } else if (data.type === 'Balance to 10%') {
          return {
            ...data,
            percentage: ((Math.round(dealTotalPrice * 0.1 - balanceFunction(index)) / dealTotalPrice) * 100).toFixed(2),
            amount: Math.ceil(dealTotalPrice * 0.1 - balanceFunction(index)),
          };
        } else if (data.type === 'Balance to 15%') {
          return {
            ...data,
            percentage: ((Math.round(dealTotalPrice * 0.15 - balanceFunction(index)) / dealTotalPrice) * 100).toFixed(2),
            amount: Math.ceil(dealTotalPrice * 0.15 - balanceFunction(index)),
          };
        } else if (data.type === 'Balance to 10% Monthly') {
          return {
            ...data,
            percentage: ((Math.round(dealTotalPrice * 0.15 - balanceFunction(index)) / dealTotalPrice) * 100).toFixed(2),
            amount: Math.ceil(dealTotalPrice * 0.15 - balanceFunction(index)),
          };
        } else if (data.type === 'Balance to 15% Monthly') {
          return {
            ...data,
            percentage: ((Math.round(dealTotalPrice * 0.15 - balanceFunction(index)) / dealTotalPrice) * 100).toFixed(2),
            amount: Math.ceil(dealTotalPrice * 0.15 - balanceFunction(index)),
          };
        } else if (data.type === 'Balance to 15% Monthly (36 Months)') {
          return {
            ...data,
            percentage: ((Math.round(dealTotalPrice * 0.15 - balanceFunction(index)) / dealTotalPrice) * 100).toFixed(2),
            amount: Math.ceil(dealTotalPrice * 0.15 - balanceFunction(index)),
          };
        } else {
          return {
            ...data,
            percentage: `${data.amount}`,
            amount:
              depositType.length > 0 ? Math.ceil(dealTotalPrice * (data?.amount! / 100)) : Math.ceil((dealTotalPrice * data.amount) / 100),
          };
        }
      });
      setDepositType(depositData);
    } else return;
  };

  const addDeposit = () => {
    let addDeposit = [
      ...depositType,
      {
        name: '', // 1st Deposit, 2nd Deposit etc
        type: 'Fixed', // Fixed or Percent
        amount: 0, // 5,000 or 2.5%
        daysDue: 0, // 30 days
        dateType: 'Number of Days Due',
        dueDate: new Date(), // Specific Date
      },
    ];
    setDepositType(addDeposit);
  };

  const rentalCalculation = () => {
    if (suiteInfo.options.length) {
      let totalRental = suiteInfo.options.reduce((a: any, b: any) => {
        return a + b.rental;
      }, 0);
      return totalRental;
    } else return 0;
  };

  return (
    <div>
      <GlobalModal>
        <strong>WARNING: Please note that this option is not elgible for this unit type.</strong>
      </GlobalModal>
      <PageTitle title={'Suite Info'} />
      <Grid container spacing={2}>
        {project.options.map((option: IOption, index: number) => {
          return (
            <React.Fragment key={index}>
              <Grid
                sx={{
                  alignSelf: 'center',
                  fontSize: '20px',
                }}
                item
                xs={12}
                sm={3}
              >
                <div>
                  <strong>{option.name}</strong>
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Dropdown
                  title={`Number of ${option.name} (${option.totalAvailable - optionsLeft(deals, option.name)} / ${option.totalAvailable})`}
                  id={'purchaseAmount'}
                  menuList={['0', '1', '2', '3']}
                  name={`${option.name}`}
                  handleSelect={(e: any) => handleOptionDropdown(e, index)}
                  value={optionValue(option.name, 'purchaseAmount')}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextInput
                  title={`${option.name} Total Amount`}
                  type="number"
                  id={'totalAmount'}
                  handleTextInput={(e: any) => handleOptionInput(e, option.name)}
                  value={optionValue(option.name, 'amount')}
                  min={'0'}
                  adornment={'$'}
                  required={false}
                />
              </Grid>
            </React.Fragment>
          );
        })}
        {selectedUnit.hasOwnProperty('rental') && selectedUnit.rental !== null ? (
          <>
            <Grid
              sx={{
                alignSelf: 'center',
                fontSize: '20px',
              }}
              item
              xs={12}
              sm={3}
            >
              <div>
                <strong>Rental</strong>
              </div>
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextInput
                title={`Total Rental Guarantee Amount`}
                type="number"
                id={'rental'}
                handleTextInput={(e: any) => {
                  setRental(e.target.value);
                }}
                helperText={
                  rental &&
                  suiteInfo.options.length &&
                  rental ===
                    suiteInfo.options.reduce((a: any, b: any) => {
                      return a + b.rental;
                    }, selectedUnit.rental)
                    ? `This Rental Guarantee has increase by ${numToCurrency.format(rentalCalculation())}`
                    : ''
                }
                value={rental}
                min={'0'}
                adornment={'$'}
                required={false}
              />
            </Grid>
          </>
        ) : null}
      </Grid>
      <Box sx={{ mt: 2 }}>
        <FormControlLabel
          control={<Checkbox color="primary" checked={excludeOptions} onClick={() => setExcludeOptions(!excludeOptions)} />}
          label={'Added options will not modify deposit structure'}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <h2>Deposit Info</h2>
        <form onSubmit={handleSubmit} autoComplete="off">
          <Dropdown
            title={'Deposit Type'}
            id={'purchaseAmount'}
            menuList={depositList()}
            name={'depositType'}
            handleSelect={(event: any) => setSelectedDeposit(event.target.value)}
            value={selectedDeposit}
          />
          {displaySelectedDeposit()}
          <Button onClick={() => addDeposit()} style={{ marginRight: '10px' }} sx={{ mt: 2 }} variant="contained" color="primary">
            Add Deposit
          </Button>
          <Button onClick={() => getDepositStructure()} sx={{ mt: 2 }} variant="contained" color="primary">
            Reset to Default
          </Button>
          <FlexBetween sx={{ mt: 2 }}>
            <Button variant="contained" color="primary" onClick={() => setActiveStep(activeStep - 1)}>
              Back
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Next
            </Button>
          </FlexBetween>
        </form>
      </Box>
    </div>
  );
};

const GETDEALS = gql`
  query dealMany($filter: FilterFindManyDealInput) {
    dealMany(filter: $filter, limit: 100000) {
      _id
      options {
        name
        purchaseAmount
        amount
      }
      unit {
        status
      }
      draft
      cancelled {
        dateCancelled
      }
      rescission {
        dateRescinded
      }
    }
  }
`;

export default SuiteStep;
