import React, { useMemo } from 'react';
import { Box, Paper } from '@mui/material';

import { numToCurrency } from '../../utils/Functions';
import StandardTable from '../tables/StandardTable';

const AdjustmentTable = (props: ChildProps) => {
  const { data, download, loading } = props;

  const columns = useMemo(() => {
    return [
      {
        Header: 'Adjustment',
        accessor: (rowData: any) => {
          return (
            <Box sx={{ cursor: 'pointer', fontWeight: rowData.name === 'Total' ? 'bold' : 'normal' }}>
              <strong>{rowData.name}</strong>
            </Box>
          );
        },
      },
      {
        Header: 'Type',
        accessor: (rowData: any) => {
          return <Box sx={{ fontWeight: rowData.name === 'Total' ? 'bold' : 'normal' }}>{rowData.type}</Box>;
        },
      },
      {
        Header: 'Amount',
        accessor: (rowData: any) => {
          return <Box sx={{ fontWeight: rowData.name === 'Total' ? 'bold' : 'normal' }}>{numToCurrency.format(rowData.amount)}</Box>;
        },
      },
    ];
  }, []);

  return (
    <Paper elevation={5}>
      <StandardTable data={data} columns={columns} download={download} loading={loading} />
    </Paper>
  );
};

interface ChildProps {
  openModal: any;
  data: any;
  unitType: string;
  download: any;
  loading: boolean;
}

export default AdjustmentTable;
