import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Buffer } from 'buffer';
import _ from 'lodash';
import Dropzone from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { gql, useQuery, useMutation, useLazyQuery, useSubscription } from '@apollo/client';
import {
  Box,
  CircularProgress,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Grid,
  Button,
  List,
  ListItem,
  IconButton,
  ListItemText,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { selectProject } from '../../../features/project/projectSlice';
import { selectUser } from '../../../features/auth/authSlice';
import { useAppDispatch } from '../../../app/hooks';
import { showSuccessSnackbar, showErrorSnackbar } from '../../../features/snackbar/snackbarSlice';
import { accessAllowed } from '../../../features/project/projectHooks';
import { useCreateActivity } from '../../../features/activity/activityHooks';
import { UnitContext } from '../../../context/UnitContext';
import TextInput from '../../common/formControls/TextInput';
import Dropdown from '../../common/formControls/Dropdown';
import { IDocuments, ISigner, IEnvelopeData } from '../../../types/docusign';
import { mergeType } from '../../../utils/Constants';
import { IMerge, IMergeData } from '../../../types/merge';
import { IExecutor } from '../../../types/project';
import { capitalizeFirstLetter, createPdf, convertAllDates, setBackgroundColor, getMergeValues } from '../../../utils/Functions';
import CustomDialog from '../../common/CustomDialog';
import LoadingWrapper from '../../common/LoadingWrapper';
import PdfCard from '../../common/PdfCard';
import { FlexBetween, FlexEnd } from '../../../commonStyles';
import { LeaseContext } from '../../../context/LeaseContext';
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from '../../../css/dropzone';

const Documents = () => {
  const { unit, filteredDeal } = useContext(UnitContext);
  const { lease, setLease, updateLease } = useContext(LeaseContext);
  const createActivity = useCreateActivity();
  const storeDispatch = useAppDispatch();
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);

  const [mergeTemplates, setMergeTemplates] = useState<IMerge[]>([]);
  const [executor, setExecutor] = useState<IExecutor | null>(project.executors ? project.executors[0] : null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogType, setDialogType] = useState<string>('');
  const [selectedDocument, setSelectedDocument] = useState<IDocuments | null>(null);
  const [selectedMerge, setSelectedMerge] = useState<IMerge[]>([]);
  const [fileImage, setFileImage] = useState<any>('');
  const [file, setFile] = useState<any>(null);
  // Upload
  const [title, setTitle] = useState<string>('');
  const [documentType, setDocumentType] = useState<string>('');

  const { isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/jpeg, image/png',
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  useQuery<IMergeData>(GETMERGE, {
    variables: {
      filter: {
        OR: [{ type: 'Tenancy' }, { type: 'Management' }, { type: 'Listing' }],
      },
    },
    onCompleted: (data) => {
      setMergeTemplates(data.mergeTemplateMany);
    },
  });

  const [deleteDocument, { loading: deleteDocumentLoading }] = useMutation(DELETEDOCUMENT, {
    onCompleted: (data) => {
      let removedDocument = lease.documents.filter((document: IDocuments) => {
        return data.documentDeleteById._id !== document._id;
      });
      setLease({
        ...lease,
        documents: removedDocument,
      });
      updateLease({
        variables: {
          _id: lease._id,
          record: {
            documents: removedDocument.map((document: IDocuments) => document._id),
          },
        },
      });
      storeDispatch(showSuccessSnackbar(`Document has been deleted/voided!`));
      createActivity({
        project: project._id,
        user: user._id,
        deal: null,
        title: `Lease Unit ${unit.suite} Delete Document`,
        content: `Document ${data.documentDeleteById.name} has been deleted and void. The status was ${data.documentDeleteById.status}`,
      });
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [createDocument, { loading: createDocumentLoading }] = useMutation(CREATEDOCUMENT, {
    onCompleted: (data) => {
      setLease({
        ...lease,
        documents: [...lease.documents, data.documentCreateOne],
      });
      let documents = lease.documents;
      if (lease.documents.length) {
        documents = [...lease.documents.map((document: IDocuments) => document._id), data.documentCreateOne._id];
      } else {
        documents = [data.documentCreateOne._id];
      }
      updateLease({
        variables: {
          _id: lease._id,
          record: {
            documents,
          },
        },
      });
      setSelectedMerge([]);
      storeDispatch(showSuccessSnackbar(`Document has been created!`));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [createMultipleDocument, { loading: createMultipleDocumentLoading }] = useMutation(CREATEMULTIPLEDOCUMENT, {
    onCompleted: (data) => {
      setDialogType('');
      setDialogOpen(false);
      storeDispatch(showSuccessSnackbar(`Document has been created!`));
      setLease({
        ...lease,
        documents: [...lease.documents, data.createEnvelopeMultipleDocuments],
      });
      let documents = lease.documents;
      if (lease.documents.length) {
        documents = [...lease.documents.map((document: IDocuments) => document._id), data.documentCreateOne._id];
      } else {
        documents = [data.documentCreateOne._id];
      }
      updateLease({
        variables: { _id: lease._id, record: { documents: [...documents, data.createEnvelopeMultipleDocuments._id] } },
      });
      setTitle('');
      setDocumentType('');
      setFileImage(null);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [sendDocument, { loading: sendDocumentLoading }] = useMutation(SENDDOCUMENT, {
    onCompleted: (data) => {
      const documentsClone = [...lease.documents];
      const updatedDocument = documentsClone.find((doc) => doc._id === data.documentCreateEnvelope._id);
      updatedDocument.status = data.documentCreateEnvelope.status;
      updatedDocument.dsEnvelopeId = data.documentCreateEnvelope.dsEnvelopeId;
      setLease({
        ...lease,
        documents: documentsClone,
      });
      createActivity({
        project: project._id,
        user: user._id,
        deal: null,
        title: `Lease Unit ${unit.suite} Document Sent!`,
        content: `Document ${data.documentCreateEnvelope.name} has been sent!`,
      });
      storeDispatch(showSuccessSnackbar(`Document has been sent!`));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [getPreviewData, { loading: previewLoading }] = useLazyQuery(GETPREVIEWDATA, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const binaryData = Buffer.from(data.documentById.previewData, 'binary');
      const blob = new Blob([binaryData], { type: 'application/pdf' });
      const previewUrl = URL.createObjectURL(blob);

      const element = document.createElement('a');
      element.href = previewUrl;
      element.target = '_blank';
      element.click();
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [getEnvelopeData, { loading: envelopeLoading }] = useLazyQuery(GETENVELOPEDATA, {
    onCompleted: (data) => {
      let envelopeArray = lease.documents.map((documents: IDocuments) => {
        if (documents._id === data.documentById._id) {
          return {
            ...documents,
            envelopeData: data.documentById.envelopeData,
          };
        } else return documents;
      });
      setLease({
        ...lease,
        documents: envelopeArray,
      });
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [getSigners, { loading: getSignersLoading }] = useLazyQuery(GETSIGNERS, {
    onCompleted: (data) => {
      let signerArray = lease.documents.map((documents: IDocuments) => {
        if (documents._id === data.documentById._id) {
          return {
            ...documents,
            signers: data.documentById.signers,
          };
        } else return documents;
      });
      setLease({
        ...lease,
        documents: signerArray,
      });
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [resendEnvelope, { loading: resendLoading }] = useLazyQuery(RESENDENVELOPE, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar(`${data.resendEnvelope}`));
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar(`${err}`));
      console.log(err, 'err');
    },
  });

  const [sendDocumentToExecutor, { loading: sendingDocumentToExecutor }] = useMutation(SENDDOCUMENTTOEXECUTOR, {
    onCompleted: (data) => {
      const documentsClone = _.cloneDeep(lease.documents);
      const updatedDocument = documentsClone.find((doc: any) => doc._id === data.documentSendToExecutor._id);
      updatedDocument.status = data.documentSendToExecutor.status;
      createActivity({
        project: project._id,
        user: user._id,
        deal: null,
        title: `Lease Unit ${unit.suite} Document Sent To Executor`,
        content: `Document ${data.documentSendToExecutor.name} has been sent to the Executor ${executor?.name}`,
      });
      storeDispatch(showSuccessSnackbar(`Document has been sent to Executor ${executor?.name}`));
      setExecutor(null);
      setLease({
        ...lease,
        documents: documentsClone,
      });
      setDialogOpen(false);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  ////////////////////////

  const handleDrop = (acceptedFiles: any) => {
    const file = acceptedFiles[0];
    const fileReader = new FileReader();
    if (file) {
      fileReader.readAsDataURL(file);
    }
    fileReader.onloadend = async () => {
      setFileImage(fileReader.result);
      setFile(acceptedFiles[0]);
    };
  };

  const messageSigners = (dsEnvelopeId: string, documentId: string, signers: ISigner[]) => {
    const signerStatus = (signer: string) => {
      if (signer === 'completed') {
        return <strong>Signed</strong>;
      } else if (signer === 'autoresponded') {
        return <strong>The email could not be delivered to the recipient</strong>;
      } else return <strong>Awaiting Signature</strong>;
    };

    if (dsEnvelopeId) {
      if (signers) {
        return signers.map((signer: ISigner, index: number) => {
          return (
            <div key={index}>
              <div>
                {capitalizeFirstLetter(signer.type)} {signer.name} - {signerStatus(signer.status)}
              </div>
            </div>
          );
        });
      }
    }
  };

  const messageEnvelope = (dsEnvelopeId: string, documentId: string, envelopeData: IEnvelopeData) => {
    if (dsEnvelopeId) {
      if (envelopeData) {
        return (
          <div>
            <div>
              Initial Sent Date: <strong>{convertAllDates(envelopeData.initialSentDateTime, 'PPpp')}</strong>
            </div>
            <div>
              Recent Sent Date: <strong>{convertAllDates(envelopeData.sentDateTime, 'PPpp')}</strong>
            </div>
            <div>
              Created Date: <strong>{convertAllDates(envelopeData.createdDateTime, 'PPpp')}</strong>
            </div>
            <div>
              Last Modified Date: <strong>{convertAllDates(envelopeData.lastModifiedDateTime, 'PPpp')}</strong>
            </div>
            <div>
              Delivered Date:{' '}
              <strong>{envelopeData.deliveredDateTime ? convertAllDates(envelopeData.deliveredDateTime, 'PPpp') : 'N/A'}</strong>
            </div>
            <div>
              Status Change Date: <strong>{convertAllDates(envelopeData.statusChangedDateTime, 'PPpp')}</strong>
            </div>
            <div>
              Completed Date:{' '}
              <strong>{envelopeData.completedDateTime ? convertAllDates(envelopeData.completedDateTime, 'PPpp') : 'N/A'}</strong>
            </div>
          </div>
        );
      }
    }
  };

  const previewDocument = (doc: any) => {
    getPreviewData({
      variables: {
        _id: doc._id,
      },
    });
  };

  const envelopeData = (doc: any) => {
    getEnvelopeData({
      variables: {
        _id: doc._id,
      },
    });
  };

  const resend = (doc: any) => {
    resendEnvelope({
      variables: {
        _id: doc._id,
      },
    });
  };

  const sendToExecutor = () => {
    sendDocumentToExecutor({
      variables: {
        documentId: selectedDocument!._id,
        executor,
      },
    });
    setDialogOpen(false);
  };

  const handleAgreementType = () => {};

  const executorList = () => {
    let executorNames = project.executors.map((executor: IExecutor, index: number) => {
      return executor.name;
    });
    return executorNames;
  };

  const handleExecutorDropdown = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
    let executor = project?.executors.find((executor: IExecutor) => executor.name === event.target.value);
    setExecutor(executor!);
  };

  const openDialog = async (type: string, document: IDocuments | null = null) => {
    if (type === 'execute' || type === 'sendEnvelope' || type === 'deleteDocument') {
      setSelectedDocument(document);
    }
    await setDialogType(type);
    await setDialogOpen(true);
  };

  const accordianContent = (document: IDocuments) => {
    if (document.status !== 'Prepared') {
      return (
        <Box
          sx={{
            mb: 2,
            width: '100%',
          }}
        >
          <Box
            sx={{
              mb: 3,
              '& .MuiAccordionSummary-content': {
                display: 'block',
              },
            }}
          >
            {messageSigners(document.dsEnvelopeId, document?._id!, document.signers)}
          </Box>
          {messageEnvelope(document.dsEnvelopeId, document?._id!, document.envelopeData)}
          <FlexBetween sx={{ mt: 2 }}>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              <Button sx={{ mr: 1, fontSize: '0.7rem' }} onClick={() => previewDocument(document)} color="primary" variant="contained">
                {previewLoading ? <CircularProgress size={30} color="secondary" /> : 'Preview Document'}
              </Button>
              {!document.signers && document.dsEnvelopeId ? (
                <Button
                  sx={{ mr: 1, fontSize: '0.7rem' }}
                  onClick={() => getSigners({ variables: { _id: document?._id } })}
                  color="primary"
                  variant="contained"
                >
                  {getSignersLoading ? <CircularProgress size={30} color="secondary" /> : 'Get Status of Signers'}
                </Button>
              ) : null}
              {!document.envelopeData && document.dsEnvelopeId ? (
                <Button sx={{ mr: 1, fontSize: '0.7rem' }} onClick={() => envelopeData(document)} color="primary" variant="contained">
                  {envelopeLoading ? <CircularProgress size={30} color="secondary" /> : 'Envelope Data'}
                </Button>
              ) : null}
              {document.status !== 'Completed' && document.dsEnvelopeId ? (
                <Button sx={{ fontSize: '0.7rem' }} onClick={() => resend(document)} color="primary" variant="contained">
                  {resendLoading ? <CircularProgress size={30} color="secondary" /> : 'Resend Envelope'}
                </Button>
              ) : null}
            </Box>
            <FlexEnd>
              {(document.status === 'Signed' || document.status === 'Verified') && accessAllowed(user, project._id, 'editDeals') ? (
                <Button
                  sx={{ mr: 1, fontSize: '0.7rem' }}
                  onClick={() => openDialog('execute', document)}
                  color="success"
                  variant="contained"
                >
                  {sendingDocumentToExecutor ? (
                    <CircularProgress size={24} color="secondary" />
                  ) : (
                    `Send to ${document.status === 'Verified' ? 'Different' : ''} Executor`
                  )}
                </Button>
              ) : null}
              {accessAllowed(user, project._id, 'editDeals') ? (
                <>
                  {!document.dsEnvelopeId ||
                  (document.status === 'Completed' && !document.dsEnvelopeId) ||
                  (!document.isAPS && document.status !== 'Completed') ? (
                    <Button
                      sx={{ fontSize: '0.7rem' }}
                      onClick={() => openDialog('deleteDocument', document)}
                      color="error"
                      variant="contained"
                    >
                      {'Void and Delete Document'}
                    </Button>
                  ) : null}
                </>
              ) : null}
            </FlexEnd>
          </FlexBetween>
        </Box>
      );
    } else {
      return (
        <Box
          sx={{
            mb: 2,
            width: '100%',
          }}
        >
          <div>
            <div>This document has not been sent out yet</div>
          </div>
          <FlexBetween sx={{ mt: 2 }}>
            <div>
              <Button sx={{ fontSize: '0.7rem' }} onClick={() => previewDocument(document)} color="primary" variant="contained">
                {previewLoading ? <CircularProgress size={30} color="secondary" /> : 'Preview Document'}
              </Button>
            </div>
            {accessAllowed(user, project._id, 'editDeals') ? (
              <FlexEnd>
                <Button
                  component={Link}
                  to={`/${project._id}/dashboard/edit-document/${document._id}`}
                  color="primary"
                  variant="contained"
                  sx={{ mr: 1, fontSize: '0.7rem' }}
                >
                  Edit Document
                </Button>
                <FlexBetween>
                  <Button
                    sx={{ mr: 1, fontSize: '0.7rem' }}
                    onClick={() => openDialog('deleteDocument', document)}
                    color="error"
                    variant="contained"
                  >
                    Delete Document
                  </Button>
                </FlexBetween>
                <Button
                  sx={{ fontSize: '0.7rem' }}
                  onClick={() => openDialog('sendEnvelope', document)}
                  color="success"
                  variant="contained"
                >
                  {document.type === 'Coop' ? 'Send to Realtor' : 'Send to Purchaser(s)'}
                </Button>
              </FlexEnd>
            ) : null}
          </FlexBetween>
        </Box>
      );
    }
  };

  const handleCloseSuccess = async () => {
    if (dialogType === 'execute') {
      sendToExecutor();
    } else if (dialogType === 'upload') {
      const args = {
        status: 'Completed',
        name: title,
        project: project._id,
        deal: filteredDeal._id,
        isAPS: false,
        type: documentType,
        mergeTemplate: null,
      };
      setDialogOpen(false);
      createDocument({ variables: { record: args, file: file } });
    } else if (dialogType === 'sendEnvelope') {
      handleSendDocument(selectedDocument!);
    } else if (dialogType === 'deleteDocument') {
      removeDocuments(selectedDocument?._id!, selectedDocument?.type!);
    } else {
      let purchasers: any[] = [];
      let options: any[] = [];
      if (selectedMerge[0].type === 'Management') {
        purchasers = lease.landlords.purchasers;
        options = lease.management.options;
      }
      if (selectedMerge[0].type === 'Listing') {
        purchasers = lease.landlords;
      }
      if (selectedMerge[0].name === 'Tenancy Agreement') {
        purchasers = lease.landlords;
      }
      if (selectedMerge.length === 1) {
        let pdf = await createPdf(
          selectedMerge[0],
          project,
          unit,
          purchasers,
          [],
          options,
          null,
          null,
          null,
          null,
          null,
          null,
          false,
          [],
          [],
          false,
          lease
        );

        let sign = selectedMerge[0].signFields.map((sign: any) => {
          return {
            index: sign.index,
            key: sign.key,
            pageNumber: sign.pageNumber,
            x: sign.x,
            y: sign.y,
          };
        });

        let mergeName = selectedMerge[0].name;

        const args = {
          status: 'Prepared',
          name: mergeName,
          project: project._id,
          deal: filteredDeal._id,
          unit: unit._id,
          signFields: sign,
          // signers,
          isAPS: false,
          type: selectedMerge[0].type,
        };

        setDialogOpen(false);
        createDocument({ variables: { record: args, file: pdf } });
      } else if (selectedMerge.length > 1) {
        const mergedPdf = await PDFDocument.create();
        let documents = await Promise.all(
          selectedMerge.map(async (document: IMerge) => {
            let autoMergeTemplate = mergeTemplates.find((mergeTemplate: IMerge) => mergeTemplate.name === document.name);

            let autoDocument = await fetch(autoMergeTemplate?.getUrl!).then(async (res) => await res.arrayBuffer());
            const documentPdf = await PDFDocument.load(autoDocument);
            let helveticaFont = await documentPdf.embedFont(StandardFonts.Helvetica);

            let pages = documentPdf.getPages();
            await autoMergeTemplate?.mergeFields.forEach(async (mergeField: any) => {
              let mergePages = pages[mergeField.pageNumber];
              let mergeValue = getMergeValues(
                mergeField,
                project,
                unit,
                null,
                purchasers,
                filteredDeal.deposit,
                options,
                null,
                null,
                null,
                null,
                null,
                filteredDeal,
                [],
                null,
                lease
              );

              if (mergeValue === 'Check') {
                mergePages.drawLine({
                  start: { x: mergeField.x + 3, y: mergePages.getHeight() - mergeField.y - 3 },
                  end: { x: mergeField.x + 8, y: mergePages.getHeight() - mergeField.y + 4 },
                  thickness: 1,
                  color: rgb(0, 0, 0),
                  opacity: 0.75,
                });
                mergePages.drawLine({
                  start: { x: mergeField.x, y: mergePages.getHeight() - mergeField.y + 1 },
                  end: { x: mergeField.x + 3, y: mergePages.getHeight() - mergeField.y - 3 },
                  thickness: 1,
                  color: rgb(0, 0, 0),
                  opacity: 0.75,
                });
              } else {
                mergePages.drawText(mergeValue, {
                  x: mergeField.x,
                  y: mergePages.getHeight() - mergeField.y - 3,
                  size: mergeField.fontSize,
                  font: helveticaFont,
                  color: rgb(0, 0, 0),
                });
              }
            });

            let copiedPages = await mergedPdf.copyPages(documentPdf, documentPdf.getPageIndices());
            copiedPages.forEach((page: any) => mergedPdf.addPage(page));

            const documentDoc = await documentPdf.save();

            let mergeSignFields = autoMergeTemplate?.signFields.map((signFields: any, index: number) => {
              return {
                index: signFields.index,
                key: signFields.key,
                pageNumber: signFields.pageNumber,
                x: Math.round(signFields.x),
                y: Math.round(signFields.y),
              };
            });

            let blob = await new Blob([documentDoc]);
            let file = await new File([blob], `${document.name}.pdf`, { type: 'application/pdf' });

            return {
              file: file,
              name: document.name,
              mergeTemplate: document._id,
              signFields: mergeSignFields,
              isAPS: false,
              type: 'Other',
            };
          })
        );

        createMultipleDocument({ variables: { project: project._id, deal: filteredDeal._id, documents: documents } });
      }
    }
  };

  const handleSendDocument = async (document: IDocuments) => {
    let name = '';

    if (document.name === 'Property Management Agreement' || document.name === 'Property Management Agreement REC') {
      name = 'management';
    } else if (document.name === 'Listing Agreement') {
      name = 'listing';
    } else if (document.name === 'Tenancy Agreement') {
      name = 'tenancy';
    }

    sendDocument({
      variables: {
        documentId: document._id,
        dealRealtorId: null,
        leaseId: lease._id,
        leaseType: name,
      },
    });
    setDialogOpen(false);
  };

  const removeDocuments = async (documentId: string, type: string) => {
    setDialogOpen(false);
    deleteDocument({ variables: { _id: documentId, deleteFile: true } });
  };

  const dialogBox = () => {
    let removeButton = 'Cancel';
    let successButton = 'Yes, I would like to create this agreement';
    let title = 'Please select an agreement you would like to create.';
    if (dialogType === 'execute') {
      successButton = 'Send to Executor';
      title = 'Please select an Executor to send Document';
    }
    if (dialogType === 'upload') {
      successButton = 'Upload Document';
      title = 'Please select a type for this document';
    }
    if (dialogType === 'sendEnvelope') {
      successButton = 'Send Envelope';
      title = 'Are you sure you would like to send this envelope?';
    }
    if (dialogType === 'deleteDocument') {
      successButton = 'Delete/Void Document';
      title = 'Are you sure you would like to delete/void this document?';
    }
    return (
      <CustomDialog
        handleClose={() => setDialogOpen(false)}
        handleCloseRemove={() => setDialogOpen(false)}
        handleCloseSuccess={handleCloseSuccess}
        open={dialogOpen}
        removeButton={removeButton}
        successButton={successButton}
        dialogContent={dialogContent()}
        dialogTitle={title}
      />
    );
  };

  const handleDropdownInput = (event: any) => {
    let selected = mergeTemplates.find((merge: IMerge) => {
      return merge.name === event.target.value;
    });
    if (selected) {
      setSelectedMerge([...selectedMerge, selected]);
    }
  };

  const handleMenuList = () => {
    let managementTemplates: string[] = [];
    let listingTemplates: string[] = [];
    let tenancyTemplates: string[] = [];
    if (lease.management) {
      managementTemplates = mergeTemplates.filter((merge: IMerge) => merge.type === 'Management').map((merge: IMerge) => merge.name);
    }
    if (lease.listing) {
      listingTemplates = mergeTemplates.filter((merge: IMerge) => merge.type === 'Listing').map((merge: IMerge) => merge.name);
    }
    if (lease.tenancy) {
      tenancyTemplates = mergeTemplates.filter((merge: IMerge) => merge.type === 'Tenancy').map((merge: IMerge) => merge.name);
    }
    return [...managementTemplates, ...listingTemplates, ...tenancyTemplates];
  };

  const dialogContent = () => {
    if (dialogType === 'create') {
      return (
        <Box sx={{ mt: 1 }}>
          <Dropdown
            id={'type'}
            title={'Agreement'}
            menuList={handleMenuList()}
            name={'type'}
            handleSelect={(e: any) => handleDropdownInput(e)}
            value={''}
          />
          {selectedMerge.length ? (
            <List>
              {selectedMerge.map((merge: IMerge, index: number) => {
                return (
                  <ListItem
                    key={index}
                    sx={{ backgroundColor: '#e5e5e5', borderRadius: '8px', mb: 1 }}
                    secondaryAction={
                      <IconButton edge="end" aria-label="delete">
                        <DeleteIcon
                          color="error"
                          onClick={() => setSelectedMerge(selectedMerge.filter((selected: IMerge) => selected._id !== merge._id))}
                        />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={`${index + 1}. ${merge.name}`} />
                  </ListItem>
                );
              })}
            </List>
          ) : null}
        </Box>
      );
    } else if (dialogType === 'execute') {
      return (
        <div>
          <Dropdown
            title={'Executor'}
            menuList={executorList()}
            name={'executors'}
            handleSelect={(e: any) => handleExecutorDropdown(e)}
            value={executor ? executor.name : ''}
          />
        </div>
      );
    } else if (dialogType === 'sendEnvelope' || dialogType === 'deleteDocument') {
      return (
        <div>
          <strong>{selectedDocument?.name!}</strong>
        </div>
      );
    } else if (dialogType === 'upload') {
      return (
        <div>
          <Box sx={{ my: 2 }}>
            <TextInput
              title={'Document Title'}
              id={'title'}
              handleTextInput={(e: any) => setTitle(e.target.value)}
              value={title}
              required={true}
            />
          </Box>
          <Box
            sx={{
              marginBottom: '30px',
              '& .MuiAccordionSummary-content': {
                display: 'block',
              },
            }}
          >
            <Dropdown
              id={'type'}
              title={'Type'}
              menuList={mergeType.filter((merge: string) => merge === 'Listing' || merge === 'Tenancy' || merge === 'Management')}
              name={'type'}
              handleSelect={(e: any) => setDocumentType(e.target.value)}
              value={documentType}
            />
          </Box>
          <div>
            {fileImage ? (
              <div>
                <Grid item xs={12}>
                  <PdfCard file={fileImage} title={title} id={'0'} handleDelete={() => setFileImage(null)} download={true} />
                </Grid>
              </div>
            ) : (
              <Dropzone onDrop={handleDrop} accept="application/pdf">
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps({ style })}>
                      <input {...getInputProps()} />
                      <p>Drag and Drop or Upload a Document (.pdf)</p>
                    </div>
                  </section>
                )}
              </Dropzone>
            )}
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <Box
        sx={{
          '& .MuiAccordion-root': {
            border: '1px solid #000',
            padding: '4px',
          },
          '& .MuiAccordionDetails-root': {
            display: 'block',
          },
          my: 2,
        }}
      >
        {dialogBox()}
        {lease ? (
          createDocumentLoading || sendDocumentLoading || deleteDocumentLoading ? (
            <LoadingWrapper title="Loading..." modal={false} />
          ) : (
            <>
              {lease.documents.length > 0 ? (
                <Box>
                  {lease.documents.map((document: IDocuments, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <Accordion
                          sx={{
                            ...setBackgroundColor(document.status),
                            mb: 1,
                          }}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <div>
                              <h3>{document.name}</h3>
                              <div>
                                Status: <strong>{document.status}</strong>
                              </div>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>{accordianContent(document)}</AccordionDetails>
                        </Accordion>
                      </React.Fragment>
                    );
                  })}
                </Box>
              ) : (
                <Box>
                  <em>There are currently no documents</em>
                </Box>
              )}
              {accessAllowed(user, project._id, 'editDeals') ? (
                <FlexBetween sx={{ mt: 2 }}>
                  <FlexBetween>
                    <div>
                      <Button onClick={() => openDialog('create')} color="primary" variant="contained">
                        Create a Document
                      </Button>
                    </div>
                  </FlexBetween>
                  <FlexBetween>
                    <div>
                      <Button onClick={() => openDialog('upload')} color="primary" variant="contained">
                        Upload a Document
                      </Button>
                    </div>
                  </FlexBetween>
                </FlexBetween>
              ) : null}
            </>
          )
        ) : (
          <div>
            <em>There are no documents.</em>
          </div>
        )}
      </Box>
    </div>
  );
};

const CREATEDOCUMENT = gql`
  mutation documentCreateOne($record: CreateOneDocumentInput, $file: Upload!) {
    documentCreateOne(record: $record, file: $file) {
      _id
      name
      project {
        _id
      }
      type
      getUrl
      status
      isAPS
      dsEnvelopeId
    }
  }
`;

const DELETEDOCUMENT = gql`
  mutation documentDeleteById($_id: MongoID!, $deleteFile: Boolean!) {
    documentDeleteById(_id: $_id, deleteFile: $deleteFile) {
      name
      status
      _id
    }
  }
`;

const SENDDOCUMENT = gql`
  mutation documentCreateEnvelope($documentId: MongoID!, $dealRealtorId: MongoID, $leaseId: MongoID, $leaseType: String) {
    documentCreateEnvelope(
      documentId: $documentId
      sendOut: true
      dealRealtorId: $dealRealtorId
      leaseId: $leaseId
      leaseType: $leaseType
    ) {
      name
      _id
      status
      dsEnvelopeId
    }
  }
`;

const GETPREVIEWDATA = gql`
  query documentById($_id: MongoID!) {
    documentById(_id: $_id) {
      _id
      previewData
    }
  }
`;

const GETENVELOPEDATA = gql`
  query documentById($_id: MongoID!) {
    documentById(_id: $_id) {
      _id
      envelopeData {
        createdDateTime
        lastModifiedDateTime
        initialSentDateTime
        sentDateTime
        statusChangedDateTime
        completedDateTime
        deliveredDateTime
      }
    }
  }
`;

const GETSIGNERS = gql`
  query documentById($_id: MongoID!) {
    documentById(_id: $_id) {
      _id
      signers {
        name
        type
        status
      }
    }
  }
`;

const RESENDENVELOPE = gql`
  query resendEnvelope($_id: MongoID!) {
    resendEnvelope(_id: $_id)
  }
`;

const SENDDOCUMENTTOEXECUTOR = gql`
  mutation documentSentToExecutor($documentId: MongoID!, $executor: executorData) {
    documentSendToExecutor(documentId: $documentId, executor: $executor) {
      _id
      status
      name
    }
  }
`;

const GETMERGE = gql`
  query mergeTemplateMany($filter: FilterFindManyMergeTemplateInput) {
    mergeTemplateMany(filter: $filter) {
      _id
      name
      mergeFields {
        key
        index
        pageNumber
        x
        y
        fontSize
        format
        wrap
      }
      signFields {
        key
        index
        pageNumber
        x
        y
      }
      type
      getUrl
      putUrl
    }
  }
`;

const CREATEMULTIPLEDOCUMENT = gql`
  mutation createEnvelopeMultipleDocuments($project: MongoID!, $deal: MongoID!, $documents: [NewDocumentInput]) {
    createEnvelopeMultipleDocuments(project: $project, deal: $deal, documents: $documents) {
      _id
      name
      project {
        _id
      }
      type
      getUrl
      status
      isAPS
      dsEnvelopeId
    }
  }
`;

export default Documents;
