import { useState, useMemo, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Box, Typography, AppBar, Tab, Tabs, Button } from '@mui/material';
import { useSelector } from 'react-redux';

import { numToCurrency, downloadExcel, downloadPdf, convertAllDates } from '../../../utils/Functions';
import { IUnit, IUnitData, IUnitHistory } from '../../../types/unit';
import StandardTable from '../../tables/StandardTable';
import { selectProject } from '../../../features/project/projectSlice';
import { customTierUnits, customTiers } from '../../../utils/CustomSettings';
import LevelSummary from './LevelSummary';
import RangeSummary from './RangeSummary';
import { FlexBetween } from '../../../commonStyles';
import { selectUser } from '../../../features/auth/authSlice';

const TypeSummary = (props: ChildProps) => {
  const { allUnits } = props;
  const user = useSelector(selectUser);
  const project = useSelector(selectProject);
  const [units, setUnits] = useState<IUnit[]>([]);
  const [tierType, setTierType] = useState<string>('Overall');
  const unitTypes = units
    .map((unit) => unit.unitType)
    .filter((v, i, a) => a.indexOf(v) === i)
    .sort();

  const { loading } = useQuery<IUnitData>(UNITS, {
    skip: !project._id,
    variables: { filter: { project: project._id } },
    onCompleted: (data) => {
      setUnits(data.unitMany);
    },
  });

  useEffect(() => {
    if (allUnits) {
      setUnits(allUnits);
    }
  }, [allUnits]);

  const handleTierChange = (e: any, value: string) => {
    setTierType(value);
  };

  const columns = useMemo(() => {
    let dataColumns = [
      {
        Header: 'Unit Type',
        accessor: (rowData: any) => rowData.unitType,
      },
      {
        Header: 'Model Type',
        accessor: (rowData: any) => rowData.modelType,
      },
      {
        Header: 'Outdoor Type',
        accessor: (rowData: any) => rowData.outdoorType,
      },
      {
        Header: 'Average PPSF',
        accessor: (rowData: any) => rowData.averagePpsf,
      },
      {
        Header: 'Average Price',
        accessor: (rowData: any) => rowData.averagePrice,
      },
      {
        Header: 'Average Size',
        accessor: (rowData: any) => rowData.averageSize,
      },
      {
        Header: 'Lowest Price',
        accessor: (rowData: any) => rowData.lowestPrice,
      },
      {
        Header: 'Highest Price',
        accessor: (rowData: any) => rowData.highestPrice,
      },
      {
        Header: 'Median',
        accessor: (rowData: any) => rowData.median,
      },
      {
        Header: 'Units Total',
        accessor: (rowData: any) => `${rowData.unitsTotal} (${Math.round((rowData.unitsTotal / units.length) * 100)}%)`,
      },
      {
        Header: 'Cumulative',
        accessor: (rowData: any) => rowData.cumulative,
      },
      {
        Header: 'Units Available',
        accessor: (rowData: any) => rowData.unitsAvailable,
      },
      {
        Header: 'Units Pending',
        accessor: (rowData: any) => rowData.unitsPending,
      },
      {
        Header: 'Units Sold (C, F)',
        accessor: (rowData: any) =>
          `${rowData.unitsSold} (${rowData.unitsTotal ? Math.round((rowData.unitsSold / rowData.unitsTotal) * 10000) / 100 : '0'}%)`,
      },
      {
        Header: 'Sold Revenue',
        accessor: (rowData: any) => `${rowData.soldRevenue}`,
      },
      {
        Header: 'Total Revenue',
        accessor: (rowData: any) => `${rowData.totalRevenue} (${rowData.totalRevenuePercentage})`,
      },
    ];

    return dataColumns;
  }, [units]);

  const download = (type: string, data: any) => {
    let unitColumns = [
      {
        label: 'Unit Types',
        id: 'unitType',
      },
      {
        label: 'Outdoor Type',
        id: 'outdoorType',
      },
      {
        label: 'Average Price',
        id: 'averagePrice',
      },
      {
        label: 'Average Size',
        id: 'averageSize',
      },
      {
        label: 'Average PPSF',
        id: 'averagePpsf',
      },
      {
        label: 'Lowest Price',
        id: 'lowestPrice',
      },
      {
        label: 'Highest Price',
        id: 'highestPrice',
      },
      {
        label: 'Median',
        id: 'median',
      },
      {
        label: 'Units Total',
        id: 'unitsTotal',
      },
      {
        label: 'Cumulative',
        id: 'cumulative',
      },
      {
        label: 'Units Available',
        id: 'unitsAvailable',
      },
      {
        label: 'Units Pending',
        id: 'unitsPending',
      },
      {
        label: 'Units Sold (C, F)',
        id: 'unitsSold',
      },
      {
        label: 'Sold Revenue',
        id: 'soldRevenue',
      },
      {
        label: 'Total Revenue',
        id: 'totalRevenue',
      },
    ];

    let widths = {
      unitType: 15,
      outdoorType: 15,
      averagePrice: 15,
      averageSize: 15,
      averagePpsf: 15,
      lowestPrice: 15,
      highestPrice: 15,
      median: 15,
      unitsTotal: 15,
      cumulative: 15,
      unitsAvailable: 15,
      unitsPending: 15,
      unitsSold: 15,
      soldRevenue: 15,
      totalRevenue: 15,
    };

    let pdfWidths = {
      unitType: 200,
      outdoorType: 200,
      averagePrice: 200,
      averageSize: 200,
      averagePpsf: 200,
      lowestPrice: 200,
      highestPrice: 200,
      median: 200,
      unitsTotal: 200,
      cumulative: 200,
      unitsAvailable: 200,
      unitsPending: 200,
      unitsSold: 200,
      soldRevenue: 15,
      totalRevenue: 15,
    };

    let sheetTitle = `${project.name} - Unit Summary`;

    if (type === 'excel') {
      downloadExcel([data], [unitColumns], [], [[widths]], [sheetTitle], sheetTitle);
    } else {
      downloadPdf([data], [unitColumns], [], [pdfWidths], [sheetTitle], sheetTitle, project.logoGetUrl);
    }
  };

  const unitTypeData = () => {
    let allUnits = units;

    if (tierType) {
      allUnits = units.filter((unit: IUnit) => customTierUnits(project._id, units, tierType).includes(unit.tier));
    }

    let unitData = unitTypes.map((type, index: number) => {
      const totalRevenueAllUnits: number = allUnits.map((unit) => unit.basePrice).reduce((a, b) => a + b, 0);
      const validUnits = allUnits.filter((unit) => unit.unitType === type);
      const validUnitPrices = allUnits.filter((unit) => unit.unitType === type).map((unit) => unit.basePrice);
      const totalPrice = validUnits.map((unit) => unit.basePrice).reduce((a, b) => a + b, 0);
      const totalSize = validUnits.map((unit) => unit.size).reduce((a, b) => a + b, 0);
      const mid = Math.floor(validUnitPrices.length / 2);
      const nums = [...validUnitPrices].sort((a, b) => a - b);
      const typeRevenue = allUnits
        .filter((unit) => unit.unitType === type)
        .map((unit) => unit.basePrice)
        .reduce((a, b) => a + b, 0);
      const soldRevenue = allUnits
        .filter((unit) => unit.unitType === type && (unit.status === 'F' || unit.status === 'C'))
        .map((unit) => unit.basePrice)
        .reduce((a, b) => a + b, 0);
      const totalUnits = allUnits.filter((unit) => unit.unitType === type).length;
      const availableUnits = allUnits.filter(
        (unit) =>
          unit.unitType === type &&
          unit.status !== 'F' &&
          unit.status !== 'C' &&
          unit.status !== 'S' &&
          unit.status !== 'O' &&
          unit.status !== 'IP' &&
          unit.status !== 'P'
      ).length;
      const pendingUnits = allUnits.filter(
        (unit) => unit.unitType === type && (unit.status === 'P' || unit.status === 'O' || unit.status === 'S')
      ).length;
      const soldUnits = allUnits.filter((unit) => unit.unitType === type && (unit.status === 'F' || unit.status === 'C')).length;

      let cumulative = 0;
      for (let i = 0; i <= index; i++) {
        cumulative += allUnits.filter((unit) => unit.unitType === unitTypes[i]).length;
      }

      return {
        unitType: type,
        outdoorType: 'N/A',
        averagePrice: validUnits.length ? numToCurrency.format(totalPrice / validUnits.length) : 'N/A',
        averageSize: validUnits.length ? (totalSize / validUnits.length).toFixed(2) : 'N/A',
        averagePpsf: totalSize ? numToCurrency.format(totalPrice / totalSize) : 'N/A',
        lowestPrice: validUnitPrices.length > 0 ? numToCurrency.format(Math.min(...validUnitPrices)) : 'N/A',
        highestPrice: validUnitPrices.length > 0 ? numToCurrency.format(Math.max(...validUnitPrices)) : 'N/A',
        median:
          validUnitPrices.length === 0
            ? 'N/A'
            : validUnitPrices.length % 2 !== 0
            ? numToCurrency.format(nums[mid])
            : numToCurrency.format((nums[mid - 1] + nums[mid]) / 2),
        soldRevenue: numToCurrency.format(soldRevenue),
        soldRevenuePercentage: isNaN(Math.round((soldRevenue / typeRevenue) * 10000) / 100)
          ? '$0'
          : Math.round((soldRevenue / totalRevenueAllUnits) * 10000) / 100 + '%',
        totalRevenue: numToCurrency.format(typeRevenue),
        totalRevenuePercentage: isNaN(Math.round((typeRevenue / totalRevenueAllUnits) * 10000) / 100)
          ? '$0'
          : Math.round((typeRevenue / totalRevenueAllUnits) * 10000) / 100 + '%',
        unitsTotal: totalUnits,
        cumulative: `${cumulative} (${Math.round((cumulative / units.length) * 100)}%)`,
        unitsAvailable: availableUnits,
        unitsPending: pendingUnits,
        unitsSold: soldUnits,
      };
    });

    const allUnitPrices = allUnits.map((unit) => unit.basePrice);
    const totalRevenue = allUnits.map((unit) => unit.basePrice).reduce((a, b) => a + b, 0);
    const totalSize = allUnits.map((unit) => unit.size).reduce((a, b) => a + b, 0);
    const totalSoldRevenue = allUnits
      .filter((unit) => unit.status === 'F' || unit.status === 'C')
      .map((unit) => unit.basePrice)
      .reduce((a, b) => a + b, 0);
    const totalAvailableUnits = allUnits.filter(
      (unit) =>
        unit.status !== 'F' &&
        unit.status !== 'C' &&
        unit.status !== 'S' &&
        unit.status !== 'O' &&
        unit.status !== 'IP' &&
        unit.status !== 'P'
    ).length;
    const totalPendingUnits = allUnits.filter((unit) => unit.status === 'P' || unit.status === 'O' || unit.status === 'S').length;
    const totalSoldUnits = allUnits.filter((unit) => unit.status === 'F' || unit.status === 'C').length;
    const midAll = Math.floor(allUnitPrices.length / 2);
    const numsAll = [...allUnitPrices].sort((a, b) => a - b);

    let totalUnitData = {
      unitType: 'Total',
      outdoorType: 'N/A',
      averagePrice: numToCurrency.format(totalRevenue / units.length),
      averageSize: (totalSize / units.length).toFixed(2),
      averagePpsf: numToCurrency.format(totalRevenue / totalSize),
      lowestPrice: allUnitPrices.length > 0 ? numToCurrency.format(Math.min(...allUnitPrices)) : 'N/A',
      highestPrice: allUnitPrices.length > 0 ? numToCurrency.format(Math.max(...allUnitPrices)) : 'N/A',
      median:
        allUnitPrices.length === 0
          ? 'N/A'
          : allUnitPrices.length % 2 !== 0
          ? numToCurrency.format(numsAll[midAll])
          : numToCurrency.format((numsAll[midAll - 1] + numsAll[midAll]) / 2),
      soldRevenue: numToCurrency.format(totalSoldRevenue),
      soldRevenuePercentage: isNaN(Math.round((totalSoldRevenue / totalRevenue) * 10000) / 100)
        ? '$0'
        : Math.round((totalSoldRevenue / totalRevenue) * 10000) / 100 + '%',
      totalRevenue: numToCurrency.format(totalRevenue),
      totalRevenuePercentage: '100%',
      unitsTotal: allUnits.length,
      cumulative: allUnits.length,
      unitsAvailable: totalAvailableUnits,
      unitsPending: totalPendingUnits,
      unitsSold: totalSoldUnits,
    };

    return [...unitData, totalUnitData];
  };

  const data = (unitField: string) => {
    let allUnits = units;

    if (tierType) {
      allUnits = units.filter((unit: IUnit) => customTierUnits(project._id, units, tierType).includes(unit.tier));
    }

    let unitData = unitTypes
      .map((type, index: number) => {
        const totalUnits = allUnits.filter((unit) => unit.unitType === type);
        const unitFilteredType = totalUnits.map((unit: any) => unit[unitField]);
        const dataTypes = unitFilteredType.filter((v, i, a) => a.indexOf(v) === i);
        const typeData = dataTypes.map((dataType) => {
          const totalRevenueAllUnits: number = allUnits
            .filter((unit) => unit.status === 'F')
            .map((unit) => unit.basePrice)
            .reduce((a, b) => a + b, 0);
          const validUnits = allUnits.filter((unit: any) => unit.unitType === type && unit[unitField] === dataType);
          const validUnitPrices = validUnits.map((unit) => unit.basePrice);
          const totalPrice = validUnits.map((unit) => unit.basePrice).reduce((a, b) => a + b, 0);
          const totalSize = validUnits.map((unit) => unit.size).reduce((a, b) => a + b, 0);
          const mid = Math.floor(validUnitPrices.length / 2);
          const nums = [...validUnitPrices].sort((a, b) => a - b);
          const typeRevenue = allUnits
            .filter((unit: any) => unit.unitType === type && unit[unitField] === dataType)
            .map((unit) => unit.basePrice)
            .reduce((a, b) => a + b, 0);
          const soldRevenue = allUnits
            .filter((unit: any) => unit.unitType === type && unit[unitField] === dataType && (unit.status === 'F' || unit.status === 'C'))
            .map((unit) => unit.basePrice)
            .reduce((a, b) => a + b, 0);
          const totalUnits = validUnits.length;
          const availableUnits = allUnits.filter(
            (unit: any) =>
              unit.unitType === type &&
              unit[unitField] === dataType &&
              unit.status !== 'F' &&
              unit.status !== 'C' &&
              unit.status !== 'S' &&
              unit.status !== 'O' &&
              unit.status !== 'IP' &&
              unit.status !== 'P'
          ).length;
          const pendingUnits = allUnits.filter(
            (unit: any) => unit[unitField] === dataType && (unit.status === 'P' || unit.status === 'O' || unit.status === 'S')
          ).length;
          const soldUnits = allUnits.filter(
            (unit: any) => unit[unitField] === dataType && (unit.status === 'F' || unit.status === 'C')
          ).length;
          return {
            modelType: unitField === 'modelType' ? dataType : '',
            outdoorType: unitField === 'outdoorType' ? dataType : '',
            unitType: type,
            averagePrice: numToCurrency.format(totalPrice / validUnits.length),
            averageSize: (totalSize / validUnits.length).toFixed(0),
            averagePpsf: numToCurrency.format(totalPrice / totalSize),
            lowestPrice: validUnitPrices.length > 0 ? numToCurrency.format(Math.min(...validUnitPrices)) : 'N/A',
            highestPrice: validUnitPrices.length > 0 ? numToCurrency.format(Math.max(...validUnitPrices)) : 'N/A',
            median:
              validUnitPrices.length === 0
                ? 'N/A'
                : validUnitPrices.length % 2 !== 0
                ? numToCurrency.format(nums[mid])
                : numToCurrency.format((nums[mid - 1] + nums[mid]) / 2),
            soldRevenue: numToCurrency.format(soldRevenue),
            soldRevenuePercentage: isNaN(Math.round((soldRevenue / typeRevenue) * 10000) / 100),
            totalRevenue: numToCurrency.format(typeRevenue),
            totalRevenuePercentage: isNaN(Math.round((typeRevenue / totalRevenueAllUnits) * 10000) / 100),
            unitsTotal: totalUnits,
            unitsAvailable: availableUnits,
            unitsPending: pendingUnits,
            unitsSold: soldUnits,
            percentageSold: `${Math.round((soldUnits / totalUnits) * 10000) / 100}%`,
          };
        });
        return typeData;
      })
      .flat();

    const allUnitPrices = allUnits.map((unit) => unit.basePrice);
    const totalRevenue = allUnits.map((unit) => unit.basePrice).reduce((a, b) => a + b, 0);
    const totalSize = allUnits.map((unit) => unit.size).reduce((a, b) => a + b, 0);
    const totalSoldRevenue = allUnits
      .filter((unit) => unit.status === 'F' || unit.status === 'C')
      .map((unit) => unit.basePrice)
      .reduce((a, b) => a + b, 0);
    const totalAvailableUnits = allUnits.filter(
      (unit) =>
        unit.status !== 'F' &&
        unit.status !== 'C' &&
        unit.status !== 'S' &&
        unit.status !== 'O' &&
        unit.status !== 'IP' &&
        unit.status !== 'P'
    ).length;
    const totalPendingUnits = allUnits.filter((unit) => unit.status === 'P' || unit.status === 'O' || unit.status === 'S').length;
    const totalSoldUnits = allUnits.filter((unit) => unit.status === 'F' || unit.status === 'C').length;
    const midAll = Math.floor(allUnitPrices.length / 2);
    const numsAll = [...allUnitPrices].sort((a, b) => a - b);

    let totalUnitData = {
      modelType: 'N/A',
      unitType: 'Total',
      outdoorType: 'N/A',
      averagePrice: numToCurrency.format(totalRevenue / units.length),
      averageSize: (totalSize / units.length).toFixed(2),
      averagePpsf: numToCurrency.format(totalRevenue / totalSize),
      lowestPrice: allUnitPrices.length > 0 ? numToCurrency.format(Math.min(...allUnitPrices)) : 'N/A',
      highestPrice: allUnitPrices.length > 0 ? numToCurrency.format(Math.max(...allUnitPrices)) : 'N/A',
      median:
        allUnitPrices.length === 0
          ? 'N/A'
          : allUnitPrices.length % 2 !== 0
          ? numToCurrency.format(numsAll[midAll])
          : numToCurrency.format((numsAll[midAll - 1] + numsAll[midAll]) / 2),
      soldRevenue: numToCurrency.format(totalSoldRevenue),
      soldRevenuePercentage: isNaN(Math.round((totalSoldRevenue / totalRevenue) * 10000) / 100)
        ? '$0'
        : Math.round((totalSoldRevenue / totalRevenue) * 10000) / 100 + '%',
      totalRevenue: numToCurrency.format(totalRevenue),
      totalRevenuePercentage: '100%',
      unitsTotal: allUnits.length,
      unitsAvailable: totalAvailableUnits,
      unitsPending: totalPendingUnits,
      unitsSold: totalSoldUnits,
    };

    return [...unitData, totalUnitData];
  };

  const priceChanges = () => {
    let unitColumns = [
      {
        label: 'Suite',
        id: 'suite',
      },
      {
        label: 'Opening Day Price',
        id: 'originalPrice',
      },
      {
        label: 'Date',
        id: 'date',
      },
      {
        label: 'Price From',
        id: 'priceFrom',
      },
      {
        label: 'Price To',
        id: 'priceTo',
      },
      {
        label: 'User',
        id: 'user',
      },
    ];

    let widths = {
      suite: 15,
      originalPrice: 15,
      date: 15,
      priceFrom: 15,
      priceTo: 15,
      user: 15,
    };

    let sheetTitle = `${project.name} - Price Change History`;

    let data = units
      .map((unit: IUnit) => {
        let history = unit.history
          .filter((history: IUnitHistory) => history.priceFrom && history.priceTo)
          .map((history: any) => {
            return {
              suite: unit.suite,
              originalPrice: numToCurrency.format(unit.originalPrice),
              date: convertAllDates(history.timestamp, 'PPpp'),
              priceFrom: numToCurrency.format(history.priceFrom),
              priceTo: numToCurrency.format(history.priceTo),
              user: history.user.fullName,
            };
          });
        return history;
      })
      .flat();

    downloadExcel([data], [unitColumns], [], [[widths]], [sheetTitle], sheetTitle);
  };

  return (
    <Box sx={{ m: 2 }}>
      <Box sx={{ mb: 2 }}>
        {customTiers(project._id, units).length ? (
          <AppBar position="sticky" sx={{ mb: 2 }}>
            <Tabs value={tierType} scrollButtons="auto" onChange={handleTierChange} aria-label="scrollable auto tabs unit type">
              {customTiers(project._id, units).map((tier: string, index: number) => {
                return <Tab key={index} value={tier} label={tier} />;
              })}
              ;
            </Tabs>
          </AppBar>
        ) : null}
        <FlexBetween sx={{ mb: 2 }}>
          <Typography gutterBottom variant="h2">
            Unit Type Summary
          </Typography>
          {user.type === 'Manager' || user.type === 'Admin' ? (
            <Box>
              <Button variant="contained" color="primary" onClick={() => priceChanges()}>
                Price Change History
              </Button>
            </Box>
          ) : null}
        </FlexBetween>
        <StandardTable download={download} columns={columns} data={unitTypeData()} loading={loading} />
      </Box>
      {units.some((unit: IUnit) => unit.outdoorType) ? (
        <Box sx={{ mb: 2 }}>
          <Typography gutterBottom variant="h2">
            Unit Type Summary (Outdoor)
          </Typography>
          <StandardTable download={download} columns={columns} data={data('outdoorType')} loading={loading} />
        </Box>
      ) : null}
      <Box>
        <Typography gutterBottom variant="h2">
          Model Type Summary
        </Typography>
        <StandardTable download={download} columns={columns} data={data('modelType')} loading={loading} />
      </Box>
      <Box sx={{ my: 2 }}>
        <RangeSummary units={units} tierType={tierType} />
      </Box>
      <Box sx={{ my: 2 }}>
        <LevelSummary units={units} tierType={tierType} />
      </Box>
    </Box>
  );
};

interface ChildProps {
  allUnits?: IUnit[];
}

const UNITS = gql`
  query unitMany($filter: FilterFindManyUnitInput) {
    unitMany(filter: $filter, limit: 10000) {
      _id
      suite
      unit
      modelType
      unitType
      bathroom
      size
      outdoorType
      basePrice
      originalPrice
      level
      exposure
      outdoorSize
      history {
        type
        description
        priceFrom
        priceTo
        timestamp
        user {
          _id
          fullName
        }
      }
      status
      tier
    }
  }
`;

export default TypeSummary;
