import { useState, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import ProjectsSummary from './ProjectsSummary';
import BasicTabs from '../common/BasicTabs';
import Summaries from './summary/Summaries';
import Project from './Project';
import { Flex } from '../../commonStyles';
import { useNavigate } from 'react-router-dom';
import Realtor from './realtor/Realtor';
import Envelopes from '../adminDashboard/envelopes/Envelopes';
import Deposits from '../adminDashboard/deposits/Deposits';
import Assignments from '../adminDashboard/assignments/Assignments';
import { useSelector } from 'react-redux';
import { selectUser } from '../../features/auth/authSlice';
import UserStats from './UserStats';
import Leasing from './leasing/Leasing';

const AllProjects = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const user = useSelector(selectUser);

  const tabs = useMemo(() => {
    let tabArray = [
      {
        label: 'Envelopes',
        component: <Envelopes />,
      },
      {
        label: 'Deposits',
        component: <Deposits />,
      },
      {
        label: 'Assignments',
        component: <Assignments />,
      },
      {
        label: 'Project Details',
        component: <Project />,
      },
      {
        label: 'Leasing',
        component: <Leasing />,
      },
    ];

    if (user._id === '6122804c33d0c70d5c7dcee9' || user._id === '612280ea33d0c70d5c7dceef' || user._id === '60d4cc484233c244509a13fc') {
      tabArray.unshift({
        label: 'User Stats',
        component: <UserStats />,
      });
    }

    if (user.type === 'Manager') {
      tabArray.unshift(
        {
          label: 'Summaries',
          component: <Summaries />,
        },
        {
          label: 'Overall Report',
          component: <ProjectsSummary />,
        },
        {
          label: 'Realtor Summary',
          component: <Realtor />,
        }
      );
    }

    return tabArray;
  }, [user]);

  return (
    <Box
      sx={{
        p: 2,
        height: '100vh',
        position: 'relative',
      }}
    >
      <Flex sx={{ mb: 2 }}>
        <Box sx={{ alignSelf: 'center' }}>
          <ArrowBackIcon sx={{ cursor: 'pointer', mr: 1, alignSelf: 'center', color: '#00142A' }} onClick={() => navigate(-1)} />
        </Box>
        <Typography variant="h2" sx={{ alignSelf: 'center' }}>
          <strong>Your Dashboard</strong>
        </Typography>
      </Flex>
      <BasicTabs tabs={tabs} sub={true} value={value} setValue={setValue} color={'#fff'} />
    </Box>
  );
};

export default AllProjects;
