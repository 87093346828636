import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Grid, TextField } from '@mui/material';
import TextInput from '../../../common/formControls/TextInput';
import { IThirdParty } from '../../../../types/CreateDealForm';


const ThirdPartyForm = (props: ChildProps) => {
  const { thirdParty, handleTextInput, handleDateChange, error, type } = props;
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextInput
            title={"Full Name"}
            name={'fullName'}
            handleTextInput={(e: any) => handleTextInput(e, type)}
            value={thirdParty.fullName}
            required={true}
            helperText={error.fullName}
            error={error.fullName ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextInput
            title={'Address'}
            name={'streetAddress'}
            handleTextInput={(e: any) => handleTextInput(e, type)}
            value={thirdParty.streetAddress}
            required={true}
            helperText={error.streetAddress}
            error={error.streetAddress ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={'DOB (YYYY/MM/DD)'}
              views={['year', 'month', 'day']}
              value={thirdParty.dob ? new Date(thirdParty.dob) : null}
              onChange={(newValue) => handleDateChange(newValue, type)}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextInput
            title={'Phone Number (xxx-xxx-xxxx)'}
            name={'primaryPhone'}
            handleTextInput={(e: any) => handleTextInput(e, type)}
            value={thirdParty.primaryPhone}
            controlled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextInput
            title={'Email'}
            type={'email'}
            name={'email'}
            handleTextInput={(e: any) => handleTextInput(e, type)}
            value={thirdParty.email}
            helperText={error.email}
            error={error.email ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextInput
            title={'Occupation and Company'}
            name={'occupation'}
            handleTextInput={(e: any) => handleTextInput(e, type)}
            value={thirdParty.occupation}
            controlled={true}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextInput
            title={'Corporation Number (If Applicable)'}
            name={'corpNumber'}
            handleTextInput={(e: any) => handleTextInput(e, type)}
            value={thirdParty.corpNumber}
            helperText={error.corpNumber}
            error={error.corpNumber ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextInput
            title={'Relationship with Purchaser'}
            name={'relationship'}
            handleTextInput={(e: any) => handleTextInput(e, type)}
            value={thirdParty.relationship}
            required={true}
            helperText={error.relationship}
            error={error.relationship ? true : false}
          />
        </Grid>
      </Grid>
    </div>
  )
}

interface ChildProps {
  thirdParty: IThirdParty;
  type: number;
  handleTextInput: any;
  handleDateChange: any;
  error: any;
}

export default ThirdPartyForm
