import React, { useState, useMemo, useReducer } from 'react';
import { Buffer } from 'buffer';
import { gql, useMutation } from '@apollo/client';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Dropzone, { useDropzone } from 'react-dropzone';
import { Grid, AccordionSummary, AccordionDetails, Button, Accordion, Box, Typography, TextField, Autocomplete } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import SwitchButton from '../common/formControls/SwitchButton';
import Status from './Status';
import Coop from './Coop';
import { unitStatusTitles } from '../../utils/Constants';
import { IStatus, IExecutor, ICoopRate, ICoopStructure, IProject, IAddress } from '../../types/project';
import TextInput from '../common/formControls/TextInput';
import PdfCard from '../common/PdfCard';
import { statusReducer, executorReducer, coopReducer } from '../../features/project/projectHooks';
import { showSuccessSnackbar, showErrorSnackbar } from '../../features/snackbar/snackbarSlice';
import { useAppDispatch } from '../../app/hooks';
import { selectProject, setProject } from '../../features/project/projectSlice';
import { selectUser } from '../../features/auth/authSlice';
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from '../../utils/Constants';
import { FlexEnd } from '../../commonStyles';

const GeneralInfo = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const project = useSelector(selectProject);

  const [name, setName] = useState(project.name);
  const [developerName, setDeveloperName] = useState(project.developerName);
  const [addresses, setAddresses] = useState(project.addresses);
  const [email, setEmail] = useState(project.email);
  const [trackingPhone, setTrackingPhone] = useState(project.trackingPhone);
  const [salesTrackingPhone, setSalesTrackingPhone] = useState(project.salesTrackingPhone);
  const [logoImage, setLogoImage] = useState<any>(project.logoGetUrl);
  const [buildingImage, setBuildingImage] = useState<any>(project.imageGetUrl);
  const [developerImage, setDeveloperImage] = useState<any>(project.developerLogoGetUrl);
  const [partnerImage, setPartnerImage] = useState<any>(project.partnerLogoGetUrl);
  const [logo, setLogo] = useState<any>(null);
  const [building, setBuilding] = useState<any>(null);
  const [developer, setDeveloper] = useState<any>(null);
  const [partner, setPartner] = useState<any>(null);
  const [commissionIncludeOptions, setCommissionIncludeOptions] = useState<boolean>(project.commissionIncludeOptions!);
  const [statusState, statusDispatch] = useReducer(statusReducer, project.status);
  const [executorState, executorDispatch] = useReducer(executorReducer, project.executors);
  const [coopState, coopDispatch] = useReducer(coopReducer, project.coopStructures);
  const [mortgageLimit, setMortgageLimit] = useState<number>(project?.mortgageLimit!);
  const [mortgageMinDay, setMortgageMinDay] = useState<number>(project?.mortgageMinDay!);
  const [hideOccupancy, setHideOccupancy] = useState<boolean>(project.hideOccupancy);
  const [defaultZeroValue, setDefaultZeroValue] = useState<string>(project.defaultZeroValue);
  const [lawyer, setLawyer] = useState<string>(project.lawyer);
  const [tagLine, setTagLine] = useState<string>(project.tagLine);
  const [combinedProjects, setCombinedProjects] = useState<string[]>(
    project.combinedProjects.length ? project.combinedProjects.map((combinedProject: IProject) => combinedProject.name) : []
  );
  const [firstTentativeOccupancy, setFirstTentativeOccupancy] = useState<Date | null>(project.firstTentativeOccupancy);
  const [finalTentativeOccupancy, setFinalTentativeOccupancy] = useState<Date | null>(project.finalTentativeOccupancy);
  const [firmOccupancy, setFirmOccupancy] = useState<Date | null>(project.firmOccupancy);
  const [outsideOccupancy, setOutsideOccupancy] = useState<Date | null>(project.outsideOccupancy);
  const [salesOffice, setSalesOffice] = useState<string>(project.salesOffice);
  const [active, setActive] = useState<boolean>(project.active);
  const [acknowledgement, setAcknowledgement] = useState<boolean>(project.acknowledgement);
  const [tagValue, setTagValue] = useState<string>('');
  const [tags, setTags] = useState<string[]>(project.tags ? project.tags : []);
  const { title, type } = props;

  const projects = user.projectAccess.map((project: any) => {
    return project.project;
  });

  const { isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/jpeg, image/jpg, image/png',
  });

  // Mutation

  const [updateProject] = useMutation(UPDATEPROJECT, {
    onCompleted: (data) => {
      storeDispatch(setProject(data.projectUpdateById.record));
      storeDispatch(showSuccessSnackbar('Project Information has been updated!'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateUserProject] = useMutation(UPDATEUSER, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Project has been created'));
      navigate('/portal');
    },
    onError: (err: any) => {
      console.log(err, 'err');
    },
  });

  const [saveProject] = useMutation(CREATEPROJECT, {
    onCompleted: (data) => {
      let projectIds: any = user?.projectAccess.map((project: any) => {
        return {
          ...project,
          project: project.project._id,
        };
      });
      updateUserProject({
        variables: { _id: user?._id, record: { projectAccess: [...projectIds, { project: data.projectCreateOne._id, access: [] }] } },
      });
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const handleSubmit = (event: any) => {
    // Save Project object only if all the mandatory fields are filled
    event.preventDefault();
    if (!addresses.length) return storeDispatch(showErrorSnackbar('No Project Address Found'));
    let phone = '';
    let salesPhone = '';
    if (trackingPhone) {
      phone = trackingPhone.toString().replace(/\D/g, '');
    }
    if (salesTrackingPhone) {
      salesPhone = salesTrackingPhone.toString().replace(/\D/g, '');
    }

    let combinedProjectIds = [];

    if (combinedProjects.length) {
      combinedProjectIds = combinedProjects.map((project: string) => {
        let selectedProject = projects.find((allProject: IProject) => allProject.name === project);
        if (selectedProject) {
          return selectedProject._id;
        } else return null;
      });
    }

    if (type === 'create') {
      saveProject({
        variables: {
          record: {
            name,
            developerName,
            addresses,
            email,
            trackingPhone: parseInt(phone!, 10),
            salesTrackingPhone: parseInt(salesPhone!, 10),
            coopStructures: coopState,
            depositStructures: [],
            options: [],
            emailTemplates: [],
            combinedProjects: combinedProjectIds,
            firstTentativeOccupancy: firstTentativeOccupancy,
            finalTentativeOccupancy: finalTentativeOccupancy,
            firmOccupancy: firmOccupancy,
            outsideOccupancy: outsideOccupancy,
            active: active,
            acknowledgement: acknowledgement,
            tags,
          },
        },
      });
    } else {
      // const { _id, emailTemplates, logoGetUrl, logoPutUrl, imageGetUrl, imagePutUrl, portal, appointmentSettings, ...newObject } = project;
      updateProject({
        variables: {
          _id: project?._id,
          record: {
            name,
            developerName,
            addresses,
            email,
            trackingPhone: parseInt(phone!, 10),
            salesTrackingPhone: parseInt(salesPhone!, 10),
            executors: executorState,
            coopStructures: coopState,
            commissionIncludeOptions,
            status: statusState,
            mortgageLimit,
            mortgageMinDay,
            hideOccupancy,
            defaultZeroValue,
            lawyer,
            tagLine,
            salesOffice,
            combinedProjects: combinedProjectIds,
            firstTentativeOccupancy: firstTentativeOccupancy,
            finalTentativeOccupancy: finalTentativeOccupancy,
            firmOccupancy: firmOccupancy,
            outsideOccupancy: outsideOccupancy,
            active: active,
            acknowledgement: acknowledgement,
            tags,
          },
        },
      });
    }
  };

  const handleDrop = (acceptedFiles: any, type: string) => {
    if (acceptedFiles.length === 0) {
      storeDispatch(showErrorSnackbar('This file type is not allowed'));
      return;
    }
    const file = acceptedFiles[0];
    const fileReader = new FileReader();
    if (file) {
      fileReader.readAsDataURL(file);
    }
    fileReader.onloadend = async () => {
      if (type === 'logo') {
        setLogo(acceptedFiles[0]);
        setLogoImage(fileReader.result);
      } else if (type === 'building') {
        setBuilding(acceptedFiles[0]);
        setBuildingImage(fileReader.result);
      } else if (type === 'developer') {
        setDeveloper(acceptedFiles[0]);
        setDeveloperImage(fileReader.result);
      } else if (type === 'partner') {
        setPartner(acceptedFiles[0]);
        setPartnerImage(fileReader.result);
      }
    };
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  // Statuses and Color
  const addStatus = () => {
    statusDispatch({ type: 'ADD' });
  };

  const handleStatusInput = (event: any, numberIndex: number) => {
    statusDispatch({ type: 'UPDATE', payload: { index: numberIndex, field: event.target.name, value: event.target.value } });
  };

  const handleColorInput = (numberIndex: number, color: any) => {
    statusDispatch({ type: 'UPDATE', payload: { index: numberIndex, field: 'color', value: color } });
  };

  const handleDeleteStatus = (numberIndex: number) => {
    statusDispatch({ type: 'DELETE', payload: { index: numberIndex } });
  };

  // Executor

  const addExecutor = () => {
    executorDispatch({ type: 'ADD' });
  };

  const handleExecutorInput = (event: any, numberIndex: number) => {
    executorDispatch({ type: 'UPDATE', payload: { index: numberIndex, field: event.target.name, value: event.target.value } });
  };

  const handleExecutorCc = (cc: boolean, numberIndex: number) => {
    executorDispatch({ type: 'UPDATE', payload: { index: numberIndex, field: 'cc', value: cc } });
  };

  const handleDeleteExecutor = (numberIndex: number) => {
    executorDispatch({ type: 'DELETE', payload: { index: numberIndex } });
  };

  // Commission

  const addCoopStructure = () => {
    coopDispatch({ type: 'ADDCOOPSTRUCTURE' });
  };

  const addCoopRate = (index: number) => {
    coopDispatch({ type: 'ADDCOOPRATE', payload: { index: index } });
  };

  const deleteCoopStructure = (index: number) => {
    coopDispatch({ type: 'DELETECOOPSTRUCTURE', payload: { index: index } });
  };

  const handleDeleteCoopRate = (numberIndex: number, structureIndex: number) => {
    coopDispatch({ type: 'DELETECOOPRATE', payload: { index: numberIndex, structureIndex: structureIndex } });
  };

  const handleCoopRateInput = (event: any, numberIndex: number, type: string, structureIndex: number) => {
    coopDispatch({
      type: 'UPDATE',
      payload: {
        index: numberIndex,
        structureIndex: structureIndex,
        field: event.target.name,
        value:
          event.target.name === 'days' || event.target.name === 'percentage' || event.target.name === 'fixedAmount'
            ? parseFloat(event.target.value)
            : event.target.value,
      },
    });
  };

  const handleCoopDate = (newValue: any, numberIndex: number, structureIndex: number) => {
    coopDispatch({
      type: 'UPDATE',
      payload: {
        structureIndex: structureIndex,
        index: numberIndex,
        field: 'date',
        value: newValue,
      },
    });
  };
  // Images

  const saveImages = async () => {
    if (logo || building || developer || partner) {
      if (logo) {
        const buffer = Buffer.from(logoImage.replace(/^data:image\/\w+;base64,/, ''), 'base64');
        const options = {
          headers: { 'Content-Type': logo.type },
          maxContentLength: Infinity,
          maxBodyLength: Infinity,
        };
        await axios.put(project.logoPutUrl!, buffer, options);
      }
      if (building) {
        const buffer = Buffer.from(buildingImage.replace(/^data:image\/\w+;base64,/, ''), 'base64');
        const options = {
          headers: { 'Content-Type': building.type },
          maxContentLength: Infinity,
          maxBodyLength: Infinity,
        };
        await axios.put(project.imagePutUrl!, buffer, options);
      }
      if (developer) {
        const buffer = Buffer.from(developerImage.replace(/^data:image\/\w+;base64,/, ''), 'base64');
        const options = {
          headers: { 'Content-Type': developer.type },
          maxContentLength: Infinity,
          maxBodyLength: Infinity,
        };
        await axios.put(project.developerLogoPutUrl!, buffer, options);
      }
      if (partner) {
        const buffer = Buffer.from(partnerImage.replace(/^data:image\/\w+;base64,/, ''), 'base64');
        const options = {
          headers: { 'Content-Type': partner.type },
          maxContentLength: Infinity,
          maxBodyLength: Infinity,
        };
        await axios.put(project.partnerLogoPutUrl!, buffer, options);
      }
      await storeDispatch(showSuccessSnackbar('Image Updated'));
    } else {
      storeDispatch(showErrorSnackbar('No Image Found'));
    }
  };

  const addAddress = () => {
    setAddresses([
      ...addresses,
      {
        streetAddress: '',
        city: '',
        province: '',
        country: '',
        postalCode: '',
      },
    ]);
  };

  const handleAddress = (e: any, numIndex: number) => {
    let address = addresses.map((address: IAddress, index: number) => {
      if (index === numIndex) {
        return {
          ...address,
          [e.target.name]: e.target.value,
        };
      } else return address;
    });
    setAddresses(address);
  };

  const deleteAddress = (numIndex: number) => {
    setAddresses(addresses.filter((address: IAddress, index: number) => numIndex !== index));
  };

  const addTag = () => {
    if (tagValue) {
      setTagValue('');
      setTags([...tags, tagValue]);
    } else return storeDispatch(showErrorSnackbar('No Tag Value Found'));
  };

  const deleteTag = (numIndex: number) => {
    let filteredTags = tags.filter((tag: string, index: number) => numIndex !== index);
    setTags(filteredTags);
  };

  return (
    <form id="project" onSubmit={handleSubmit} autoComplete="off">
      <h2>{title}</h2>
      <Box
        sx={{
          border: '1px solid #000',
          p: 3,
          position: 'relative',
          borderRadius: '8px',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <TextField
              fullWidth
              label={'Project Name'}
              title={'Project Name'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
              value={name}
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <TextField
              fullWidth
              label={'Developer Name'}
              title={'Developer Name'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDeveloperName(e.target.value)}
              value={developerName}
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <TextField
              fullWidth
              label={'Email'}
              title={'Email'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              value={email}
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <TextField
              fullWidth
              label={'Online OSC Number'}
              title={'Online OSC Number'}
              type="number"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTrackingPhone(e.target.value)}
              value={trackingPhone}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <TextField
              fullWidth
              label={'Onsite Sales Office Number'}
              title={'Onsite Sales Office Number'}
              type="number"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSalesTrackingPhone(e.target.value)}
              value={salesTrackingPhone}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <TextField
              fullWidth
              label={'Sales Office'}
              title={'Sales Office'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSalesOffice(e.target.value)}
              value={salesOffice}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <TextField
              fullWidth
              label={'Lawyer'}
              title={'Lawyer'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLawyer(e.target.value)}
              value={lawyer}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <Autocomplete
              sx={{ height: '100%' }}
              multiple
              options={projects.map((project: IProject) => project.name)}
              getOptionLabel={(option: string) => option}
              isOptionEqualToValue={(option, value) => option === value}
              disableClearable={false}
              freeSolo={false}
              value={combinedProjects}
              onChange={(event: any, newValue: any | null) => {
                setCombinedProjects(newValue.map((option: string) => option));
              }}
              renderInput={(params) => <TextField {...params} label="Combined Projects" size="medium" />}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={'Tag Line'}
              title={'TagLine'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTagLine(e.target.value)}
              value={tagLine}
            />
          </Grid>
          <Grid item xs={2}>
            <SwitchButton state={active} handleChange={() => setActive(!active)} title={'Active'} />
          </Grid>
          <Grid item xs={2}>
            <SwitchButton
              state={acknowledgement}
              handleChange={() => setAcknowledgement(!acknowledgement)}
              title={'Acknowledgement Step'}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <h4>Logo</h4>
            {logoImage ? (
              <PdfCard file={logoImage} title={'Logo'} id={'0'} handleDelete={() => setLogoImage(null)} download={true} />
            ) : (
              <Dropzone onDrop={(files) => handleDrop(files, 'logo')} accept="image/jpg, image/jpeg, image/png">
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps({ style })}>
                      <input {...getInputProps()} />
                      <p>Drag and Drop or Upload the Project's Logo (.jpg)</p>
                    </div>
                  </section>
                )}
              </Dropzone>
            )}
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <h4>Building Image</h4>
            {buildingImage ? (
              <PdfCard file={buildingImage} title={'Building'} id={'0'} handleDelete={() => setBuildingImage(null)} download={true} />
            ) : (
              <Dropzone onDrop={(files) => handleDrop(files, 'building')} accept="image/jpg, image/jpeg, image/png">
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps({ style })}>
                      <input {...getInputProps()} />
                      <p>Drag and Drop or Upload the Building's Image</p>
                    </div>
                  </section>
                )}
              </Dropzone>
            )}
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <h4>Developer Logo</h4>
            {developerImage ? (
              <PdfCard
                file={developerImage}
                title={'Developer Logo'}
                id={'0'}
                handleDelete={() => setDeveloperImage(null)}
                download={true}
              />
            ) : (
              <Dropzone onDrop={(files) => handleDrop(files, 'developer')} accept="image/jpg, image/jpeg, image/png">
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps({ style })}>
                      <input {...getInputProps()} />
                      <p>Drag and Drop or Upload the Developer's Logo (.jpg)</p>
                    </div>
                  </section>
                )}
              </Dropzone>
            )}
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <h4>Partner Logo</h4>
            {partnerImage ? (
              <PdfCard file={partnerImage} title={'Partner Logo'} id={'0'} handleDelete={() => setPartnerImage(null)} download={true} />
            ) : (
              <Dropzone onDrop={(files) => handleDrop(files, 'partner')} accept="image/jpg, image/jpeg, image/png">
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps({ style })}>
                      <input {...getInputProps()} />
                      <p>Drag and Drop or Upload the Partner's Logo (.jpg)</p>
                    </div>
                  </section>
                )}
              </Dropzone>
            )}
          </Grid>
        </Grid>
        <div>
          <Button color="primary" variant="contained" onClick={() => saveImages()}>
            Save Images
          </Button>
        </div>
      </Box>
      <h2>Addresses</h2>
      <Box
        sx={{
          border: '1px solid #000',
          padding: '30px 20px',
          position: 'relative',
          borderRadius: '8px',
          marginBottom: '20px',
        }}
      >
        {addresses.length ? (
          <Grid container spacing={2}>
            {addresses.map((address: IAddress, index: number) => {
              return (
                <React.Fragment key={index}>
                  <Grid item xs={12} sm={6} md={2}>
                    <TextField
                      fullWidth
                      label={'Address'}
                      title={'Address'}
                      name="streetAddress"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAddress(e, index)}
                      value={address.streetAddress}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <TextField
                      fullWidth
                      label={'City'}
                      title={'City'}
                      name="city"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAddress(e, index)}
                      value={address.city}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <TextField
                      fullWidth
                      label={'Province'}
                      title={'Province'}
                      name="province"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAddress(e, index)}
                      value={address.province}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <TextField
                      fullWidth
                      label={'Country'}
                      title={'Country'}
                      name="country"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAddress(e, index)}
                      value={address.country}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <TextField
                      fullWidth
                      label={'Postal Code'}
                      title={'Postal Code'}
                      name="postalCode"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAddress(e, index)}
                      value={address.postalCode}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <Button variant="contained" onClick={() => deleteAddress(index)} color="error">
                      Delete
                    </Button>
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        ) : (
          <Box>
            <Box>There are currently no addresses set up.</Box>
          </Box>
        )}
        <Box sx={{ mt: 2 }}>
          <Button variant="contained" color="primary" onClick={() => addAddress()}>
            Add an Address
          </Button>
        </Box>
      </Box>
      <h2>Custom Status</h2>
      <Box
        sx={{
          border: '1px solid #000',
          padding: '30px 20px',
          position: 'relative',
          borderRadius: '8px',
          marginBottom: '20px',
        }}
      >
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography>Default Status</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              {unitStatusTitles.map((defaultStatus: IStatus, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <Status
                      type={'default'}
                      key={index}
                      numberIndex={index}
                      value={defaultStatus.name}
                      title={defaultStatus.name}
                      code={defaultStatus.code}
                      color={defaultStatus.color}
                      disabled={true}
                    />
                  </React.Fragment>
                );
              })}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Grid container spacing={2}>
          {statusState.map((status: IStatus, index: number) => {
            return (
              <React.Fragment key={index}>
                <Status
                  type={'custom'}
                  numberIndex={index}
                  key={index}
                  value={status.name}
                  title={status.name}
                  code={status.code}
                  color={status.color}
                  disabled={false}
                  handleStatusInput={handleStatusInput}
                  handleDelete={handleDeleteStatus}
                  handleColorInput={handleColorInput}
                  statusDispatch={statusDispatch}
                />
              </React.Fragment>
            );
          })}
          <Grid sx={{ mt: 1 }} item xs={12} sm={4}>
            <Button variant="contained" color="primary" onClick={() => addStatus()}>
              Insert Custom Status
            </Button>
          </Grid>
        </Grid>
      </Box>
      <h2>Executors</h2>
      <Box
        sx={{
          border: '1px solid #000',
          padding: '30px 20px',
          position: 'relative',
          borderRadius: '8px',
          marginBottom: '20px',
        }}
      >
        {executorState.length > 0 ? (
          <Grid container spacing={2}>
            {executorState.map((executor: IExecutor, index: number) => {
              return (
                <React.Fragment key={index}>
                  <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
                    <TextInput
                      name={'name'}
                      title={'Executor Name'}
                      label={'name'}
                      handleTextInput={(e: any) => handleExecutorInput(e, index)}
                      value={executor.name}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <TextInput
                      name={'email'}
                      type={'email'}
                      title={'Executor Email'}
                      label={'email'}
                      handleTextInput={(e: any) => handleExecutorInput(e, index)}
                      value={executor.email}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                    <SwitchButton state={executor.cc} handleChange={() => handleExecutorCc(!executor.cc, index)} title={'Cc on Deal'} />
                  </Grid>
                  <Grid item xs={12} sm={1} md={1} lg={1} xl={1} sx={{ cursor: 'pointer', textAlign: 'center' }}>
                    <CloseIcon color="secondary" onClick={() => handleDeleteExecutor(index)} />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        ) : (
          <Box sx={{ mb: 4 }}>
            <em>There are currently no executors for this project. Please add one below.</em>
          </Box>
        )}
        <Button sx={{ mt: 1 }} variant="contained" color="primary" onClick={() => addExecutor()}>
          Add an Executor
        </Button>
      </Box>
      <h2>Co-op Commission Structures</h2>
      <Box
        sx={{
          border: '1px solid #000',
          padding: '30px 20px',
          position: 'relative',
          borderRadius: '8px',
          marginBottom: '20px',
        }}
      >
        <Box>Please set the co-op commission structures for this project.</Box>
        {coopState.length > 0 ? (
          <div>
            <Box sx={{ my: 2 }}>
              <SwitchButton
                state={commissionIncludeOptions}
                handleChange={() => setCommissionIncludeOptions(!commissionIncludeOptions)}
                title={'Commission includes all Options (Total Price)'}
              />
            </Box>
            {coopState.map((coopStructure: ICoopStructure, index: number) => {
              return (
                <React.Fragment key={index}>
                  <Box>
                    <TextField
                      title={'Coop Structure Name'}
                      name={'name'}
                      fullWidth
                      value={coopStructure.name}
                      label={'Coop Structure Name'}
                      required
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        coopDispatch({
                          type: 'UPDATENAME',
                          payload: {
                            index: index,
                            value: e.target.value,
                          },
                        })
                      }
                    />
                  </Box>
                  <Grid container spacing={2} sx={{ mt: 2 }}>
                    {coopStructure.coopRates.map((coopRate: ICoopRate, numIndex: number) => {
                      return (
                        <Coop
                          key={index}
                          index={numIndex}
                          structureIndex={index}
                          coopRate={coopRate}
                          handleCoopType={handleCoopRateInput}
                          handleCoopInput={handleCoopRateInput}
                          handleDeleteCoop={handleDeleteCoopRate}
                          handleCoopDate={handleCoopDate}
                        />
                      );
                    })}
                  </Grid>
                  <FlexEnd sx={{ mb: 2 }}>
                    <Button sx={{ mt: 1, mr: 1 }} variant="contained" color="primary" onClick={() => addCoopRate(index)}>
                      Add Coop Rate
                    </Button>
                    <Button sx={{ mt: 1 }} variant="contained" color="error" onClick={() => deleteCoopStructure(index)}>
                      Delete Coop Structure
                    </Button>
                  </FlexEnd>
                </React.Fragment>
              );
            })}
          </div>
        ) : (
          <Box sx={{ mb: 4 }}>
            <em>There are currently no co-op rates for this project. Please add one below.</em>
          </Box>
        )}
        <Button sx={{ mt: 1 }} variant="contained" color="primary" onClick={() => addCoopStructure()}>
          Add Co-op Structure
        </Button>
      </Box>
      <h2>Mortgage</h2>
      <Box
        sx={{
          border: '1px solid #000',
          padding: '30px 20px',
          position: 'relative',
          borderRadius: '8px',
          marginBottom: '20px',
        }}
      >
        <Box sx={{ mb: 4 }}>Please set the mortgage pre-approval threshold</Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <TextInput
              name={'mortgageLimit'}
              type={'number'}
              title={'Mortgage Pre-approval Limit'}
              label={'mortgageLimit'}
              handleTextInput={(e: any) => setMortgageLimit(parseFloat(e.target.value))}
              value={mortgageLimit.toString()}
              required={true}
              endAdornment={'%'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextInput
              name={'mortgageMinDay'}
              type={'number'}
              title={'Mortgage Minimum Days'}
              label={'mortgage Min Day'}
              handleTextInput={(e: any) => setMortgageMinDay(parseFloat(e.target.value))}
              value={mortgageMinDay.toString()}
              required={true}
            />
          </Grid>
        </Grid>
      </Box>
      <h2>Occupancy</h2>
      <Box
        sx={{
          border: '1px solid #000',
          padding: '30px 20px',
          position: 'relative',
          borderRadius: '8px',
          marginBottom: '20px',
        }}
      >
        <Box sx={{ mb: 2 }}>Please set the Occupancy Dates</Box>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item sm={3} xs={12}>
              <DatePicker
                views={['year', 'month', 'day']}
                label={'First Tentative Occupancy Date (YYYY/MM/DD)'}
                value={firstTentativeOccupancy ? new Date(firstTentativeOccupancy) : null}
                onChange={(newValue) => setFirstTentativeOccupancy(newValue)}
                sx={{ width: '100%'}}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <DatePicker
                views={['year', 'month', 'day']}
                label={'Final Tentative Occupancy Date'}
                value={finalTentativeOccupancy ? new Date(finalTentativeOccupancy) : null}
                onChange={(newValue) => setFinalTentativeOccupancy(newValue)}
                sx={{ width: '100%'}}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <DatePicker
                views={['year', 'month', 'day']}
                label={'Firm Occupancy Date'}
                value={firmOccupancy ? new Date(firmOccupancy) : null}
                onChange={(newValue) => setFirmOccupancy(newValue)}
                sx={{ width: '100%'}}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <DatePicker
                views={['year', 'month', 'day']}
                label={'Outside Occupancy Date'}
                value={outsideOccupancy ? new Date(outsideOccupancy) : null}
                onChange={(newValue) => setOutsideOccupancy(newValue)}
                sx={{ width: '100%'}}
              />
            </Grid>
          </Grid>
        </LocalizationProvider>
      </Box>
      <h2>Miscellaneous</h2>
      <Box
        sx={{
          border: '1px solid #000',
          padding: '30px 20px',
          position: 'relative',
          borderRadius: '8px',
          marginBottom: '20px',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <SwitchButton
              state={hideOccupancy}
              handleChange={() => setHideOccupancy(!hideOccupancy)}
              title={'Hide Occupancy on Sent Deal Email'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              label={'Default Zero Value Option Merge Field'}
              title={'Default Zero Value Option Merge Field'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDefaultZeroValue(e.target.value)}
              value={defaultZeroValue}
              required={true}
            />
          </Grid>
        </Grid>
        <Box>
          <Typography sx={{ mb: 2 }} variant="h2" gutterBottom>
            <strong>Tags</strong>
          </Typography>
          <Box>
            <TextField
              fullWidth
              label="Enter a Tag"
              variant="outlined"
              value={tagValue}
              onChange={(e) => setTagValue(e.target.value)}
              size="medium"
            />
            <Button sx={{ mt: 2 }} variant="contained" color="primary" onClick={() => addTag()}>
              Add Tag
            </Button>
          </Box>
          {tags.length ? (
            <Grid container spacing={2}>
              {tags.map((tag: string, index: number) => {
                return (
                  <Grid key={index} item sx={{ mt: 2 }}>
                    <Box sx={{ display: 'flex', p: 1, border: '1px solid #000', borderRadius: '8px' }}>
                      {tag}
                      <CloseIcon sx={{ ml: 1, cursor: 'pointer' }} onClick={() => deleteTag(index)} />
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          ) : null}
        </Box>
      </Box>
      <Box sx={{ my: 2, py: 2 }}>
        <Button form="project" type="submit" variant="contained" color="success">
          Save Project
        </Button>
      </Box>
    </form>
  );
};

interface ChildProps {
  title: string;
  type: string;
}

const CREATEPROJECT = gql`
  mutation projectCreateOne($record: CreateOneProjectInput!) {
    projectCreateOne(record: $record) {
      _id
      name
      developerName
      addresses {
        streetAddress
        city
        province
        postalCode
        country
      }
      email
      trackingPhone
      salesTrackingPhone
      mergeTemplates {
        name
        mergeTemplate {
          _id
        }
        apsTemplates {
          name
          pageNumber
        }
      }
      options {
        name
        totalAvailable
        price
        type
        allowedUnits
      }
      depositStructures {
        name
        deposits {
          name
          type
          amount
          daysDue
          dateType
          dueDate
        }
        default
      }
      status {
        name
        code
        color
      }
      tags
    }
  }
`;

const UPDATEUSER = gql`
  mutation userUpdateById($_id: MongoID!, $record: UpdateByIdUserInput!) {
    userUpdateById(_id: $_id, record: $record) {
      record {
        _id
        fullName
        projectAccess {
          project {
            _id
          }
          access
        }
      }
    }
  }
`;

const UPDATEPROJECT = gql`
  mutation projectUpdateById($_id: MongoID!, $record: UpdateByIdProjectInput!) {
    projectUpdateById(_id: $_id, record: $record) {
      record {
        _id
        name
        developerName
        email
        trackingPhone
        salesTrackingPhone
        addresses {
          streetAddress
          city
          province
          postalCode
          country
        }
        options {
          name
          totalAvailable
          price
          allowedUnits
          type
        }
        depositStructures {
          name
          deposits {
            name
            type
            amount
            daysDue
            dateType
            dueDate
          }
          default
        }
        mergeTemplates {
          name
          mergeTemplate {
            name
            _id
          }
          apsTemplates {
            name
            apsTemplate {
              _id
              name
              type
            }
            pageNumber
            attachToAps
          }
        }
        status {
          name
          code
          color
        }
        executors {
          name
          email
          cc
        }
        mortgageLimit
        mortgageMinDay
        hideOccupancy
        defaultZeroValue
        lawyer
        tagLine
        tags
        salesOffice
        adjustments {
          name
          type
        }
        commissionIncludeOptions
        emailTemplates {
          _id
          name
          subject
          html
        }
        logoGetUrl
        logoPutUrl
        imageGetUrl
        imagePutUrl
        developerLogoGetUrl
        developerLogoPutUrl
        partnerLogoGetUrl
        partnerLogoPutUrl
        coopStructures {
          name
          coopRates {
            type
            days
            date
            description
            percentage
            fixedAmount
          }
        }
        firstTentativeOccupancy
        finalTentativeOccupancy
        firmOccupancy
        outsideOccupancy
        active
        acknowledgement
        decorModels {
          modelType
          allowed
        }
        portal {
          primaryColor
        }
        combinedProjects {
          _id
          name
        }
      }
    }
  }
`;

export default GeneralInfo;
