import { useState, useReducer, useContext } from 'react';
import { useSelector } from 'react-redux';
import { LeaseContext } from '../../../context/LeaseContext';
import { Box, Typography, Button, TextField, Grid, InputAdornment, Fade } from '@mui/material';

import { UnitContext } from '../../../context/UnitContext';
import { purchasersReducer } from '../../../utils/Reducers';
import Purchasers from './Purchasers';
import { selectProject } from '../../../features/project/projectSlice';
import { IPurchaserInfo } from '../../../types/CreateDealForm';

const Landlord = () => {
  const project = useSelector(selectProject);
  const { lease, createLease, setAgreementType } = useContext(LeaseContext);
  const { filteredDeal, unit, assignment } = useContext(UnitContext);
  const [landlordsState, landlordsDispatch] = useReducer(purchasersReducer, lease && lease.landlords ? lease.landlords : []);
  const [type, setType] = useState<boolean | null>(null);

  const handleLandlord = (type: boolean) => {
    if (type === false) {
      setType(type);
    } else {
      createLease({
        variables: {
          record: {
            active: true,
            project: project._id,
            unit: unit._id,
            landlords: assignment
              ? assignment.purchasers.map((purchaser: IPurchaserInfo) => purchaser._id)
              : filteredDeal.purchasers.map((purchaser: IPurchaserInfo) => purchaser._id),
            management: null,
            listing: null,
            tenancy: null,
            documents: [],
          },
        },
      });
      setAgreementType('summary');
    }
  };

  return (
    <Box>
      {type === null && filteredDeal && !lease ? (
        <Fade in={true} timeout={2000}>
          <Box sx={{ textAlign: 'center' }}>
            <strong>Will all the current purchasers be the landlord of this new lease?</strong>
            <Box sx={{ mt: 2 }}>
              <Button sx={{ mr: 2 }} variant="contained" onClick={() => handleLandlord(true)} color="success">
                Yes
              </Button>
              <Button variant="contained" onClick={() => handleLandlord(false)} color="error">
                No
              </Button>
            </Box>
          </Box>
        </Fade>
      ) : type === false || !filteredDeal || lease ? (
        <Box sx={{ mt: 2 }}>
          <Purchasers title="Landlords" purchasersState={landlordsState} dispatch={landlordsDispatch} />
        </Box>
      ) : null}
    </Box>
  );
};

export default Landlord;
