import { Box, Paper, Card, CardMedia, CardContent, Typography } from '@mui/material';
import React from 'react';

const LeaseMenu = (props: ChildProps) => {
  const { handleAgreementType } = props;
  const menu = [
    {
      type: 'rentalManagement',
      title: 'Rental Management Agreement',
      url: 'https://rdsimages.s3.ca-central-1.amazonaws.com/rental-image.jpg',
      description:
        'A Rental Management Agreement is a contract between a property owner and the company or person hired to manage the property. This contract covers all of the responsibilities that a management company is taking on for the owner',
    },
    {
      type: 'listing',
      title: 'Listing Agreement',
      url: 'https://rdsimages.s3.ca-central-1.amazonaws.com/listing-image.jpg',
      description:
        'The Listing Agreement is the contract between you and the brokerage that permits them to market and sell your home. These agreements should be in writing in order to protect the interest of all parties.',
    },
    {
      type: 'tenancy',
      title: 'Tenancy Agreement',
      url: 'https://rdsimages.s3.ca-central-1.amazonaws.com/tenancy-image.jpg',
      description:
        'Also known as Standard Form Of Lease. The Tenancy Agreement is the contract between you and the tenant. The Tenanacy Agreement is a way to document things such as who the parties are (landlord and tenant), the address of the rental unit, the rent amount and when it is due, and what is included (or not included) with the rental unit.',
    },
  ];

  return (
    <div>
      <Box>
        <Box sx={{ textAlign: 'center' }}>
          <h2>Select an Agreement to Start</h2>
        </Box>
        <Box sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center', height: '100%' }}>
          {menu.map((agreement: IMenu, index: number) => {
            return (
              <Paper
                key={index}
                elevation={12}
                sx={{
                  mx: 2,
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                  transition: 'transform 0.2s ease-in-out',
                }}
              >
                <Card onClick={() => handleAgreementType(agreement.type)} sx={{ maxWidth: 345, height: '100%' }}>
                  <CardMedia component="img" height="300" image={agreement.url} alt="green iguana" />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      <strong>{agreement.title}</strong>
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {agreement.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Paper>
            );
          })}
        </Box>
      </Box>
    </div>
  );
};

interface ChildProps {
  handleAgreementType: any;
}

interface IMenu {
  title: string;
  url: string;
  description: string;
  type: string;
}

export default LeaseMenu;
