import { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TextField, Box, Button, Grid, Autocomplete, FormControl, Select, InputLabel, MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../features/auth/authSlice';
import { IProject } from '../../../types/project';
import { useAppDispatch } from '../../../app/hooks';
import { showErrorSnackbar } from '../../../features/snackbar/snackbarSlice';
import { docusignStatus } from '../../../utils/Constants';
import { IDocuments } from '../../../types/docusign';
import LoadingLogo from '../../common/LoadingLogo';
import { convertAllDates, downloadExcel, downloadPdf } from '../../../utils/Functions';
import StandardTable from '../../tables/StandardTable';

const Envelopes = () => {
  const user = useSelector(selectUser);
  const storeDispatch = useAppDispatch();
  const [dateStart, setDateStart] = useState<Date | null>(new Date(new Date().setHours(0, 0, 0, 0)));
  const [dateEnd, setDateEnd] = useState<Date | null>(new Date());
  const [project, setProject] = useState<IProject | null>(null);
  const [status, setStatus] = useState<string>('');
  const [documents, setDocuments] = useState<IDocuments[]>([]);

  const [getEnvelopes, { loading }] = useLazyQuery(ENVELOPES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setDocuments(data.getEnvelopes.filter((envelope: IDocuments) => envelope.deal));
    },
  });

  const columns = useMemo(() => {
    return [
      {
        Header: 'Suite',
        accessor: (rowData: any) => {
          return (
            <Link
              style={{ textDecoration: 'none', color: 'inherit' }}
              rel="noopener noreferrer"
              target="_blank"
              to={`/${rowData.deal.project?._id}/dashboard/${rowData.deal.unit._id}`}
            >
              <strong style={{ cursor: 'pointer' }}>{rowData.deal.unit.suite}</strong>
            </Link>
          );
        },
      },
      {
        Header: 'Envelope Name',
        accessor: (rowData: any) => rowData.name,
      },
      {
        Header: 'Status',
        accessor: (rowData: any) => rowData.status,
      },
      {
        Header: 'Envelope Created Date',
        accessor: (rowData: any) => convertAllDates(rowData.createdAt, 'PPpp'),
      },
    ];
  }, []);

  const handleEnvelopes = () => {
    if (!project) return storeDispatch(showErrorSnackbar('Project Required'));
    if (!status) return storeDispatch(showErrorSnackbar('Status Required'));
    getEnvelopes({ variables: { project: project?._id, status, dateStart, dateEnd } });
  };

  const download = (type: string, data: any) => {
    let headers = [
      {
        label: 'Suite',
        id: 'suite',
      },
      {
        label: 'Envelope Name',
        id: 'name',
      },
      {
        label: 'Status',
        id: 'status',
      },
      {
        label: 'Enveloped Created Date',
        id: 'createdAt',
      },
    ];

    let widths = {
      suite: 15,
      name: 15,
      status: 15,
      createdAt: 15,
    };

    let pdfWidths = {
      suite: 200,
      name: 200,
      status: 200,
      createdAt: 200,
    };

    let newData = data.map((data: any) => {
      return {
        suite: data.deal.unit.suite,
        name: data.name,
        status: data.status,
        createdAt: convertAllDates(data.createdAt, 'PPpp'),
      };
    });

    let sheetTitle = `${project ? project.name : 'Documents'}`;

    if (type === 'excel') {
      downloadExcel([newData], [headers], [], [[widths]], [sheetTitle], sheetTitle);
    } else {
      downloadPdf([newData], [headers], [], [pdfWidths], [sheetTitle], sheetTitle);
    }
  };

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              sx={{ height: '100%' }}
              options={[user?.projectAccess!.map((projectAccess: any) => projectAccess?.project)].flat()}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              disableClearable={false}
              freeSolo={false}
              value={project}
              onChange={(e, value) => {
                setProject(value!);
              }}
              renderInput={(params) => <TextField {...params} label="Project" size="medium" />}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <InputLabel id="id-question-label">Docusign Status</InputLabel>
              <Select
                value={status}
                labelId="id-status-label"
                id="id-status"
                label="Docusign Status"
                name="status"
                onChange={(e) => setStatus(e.target.value)}
              >
                {docusignStatus.map((status: string) => {
                  return <MenuItem value={status}>{status}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <DateTimePicker
              label={'Document Created Start Date (YYYY/MM/DD)'}
              value={dateStart}
              onChange={(newValue) => {
                setDateStart(newValue);
              }}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <DateTimePicker
              label={'Document Created End Date (YYYY/MM/DD)'}
              value={dateEnd}
              onChange={(newValue) => {
                setDateEnd(newValue);
              }}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button sx={{ height: '100%' }} variant="contained" color="primary" onClick={() => handleEnvelopes()}>
              Search
            </Button>
          </Grid>
        </Grid>
      </LocalizationProvider>
      {loading ? (
        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          <LoadingLogo />
        </Box>
      ) : documents.length ? (
        <Box>
          <Box>
            <StandardTable data={documents} columns={columns} download={download} />
          </Box>
        </Box>
      ) : (
        <Box sx={{ textAlign: 'center' }}>
          <em>No Envelopes Found!</em>
        </Box>
      )}
    </Box>
  );
};

const ENVELOPES = gql`
  query getEnvelopes($project: MongoID!, $status: String!, $dateStart: Date!, $dateEnd: Date!) {
    getEnvelopes(project: $project, status: $status, dateStart: $dateStart, dateEnd: $dateEnd) {
      _id
      name
      createdAt
      status
      deal {
        project {
          name
          _id
        }
        unit {
          _id
          suite
        }
      }
    }
  }
`;

export default Envelopes;
