import { useState, useMemo } from 'react';
import { gql } from '@apollo/client';
import BasicTabs from '../../common/Tabs';
import { Container } from '../../../commonStyles';
import Tenancy from './reports/Tenancy';
import Listing from './reports/Listing';
import RentalManagement from './reports/RentalManagement';

const Report = () => {
  const [dateStart, setDateStart] = useState<Date | null>(new Date(new Date().setHours(0, 0, 0, 0)));
  const [dateEnd, setDateEnd] = useState<Date | null>(new Date());
  const [value, setValue] = useState<number>(0);

  const tabs = useMemo(() => {
    return [
      {
        label: 'Tenancy',
        component: <Tenancy />,
      },
      {
        label: 'Listing',
        component: <Listing />,
      },
      {
        label: 'Rental Management',
        component: <RentalManagement />,
      },
    ];
  }, []);

  return (
    <Container sx={{ backgroundColor: '#fff', pt: 0, px: 0 }}>
      <BasicTabs color="secondary.main" tabs={tabs} sub={false} value={value} setValue={setValue} />
    </Container>
  );
};

const LEASE = gql`
  query getDailySummary($projects: [MongoID], $dateStart: Date!, $dateEnd: Date!) {
    getDailySummary(projects: $projects, dateStart: $dateStart, dateEnd: $dateEnd) {
      deals {
        project {
          _id
          name
          createdAt
          portal {
            primaryColor
          }
        }
        cancelled
        rescission
        newSales
        newDeals
        statuses {
          _id
          count
          size
          revenue
          revenueNet
        }
      }
    }
  }
`;

export default Report;
