import { useState, useReducer, useContext } from 'react';
import { Box, Typography, Button, FormControl, FormControlLabel, FormGroup, Checkbox, TextField, Grid, Autocomplete } from '@mui/material';
import { IUser } from '../../../types/user';
import { purchasersReducer } from '../../../utils/Reducers';
import { Container, FlexEnd } from '../../../commonStyles';
import { LeaseContext } from '../../../context/LeaseContext';
import Purchasers from './Purchasers';
import Dropdown from '../../common/formControls/Dropdown';
import CustomDialog from '../../common/CustomDialog';
import { IDocuments } from '../../../types/docusign';
import { useAppDispatch } from '../../../app/hooks';
import { showErrorSnackbar } from '../../../features/snackbar/snackbarSlice';

const Management = (props: ChildProps) => {
  const storeDispatch = useAppDispatch();
  const { users } = props;
  const { saveManagement, deleteManagement, lease } = useContext(LeaseContext);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<string[]>(lease && lease.management ? lease.management.options : ['N/A', 'N/A', 'N/A', 'N/A']);
  const [insurance, setInsurance] = useState<boolean>(lease && lease.management ? lease.management.insurance : false);
  const [rec, setRec] = useState<boolean>(lease && lease.management ? lease.management.rec : false);
  const [reRentalFee, setReRentalFee] = useState<string>(lease && lease.management ? lease.management.reRentalFee : '100.00%');
  const [inspection, setInspection] = useState<string>(lease && lease.management ? lease.management.inspection : '$50.00');
  const [leaseRenewal, setLeaseRenewal] = useState<string>(lease && lease.management ? lease.management.leaseRenewal : '$200.00');
  const [startupFee, setStartupFee] = useState<string>(lease && lease.management ? lease.management.startupFee : '100.00%');
  const [managementFee, setManagementFee] = useState<string>(
    lease && lease.management
      ? lease.management.managementFee
      : '6% of the rent per month, plus applicable taxes, of the gross rentals charged by the Manager in respect of the Property for such month.'
  );
  const [executor, setExecutor] = useState<IUser | null>(lease && lease.management ? lease.management.executor : null);

  const handleOptionNumber = (e: any, index: number) => {
    let optionsArray = options.map((option, numIndex) => {
      if (index === numIndex) {
        return e;
      } else return option;
    });
    setOptions(optionsArray);
  };

  const handleDropdownInput = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
    let selectedUser = users.find((user: IUser) => user.fullName === event.target.value);
    if (selectedUser) {
      setExecutor(selectedUser);
    }
  };

  const handleDeleteManagement = () => {
    if (lease.documents.find((document: IDocuments) => document.type === 'Management'))
      return storeDispatch(showErrorSnackbar('There is currently a Property Management Document. Please delete the envelope to proceed.'));
    deleteManagement(lease);
  };

  return (
    <Box>
      <CustomDialog
        handleClose={() => setDialogOpen(false)}
        handleCloseRemove={() => setDialogOpen(false)}
        handleCloseSuccess={() => handleDeleteManagement()}
        open={dialogOpen}
        removeButton={'Cancel'}
        successButton={'Yes, I would like to delete Rental Management Profile'}
        dialogContent={<Box sx={{ my: 2 }}>Are you sure you would like to delete the Rental Management Profile?</Box>}
        dialogTitle={'Delete Rental Management Profile'}
      />
      {lease && lease.management ? (
        <FlexEnd sx={{ mt: 2 }}>
          <Button variant="contained" color="error" onClick={() => setDialogOpen(true)}>
            Delete Management
          </Button>
        </FlexEnd>
      ) : null}
      <form
        onSubmit={(e) =>
          saveManagement(
            e,
            insurance,
            options,
            rec,
            executor,
            reRentalFee,
            inspection,
            leaseRenewal,
            startupFee,
            managementFee
          )
        }
      >
        <Container sx={{ mt: 2 }}>
          <Typography variant={'h5'}>Options</Typography>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Autocomplete
                options={['N/A', 'TBD']}
                getOptionLabel={(option: any) => option}
                disableClearable={false}
                freeSolo={true}
                value={options[0]}
                onChange={(event: any, newValue: any | null) => {
                  if (newValue) {
                    handleOptionNumber(newValue, 0);
                  }
                }}
                onInputChange={(e: any, newValue) => {
                  handleOptionNumber(newValue, 0);
                }}
                renderInput={(params) => <TextField required {...params} label="Parking #1" size="small" />}
              />
            </Grid>
            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Autocomplete
                options={['N/A', 'TBD']}
                getOptionLabel={(option: any) => option}
                disableClearable={false}
                freeSolo={true}
                value={options[1]}
                onChange={(event: any, newValue: any | null) => {
                  if (newValue) {
                    handleOptionNumber(newValue, 1);
                  }
                }}
                renderInput={(params) => <TextField required {...params} label="Parking #2" size="small" />}
              />
            </Grid>
            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Autocomplete
                options={['N/A', 'TBD']}
                getOptionLabel={(option: any) => option}
                disableClearable={false}
                freeSolo={true}
                value={options[2]}
                onChange={(event: any, newValue: any | null) => {
                  if (newValue) {
                    handleOptionNumber(newValue, 2);
                  }
                }}
                renderInput={(params) => <TextField required {...params} label="Locker #1" size="small" />}
              />
            </Grid>
            <Grid item lg={2} md={3} sm={6} xs={12}>
              <Autocomplete
                options={['N/A', 'TBD']}
                getOptionLabel={(option: any) => option}
                disableClearable={false}
                freeSolo={true}
                value={options[3]}
                onChange={(event: any, newValue: any | null) => {
                  if (newValue) {
                    handleOptionNumber(newValue, 3);
                  }
                }}
                renderInput={(params) => <TextField required {...params} label="Locker #2" size="small" />}
              />
            </Grid>
          </Grid>
        </Container>
        <Container sx={{ mt: 2 }}>
          <Typography variant={'h5'}>Miscellaneous</Typography>
          <Box>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  control={<Checkbox checked={insurance} onChange={() => setInsurance(!insurance)} />}
                  label={`Opt in for insurance?`}
                  labelPlacement="end"
                />
                <FormControlLabel control={<Checkbox checked={rec} onChange={() => setRec(!rec)} />} label={`REC?`} labelPlacement="end" />
              </FormGroup>
            </FormControl>
            <Grid spacing={2} container sx={{ mt: 1 }}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  title={'Re Rental Fee'}
                  name={'reRentalFee'}
                  fullWidth
                  value={reRentalFee}
                  required
                  label={'Re Rental Fee'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setReRentalFee(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  title={'Inspection'}
                  name={'inspection'}
                  fullWidth
                  value={inspection}
                  required
                  label={'Inspection'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInspection(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  title={'Tenant Lease Renewal'}
                  name={'leaseRenewal'}
                  fullWidth
                  value={leaseRenewal}
                  required
                  label={'Tenant Lease Renewal'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLeaseRenewal(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  title={'Start Up Fee'}
                  name={'startupFee'}
                  fullWidth
                  value={startupFee}
                  required
                  label={'Start Up Fee'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setStartupFee(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  title={'Management Fee'}
                  name={'managementFee'}
                  fullWidth
                  multiline
                  rows={5}
                  value={managementFee}
                  required
                  label={'Management Fee'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setManagementFee(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}></Grid>
              <Grid item xs={12} md={6}>
                <Dropdown
                  required
                  title={'User to execute'}
                  menuList={users.map((user: IUser) => user.fullName)}
                  name={'executor'}
                  handleSelect={(e: any) => handleDropdownInput(e)}
                  value={executor ? executor.fullName : ''}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
        <FlexEnd sx={{ mt: 2 }}>
          <Button variant="contained" color="success" type="submit">
            Save Managment Agreement
          </Button>
        </FlexEnd>
      </form>
    </Box>
  );
};

interface ChildProps {
  users: IUser[];
}

export default Management;
