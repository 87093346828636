import React, { useContext } from 'react';
import { Box, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import { LeaseContext } from '../../../context/LeaseContext';
import { ILease } from '../../../types/lease';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { convertAllDates } from '../../../utils/Functions';
import Summary from './Summary';

const LeaseList = () => {
  const { leases } = useContext(LeaseContext);

  return (
    <Box sx={{ mt: 2 }}>
      {leases.filter((lease: ILease) => !lease.active).length ? (
        leases
          .filter((lease: ILease) => !lease.active)
          .map((lease: ILease) => {
            return (
              <Accordion sx={{ mt: 2 }} elevation={24}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography>
                    <strong>Lease - {convertAllDates(lease.createdAt, 'PP')}</strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ mt: 2 }}>
                  <Summary lease={lease} />
                </AccordionDetails>
              </Accordion>
            );
          })
      ) : (
        <Box sx={{ mt: 2 }}>There are currently no past leases</Box>
      )}
    </Box>
  );
};

export default LeaseList;
