import { useContext } from 'react';
import { TextField, Grid } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Dropdown from '../../../common/formControls/Dropdown';
import { CreateDealContext } from '../../../../context/CreateDealContext';

const dealStatus = ['A', 'O', 'S', 'C', 'F'];

const UploadForm = () => {
  const { preparedDate, setPreparedDate, signDate, setSignDate, executeDate, setExecuteDate, firmDate, setFirmDate, status, setStatus } =
    useContext(CreateDealContext);

  const handleStatus = (event: any) => {
    setStatus(event.target.value);
    setFirmDate(null);
  };

  const handleDateChange = (date: Date | null, type: string) => {
    if (type === 'preparedDate') {
      setPreparedDate(date);
    } else if (type === 'signDate') {
      setSignDate(date);
    } else if (type === 'executeDate') {
      setExecuteDate(date);
    } else if (type === 'firmDate') {
      setFirmDate(date);
    }
  };

  return (
    <div>
      <h2>Status</h2>
      <Dropdown
        title={'Select Status'}
        menuList={dealStatus}
        name={'status'}
        handleSelect={(e: any) => handleStatus(e)}
        value={status ? status : ''}
      />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item sm={3} xs={12}>
            <DatePicker
              label={'Prepared Date (YYYY/MM/DD)'}
              views={['year', 'month', 'day']}
              value={preparedDate}
              onChange={(newValue) => handleDateChange(newValue, 'preparedDate')}
              sx={{ width: '100%'}}
            />
          </Grid>
          {status === 'C' || status === 'F' ? (
            <Grid item sm={3} xs={12}>
              <DatePicker
                views={['year', 'month', 'day']}
                label={'Sign Date (YYYY/MM/DD)'}
                value={signDate}
                onChange={(newValue) => handleDateChange(newValue, 'signDate')}
                sx={{ width: '100%'}}
              />
            </Grid>
          ) : null}
          {status === 'C' || status === 'F' ? (
            <Grid item sm={3} xs={12}>
              <DatePicker
                views={['year', 'month', 'day']}
                label={'Execute Date (YYYY/MM/DD)'}
                value={executeDate}
                onChange={(newValue) => handleDateChange(newValue, 'executeDate')}
                sx={{ width: '100%'}}
              />
            </Grid>
          ) : null}
          {status === 'F' ? (
            <Grid item sm={3} xs={12}>
              <DatePicker
                views={['year', 'month', 'day']}
                label={'Firm Date (YYYY/MM/DD)'}
                value={firmDate}
                onChange={(newValue) => handleDateChange(newValue, 'firmDate')}
                sx={{ width: '100%'}}
              />
            </Grid>
          ) : null}
        </Grid>
      </LocalizationProvider>
    </div>
  );
};

export default UploadForm;
